import { createColumnHelper } from '@tanstack/react-table';
import { IEJOColumns } from '../utils/types';


const columnHelper = createColumnHelper<IEJOColumns>();

const ejoPendingApprovalColumns = [
  
  columnHelper.accessor('ejoNumber', {
    id: 'ejoNumber',
    header: 'EJO I.D.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('projectLocation', {
    id: 'projectLocation',
    header: 'Project Location',
    cell: (row) => row.getValue(),
   enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('serviceRequestNo', {
    id: 'serviceRequestNo',
    header: 'SR No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('serviceRequestType', {
    id: 'serviceRequestType',
    header: 'Service Request Type',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('remarks', {
    id: 'remarks',
    header: 'Remarks',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
   enableColumnFilter: true,
  }),
  columnHelper.accessor('daysElapsed', {
    id: 'daysElapsed',
    header: 'Days Elapsed',
    cell: (row) => row.getValue(),
   enableGlobalFilter: true,
    enableColumnFilter: true,
  })
];

export default ejoPendingApprovalColumns;
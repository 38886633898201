import { useMutation, useQuery } from '@tanstack/react-query';
import MechanicApi from '../services/mechanic';
import { useSnackBar } from '../components/ToastProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { IMechanic, ICreateMechanic, ExportMechanic , IImportMechanic} from '../modules/DatabaseManagement/PersonnelDBs/Mechanic/utils/Mechanic-utils';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';

interface ApiError {
  status: number;
  message: string;
}

interface useGetIMechanicOptions {
  name?: string;
}

export function useGetMechanicQuery(options?: useGetIMechanicOptions) {
  return useQuery<IMechanic[]>(['Mechanic'], () => MechanicApi.getMechanics(), {
    onError(err) {
      console.log(err);
    },
    select(Mechanic) {
      return options?.name ? Mechanic.filter(l => l.name === options?.name) : Mechanic;
    },
  });
}


export function useGetIMechanicById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`Mechanic-${id}`, id], () => MechanicApi.geMechanicsById(id!));
}

export const useExportMechanic = (data: ExportMechanic[]) => {
  const newMechanicList = [];
  
  const MechanicList = data;

  for(let i in MechanicList){
    let json = {
      name: MechanicList[i].name,
      type: MechanicList[i].type,
      isActive: MechanicList[i].isActive,
    }
    newMechanicList.push(json);
  }
  return newMechanicList;
}

export function useImportMechanic() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((newMechanicData: IImportMechanic[]) => MechanicApi.importMechanics(newMechanicData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['Mechanic'] }).then(() => {
        
        if( data[1].invalidData.length >= 1){
             showSnackBar(data[1].invalidData[0].errorReason, 'error');
             setTimeout(() => {
              window.location.reload();
            }, 500);
        }
        else{
          showSnackBar('Successfully Inserted Mechanics', 'success');
          navigate('/mechanic', { replace: true });
          window.location.reload();
        }
     
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}


export function useUpdateMechanic() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newMechanicData: Partial<ICreateMechanic>) => MechanicApi.updateMechanic(id!, newMechanicData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['Mechanic'] }).then(() => {
        showSnackBar('Successfully Updated Mechanic', 'success');
        setTimeout(() => {
          navigate('/mechanic', { replace: true });
        }, 500);
   
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useDeleteMechanic(data: any, id: string) {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((data: any) => MechanicApi.updateMechanic(id!, data), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['Mechanic'] }).then(() => {        
        showSnackBar('Successfully Deleted Mechanic', 'success');
        setTimeout(() => {
          navigate('/mechanic', { replace: true });
        }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useCreateMechanic() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newMechanicData: Partial<ICreateMechanic>) => MechanicApi.createMechanic(newMechanicData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['Mechanic'] }).then(() => {
          showSnackBar('Successfully Inserted Mechanic', 'success');
          setTimeout(() => {
            navigate('/mechanic', { replace: true });
          }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}
import { useNavigate, useParams, Outlet, Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { flexRender } from "@tanstack/react-table";
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from "../../../../components/common/app-table/ui";
import useDefaultTable from "../../../../components/common/app-table/useDefaultTable";
import LoadingIndicator from "../../../../components/common/LoadingIndicator";
import AddActivityButton from "../ui/AddActivityButton";
import { appColors } from "../../../../theme";
import { IEURActivity } from "../utils/types";
import { useCreateColumns } from "../hooks/useCreateColumns";
import { useGetEURActivitiesByEURNo } from "../../../../hooks/useEURActivities";
import { useSMRContext } from "../Context/SMRContext";
import { useEffect } from "react";
import { useEURView } from "../hooks/useGetLocationOrParams";
import React from "react";

const EURActivityTable = () => {
  const { setOpenDialog, newActivity, setType, type, setNewActivity } =
    useSMRContext();

  const isViewing = useEURView();
  const columns = useCreateColumns();
  const activityList = useGetEURActivitiesByEURNo();

  const activities: IEURActivity[] = activityList.data
    ? activityList.data
    : newActivity;
  let errorData = localStorage.getItem("ErrorActivityData");
  useEffect(() => {
    if (activityList.data) setNewActivity(activities);
  }, [activityList.data]);

  useEffect(() => {
    if (errorData) {
      const parsedData: IEURActivity[] = JSON.parse(errorData);
      setNewActivity(parsedData);
    }
  }, [errorData]);

  const goToAddActivityEUR = () => {
    setOpenDialog(true);
    setType("new");
  };

  const { table, dataCount, searchFilter, pageIndex, pageSize } =
    useDefaultTable<IEURActivity>(newActivity, columns);

  const emptyText = searchFilter ? "No data found" : "No data yet";

  if (activityList?.isLoading) return <LoadingIndicator show />;

  return (
    <>
      <Stack direction="column" mb={2} mt={5} gap={3}>
        <Stack direction="row" justifyContent="space-between">
          <AddActivityButton
            onClick={goToAddActivityEUR}
            disabled={isViewing ? true : false}
          />
        </Stack>
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align="center">
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody
              dataCount={dataCount}
              // loading={loading}
              emptyText={emptyText}
            >
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align="center">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position="relative">
          <TablePagination
            sx={{ bgcolor: "white", borderTop: "1px solid #eee" }}
            component="div"
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) =>
              table.setPageSize(Number(e.target.value))
            }
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
      <Outlet />
    </>
  );
};

export default React.memo(EURActivityTable);

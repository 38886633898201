import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useDeleteServiceRequestType } from '../../../../../hooks/useServiceRequestType';
import { ISRT } from './serviceRequestType-utils';

interface Ownprops {
    serviceRequestType: ISRT; 
  }

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ServiceRequestTypeModal = ({ serviceRequestType, setOpen, open }: Ownprops & { setOpen: React.Dispatch<React.SetStateAction<boolean>>, open: boolean }) => {
 
  const { id, requestName } = serviceRequestType;
  const data = {
    isDeleted: true
  }
  const deleteSRT = useDeleteServiceRequestType(data, id);

  const handleClose = () => setOpen(false);
  const handleDelete = () => {
    deleteSRT.mutate(data);
    setOpen(false);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Are you sure you want to delete {requestName} ?
            </Typography>
            <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button style={{marginRight: '10px'}} color='primary' onClick={handleClose}>CANCEL</Button>
                <Button color='warning' onClick={()=> handleDelete()}>DELETE</Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ServiceRequestTypeModal;

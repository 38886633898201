import { ICreateOperetorLicenses } from '../modules/DatabaseManagement/PersonnelDBs/HEOperatorCertificateType/utils/HEOperatorCertificateType-utils';
import { apiClient, apiEndpoints } from './apiClient';
import { IOperatorLicenses } from '../modules/DatabaseManagement/PersonnelDBs/HEOperatorCertificateType/utils/HEOperatorCertificateType-utils';

export type GetManyReturnType = {
  status: string;
  data: IOperatorLicenses[];
};

export type GetOneReturnType = {
  status: string;
  data: IOperatorLicenses;
};

const getOperatorsLicenseTypes = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.operatorLicenseTypes);
  return response.data.data;
};

const getOperatorsLicenseTypesById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.operatorLicenseTypes}/${id}`
  );
  return response.data.data;
};


const importOperatorsLicenseTypes = async (newOperatorData: any) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.operatorLicenseTypes}/import-data`,
    newOperatorData
  );
  return response.data.data;
};

const updateOperatorLicenseTypes = async (id: string, updateOperatorData: Partial<ICreateOperetorLicenses>) => {
  delete updateOperatorData.id;
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.operatorLicenseTypes}/${id}`,
    updateOperatorData
  );

  return response.data.data;
};

const createOperatorLicenseTypes = async (newOperatorData: Partial<ICreateOperetorLicenses>) => {
  delete newOperatorData.id;
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.operatorLicenseTypes,
    newOperatorData
  );
  return response.data.data;
};

export default {
  getOperatorsLicenseTypes,
  getOperatorsLicenseTypesById,
  importOperatorsLicenseTypes,
  updateOperatorLicenseTypes,
  createOperatorLicenseTypes
};
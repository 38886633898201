import PageContainer from '../../../../components/common/PageContainer';
import LaborTypeTable from './LaborTypeTable';

function LaborTypePage() {
  return (
    <>
      <PageContainer title='Servicing Databases' subTitle='Labor Type + Rate Master' showDate>
        <LaborTypeTable />
      </PageContainer>
    </>
  );
}

export default LaborTypePage;

import * as yup from 'yup';
import moment from 'moment';
import { ICreateLaborType } from './LaborType-utils';

const currentDate = new Date(Date.now());

export const LaborTypeInitialValues: ICreateLaborType = {
  id: '',
  laborType: '',
  unitOfMeasurement: '',
  isActive: '',
  updatePeriod:'',
};
export const LaborTypeSchema = yup.object({
    laborType: yup.string().trim().required('Labor Type is required'),
    unitCost: yup.number().required('Unit Cost is required'),
    unitOfMeasurement: yup.string().required('Unit of Measurement is required'),
    isActive: yup.boolean().required('Status is required'),
    updatePeriod: yup.string().required('Update period is required'),
  });
import * as yup from 'yup';
import { InitialHEOperator } from './HEOperator-utils';


export const HEOperatorInitialValues: InitialHEOperator = {
  operatorName: '',
  productFamilyId: '',
  licenseTypeId: '',
  licenseType: '',
  operatorType: '',
  dateReceived: '',
  productFamily: '',
  isActive: 'true',
};

export const HEOperatorSchema = yup.object({
    operatorName: yup.string().trim().required('Operator Name is required'),
    productFamilyId: yup.string().trim().required('Product Family is required'),
    licenseTypeId: yup.string().trim().required('License Type is required'),
    dateReceived: yup.string().trim().required("Date Received is required"),
    isActive: yup.boolean().required('Status is required'),
  });
import { Outlet, useLocation } from "react-router-dom";
import PageContainer from "../../../components/common/PageContainer";

const EJOAndESRApprovalPage = () => {
  const location = useLocation();

  const route = location.pathname.split("/");

  const subTitle =
    route[1] == "esr-management" ? "ESR Management" : "EJO/ESR Approval";

  const subModuleTitle =
    (route[2] == `ejo-pending` && `EJOs Pending Approval`) ||
    (route[2] == `ejo-approved-and-esr-uncreated` &&
      `EJO Approved > ESR Uncreated`) ||
    (route[2] == `esr-pending-approval` && `ESR Pending Approval`) ||
    (route[2] == `esr-approved-and-unstarted` && `ESR Approved > Unstarted`) ||
    (route[2] == `esr-wip` && `ESR WIP`);

  return (
    <>
      <PageContainer
        title="My Dashboard + Tasks"
        subTitle={`${subTitle}`}
        submoduleTitle={`${subModuleTitle}`}
        showDate
      >
        <Outlet />
      </PageContainer>
    </>
  );
};

export default EJOAndESRApprovalPage;

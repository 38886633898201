import { createColumnHelper } from '@tanstack/react-table';
import { ITaskTypes } from './utils/taskTypes-utils';
import TaskTypesActionButton from './utils/TaskTypesActionsButtons';
import Chip from '@mui/material/Chip';

const columnHelper = createColumnHelper<ITaskTypes>();

const taskTypesColumns = [
    columnHelper.accessor('id', {
        id: 'id',
        header: 'Item No.',
        cell: ({ row }) => row.index + 1,
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('taskTypeNo', {
        id: 'taskTypeNo',
        header: 'Task Type No.',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('taskType', {
        id: 'taskType',
        header: 'Task Type',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('isActive', {
        id: 'isActive',
        header: 'Status',
        cell: (row) => (
            <Chip
                label={row.getValue() ? 'Active' : 'Inactive'}
                color={row.getValue() ? 'primary' : 'warning'}
                style={{ color: row.getValue() ? 'white' : 'white', height: '25px' }}
            />
        ),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.display({
        id: 'actions',
        header: 'Action',
        cell: ({ row }) => (
            <TaskTypesActionButton taskTypes={row.original} />
        ), 
        enableHiding: false,
    }),
    
    
];

export default taskTypesColumns;

import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { IERFExport, AllocationExport, IPRExport, InTransitsExport, IEXFExport, IEORRExport } from '../utils/types';

const ERFMap = {
  'Project Name': 'project',
  'ERF Number' : 'erfNo',
  'ERF Date' : 'erfDate',
  'SERF Number' : 'serfNo',
  'Product Family': 'productFamily',
  'Capacity' : 'capacity',
  'Start Date' : 'startDate',
  'End Date' : 'endDate' 
}

const AllocationMap = {
  'Allocation Number': 'allocationNo',
  'Allocation Date' : 'allocationDate',
  'Project' : 'project',
  'SERF Number' : 'serfNo',
  'Product Family': 'productFamily',
  'Capacity' : 'capacity',
  'Equipment Number' : 'equipmentNo',
  'Start Date' : 'startDate',
  'Requested End Date' : 'endDate' 
}

const PRSMap = {
  'PR Number' : 'prNo',
  'Days Since PR Created' : 'prDate',
  'Project' : 'project',
  'Serf Number' : 'serfNo',
  'Product Family' : 'productFamily',
  'Capacity' : 'capacity',
  'Start Date' : 'startDate',
  'Requested End Date' : 'endDate'
}

const IntransitMap = {
  'Equipment Number' : 'equipmentNo',
  'Allocation Delivery Number' : 'allocationDeliveryNo',
  'Allocation Delivery Date' : 'allocationDeliveryDate',
  'Allocation Pick Up Location' : 'allocationPickUpLocation',
  'Allocation Truck Number' : 'allocationTruckNo',
  'Allocation Estimated Pick Up Date' : 'allocationEPUD',
  'Allocation Drop Off Location' : 'allocationDropOffLocation',
  'Allocation ETA at Drop Off' : 'allocationETA',
  'Days Outstanding' : 'daysOutstanding'
}

const EXFMap = {
  'EXF Number' : 'exfNo',
  'EXF Creation Date' : 'exfDate',
  'Project' : 'project',
  'Equipment Number' : 'equipmentNo',
  'EM End Date' : 'emEndDate',  
  'EXF Requested End Date' : 'exfRequestedEndDate',  
  'EXF Status' : 'exfStatus',  
  'EM EXF End Date' : 'exfEMEndDate',  
  'EM Remarks on EXF Status' : 'exfEMRemarks'
}

const EORRMap = {
  'EORR Number' : 'eorrNo',  
  'EORR Date' : 'eorrDate',
  'Project' : 'project',
  'Equipment Number' : 'equipmentNo',
  'Requested Pickup Date' : 'eorrRequestedPUD',
  'Days Since EORR Created' : 'daysSinceEorrCreated',
  'EORR Pickup Number' : 'eorrPickUpNo',
  'EORR Truck Number' : 'eorrTruckNo',
  'EORR Estimated Pick up Date' : 'eorrEPUD'
}

export const exportIERFToExcel = (data: IERFExport[], filename: string) => {
  const headings = [Object.keys(ERFMap)];
  const columnHeaders = [...Object.values(ERFMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportAllocationToExcel = (data: AllocationExport[], filename: string) => {
  const headings = [Object.keys(AllocationMap)];
  const columnHeaders = [...Object.values(AllocationMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportPRSToExcel = (data: IPRExport[], filename: string) => {
  const headings = [Object.keys(PRSMap)];
  const columnHeaders = [...Object.values(PRSMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportInTransitToExcel = (data: InTransitsExport[], filename: string) => {
  const headings = [Object.keys(IntransitMap)];
  const columnHeaders = [...Object.values(IntransitMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportEXFToExcel = (data: IEXFExport[], filename: string) => {
  const headings = [Object.keys(EXFMap)];
  const columnHeaders = [...Object.values(EXFMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportEORRToExcel = (data: IEORRExport[], filename: string) => {
  const headings = [Object.keys(EORRMap)];
  const columnHeaders = [...Object.values(EORRMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};
import { forwardRef } from "react";
import Stack, { StackProps } from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { appColors } from "../../../theme";

interface OwnProps {
  label?: string;
  placeholder?: string;
  labelPosition?: "top" | "left";
  labelAlignment?: "center" | "inherit" | "right" | "left" | "justify";
  labelWidth?: string;
  fieldWidth?: string;
  fontWeight?: number;
  required?: boolean;
  rootProps?: StackProps;
  isMultiline?: boolean;
}

type AppFieldProps = OwnProps & Omit<TextFieldProps, "label">;

export const AppTextField = forwardRef<HTMLInputElement, AppFieldProps>(
  (
    {
      label,
      placeholder,
      required,
      isMultiline,
      rootProps,
      labelPosition,
      labelAlignment,
      labelWidth,
      fieldWidth,
      fontWeight,
      ...rest
    },
    ref
  ) => {
    return (
      <Stack
        direction={labelPosition && labelPosition === "top" ? "column" : "row"}
        gap={labelPosition && labelPosition === "top" ? 0.5 : 1.5}
        {...rootProps}
        justifyContent="space-between"
        alignItems={
          labelPosition && labelPosition === "top" ? "flex-start" : "center"
        }
        width="100%"
      >
        {label && (
          <Typography
            color={appColors.formText}
            component="label"
            fontSize={labelPosition && labelPosition === "top" ? 13 : 15}
            width={isMultiline ? 80 : labelWidth ? labelWidth : 130}
            align={
              isMultiline ? "left" : labelAlignment ? labelAlignment : "right"
            }
            htmlFor={rest.name}
            fontWeight={fontWeight ? fontWeight : 600}
            // mt={1.5}
            whiteSpace="nowrap"
          >
            {label}&nbsp;
            {required && (
              <Typography component="small" color="error.main">
                *
              </Typography>
            )}
          </Typography>
        )}
        <Stack width={isMultiline ? 1 : fieldWidth ? fieldWidth : 400}>
          <TextField
            aria-required={required}
            id={rest.name}
            placeholder={placeholder ? placeholder : "Type here"}
            {...rest}
            ref={ref}
            size="small"
            InputProps={{
              sx: { fontSize: "14px", width: "100%", ...rest.sx },
              ...rest.InputProps,
            }}
          />
        </Stack>
      </Stack>
    );
  }
);

export default AppTextField;

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { ESRManpowerColumns } from "../ESR/ESR-Manpower/utils/types";
import { ESREquipmentServiceColumns } from "../ESR/ESR-EquipmentService/utils/types";

interface ESRApprovalContext {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  esrManpowerArray: ESRManpowerColumns[];
  setEsrManpowerArray: Dispatch<SetStateAction<ESRManpowerColumns[]>>;
  esrEquipmentServicesArray: ESREquipmentServiceColumns[];
  setEsrEquipmentServicesArray: Dispatch<
    SetStateAction<ESREquipmentServiceColumns[]>
  >;
  esrActionType: string;
  setESRActionType: Dispatch<SetStateAction<string>>;
  isViewOnly: boolean;
  setViewOnly: Dispatch<SetStateAction<boolean>>;
}

const ESRApprovalContext = createContext<ESRApprovalContext | undefined>(
  undefined
);

export const ESRApprovalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isViewOnly, setViewOnly] = useState<boolean>(true);
  const [esrManpowerArray, setEsrManpowerArray] = useState<
    ESRManpowerColumns[]
  >([]);
  const [esrEquipmentServicesArray, setEsrEquipmentServicesArray] = useState<
    ESREquipmentServiceColumns[]
  >([]);
  const [esrActionType, setESRActionType] = useState<string>("");

  return (
    <ESRApprovalContext.Provider
      value={{
        openDialog,
        setOpenDialog,
        esrManpowerArray,
        setEsrManpowerArray,
        esrEquipmentServicesArray,
        setEsrEquipmentServicesArray,
        esrActionType,
        setESRActionType,
        isViewOnly,
        setViewOnly,
      }}
    >
      {children}
    </ESRApprovalContext.Provider>
  );
};

export const useESRApprovalContext = (): ESRApprovalContext => {
  const context = useContext(ESRApprovalContext);
  if (!context) {
    throw new Error(
      "useESRApprovalContext must be used within a ESRApprovalProvider"
    );
  }
  return context;
};

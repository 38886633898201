import PageContainer from '../../../../components/common/PageContainer';
import LocationTable from './LocationTypeTable';

function LocationPage() {
  return (
    <>
      <PageContainer title='Other Databases' subTitle='Location Master' showDate>
        Location Master
        <LocationTable />
      </PageContainer>
    </>
  );
}

export default LocationPage;

import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { IParts } from './utils/parts-utils';
import PartsActionButtons from './utils/PartsActionButtons';
import Chip from '@mui/material/Chip';

const columnHelper = createColumnHelper<IParts>();

const partsColumns = [
    columnHelper.accessor('partNumber', {
        id: 'partNumber',
        header: 'Item No.',
        cell: ({row}) => row.index +1,
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('partNumber', {
        id: 'partNumber',
        header: 'Part No.',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('model', {
        id: 'model',
        header: 'Model',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('system', {
        id: 'system',
        header: 'System',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('subsystem', {
        id: 'subsystem',
        header: 'Sub-System',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('description', {
        id: 'description',
        header: 'Description',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('make', {
        id: 'make',
        header: 'Make',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('willfitOrEOM', {
        id: 'willfitOrEOM',
        header: 'Will fit or EOM',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('supplier', {
        id: 'supplier',
        header: 'Supplier',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('unitOfMeasurement', {
        id: 'unitOfMeasurement',
        header: 'Unit of measurement',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('packageCost', {
        id: 'packageCost',
        header: 'Package Cost',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('packageQty', {
        id: 'packageQty',
        header: 'Package Quantity',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('unitCost', {
        id: 'unitCost',
        header: 'Unit Cost',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('updatePeriod', {
        id: 'updatePeriod',
        header: 'Update Period',
        cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('isActive', {
        id: 'isActive',
        header: 'Status',
        cell: (row) => (
            <Chip
                label={row.getValue() ? 'Active' : 'Inactive'}
                color={row.getValue() ? 'primary' : 'warning'}
                style={{ color: row.getValue() ? 'white' : 'white', height: '25px' }}
            />
        ),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.display({
        id: 'actions',
        header: 'Action',
        cell: ({ row }) => <PartsActionButtons parts={row.original} />,
        enableHiding: false,
    })
];

export default partsColumns;

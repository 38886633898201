import { useQuery, useMutation } from '@tanstack/react-query';
import { IParts, INonExistencePartNumber } from '../modules/DatabaseManagement/ServicingDBs/Parts/utils/parts-utils';
import partsApi from '../services/dataBaseManagement/servicingDb/parts';
import { useSnackBar } from '../components/ToastProvider';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router';
import { string } from 'yup/lib/locale';
import partsDescription from '../services/partsDescription';


interface ApiError {
  status: number;
  message: string;
}

export function useGetPartsQuery() {
  return useQuery<IParts[]>(['parts'], () => partsApi.getParts(), {
    onError(err) {
      console.log(err);
    },
    select(parts) {
        return parts;
    }
  });
}

export function useGetNonExistencePartNumber() {
  return useQuery<INonExistencePartNumber[]>(['partnumber'], () => partsApi.getNonExistencePartNumber(), {
    onError(err) {
      console.log(err);
    },
    select(parts) {
        return parts;
    }
  });
}


export const useExportParts = (data: IParts[]) => {
  const newPartsList = [];
  
  const PartsList = data;

  for(let i in PartsList){
    let json = {
      partNumber : PartsList[i].partNumber,
      model : PartsList[i].model,
      system : PartsList[i].system,
      subsystem : PartsList[i].subsystem,
      description : PartsList[i].description,
      make : PartsList[i].make,
      willfitOrEOM : PartsList[i].willfitOrEOM,
      supplier : PartsList[i].supplier,
      unitOfMeasurement : PartsList[i].unitOfMeasurement,
      packageCost : PartsList[i].packageCost,
      packageQty : PartsList[i].packageQty,
      unitCost : PartsList[i].unitCost,
      updatePeriod : PartsList[i].updatePeriod,
      isActive : PartsList[i].isActive,
      partsDescriptionId: PartsList[i].partsDescriptionId,
    }
    newPartsList.push(json);
  }
  return newPartsList;
}


export function useImportParts() {
  
  const { showSnackBar } = useSnackBar();

  return useMutation((newPartsData: IParts[]) => partsApi.importParts(newPartsData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['Parts'] }).then(() => {
        if( data[1].invalidData.length >= 1){
             showSnackBar(data[1].invalidData[0].errorReason, 'error');
             setTimeout(() => {
              window.location.reload();
            }, 1500);
        }
        else{
          showSnackBar('Successfully Inserted Parts', 'success');
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
     
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}

export function useCreateParts() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newPartsData: Partial<IParts>) => partsApi.createPart(newPartsData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['Part'] }).then(() => {
          showSnackBar('Successfully Inserted Part', 'success');
          setTimeout(() => {
            navigate('/parts', { replace: true });
          }, 100);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;
      let errorType: any;
      errorType = errorData
      if(errorType[0].message == undefined){
        showSnackBar(errorType, 'error');
      }
      else{
        showSnackBar(errorType[0].message, 'error');
      }
    },
  });
}

export function useGetPartById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`parts-${id}`, id], () => partsApi.getPartById(id!));
}

export function useUpdatePart() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newPartData: Partial<IParts>) => partsApi.updatePart(id!, newPartData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['part'] }).then(() => {
        showSnackBar('Successfully Updated part', 'success');
        setTimeout(() => {
          navigate('/parts', { replace: true });
        }, 100);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}
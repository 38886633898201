import { Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { appColors } from '../../../theme';

interface AppTimePickerProps {
  error?: boolean;
  helperText?: string;
  label: string;
  name: string;
  required?: boolean;
  value: Date | null;
  onChange: (value: Date | null) => void;
}

const AppTimePicker = (props: AppTimePickerProps) => {
  const { error, helperText, value, onChange, label, required, name } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack
        direction="row"
        gap={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography
          htmlFor={name}
          aria-required={required}
          color={appColors.formText}
          component="label"
          // mt={1.5}
          fontWeight="600"
          fontSize={15}
          width={180}
          align="right"
          whiteSpace="nowrap"
        >
          {label}&nbsp;
          {required && (
            <Typography component="small" color="error.main">
              *
            </Typography>
          )}
        </Typography>
       <div style={{width: "100%",marginLeft:"10px"}}>
       <TimePicker
          value={value}
          onChange={onChange}
          sx={{ width: "100%" }}
        />
        {error &&   <Typography component="small" color="error.main" style={{fontSize: "14px", textAlign: "left", float: "left"}} >
          {helperText}
        </Typography>}
      
       </div>
        
      </Stack>

    </LocalizationProvider>
  );
};

export default AppTimePicker;
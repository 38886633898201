import { useQuery, useMutation } from '@tanstack/react-query';
import { ILaborType, ExportLaborType, IImportLaborType, ICreateLaborType } from '../modules/DatabaseManagement/ServicingDBs/LaborType/utils/LaborType-utils';
import laborTypeAPi from '../services/labortype';
import { queryClient } from '../react-query/queryClient';
import { useSnackBar } from '../components/ToastProvider';
import { useNavigate, useParams } from 'react-router';
import { AxiosError } from 'axios';

interface ApiError {
  status: number;
  message: string;
}
interface useGetunitCostOptions {
  isActive?: boolean;
  unitCost?: string;
}

export function useGetLaborTypeQuery(options?: useGetunitCostOptions) {
  return useQuery<ILaborType[]>(['LaborTypes'], () => laborTypeAPi.getLaborType(), {
    onError(err) {
      console.log(err);
    },
    select(LaborTypes) {
      if (options?.isActive) {
        const uniqueLaborTypes = LaborTypes.filter(
          (obj, idx) => LaborTypes.findIndex((eq) => eq.isActive === obj.isActive) === idx
        )
        return uniqueLaborTypes;
      } else {
        return LaborTypes;
      }
    }
  });
}


export function useGetLaborTypeById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`LaborTypes-${id}`, id], () => laborTypeAPi.getLaborTypeById(id!));
}

export const useExportLaborTypes = (data: ExportLaborType[]) => {
  const newLaborTypesList = [];
  
  const LaborTypesList = data;

  for(let i in LaborTypesList){
    let json = {
      laborTypeId: LaborTypesList[i].laborTypeId,
      laborType: LaborTypesList[i].laborType,
      unitCost: LaborTypesList[i].unitCost,
      unitOfMeasurement: LaborTypesList[i].unitOfMeasurement,
      updatePeriod: LaborTypesList[i].updatePeriod,
      isActive: LaborTypesList[i].isActive,
    }
    newLaborTypesList.push(json);
  }
  return newLaborTypesList;
}

export function useImportLaborTypes() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((newLaborTypesData: IImportLaborType[]) => laborTypeAPi.importLaborType(newLaborTypesData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['LaborTypes'] }).then(() => {
        
        if( data[1].invalidData.length >= 1){
             showSnackBar(data[1].invalidData[0].errorReason, 'error');
             navigate('/labor-type-rate', { replace: true });
             window.location.reload();
        }
        else{
          showSnackBar('Successfully Inserted Labor Type', 'success');
          navigate('/labor-type-rate', { replace: true });
          window.location.reload();
        }
     
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}


export function useUpdateLaborTypes() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newLaborTypesData: Partial<ICreateLaborType>) => laborTypeAPi.updateLaborType(id!, newLaborTypesData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['LaborTypes'] }).then(() => {
        showSnackBar('Successfully Updated Labor Type', 'success');
        navigate('/labor-type-rate', { replace: true });
   
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useDeleteLaborTypes(data: any, id: string) {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((data: any) => laborTypeAPi.updateLaborType(id!, data), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['LaborTypes'] }).then(() => {        
        showSnackBar('Successfully Deleted Labor Type', 'success');
        navigate('/labor-type-rate', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useCreateLaborTypes() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newLaborTypesData: Partial<ICreateLaborType>) => laborTypeAPi.createLaborType(newLaborTypesData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['LaborTypes'] }).then(() => {
          showSnackBar('Successfully Inserted Labor Type', 'success');
          setTimeout(() => {
            navigate('/labor-type-rate', { replace: true });
          }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}
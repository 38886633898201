import { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppSelect from '../../../../components/common/forms/AppSelect';
import PageContainer from '../../../../components/common/PageContainer';
import { ICreateLocation } from './utils/Location-utils';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { LocationSchema, LocationInitialValues} from './utils/schema';
import { useGetstreetLocationById, useUpdateLocations, useCreateLocations } from '../../../../hooks/useLocations';
import { appColors, appStyles } from '../../../../theme';

const LocationForm = () => {
  const navigate = useNavigate();
  const currentLocation = useGetstreetLocationById();
  const updateLocation = useUpdateLocations();
  const createLocation = useCreateLocations();
  const statusOptions: SelectOptionType[] = [{'id': 'true', 'label': 'Active' , 'value': 'true'},{'id': 'false', 'label': 'Inactive' , 'value': 'false'} ]
  const typeOptions: SelectOptionType[] = [
    {'id': 'Project','label': 'Project' , 'value': 'Project'},
    {'id': 'Motorpool', 'label': 'Motorpool' , 'value': 'Motorpool'},
    {'id': 'HO', 'label': 'HO' , 'value': 'HO'},
    {'id': 'Unclassified', 'label': 'Unclassified' , 'value': 'Unclassified'}
   ]

const { register, 
        handleSubmit,
        setValue,
        control,
        formState: { errors, isValid }
      } = useForm<ICreateLocation>({
        defaultValues: currentLocation.data! ? currentLocation.data : LocationInitialValues,
        mode: 'onChange',
        resolver: yupResolver(LocationSchema),
      });

  useEffect(() => {
    setValue("isActive", 'true');
    if (!currentLocation.data) return;
    setValue("name", currentLocation.data?.name);
    setValue("streetLocation", currentLocation.data?.streetLocation);
    setValue("type", currentLocation.data?.type);
    setValue("isActive", currentLocation.data?.isActive ? 'true' : 'false');
  }, [currentLocation.data, setValue]);

  const onError = (err: typeof errors) => {
    console.error(err);
  };

  const onSubmit = (data : ICreateLocation) => {
    if (currentLocation.data) {
      updateLocation.mutate(data);
    }
    else {
      createLocation.mutate(data)
    }
  };

  const backTo = () => navigate('/location');
  
  return (
    <>
      <PageContainer
        title='Manage My Databases'
        subTitle='Location Master'
        controls={
          <Stack direction='row' gap={2}>
            <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disabled={!isValid }
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
        <Divider color='black' />
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
          <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
            <Stack direction="column" gap={1.5} width="45%">   
              <AppTextField
                label="Name"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Name"
                error={!!errors.name}
                helperText={errors.name?.message}
                {...register('name')}
              />
              <AppTextField
                label="Street Location"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Street Location"
                error={!!errors.streetLocation}
                helperText={errors.streetLocation?.message}
                {...register('streetLocation')}
              />
                <Controller
                        control={control}
                        name='type'
                        render={({ field }) => (
                          <AppSelect
                            label="Type"
                            labelAlignment="left"
                            labelWidth='33%'
                            fieldWidth= '67%'
                            fontWeight={400}
                            placeholder="Select here"
                            error={!!errors.type}
                            helperText={errors.type?.message}
                            options={typeOptions}
                            {...register('type')}
                            {...field}
                          />
                      )}
                    />
                <Controller
                    control={control}
                    name='isActive'
                    render={({ field }) => (
                      <AppSelect
                        label="Status"
                        disabled= {currentLocation.data ? false : true}
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.isActive}
                        helperText={errors.isActive?.message}
                        options={statusOptions}
                        {...register('isActive')}
                        {...field}
                      />
                  )}
                />

               
            </Stack>
          </Stack>
          </Stack>
        </Stack>
      </PageContainer>
      <Outlet />
    </>
  )
}

export default LocationForm;
import { useQuery, useMutation } from '@tanstack/react-query';
import { IProductFamily } from "../modules/DatabaseManagement/EquipmentDBs/Equipment/utils/equipment-utils";
import productFamilyApi from '../services/productFamily';

interface useGetEquipmentOptions {
    dropdown?: boolean;
    productFamilyId?: string;
  }
  
export function useGetProductFamilyQuery(options?: useGetEquipmentOptions) {
    return useQuery<IProductFamily[]>(['productFamily'], () => productFamilyApi.getProductFamily(), {
      onError(err) {
        console.log(err);
      },
      select(productFamily) {
        return productFamily;
      }
    });
  }


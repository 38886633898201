import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { ExportHECertificateType} from './HECertificateType-utils';

const ExportHECertificateTypeMap = {
    'Certificate Type': 'certificateType',
    'Product Family': 'productFamily',
    'Status' : 'status'
}

const ExportHECertificateTypeMapImport = {
  'Certificate Type': 'certificateType',
  'Product Family': 'productFamily'
   
}


export const ExportHECertificateTypeToExcel = (data: ExportHECertificateType[], filename: string) => {
    const headings = [Object.keys(ExportHECertificateTypeMap)];
    const columnHeaders = [...Object.values(ExportHECertificateTypeMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    const formattedData = data.map(({isActive, productFamilyId, ...item}) => {
      return {
        ...item,
        status : isActive ? 'Active' : 'Inactive'
      };
    });

    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
    console.log(formattedData)
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
     XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };

  export const GetTemplateExportHECertificateType= (data: ExportHECertificateType[], filename: string) => {
    const headings = [Object.keys(ExportHECertificateTypeMapImport)];
    const columnHeaders = [...Object.values(ExportHECertificateTypeMapImport)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };
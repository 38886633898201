import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { EJOSR } from "../EJO-SR/utils/types";
import { EJORequestBody } from "../utils/types";

interface EJOManagement {
  // ? Opener ng dialog e.g mag add ng sr mag open to gamit tong state na to
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  openConfirmDeleteSRDialog: boolean;
  setOpenConfirmDeleteSRDialog: Dispatch<SetStateAction<boolean>>;
  serviceRequestsArray: EJOSR[];
  setServiceRequestsArray: Dispatch<SetStateAction<EJOSR[]>>;
  currentSRSelected: EJOSR;
  setCurrentSRSelected: Dispatch<SetStateAction<EJOSR>>;
  currentEJOSelected: EJORequestBody;
  setCurrentEJOSelected: Dispatch<SetStateAction<EJORequestBody>>;
  // ? Separate action type ejo and sr
  ejoActionType: string;
  setEjoActionType: Dispatch<SetStateAction<string>>;
  srActionType: string;
  setSrActionType: Dispatch<SetStateAction<string>>;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

const EJOManagementContext = createContext<EJOManagement | undefined>(
  undefined
);

export const EJOManagementProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openConfirmDeleteSRDialog, setOpenConfirmDeleteSRDialog] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [serviceRequestsArray, setServiceRequestsArray] = useState<EJOSR[]>([]);
  const [currentSRSelected, setCurrentSRSelected] = useState<EJOSR>({
    id: "",
    serviceRequest: "",
    serviceRequestTypeId: "",
    remarks: "",
  });
  const [currentEJOSelected, setCurrentEJOSelected] = useState<EJORequestBody>({
    ejoCreator: "",
    warranty: true,
    dateCreated: Date(),
    dateNeeded: Date(),
    projectLocationId: "",
    equipmentId: "",
    smrMileage: 0,
    isOperable: false,
    serviceRequestList: [],
  });
  const [ejoActionType, setEjoActionType] = useState<string>("new");
  const [srActionType, setSrActionType] = useState<string>("new");

  return (
    <EJOManagementContext.Provider
      value={{
        openDialog,
        setOpenDialog,
        openConfirmDeleteSRDialog,
        setOpenConfirmDeleteSRDialog,
        serviceRequestsArray,
        setServiceRequestsArray,
        currentSRSelected,
        setCurrentSRSelected,
        currentEJOSelected,
        setCurrentEJOSelected,
        ejoActionType,
        setEjoActionType,
        srActionType,
        setSrActionType,
        isEditing,
        setIsEditing,
      }}
    >
      {children}
    </EJOManagementContext.Provider>
  );
};

export const useEJOManagementContext = (): EJOManagement => {
  const context = useContext(EJOManagementContext);
  if (!context) {
    throw new Error(
      "useEJOManagementContext must be used within a EJOManagementProvider"
    );
  }
  return context;
};

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppDialog from '../../../components/common/AppDialog';
import AppTextField from '../../../components/common/forms/AppTextField';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import MultiEmailForm from '../../../components/common/MultiEmailForm';
import { appStyles } from '../../../theme';
import { emailInitialValues, emailSchema, IEmailForm } from './ct-utils';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import MultipleUploadButton from '../../../components/common/MultipleUploadButton';
import MultiFileEmail from '../../../components/common/MultiFileEmail';
import { 
  useGetEquipmentById,
  useSendEmail 
} from '../../../hooks/useCertificateTracker';
import { Button, Stack } from '@mui/material';

const CtEmailForm = () => {
  const navigate = useNavigate();
  const sendEmail = useSendEmail();
  const equipment = useGetEquipmentById();
  const equipmentData = equipment.data;
  const [errorMessageFile, setErrorMessageFile] = useState('');
  const handleClose = () => navigate('/equipment-certificates', { replace: true });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IEmailForm>({
    defaultValues: emailInitialValues,
    mode: 'onChange',
    resolver: yupResolver(emailSchema),
  });

  useEffect(() => {
    if (!equipmentData) return;

    const { equipmentNo } = equipmentData

    setValue("subject", equipmentNo);

  }, [equipmentData, setValue]);

  const onSubmit = (email: IEmailForm) => {
    sendEmail.mutate(email);
    navigate('/equipment-certificates', { replace: true });
  };

  const emailFiles = watch('media');

  const { append, remove } = useFieldArray({ control, name: 'media' });

  const handleMediaUpload = (files: FileList | null) => {
    if (!files) return;

    // check if the resulting number of files is more than 3
    const resultingCount = emailFiles.length + files.length;
    if (resultingCount > 3) {
      setErrorMessageFile('You can only upload a maximum of 3 files');
      return console.log('You can only upload a maximum of 3 files');
    }
    else{
      setErrorMessageFile('');
    }

    // check for possible duplicate files (lookup value is filename)
    const emailFilenames = emailFiles.map((item) => item.fileName);

    for (let i = 0; i < files.length; i++) {
      if (emailFilenames.includes(files[i].name)) {
        return console.log(`${files[i].name} already exists`);
      }
    }

    Array.from(files).forEach((file) => {
      append({
        fileName: file.name,
        source: file,
      });
    });
  };

  if (sendEmail.isLoading) return <LoadingIndicator show />;

  return (
    <AppDialog open onDialogClose={handleClose} title='Email PIC' maxWidth='lg'>
      <Stack
        component='form'
        height='100%'
        bgcolor='white'
        py={3}
        boxShadow={appStyles.shadow}
        borderRadius={1}
      >
        <Stack px={3} height='100%' overflow='auto' gap={2}>
          <Controller
            control={control}
            name='toemail'
            render={({ field: { onChange, value } }) => (
              <MultiEmailForm
                value={value}
                setValue={onChange}
                id='toemail'
                label='To:'
                placeholder='Type here'
                isEmail={true}
                helperText={errors.toemail && 'Email is required'}
              />
            )}
          />

          <Controller
            control={control}
            name='ccemail'
            render={({ field: { onChange, value } }) => (
              <MultiEmailForm
                value={value}
                setValue={onChange}
                id='ccemail'
                label='Cc:'
                placeholder='Type here'
                isEmail={false}
              />
            )}
          />

          <AppTextField
            label='Subject'
            required={false}
            id="subject"
            isMultiline={true}
            sx={{
              width: '100%',
              alignItems: 'start',
              mt: 0.75,
            }}
            helperText={errors.subject && <span style={{ color: '#DD4C39', marginLeft: '-14px' }}>Subject is required</span>}
            {...register('subject')}
          />
          <AppTextField
            id='message'
            label='Message'
            required={false}
            isMultiline={true}
            multiline
            sx={{
              height: 300,
              width: '100%',
              alignItems: 'start',
              mt: 0.75,
            }}
            helperText={errors.message && <span style={{ color: '#DD4C39', marginLeft: '-14px' }}>Message is required</span>}
            {...register('message')}
          />
          <Stack direction='column' gap={3} ml='auto'>
            <Stack
              direction='row'
              gap={3}
              ml='auto'
              justifyContent='space-evenly'
              alignItems='flex-end'
            >
              {emailFiles.map(({ source, fileName }, index) => (
                <MultiFileEmail key={fileName} label={fileName} onDelete={() => remove(index)} />
              ))}
              <MultipleUploadButton
                key={emailFiles.length}
                onChange={(e) => handleMediaUpload(e.currentTarget.files)}
              />
              
            </Stack>
            {errorMessageFile && <span style={{ color: '#DD4C39', float: 'left' }}>{errorMessageFile}</span>}
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={errors.toemail ? true : false}
              sx={{ width: 150, ml: 'auto' }}
            >
              Send
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
};

export default CtEmailForm;

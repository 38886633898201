import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AppTextField from "../../../../../components/common/forms/AppTextField";
import AppDatePicker from "../../../../../components/common/forms/AppDatePicker";
import AppSelect from "../../../../../components/common/forms/AppSelect";
import { useGetEquipmentsQuery } from "../../../../../hooks/useEquipments";
import { useGetLocationsQuery } from "../../../../../hooks/useLocations";
import { useGetAssigneesQuery } from "../../../../../hooks/useAssignees";
import { appColors } from "../../../../../theme";
import { useEJOESRApprovalContext } from "../Context/EJOESRApprovalContext";
import {
  EJOManagementFieldsApproval,
  EJORequestBodyApproval,
} from "./utils/types";
import {
  EJOManagementInitialValues,
  EJOManagementSchema,
} from "./utils/schema";
import {
  useGetAllServiceRequests,
  useGetCurrentEJOSelected,
  useUpdateEJO,
} from "../../../../../hooks/useEJOApproval";
import { useGetUserByIdParam } from "../../../../../hooks/useUser";
import { Box, Grid, Switch, Typography } from "@mui/material";
import EJOSRTable from "./EJO-SR/SRTable";
import EJOSRManagementForm from "./EJO-SR/SRForm";
import { useAuth } from "../../../../../components/AuthProvider";
import EJOConfirmDialog from "./EJOConfirmDialog";
import EJOSRConfirmDeleteDialog from "./EJO-SR/EJOSRConfirmDeleteDialog";

const EJOApprovalForm = () => {
  const { id: paramsId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);

  const currUser = useAuth();
  const rolePermissions = currUser && currUser.currentUserRole?.permissions;

  const [isSRLoaded, setSRLoaded] = useState<Boolean>(false);
  const [ejoCreatorName, setEJOCreatorName] = useState<string>("");
  const [openClearSrDialog, setOpenClearSrDialog] = useState<boolean>(false);

  const {
    serviceRequestsArray,
    setServiceRequestsArray,
    currentEJOSelected,
    setCurrentEJOSelected,
    isViewOnly,
    setViewOnly,
  } = useEJOESRApprovalContext();

  const { data: ejo } = useGetCurrentEJOSelected(paramsId);
  const { data: srList, isFetching: srIsFetching } =
    useGetAllServiceRequests(paramsId);
  const updateEJO = useUpdateEJO();
  const equipmentData = useGetEquipmentsQuery();
  const locationData = useGetLocationsQuery();
  const picData = useGetAssigneesQuery();
  const ejoCreator = useGetUserByIdParam(currentEJOSelected.ejoCreator);

  const equipmentOptions: any[] =
    equipmentData?.data === undefined
      ? []
      : equipmentData.data.map((e) => {
          return {
            id: e.id,
            label: e.equipmentNo,
            value: e.id,
          };
        });

  const locationOptions: any[] =
    locationData?.data === undefined
      ? []
      : locationData.data.map((e) => {
          return {
            id: e.id,
            label: e.name,
            value: e.id,
          };
        });

  const picOptions: any[] =
    picData?.data === undefined
      ? []
      : picData.data.map((e) => {
          return {
            id: e.id,
            label: e.name,
            value: e.id,
          };
        });

  const {
    register,
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EJOManagementFieldsApproval>({
    defaultValues: EJOManagementInitialValues,
    mode: "onChange",
    resolver: yupResolver(EJOManagementSchema),
  });

  const [currentEquipmentId, setCurrentEquipmentId] = useState<string>(
    watch("equipmentId")
  );

  const equipmentModel = useMemo(() => {
    if (!equipmentData.data) {
      return;
    }

    const foundEquipment = equipmentData.data.find(
      (i) => watch("equipmentId") == i.id
    );

    return foundEquipment?.model;
  }, [watch("equipmentId"), equipmentData]);

  useEffect(() => {
    setViewOnly(checkViewOnly() ?? true);
    setServiceRequestsArray([]);
  }, []);

  useEffect(() => {
    setCurrentEJOSelected(ejo ?? currentEJOSelected);
  }, [ejo]);

  // useEffect(() => {
  //   if (!srIsFetching && !isSRLoaded) {
  //     if (srList) {
  //       srList.forEach((item, index) => {
  //         item.tableId = (index + 1).toString();
  //       });

  //       setServiceRequestsArray(srList);
  //       setSRLoaded(true);
  //     }
  //   }
  // }, [srList]);

  useEffect(() => {
    //* If both the current selected and the recent value is same
    if (currentEquipmentId == watch("equipmentId")) {
      return;
    }

    //* Use-case: Create, when the currentEquipmentId is empty
    if (!currentEquipmentId) {
      setCurrentEquipmentId(watch("equipmentId"));
    }

    if (serviceRequestsArray.length != 0) {
      setOpenClearSrDialog(true);
    }
  }, [watch("equipmentId")]);

  useEffect(() => {
    if (paramsId) {
      if (ejo && srList) {
        setValue("isOperable", ejo.isOperable);
        setValue("dateNeeded", ejo.dateNeeded);
        setValue("smrMileage", ejo.smrMileage);
        setValue("equipmentId", ejo.equipmentId);
        setValue("assigneeId", ejo.assigneeId ?? "");
        setValue("projectLocationId", ejo.projectLocationId);
        setValue("serviceRequestList", serviceRequestsArray);
        setCurrentEquipmentId(ejo.equipmentId);
        setServiceRequestsArray(srList);
      }
    }
  }, [ejo, srList, paramsId, setValue]);

  useEffect(() => {
    setEJOCreatorName(
      ejoCreator.data?.id
        ? ejoCreator.data.firstName + " " + ejoCreator.data.lastName
        : ""
    );
  }, [ejoCreator]);

  const onSubmit = (ejoData: EJOManagementFieldsApproval) => {
    function formatDate(date: Date) {
      // Extract date and time components
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

      // Extract timezone offset
      const timezoneOffset = date.getTimezoneOffset();
      const sign = timezoneOffset > 0 ? "-" : "+";
      const absOffset = Math.abs(timezoneOffset);
      const offsetHours = String(Math.floor(absOffset / 60)).padStart(2, "0");
      const offsetMinutes = String(absOffset % 60).padStart(2, "0");

      // Format offset
      const offsetString = `${sign}${offsetHours}${offsetMinutes}`;

      // Format final date string
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${offsetString}`;
    }

    const formattedDateNeeded = formatDate(new Date(ejoData.dateNeeded));
    const formattedSRList = serviceRequestsArray.map((sr) => {
      const isExistingInDb = sr.id.length > 5;

      if (!isExistingInDb) {
        return {
          ...sr,
          id: "",
        };
      }

      return sr;
    });

    const formattedEJOData: EJORequestBodyApproval = {
      isOperable: Boolean(ejoData.isOperable),
      dateNeeded: formattedDateNeeded,
      smrMileage: Number(ejoData.smrMileage),
      equipmentId: ejoData.equipmentId,
      projectLocationId: ejoData.projectLocationId,
      assigneeId: ejoData.assigneeId,
      // serviceRequestList: serviceRequestsArray.map(
      //   ({ tableId, ...rest }) => rest
      // ),
      serviceRequestList: formattedSRList,
      status: "Approved",
      // Already being set in API: approvedBy, dateApproved, updatedBy, dateUpdated
    };

    updateEJO.mutate(formattedEJOData);
  };

  const onProceedEquipmentAction = (): void => {
    setCurrentEquipmentId(watch("equipmentId"));
    setServiceRequestsArray([]);
    setOpenClearSrDialog(false);
  };

  const onCancelEquipmentAction = (): void => {
    setValue("equipmentId", currentEquipmentId);
    setOpenClearSrDialog(false);
  };

  const onError = (err: typeof errors) => {
    console.error(err);
  };

  const backTo = () => {
    //* To clear the fields
    setServiceRequestsArray([]);
    setCurrentEJOSelected({
      ejoCreator: "",
      dateCreated: Date(),
      dateNeeded: Date(),
      projectLocationId: "",
      equipmentId: "",
      assigneeId: "",
      smrMileage: 0,
      isOperable: false,
      serviceRequestList: [],
    });
    navigate(`/${pathSegments[0]}/${pathSegments[1]}`);
  };

  const checkViewOnly = () => {
    return !(
      rolePermissions?.includes("EJO::ESR::APPROVAL") &&
      pathSegments[1]?.includes("ejo-pending")
    );
  };

  //* Used for validating. Did not use isValid because isValid still return false even though the data is valid
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <>
      <Stack direction="row" gap={2} justifyContent={"flex-end"} mb={1}>
        <Button
          sx={{ backgroundColor: appColors.lightGray, color: "black" }}
          onClick={backTo}
          disabled={updateEJO.isLoading}
        >
          {
            (rolePermissions?.includes("EJO::ESR::APPROVAL") && pathSegments[1]?.includes("ejo-pending")) ||
            (rolePermissions?.includes("ESR::MANAGEMENT") && pathSegments[1]?.includes("ejo-approved-and-esr-uncreated"))
            ? "Cancel" : "Back"
          }
        </Button>
        {(rolePermissions?.includes("EJO::ESR::APPROVAL") && pathSegments[1]?.includes("ejo-pending")) && (
          <Button
            onClick={handleSubmit(onSubmit, onError)}
            disabled={
              hasErrors ||
              serviceRequestsArray.length === 0 ||
              updateEJO.isLoading
            }
            disableElevation
            variant="contained"
            sx={{ textTransform: "capitalize", borderRadius: 1.5 }}
          >
            {!updateEJO.isLoading ? "Approve" : null}
            {updateEJO.isLoading && "Approving..."}
          </Button>
        )}
        {(rolePermissions?.includes("ESR::MANAGEMENT") && pathSegments[1]?.includes("ejo-approved-and-esr-uncreated")) && (
          <Button
            onClick={handleSubmit(onSubmit, onError)}
            disabled={true}
            disableElevation
            variant="contained"
            sx={{ textTransform: "capitalize", borderRadius: 1.5 }}
          >
            {!updateEJO.isLoading ? "Submit For Approval" : null}
            {updateEJO.isLoading && "Submitting..."}
          </Button>
        )}
      </Stack>
      <Divider color="black" />
      <Grid container spacing={2} sx={{ marginTop: "1em" }}>
        {/* TITLE EJO NUMBER */}
        <Grid container spacing={2} sx={{ marginLeft: ".14em" }}>
          <Grid item sm={12} md={12} lg={6}>
            <AppTextField
              label="EJO NO."
              labelAlignment="left"
              labelWidth="20%"
              fieldWidth="80%"
              fontWeight={400}
              placeholder="0"
              value={currentEJOSelected.ejoNumber}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <AppDatePicker
              name="dateCreated"
              value={currentEJOSelected.dateCreated ?? new Date()}
              onChange={() => {}}
              label="Date Created"
              labelAlignment="left"
              labelWidth="20%"
              fieldWidth="80%"
              fontWeight={400}
              disabled={true}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <Controller
              control={control}
              name="dateNeeded"
              render={({ field: { value, onChange } }) => (
                <AppDatePicker
                  name="dateNeeded"
                  value={new Date(value)}
                  onChange={onChange}
                  label="Date Needed"
                  labelAlignment="left"
                  labelWidth="20%"
                  fieldWidth="80%"
                  fontWeight={400}
                  error={!!errors.dateNeeded}
                  helperText={errors.dateNeeded?.message}
                  minDate={new Date()}
                  disabled={isViewOnly}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <Controller
              control={control}
              name="equipmentId"
              render={({ field }) => (
                <AppSelect
                  label="Equipment No."
                  labelAlignment="left"
                  labelWidth="20%"
                  fieldWidth="80%"
                  fontWeight={400}
                  placeholder="Select here"
                  error={!!errors.equipmentId}
                  helperText={errors.equipmentId?.message}
                  options={equipmentOptions}
                  {...register("equipmentId")}
                  {...field}
                  disabled={isViewOnly}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <Controller
              control={control}
              name="projectLocationId"
              render={({ field }) => (
                <AppSelect
                  label="Location"
                  labelAlignment="left"
                  labelWidth="20%"
                  fieldWidth="80%"
                  fontWeight={400}
                  placeholder="Select here"
                  options={locationOptions}
                  error={!!errors.projectLocationId}
                  helperText={errors.projectLocationId?.message}
                  {...register("projectLocationId")}
                  {...field}
                  disabled={isViewOnly}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <AppTextField
              label="SMR/Mileage"
              labelAlignment="left"
              labelWidth="20%"
              fieldWidth="80%"
              fontWeight={400}
              placeholder="0"
              error={!!errors.smrMileage}
              helperText={errors.smrMileage?.message}
              {...register("smrMileage")}
              disabled={isViewOnly}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <Controller
              control={control}
              name="isOperable"
              render={({ field: { value, onChange } }) => (
                <Stack direction="row" alignContent="center">
                  <Box sx={{ width: "20%" }}>
                    {" "}
                    <Typography
                      color={appColors.formText}
                      whiteSpace="nowrap"
                      fontSize={15}
                      sx={{
                        paddingTop: ".5em",
                        marginRight: "2.5em",
                      }}
                    >
                      Operable?
                    </Typography>
                  </Box>
                  <Box sx={{ width: "80%", display: "flex" }}>
                    <Switch
                      checked={getValues().isOperable}
                      {...register("isOperable")}
                      value={value}
                      onChange={onChange}
                      disabled={isViewOnly}
                    />
                    <Typography
                      color={appColors.formText}
                      whiteSpace="nowrap"
                      fontSize={15}
                      sx={{
                        paddingTop: ".5em",
                      }}
                    >
                      {!value ? "No" : "Yes"}
                    </Typography>
                  </Box>
                </Stack>
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <EJOSRTable />
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <AppTextField
              label="Created By"
              labelAlignment="left"
              labelWidth="20%"
              fieldWidth="80%"
              fontWeight={400}
              placeholder=" "
              value={ejoCreatorName}
              disabled={true}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <Controller
              control={control}
              name="assigneeId"
              render={({ field }) => (
                <AppSelect
                  label="PIC"
                  labelAlignment="left"
                  labelWidth="20%"
                  fieldWidth="80%"
                  fontWeight={400}
                  placeholder="Select here"
                  options={picOptions}
                  error={!!errors.assigneeId}
                  helperText={errors.assigneeId?.message}
                  {...register("assigneeId")}
                  {...field}
                  disabled={isViewOnly}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <EJOSRManagementForm equipmentModel={equipmentModel} />
      <EJOConfirmDialog
        openClearSrDialog={openClearSrDialog}
        setOpenClearSrDialog={setOpenClearSrDialog}
        handleSubmit={onProceedEquipmentAction}
        handleClose={onCancelEquipmentAction}
      />
      <EJOSRConfirmDeleteDialog />
    </>
  );
};

export default EJOApprovalForm;

import { createColumnHelper } from '@tanstack/react-table';
import { IHECertificateType } from './utils/HECertificateType-utils';
import HECertificateTypeActionButton from './utils/HECertificateTypeActionsButtons';
import Chip from '@mui/material/Chip';

const columnHelper = createColumnHelper<IHECertificateType>();

const HECertificateTypeColumn = [
    columnHelper.accessor('id', {
        id: 'id',
        header: 'Item No',
        cell: ({row}) => row.index +1,
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('certificateType', {
        id: 'certificateType',
        header: 'Certificate Type',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('productFamily', {
        id: 'productFamily',
        header: 'Product Family',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('isActive', {
        id: 'isActive',
        header: 'Status',
        cell: (row) => (
            <Chip
                label={row.getValue() ? 'Active' : 'Inactive'}
                color={row.getValue() ? 'primary' : 'warning'}
                style={{ color: row.getValue() ? 'white' : 'white', height: '25px' }}
            />
        ),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.display({
        id: 'actions',
        header: 'Action',
        cell: ({ row }) => (
            <HECertificateTypeActionButton HECertificateType={row.original} />
        ), 
        enableHiding: false,
    }),
    
    
];

export default HECertificateTypeColumn;

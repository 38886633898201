import { useMutation, useQuery } from '@tanstack/react-query';
import FueltypeApi from '../services/fueltype';
import { useSnackBar } from '../components/ToastProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { IFueltype, ICreateFueltype, ExportFueltype , IImportFueltype} from '../modules/DatabaseManagement/OtherDBs/FuelType/utils/FuelType-utils';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';

interface ApiError {
  status: number;
  message: string;
}

interface useGetIFueltypeOptions {
  name?: string;
}

export function useGetFuelTypeQuery(options?: useGetIFueltypeOptions) {
  return useQuery<IFueltype[]>(['fueltype'], () => FueltypeApi.getFuelTypes(), {
    onError(err) {
      console.log(err);
    },
    select(FuelType) {
      return options?.name ? FuelType.filter(l => l.name === options?.name) : FuelType;
    },
  });
}


export function useGetIFueltypeById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`fueltype-${id}`, id], () => FueltypeApi.geFuelTypesById(id!));
}

export const useExportFuelType = (data: ExportFueltype[]) => {
  const newFuelTypeList = [];
  
  const FuelTypeList = data;

  for(let i in FuelTypeList){
    let json = {
      name: FuelTypeList[i].name,
      isActive: FuelTypeList[i].isActive,
    }
    newFuelTypeList.push(json);
  }
  return newFuelTypeList;
}

export function useImportFuelType() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((newFuelTypeData: IImportFueltype[]) => FueltypeApi.importFuelTypes(newFuelTypeData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['fueltype'] }).then(() => {
        
        if( data[1].invalidData.length >= 1){
             showSnackBar(data[1].invalidData[0].errorReason, 'error');
             setTimeout(() => {
              window.location.reload();
            }, 500);
        }
        else{
          showSnackBar('Successfully Inserted Fuel Types', 'success');
          navigate('/fuel-type', { replace: true });
          window.location.reload();
        }
     
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}


export function useUpdateFuelType() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newFuelTypeData: Partial<ICreateFueltype>) => FueltypeApi.updateFuelType(id!, newFuelTypeData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['fueltype'] }).then(() => {
        showSnackBar('Successfully Updated Fuel Type', 'success');
        setTimeout(() => {
          navigate('/fuel-type', { replace: true });
        }, 500);
   
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useDeleteFuelType(data: any, id: string) {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((data: any) => FueltypeApi.updateFuelType(id!, data), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['fueltype'] }).then(() => {        
        showSnackBar('Successfully Deleted Fuel Type', 'success');
        setTimeout(() => {
          navigate('/fuel-type', { replace: true });
        }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useCreateFuelType() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newFuelTypeData: Partial<ICreateFueltype>) => FueltypeApi.createFuelType(newFuelTypeData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['fueltype'] }).then(() => {
          showSnackBar('Successfully Inserted Fuel Type', 'success');
          setTimeout(() => {
            navigate('/fuel-type', { replace: true });
          }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}
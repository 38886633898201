import { Stack, IconButton, Tooltip } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useNavigate } from 'react-router-dom';
import { IEquipments } from './equipment-utils';

interface Ownprops {
  equipment: IEquipments;
}

const EquipmentsActionButton = ({ equipment }: Ownprops) => {
  const { id } = equipment;

  const navigate = useNavigate();

  const goToUserAccountForm = () => navigate(`/equipment-new/${id}`);

  return (
    <Stack direction='row' gap={1.5}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goToUserAccountForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default EquipmentsActionButton;

import { useMutation, useQuery } from '@tanstack/react-query';
import { Location } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import locationApi from '../services/locations';
import { useSnackBar } from '../components/ToastProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { IImportLocation, ICreateLocation, ExportLocation } from '../modules/DatabaseManagement/OtherDBs/Location/utils/Location-utils';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';

interface ApiError {
  status: number;
  message: string;
}

interface useGetLocationOptions {
  type?: string;
}

export function useGetLocationsQuery(options?: useGetLocationOptions) {
  return useQuery<Location[]>(['locations'], () => locationApi.getLocations(), {
    onError(err) {
      console.log(err);
    },
    select(locations) {
      return options?.type ? locations.filter(l => l.type === options?.type) : locations;
    },
  });
}


export function useGetstreetLocationById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`locations-${id}`, id], () => locationApi.geLocationsById(id!));
}

export const useExportLocations = (data: ExportLocation[]) => {
  const newLocationsList = [];
  
  const LocationsList = data;

  for(let i in LocationsList){
    let json = {
      name: LocationsList[i].name,
      streetLocation: LocationsList[i].streetLocation,
      type: LocationsList[i].type,
      isActive: LocationsList[i].isActive,
    }
    newLocationsList.push(json);
  }
  return newLocationsList;
}

export function useImportLocations() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((newLocationsData: IImportLocation[]) => locationApi.importLocations(newLocationsData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['locations'] }).then(() => {
        
        if( data[1].invalidData.length >= 1){
             showSnackBar(data[1].invalidData[0].errorReason, 'error');
             setTimeout(() => {
              window.location.reload();
            }, 500);
        }
        else{
          showSnackBar('Successfully Inserted Location', 'success');
          navigate('/location', { replace: true });
          window.location.reload();
        }
     
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}


export function useUpdateLocations() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newLocationsData: Partial<ICreateLocation>) => locationApi.updateLocationType(id!, newLocationsData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['locations'] }).then(() => {
        showSnackBar('Successfully Updated Location', 'success');
        setTimeout(() => {
          navigate('/location', { replace: true });
        }, 500);
   
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useDeleteLocations(data: any, id: string) {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((data: any) => locationApi.updateLocationType(id!, data), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['locations'] }).then(() => {        
        showSnackBar('Successfully Deleted Location', 'success');
        setTimeout(() => {
          navigate('/location', { replace: true });
        }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useCreateLocations() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newLocationsData: Partial<ICreateLocation>) => locationApi.createLocationType(newLocationsData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['locations'] }).then(() => {
          showSnackBar('Successfully Inserted Location', 'success');
          setTimeout(() => {
            navigate('/location', { replace: true });
          }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}
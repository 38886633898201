import { ISRT } from '../../../modules/DatabaseManagement/ServicingDBs/ServiceRequestType/utils/serviceRequestType-utils'
import { apiClient, apiEndpoints } from '../../apiClient';


export type GetManyReturnType = {
    status: string;
    data: ISRT[];
};

export type GetOneReturnType = {
    status: string;
    data: ISRT;
};
  
const getServiceRequestType= async () => {
    const response = await apiClient.get<GetManyReturnType>(apiEndpoints.serviceRequestType);
    return response.data.data;
};

const getServiceRequestTypeById = async (id: string) => {
    const response = await apiClient.get<GetOneReturnType>(
      `${apiEndpoints.serviceRequestType}/${id}`
    );
    return response.data.data;
};

const updateServiceRequestType = async (id: string, updateServiceRequestTypeData: Partial<ISRT>) => {
    const response = await apiClient.patch<GetOneReturnType>(
      `${apiEndpoints.serviceRequestType}/${id}`,
      updateServiceRequestTypeData
    );
  
    return response.data.data;
};

const createServiceRequestType = async (newServiceRequestTypeData: Partial<ISRT>) => {
    const response = await apiClient.post<GetOneReturnType>(
      apiEndpoints.serviceRequestType,
      newServiceRequestTypeData
    );
    return response.data.data;
};

const importServiceRequestType = async (newServiceRequestTypeData: any) => {
    const response = await apiClient.post<GetOneReturnType>(
      `${apiEndpoints.serviceRequestType}/import-data`,
      newServiceRequestTypeData
    );
    return response.data.data;
};

export default {
    getServiceRequestType,
    getServiceRequestTypeById,
    updateServiceRequestType,
    createServiceRequestType,
    importServiceRequestType
};
import { Outlet } from 'react-router-dom';
import PageContainer from '../../../../components/common/PageContainer';
import EjoManpowerTable from './Table';

const EjoManPowerPage = () => {
  return (
    <>
      <PageContainer 
        title='Reports' 
        subTitle='EJO Manpower Report'
        submoduleTitle='EJO Manpower Report'  
        showDate
      >
        <EjoManpowerTable />
      </PageContainer>
      <Outlet />
    </>
  );
}

export default EjoManPowerPage;
import * as yup from 'yup';
import { 
  ForgotPasswordField,
  ResetPasswordField 
} from './types';

export const ForgotPasswordInitialValues: ForgotPasswordField = {
  email : ''
};

export const ForgotPasswordSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .trim()
    .email('Must be a valid email'),
});

export const ResetPasswordInitialValues: ResetPasswordField = {
  password : '',
  confirmPassword : ''
};

export const ResetPasswordSchema = yup.object({
  password: yup
    .string()
    .required("This field is required.")
    .trim()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,36}$)/,
      "Must follow the guidelines on the right."
    ),
    confirmPassword: yup
    .string()
    .trim()
    .when("password", {
      is: (val: string) => val === "", // Check if password field is empty
      then: yup.string().oneOf(
        [yup.ref("password"), ""],
        "Please input password first"
      ),
      otherwise: yup.string().oneOf(
        [yup.ref("password"), null],
        "Password do not match"
      ),
    }),
});
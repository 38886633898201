import { IProductFamily } from '../modules/DatabaseManagement/EquipmentDBs/Equipment/utils/equipment-utils';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
    status: string;
    data: IProductFamily[];
  };


const getProductFamily = async () => {
    const response = await apiClient.get<GetManyReturnType>(apiEndpoints.productFamily);
    return response.data.data;
};

export default {
    getProductFamily
  };
import { createColumnHelper } from "@tanstack/react-table";
import { IconButton, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { useEJOESRApprovalContext } from "../../Context/EJOESRApprovalContext";
import { ESRColumns } from "../utils/types";
import {
  EditActionButton,
  ViewActionButton,
} from "../../../../../../components/common/primebmd-ui/EditActionButton";
import { useAuth } from "../../../../../../components/AuthProvider";

export const useCreateESRPendingApprovalColumns = () => {
  const currUser = useAuth();
  const hasESRManagementPermission =
    currUser &&
    currUser?.currentUserRole?.permissions.includes("ESR::MANAGEMENT");

  const columnHelper = createColumnHelper<ESRColumns>();

  const esrPendingApprovalColumns = [
    columnHelper.accessor("esrNumber", {
      id: "esrNumber",
      header: "ESR No.",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("equipmentNo", {
      id: "equipmentNo",
      header: "Equipment No.",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor("projectLocation", {
      id: "projectLocation",
      header: "Project / Location",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor("daysElapsed", {
      id: "daysElapsed",
      header: "Days Elapsed",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    ...(hasESRManagementPermission
      ? [
          columnHelper.display({
            id: "actions",
            header: "Action",
            cell: ({ row }) => (
              <Stack direction="row" gap="2">
                <ViewActionButton route={`view/${row.original.id}`} />
              </Stack>
            ),
            enableHiding: false,
          }),
        ]
      : [
          columnHelper.display({
            id: "actions",
            header: "Action",
            cell: ({ row }) => (
              <>
                <EditActionButton route={`approve/${row.original.id}`} />
              </>
            ),

            enableHiding: false,
          }),
        ]),
  ];

  return esrPendingApprovalColumns;
};

import * as yup from 'yup';
import { ICreatePartsDescription } from './PartsDescription-utils';

export const PartsDescriptionInitialValues: ICreatePartsDescription = {
  id: '',
  description: '',
  isActive: '',
};
export const PartsDescriptionSchema = yup.object({
    description: yup.string().trim().required('Parts description is required'),
    isActive: yup.boolean().required('Status is required'),
  });
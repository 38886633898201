import { RouteObject } from "react-router-dom";

// root
import MyDashboardsPlusTasksPage from "./MyDashboardsPlusTasksPage";

//pages
import EURPage from "./EUR/EURPage";
import EURSMRForm from "./EUR/EURAcknowledge/EURSMRForm";
import EURAcknowledge from "./EUR/EURAcknowledge";
import OperatorLicensePage from "./OperatorLicenses/OperatorLicensePage";
import OperatorLicenseList from "./OperatorLicenses/OperatorLicenseList";
import OperatorLicenseForm from "./OperatorLicenses/OperatorLicenseForm";
import OperatorLicenseEmailForm from "./OperatorLicenses/OperatorLicenseEmailForm";
import CtPage from "./CertificateTracker/CtPage"; // -> Certificate Tracker (Equipment Cert)
import CtList from "./CertificateTracker/CtList";
import CtLogForm from "./CertificateTracker/CtLogForm";
import CtEmailForm from "./CertificateTracker/CtEmailForm";
import MyEJOAndESRPage from "./EJO-ESR/MyEJOAndESRPage";
import EJOApprovedAndESRUncreatedPage from "./EJO-ESR/EJOApprovedAndESRUncreated/EJOApprovedAndESRUncreatedPage";
import ESRApprovedAndUnstartedPage from "./EJO-ESR/ESRApprovedAndUnstarted/ESRApprovedAndUnstartedPage";
import EJOPendingPage from "./EJO-ESR/EJOPendingApproval/EJOPendingPage";
import ESRCreatedAndPendingPage from "./EJO-ESR/ESRCreatedAndPendingApproval/ESRCreatedAndPendingPage";
import ESRWIPPage from "./EJO-ESR/ESRWIP/ESRWIPPage";
import EJOAndESRApprovalPage from "./EJO-ESR-Approval/EJOAndESRApprovalPage";
import { EJOESRApprovalProvider } from "./EJO-ESR-Approval/shared/Context/EJOESRApprovalContext";
import MyEquipmentRequestsPage from "./MyEquipmentRequests/MyEquipmentRequestsPage";
import MyEPFAndErfPage from "./MyEquipmentRequests/MyEPFsAndERFs/EPFAndERFPage";
import CreateEPFForm from "./MyEquipmentRequests/MyEPFsAndERFs/CreateEPFForm";
import AllocateForm from "./MyEquipmentRequests/MyEPFsAndERFs/AllocateForm";
import MyEXFPage from "./MyEquipmentRequests/MyEXFs/EXFPage";
import MyEORRPage from "./MyEquipmentRequests/MyEORRs/EORRPage";
import AllocationsPage from "./MyEquipmentRequests/MyAllocations/AllocationsPage";
import InTransitsPage from "./MyEquipmentRequests/MyInTransits/InTransitsPage";
import PRPage from "./MyEquipmentRequests/MyPRs/PRPage";
import MyOnRentPage from "./MyOnRentEquipments/OnRentPage";
import CreateDeliveryForm from "./MyEquipmentRequests/MyAllocations/CreateDeliveryForm";
import ArrivedOnSiteConfirmation from "./MyEquipmentRequests/MyEPFsAndERFs/ArrivedOnSiteConfirmation";
import CreateEXFForm from "./MyOnRentEquipments/CreateEXFForm";
import CreateEORRForm from "./MyOnRentEquipments/CreateEORRForm";
import CreatePRConfirmation from "./MyEquipmentRequests/MyAllocations/CreatePRConfirmation";
import ClosePRMessage from "./MyEquipmentRequests/MyPRs/ClosePRMessage";
import CreatePickUpForm from "./MyEquipmentRequests/MyEORRs/CreatePickUpForm";
import PickedUpFromSiteConfirmation from "./MyEquipmentRequests/MyEORRs/PickedUpFromSiteConfirmation";
import ConfirmAllocationWithdrawal from "./MyEquipmentRequests/MyAllocations/ConfirmAllocationWithdrawal";
import EXFApprovalForm from "./MyEquipmentRequests/MyEXFs/ApprovalForm";
import ApproveWithChangeForm from "./MyEquipmentRequests/MyEXFs/ApproveWithChangeForm";
import { SMRProvider } from "./EUR/Context/SMRContext";
import EJOManagementPage from "./EJO-ESR/EJOManagement/EJOManagementPage";
import EJOManagementForm from "./EJO-ESR/EJOManagement/EJOManagementForm";
import EJOManagementTable from "./EJO-ESR/EJOManagement/EJOManagementTable";
import { EJOManagementProvider } from "./EJO-ESR/EJOManagement/Context/EJOManagementContext";
import EJOApprovalForm from "./EJO-ESR-Approval/shared/EJO/EJOApprovalForm";
import EJOApprovedAndESRUncreatedTable from "./EJO-ESR-Approval/EJOApprovedAndESRUncreated/Table";
import EJOPendingTable from "./EJO-ESR-Approval/EJOPendingApproval/Table";
import ESRPendingTable from "./EJO-ESR-Approval/ESRCreatedAndPendingApproval/Table";
import ESRApprovedTable from "./EJO-ESR-Approval/ESRApprovedAndUnstarted/Table";
import ESRWipTable from "./EJO-ESR-Approval/ESRWIP/Table";
import { ESRApprovalProvider } from "./EJO-ESR-Approval/shared/Context/ESRApprovalContext";
import ESRApprovalForm from "./EJO-ESR-Approval/shared/ESR/ESRApprovalForm";

const MyDashboardsPlusTasksRoutes: RouteObject = {
  path: "",
  element: <MyDashboardsPlusTasksPage />,
  children: [
    {
      path: "my-equipment-requests",
      element: <MyEquipmentRequestsPage />,
      children: [
        {
          path: "my-erf",
          element: <MyEPFAndErfPage />,
          children: [
            {
              path: "new",
              element: <CreateEPFForm />,
            },
            {
              path: "allocate/:id",
              element: <AllocateForm type="my-erf" />,
            },
            {
              path: "arrived-on-site/:id",
              element: <ArrivedOnSiteConfirmation />,
            },
            {
              path: "create-pr/:id",
              element: <CreatePRConfirmation />,
            },
          ],
        },
        {
          path: "my-allocations",
          element: <AllocationsPage />,
          children: [
            {
              path: "create-delivery/:id",
              element: <CreateDeliveryForm />,
            },
            {
              path: "allocate/:id",
              element: <AllocateForm type="my-allocations" />,
            },
            {
              path: "withdraw-allocation/:id",
              element: <ConfirmAllocationWithdrawal />,
            },
          ],
        },
        {
          path: "my-in-transits",
          element: <InTransitsPage />,
        },
        {
          path: "my-pr",
          element: <PRPage />,
          children: [
            {
              path: "close-pr/:id",
              element: <ClosePRMessage />,
            },
          ],
        },
        {
          path: "my-exf",
          element: <MyEXFPage />,
          children: [
            {
              path: "approve/:id",
              element: <EXFApprovalForm />,
            },
            {
              path: "approve-with-change/:id",
              element: <ApproveWithChangeForm />,
            },
            {
              path: "reject/:id",
              element: <EXFApprovalForm />,
            },
          ],
        },
        {
          path: "my-eorr",
          element: <MyEORRPage />,
          children: [
            {
              path: "create-pickup/:id",
              element: <CreatePickUpForm />,
            },
            {
              path: "picked-up-from-site/:id",
              element: <PickedUpFromSiteConfirmation />,
            },
          ],
        },
      ],
    },
    {
      path: "my-on-rent-equipment",
      element: <MyOnRentPage />,
      children: [
        {
          path: "create-exf/:id",
          element: <CreateEXFForm />,
        },
        {
          path: "create-eorr/:id",
          element: <CreateEORRForm />,
        },
      ],
    },
    {
      path: "eur",
      element: <EURPage />,
      children: [
        {
          path: "",
          element: <EURAcknowledge />,
        },
      ],
    },
    {
      path: "eur/new",
      element: (
        <SMRProvider>
          <EURSMRForm />
        </SMRProvider>
      ),
    },
    {
      path: "eur/edit/:id",
      element: (
        <SMRProvider>
          <EURSMRForm />
        </SMRProvider>
      ),
    },
    {
      path: "eur/view/:id",
      element: (
        <SMRProvider>
          <EURSMRForm />
        </SMRProvider>
      ),
    },
    // {
    //   path: 'eur/:id/activity',
    //   element: <EURActivityList />,
    //   children: [
    //     {
    //       path: 'new',
    //       element: <EURActivityForm />,
    //     },
    //     {
    //       path: 'edit/:activityId',
    //       element: <EURActivityForm />,
    //     },
    //   ],
    // },
    {
      path: "equipment-certificates",
      element: <CtPage />,
      children: [
        {
          path: "",
          element: <CtList />,
          children: [
            {
              path: "new/:equipmentId",
              element: <CtLogForm />,
            },
            {
              path: "edit/:id",
              element: <CtLogForm />,
            },
            {
              path: "email/:equipmentId",
              element: <CtEmailForm />,
            },
          ],
        },
      ],
    },
    {
      path: "operator-licenses",
      element: <OperatorLicensePage />,
      children: [
        {
          path: "",
          element: <OperatorLicenseList />,
          children: [
            {
              path: "new/:operatorId",
              element: <OperatorLicenseForm />,
            },
            {
              path: "edit/:id",
              element: <OperatorLicenseForm />,
            },
            {
              path: "email/:operatorId",
              element: <OperatorLicenseEmailForm />,
            },
          ],
        },
      ],
    },

    {
      path: "my-ejo-and-esr",
      element: <MyEJOAndESRPage />,
      children: [
        {
          path: "ejo-pending",
          element: <EJOPendingPage />,
        },
        {
          path: "ejo-approved-and-esr-uncreated",
          element: <EJOApprovedAndESRUncreatedPage />,
        },
        {
          path: "esr-created-and-pending",
          element: <ESRCreatedAndPendingPage />,
        },
        {
          path: "esr-approved-and-unstarted",
          element: <ESRApprovedAndUnstartedPage />,
        },
        {
          path: "esr-wip",
          element: <ESRWIPPage />,
        },
      ],
    },
    {
      path: "ejo-management",
      element: <EJOManagementPage />,
      children: [
        {
          index: true,
          element: (
            <EJOManagementProvider>
              <EJOManagementTable />
            </EJOManagementProvider>
          ),
        },
        {
          path: "new",
          element: (
            <EJOManagementProvider>
              <EJOManagementForm />
            </EJOManagementProvider>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <EJOManagementProvider>
              <EJOManagementForm />
            </EJOManagementProvider>
          ),
        },
      ],
    },
    {
      path: "ejo-and-esr-approval",
      element: (
        <EJOESRApprovalProvider>
          <EJOAndESRApprovalPage />
        </EJOESRApprovalProvider>
      ),
      children: [
        {
          path: "ejo-pending",
          element: <EJOPendingTable />,
        },
        {
          path: "ejo-pending/edit/:id",
          element: <EJOApprovalForm />,
        },
        {
          path: "ejo-approved-and-esr-uncreated",
          element: <EJOApprovedAndESRUncreatedTable />,
        },
        {
          path: "ejo-approved-and-esr-uncreated/view/:id",
          element: <EJOApprovalForm />,
        },
        {
          path: "ejo-approved-and-esr-uncreated/edit/:id",
          element: <EJOApprovalForm />,
        },
        {
          path: "esr-pending-approval",
          element: (
            <ESRApprovalProvider>
              <ESRPendingTable />
            </ESRApprovalProvider>
          ),
        },
        {
          path: "esr-pending-approval/approve/:id",
          element: (
            <ESRApprovalProvider>
              <ESRApprovalForm />
            </ESRApprovalProvider>
          ),
        },
        {
          path: "esr-approved-and-unstarted",
          element: (
            <ESRApprovalProvider>
              <ESRApprovedTable />
            </ESRApprovalProvider>
          ),
        },
        {
          path: "esr-approved-and-unstarted/view/:id",
          element: (
            <ESRApprovalProvider>
              <ESRApprovalForm />
            </ESRApprovalProvider>
          ),
        },
        {
          path: "esr-wip",
          element: <ESRWipTable />,
        },
        {
          path: "esr-wip/view/:id",
          element: (
            <ESRApprovalProvider>
              <ESRApprovalForm />
            </ESRApprovalProvider>
          ),
        },
      ],
    },
    {
      path: "esr-management",
      element: (
        <EJOESRApprovalProvider>
          <EJOAndESRApprovalPage />
        </EJOESRApprovalProvider>
      ),
      children: [
        {
          path: "ejo-approved-and-esr-uncreated",
          element: <EJOApprovedAndESRUncreatedTable />,
        },
        {
          path: "ejo-approved-and-esr-uncreated/view/:id",
          element: <EJOApprovalForm />,
        },
        {
          path: "ejo-approved-and-esr-uncreated/edit/:id",
          element: <EJOApprovalForm />,
        },
        {
          path: "esr-pending-approval",
          element: (
            <ESRApprovalProvider>
              <ESRPendingTable />
            </ESRApprovalProvider>
          ),
        },
        {
          path: "esr-pending-approval/view/:id",
          element: (
            <ESRApprovalProvider>
              <ESRApprovalForm />
            </ESRApprovalProvider>
          ),
        },
        {
          path: "esr-approved-and-unstarted",
          element: (
            <ESRApprovalProvider>
              <ESRApprovedTable />
            </ESRApprovalProvider>
          ),
        },
        {
          path: "esr-approved-and-unstarted/view/:id",
          element: (
            <ESRApprovalProvider>
              <ESRApprovalForm />
            </ESRApprovalProvider>
          ),
        },
        {
          path: "esr-wip",
          element: <ESRWipTable />,
        },
        {
          path: "esr-wip/view/:id",
          element: (
            <ESRApprovalProvider>
              <ESRApprovalForm />
            </ESRApprovalProvider>
          ),
        },
      ],
    },
  ],
};

export default MyDashboardsPlusTasksRoutes;

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IAllocateFields } from '../utils/types';
import { AllocateInitialValues, EMDatesSchema } from '../utils/schema';
import { useGetAvailableEquipments, useAllocateEquipment } from '../../../../hooks/useEPFERF';
import { IEquipmentDeploymentStatus } from '../utils/types';
import { appColors } from '../../../../theme';
import AppDialog from '../../../../components/common/AppDialog';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs'; // Import dayjs library

import moment from 'moment';

interface IProps {
  type: string;
}

const AllocationForm = (props: IProps) => {

 
  const [indexMofication, setIndexMofication] = useState(false);
  const navigate = useNavigate();
  const allocateEquipment =  useAllocateEquipment(props.type);
  const getAvailableEquipments =  useGetAvailableEquipments(props.type);
  const equipments: IEquipmentDeploymentStatus[] = 
    getAvailableEquipments?.data === undefined ? [] : getAvailableEquipments?.data;
  const today = dayjs(); // Get today's date using dayjs 

  const mainERFIndex = equipments.findIndex((field) => field.mainERF);
  const [activeIndex, setIndex] = useState<number>(mainERFIndex);

  useEffect(() => {
    const delayBeforeSettingIndex = () => {
      setTimeout(() => {
        const mainERFIndex = equipments.findIndex((field) => field.mainERF);
        setIndex(mainERFIndex);
      }, 100); // Delay of 1000ms (1 second)
    };

    delayBeforeSettingIndex();
  }, [equipments]);

console.log(activeIndex)
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IAllocateFields>({
    // defaultValues: AllocateInitialValues,
    mode: 'onChange',
    resolver: yupResolver(EMDatesSchema),
  });

  const handleClose = () => {
    if(props.type == "my-erf")
    navigate('/my-equipment-requests/my-erf', { replace: true }); 
    else if(props.type == "my-allocations")
    navigate('/my-equipment-requests/my-allocations', { replace: true }); 
  }

  const onSubmit = (formData: IAllocateFields) => {
    let equipmentId = '';
    
    if (activeIndex !== undefined) {
      equipmentId = equipments[activeIndex].equipmentId;
    } else if (props.type === "my-allocations") {
     
      const equipmentIds = equipments.filter((item: any) => item.mainERF === true).map((item: any) => item.equipmentId);
      equipmentId = equipmentIds[0];
      
    }

    const newFormData = {
      ...formData,
      equipmentId: equipmentId,
      type: props.type
    }
    
    allocateEquipment.mutate(newFormData);
  };

  if( getAvailableEquipments?.isLoading 
    || allocateEquipment.isLoading 
    || getAvailableEquipments?.isFetching
    ) 
  {
    return <LoadingIndicator show />;
  }

 

 
  return (
    <AppDialog open onDialogClose={handleClose} title='Equipment Allocation' maxWidth='lg' titleAlignment="center">
      <Stack component='form' height='100%' bgcolor='white' py={3} borderRadius={1}>
        <Stack px={3} height='100%' overflow='auto' gap={5}>
          <Stack direction='column' width="100%" justifyContent='center' margin='auto'>
            <Stack 
              direction='row' 
              className="head"  
              width="100%" 
              textAlign="center"
              alignItems="center"
              style={{
                backgroundColor: "#B2B2B2",
                lineHeight: 1.25,
                padding: '5px 0px',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                fontSize: 13,
                color: '#fff'
              }}
            >
              <Box width="5%"></Box>
              <Box width="12%">Equipment No.</Box>
              <Box width="12%">Start Date</Box>
              <Box width="12%">Requested End Date</Box>
              <Box width="20%">EM Start Date</Box>
              <Box width="20%">EM End Date</Box>
              <Box width="11%">Current Deployment Status</Box>
              <Box width="12%">Action</Box>
            </Stack>
            {
            equipments.length === 0 
            ? <Stack width="100%" direction='row' justifyContent='center' pt={3}>
                No available equipment as of the moment.
              </Stack>
            : equipments.map((field, index) => (
              
                <Stack 
                  direction='row' 
                  className="body" 
                  fontSize={13} 
                  width="100%" 
                  textAlign="center" 
                  alignItems="center" 
                  sx={{
                    borderBottom: '1px solid #ccc',
                    padding: '2px 0px'
                  }}
                  key={field.id}
                >
                 
                  <Box width="5%">
                  <Checkbox
                  id={`check-${index}`}
                  checked={Boolean(activeIndex === index || (field.mainERF && props.type === "my-allocations" && !indexMofication))}
                  onChange={() => {
                    setIndexMofication(true);
                    setIndex(index); // Update the activeIndex to the current checkbox
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                              
                  </Box>
                  <Box width="12%">{field.equipmentNo}</Box>

                  <Box width="12%">{moment(field.startDate).format('MM/DD/yyyy')}</Box>
                  <Box width="12%">{moment(field.endDate).format('MM/DD/yyyy')}</Box>
              
                  <Stack direction="row" width="40%" gap={2}>
                  <Box width="50%">
                    {
                       Boolean(activeIndex === index || (field.mainERF && props.type === "my-allocations" && !indexMofication)) && 
                      <Controller
                        control={control}
                        name='emStartDate'
                        defaultValue = { props.type == "my-allocations" ? new Date(field.emStartDate) : new Date(Date.now()) } 
                        render={({ field: { value, onChange } }) => (
                          <AppDatePicker
                            name='date'
                            value={(field.mainERF && props.type == "my-allocations" ) ? field.emStartDate : value}
                            onChange={onChange}
                            label=''
                            labelWidth='0%'
                            fieldWidth= '100%'
                            fontWeight={400}
                            error={!!errors?.emStartDate}
                            helperText={errors?.emStartDate?.message}
                            minDate={today.toDate()}
                          />
                        )}
                      />
                    }
                  </Box>
                
                  <Box width="50%">
                    {
                    Boolean(activeIndex === index || (field.mainERF && props.type === "my-allocations" && !indexMofication)) &&
                      <Controller
                        control={control}
                        name='emEndDate'
                        defaultValue = { props.type == "my-allocations" ? new Date(field.emEndDate) : new Date(Date.now()) } 
                        render={({ field: { value, onChange } }) => (
                          <AppDatePicker
                            name='date'
                            value={(field.mainERF && props.type == "my-allocations" ) ? field.emEndDate :value}
                            onChange={onChange}
                            label=''
                            labelWidth='0%'
                            fieldWidth= '100%'
                            fontWeight={400}
                            error={!!errors?.emEndDate}
                            helperText={errors?.emEndDate?.message}
                            minDate={today.toDate()}
                          />
                        )}
                      />
                    }
                  </Box>
              </Stack>

                  <Box width="11%">{field.status}</Box>
                  <Box width="12%">
                  <Button 
                    data-test="AllocatBtn"
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                      Boolean(!isValid || activeIndex !== index)
                      // ||
                      // !Boolean( (field.mainERF && props.type === "my-allocations" && !indexMofication))
                    }
                    sx={{
                      background: appColors.warning,
                      ":hover": {
                        backgroundColor: appColors.warningDark,
                      }
                    }}
                  >
                    Allocate
                  </Button>

                  </Box>
                </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
}

export default AllocationForm;
import { createColumnHelper } from "@tanstack/react-table";
import { EJOManagementColumns } from "./types";
import { EditActionButton } from "../../../../../components/common/primebmd-ui/EditActionButton";

const columnHelper = createColumnHelper<EJOManagementColumns>();

const ejoManagementColumns = [
  columnHelper.accessor("ejoNumber", {
    id: "ejoNumber",
    header: "EJO No.",
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor("equipmentNo", {
    id: "equipmentNo",
    header: "Equipment No.",
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor("projectLocation", {
    id: "projectLocation",
    header: "Project Location",
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor("daysElapsed", {
    id: "daysElapsed",
    header: "Days Elapsed",
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.display({
    id: "actions",
    header: "Action",
    cell: ({ row }) => (
      <EditActionButton route={`/ejo-management/edit/${row.original.id}`} />
    ),

    enableHiding: false,
  }),
];

export default ejoManagementColumns;

import { useMutation, useQuery } from '@tanstack/react-query';
import certificateTypeAPI from '../services/certificateType';
import { useNavigate, useParams } from 'react-router-dom';
import { ExportHECertificateType, IHECertificateType, ICreateHECertificateType } from '../modules/DatabaseManagement/EquipmentDBs/HECertificateType/utils/HECertificateType-utils';
import { useSnackBar } from '../components/ToastProvider';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';

interface ApiError {
  status: number;
  message: string;
}

interface useGetICertificateTypeOptions {
certificateType?: string;
}

export function useGetCertificateTypeQuery(options?: useGetICertificateTypeOptions) {

  return useQuery<IHECertificateType[]>(['certificateType'], () => certificateTypeAPI.getCertificateType(), {
    onError(err) {
      console.log(err);
    },
    select(certificate) {
      return options?.certificateType ? certificate.filter(l => l.certificateType === options?.certificateType) : certificate;
    },
  });
}



export function useGetCertificateTypeById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`certificateType-${id}`, id], () => certificateTypeAPI.getCertificateTypeById(id!));
}

export const useExportHECertificateType = (data: ExportHECertificateType[]) => {
  const newCertificateTypeList = [];
  
  const CertificateTypeList = data;

  for(let i in CertificateTypeList){
    let json = {
      certificateType: CertificateTypeList[i].certificateType,
      productFamilyId: CertificateTypeList[i].productFamilyId,
      productFamily: CertificateTypeList[i].productFamily,
      isActive: CertificateTypeList[i].isActive,
    }
    newCertificateTypeList.push(json);
  }
  return newCertificateTypeList;
}

export function useImportCertificateType() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((newCertificateTypeData: IHECertificateType[]) => certificateTypeAPI.importCertificateType(newCertificateTypeData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['certificateType'] }).then(() => {
        
        if( data[1].invalidData.length >= 1){
             showSnackBar(data[1].invalidData[0].errorReason, 'error');
             setTimeout(() => {
              window.location.reload();
            }, 500);
        }
        else{
          showSnackBar('Successfully Inserted Certificate Type', 'success');
          navigate('/he-certificate-type', { replace: true });
          window.location.reload();
        }
     
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}


export function useUpdateCertificateType() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newCertificateTypeData: Partial<ICreateHECertificateType>) => certificateTypeAPI.updateCertificateType(id!, newCertificateTypeData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['certificateType'] }).then(() => {
        showSnackBar('Successfully Updated Certificate Type', 'success');
        setTimeout(() => {
          navigate('/he-certificate-type', { replace: true });
        }, 500);
   
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useDeleteCertificateType( id: string) {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((data: any) => certificateTypeAPI.deleteCertificateType(id), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['certificateType'] }).then(() => {        
        showSnackBar('Successfully Deleted Certificate Type', 'success');
        setTimeout(() => {
          navigate('/he-certificate-type', { replace: true });
        }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useCreateCertificateType() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newCertificateTypeData: Partial<ICreateHECertificateType>) => certificateTypeAPI.createCertificateType(newCertificateTypeData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['certificateType'] }).then(() => {
          showSnackBar('Successfully Inserted Certificate Type', 'success');
          setTimeout(() => {
            navigate('/he-certificate-type', { replace: true });
          }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}
import { Box, Paper, Stack, TablePagination } from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../../components/common/app-table/ui';
import useDefaultTable from '../../../../components/common/app-table/useDefaultTable';
import FormAddButton from '../../../../components/common/FormAddButton';
import AppSearchField from '../../../../components/common/forms/AppSearchField';
import { ExportButton, ImportButton, GetTemplateButton } from '../../../../components/common/primebmd-ui/DataButtons';
import { IParts } from './utils/parts-utils';
import partsColumns from './partsColumn';
import { exportPartsToExcel, GetTemplateParts } from './utils/exporting';
import { useGetPartsQuery, useExportParts, useImportParts, useGetNonExistencePartNumber } from '../../../../hooks/useParts'; 
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { useSnackBar } from '../../../../components/ToastProvider';
import MultipleUploadButton from '../../../../components/common/MultipleUploadButton';
import * as xlsx from 'xlsx';

const PartsTable = () => {
  const { showSnackBar } = useSnackBar();
  const partsData = useGetPartsQuery();
  const importData = useImportParts();
  const nonExistencePartNumber = useGetNonExistencePartNumber();
  const data: IParts[] = partsData?.data === undefined ? [] : partsData.data;
  const formattedData: IParts[] = useExportParts(data);

  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } = useDefaultTable<IParts>(
    data,
    partsColumns
  );

  const navigate = useNavigate();

  const emptyText = searchFilter ? 'No data found' : 'No data yet';

  const goToAddUserAccount = () => navigate('/parts-new');

  const handleExport = () => {
    exportPartsToExcel(formattedData, 'Parts Database');
  }

  const handleTemplate = () => {
    GetTemplateParts(nonExistencePartNumber?.data ? nonExistencePartNumber : [], 'Template Parts Database');
  }

  const readUploadFile = (e : any) => {
      e.preventDefault();
      if(e.target.files[0] != undefined){
      const checkExcelName = e.target.files[0].name.split("_");
      if (checkExcelName[0].toUpperCase() == 'TEMPLATE PARTS DATABASE') {
          const reader = new FileReader();
          reader.onload = (e) => {
              const data = e.target?.result;
              const workbook = xlsx.read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const json = xlsx.utils.sheet_to_json(worksheet, {raw: false});
              const jsonData: any =  json === undefined ? [] : json;

              if(jsonData.length !== 0){
                const fieldsToConvert = ["packageQty", "packageCost"];
                const convertFieldsToNumber = (data: any, fieldsToConvert: any) => {
                  return data.map((item: any) => {
                    const newItem = { ...item };
                    fieldsToConvert.forEach((field: any) => {
                      if (newItem[field] !== undefined) {
                        newItem[field] = Number(newItem[field]);
                      }
                    });
                    return newItem;
                  });
                };
                const modifiedArray = convertFieldsToNumber(jsonData, fieldsToConvert);
                  importData.mutate(modifiedArray);
              }
              else {
                showSnackBar('No data found on excel', 'error')
                  window.location.reload();
              };
          };
          reader.readAsArrayBuffer(e.target.files[0]);
      }
      else showSnackBar('Invalid file upload please download Excel template', 'error');
      }
  }

  if (partsData?.isLoading) return <LoadingIndicator show />;

  return (
    <>
      <Stack direction='row'  justifyContent='flex-end' mb={2} gap={2}>
        <GetTemplateButton onClick={handleTemplate} />
        <MultipleUploadButton
          accept=".xlsx, .xls"
          onChange={readUploadFile}
        />
        <ExportButton onClick={handleExport} />
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
        <FormAddButton onClick={goToAddUserAccount} />
     
       
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader sx={{ minWidth: '2300px' }}>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody dataCount={dataCount} loading={partsData?.isLoading} emptyText={emptyText}>
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
      {partsData?.isError && showSnackBar('Network Error', 'error')}
    </>
  );
};

export default PartsTable;
import { createColumnHelper } from "@tanstack/react-table";
import { IconButton, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { useEJOESRApprovalContext } from "../../Context/EJOESRApprovalContext";
import { ESRManpowerColumns } from "../ESR-Manpower/utils/types";

export const useCreateESRManpowerColumns = () => {
  const columnHelper = createColumnHelper<ESRManpowerColumns>();

  const esrManpowerColumns = [
    columnHelper.accessor("id", {
      header: "",
      cell: () => null,
      meta: {
        hidden: true,
      },
    }),
    columnHelper.accessor("mechanicName", {
      id: "mechanicName",
      header: "Manpower",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("manHours", {
      id: "manHours",
      header: "Man Hours",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
  ];

  return esrManpowerColumns;
};

import { useMutation, useQuery } from '@tanstack/react-query';
import { IPMSCodeTaskParts } from '../modules/DatabaseManagement/ServicingDBs/PMSCodeTaskParts/utils/PMSCodeTaskParts-utils';
import PMSCodeTaskPartsApi from '../services/pMSCodeTaskParts';
import { useSnackBar } from '../components/ToastProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { IImportPMSCodeTaskParts, ICreatePMSCodeTaskParts, ExportPMSCodeTaskParts  } from '../modules/DatabaseManagement/ServicingDBs/PMSCodeTaskParts/utils/PMSCodeTaskParts-utils';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';

interface ApiError {
  status: number;
  message: string;
}

interface useGetPMSCodeTaskPartsOptions {
  type?: string;
}

export function useGetPMSCodeTaskPartssQuery(options?: useGetPMSCodeTaskPartsOptions) {
  return useQuery<IPMSCodeTaskParts[]>(['PMSCodeTaskPartss'], () => PMSCodeTaskPartsApi.getPartDescription(), {
    onError(err) {
      console.log(err);
    },
    select(PMSCodeTaskPartss) {
      return options?.type ? PMSCodeTaskPartss.filter(l => l.pmCode === options?.type) : PMSCodeTaskPartss;
    },
  });
}


export function useGetsPMSCodeTaskPartsById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`PMSCodeTaskPartss-${id}`, id], () => PMSCodeTaskPartsApi.getPartDescriptionById(id!));
}

export const useExportPMSCodeTaskPartss = (data: ExportPMSCodeTaskParts[]) => {
  const newPMSCodeTaskPartssList = [];
  
  const PMSCodeTaskPartssList = data;

  for(let i in PMSCodeTaskPartssList){
    let json = {
      pmCode: PMSCodeTaskPartssList[i].pmCode,
      taskType: PMSCodeTaskPartssList[i].taskType,
      taskItem: PMSCodeTaskPartssList[i].taskItem,
      partNumber: PMSCodeTaskPartssList[i].partNumber,
      quantity: PMSCodeTaskPartssList[i].quantity,
      partDescription: PMSCodeTaskPartssList[i].partDescription,
      isActive: PMSCodeTaskPartssList[i].isActive,
    }
    newPMSCodeTaskPartssList.push(json);
  }
  return newPMSCodeTaskPartssList;
}

export function useImportPMSCodeTaskPartss() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((newPMSCodeTaskPartssData: IImportPMSCodeTaskParts[]) => PMSCodeTaskPartsApi.importPartDescription(newPMSCodeTaskPartssData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['PMSCodeTaskPartss'] }).then(() => {
        
        if( data[1].invalidData.length >= 1){
             showSnackBar(data[1].invalidData[0].errorReason, 'error');
     
             setTimeout(() => {
              window.location.reload();
            }, 1000);
        }
        else{
          showSnackBar('Successfully Inserted PMS Code', 'success');
          navigate('/pms-code-task-parts', { replace: true });

          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
     
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}


export function useUpdatePMSCodeTaskPartss() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newPMSCodeTaskPartssData: Partial<ICreatePMSCodeTaskParts>) => PMSCodeTaskPartsApi.updatePMSCodeTaskPartsType(id!, newPMSCodeTaskPartssData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['PMSCodeTaskPartss'] }).then(() => {
        showSnackBar('Successfully Updated PMS Code', 'success');
        setTimeout(() => {
          navigate('/pms-code-task-parts', { replace: true });
        }, 500);
   
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}

export function useDeletePMSCodeTaskPartss( id: string) {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((data: any) => PMSCodeTaskPartsApi.deletePMSCodeTaskParts(id), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['PMSCodeTaskPartss'] }).then(() => {        
        showSnackBar('Successfully Deleted PMS Code', 'success');
        setTimeout(() => {
          navigate('/pms-code-task-parts', { replace: true });
        }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}



export function useCreatePMSCodeTaskPartss() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newPMSCodeTaskPartssData: Partial<ICreatePMSCodeTaskParts>) => PMSCodeTaskPartsApi.createPMSCodeTaskPartsType(newPMSCodeTaskPartssData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['PMSCodeTaskPartss'] }).then(() => {
          showSnackBar('Successfully Inserted PMS Code', 'success');
          setTimeout(() => {
            navigate('/pms-code-task-parts', { replace: true });
          }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


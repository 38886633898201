
import { ICreateTaskType, ITaskTypes } from '../modules/DatabaseManagement/ServicingDBs/ServiceTask/utils/taskTypes-utils';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
  status: string;
  data: ITaskTypes[];
};

export type GetOneReturnType = {
  status: string;
  data: ITaskTypes;
};

const getTaskTypes = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.taskTypes);
  return response.data.data;
};

const getTaskTypesById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.taskTypes}/${id}`
  );
  return response.data.data;
};

const importTaskTypes = async (newTaskTypesData: any) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.taskTypes}/import-data`,
    newTaskTypesData
  );
  return response.data.data;
};

const updateTaskTypes = async (id: string, updateTaskTypesData: Partial<ICreateTaskType>) => {
  delete updateTaskTypesData.id;
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.taskTypes}/${id}`,
    updateTaskTypesData
  );

  return response.data.data;
};

const createTaskTypes = async (newTaskTypesData: Partial<ICreateTaskType>) => {
  delete newTaskTypesData.id;
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.taskTypes,
    newTaskTypesData
  );
  return response.data.data;
};

export default {
  getTaskTypes,
  getTaskTypesById,
  importTaskTypes,
  updateTaskTypes,
  createTaskTypes
};
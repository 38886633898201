import type { ReactNode, CSSProperties } from "react";

import HomeIcon from "@mui/icons-material/Home";
import UsersIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ReportIcon from "@mui/icons-material/Assessment";
import DatabaseIcon from "@mui/icons-material/StorageSharp";
import CircleIcon from "@mui/icons-material/Circle";
import { appColors } from "../../theme";

const { primary, sidebarNavActiveBg } = appColors;

export const activeStyles: CSSProperties = {
  backgroundColor: sidebarNavActiveBg,
  color: primary,
};

export const subItemActiveStyles: CSSProperties = {
  backgroundColor: sidebarNavActiveBg,
  color: primary,
};

export interface SidebarMenu {
  label: string;
  icon?: ReactNode;
  path?: string;
  subItems?: SidebarMenu[];
}

// NOTE: ROUTES CAN BE CHANGED
const menu: SidebarMenu[] = [
  {
    label: "Homepage",
    icon: <HomeIcon fontSize="small" />,
    path: "/home/on-rent",
  },
  {
    label: "Manage My Users",
    icon: <UsersIcon fontSize="small" />,
    subItems: [
      {
        label: "Users",
        path: "/users",
      },
      {
        label: "Roles and Permissions",
        path: "/roles",
      },
    ],
  },
  {
    label: "View My Dashboards + Tasks",
    icon: <DashboardIcon fontSize="small" />,
    subItems: [
      {
        label: "My Equipment Requests",
        subItems: [
          {
            label: "My ERFs",
            path: "/my-equipment-requests/my-erf",
          },
          {
            label: "My EXFs",
            path: "/my-equipment-requests/my-exf",
          },
          {
            label: "My EORRs",
            path: "/my-equipment-requests/my-eorr",
          },
        ],
      },
      {
        label: "My On Rent Equipment",
        path: "/my-on-rent-equipment",
      },
      {
        label: "My EURs",
        path: "/eur",
      },
      {
        label: "EJO Management",
        path: "/ejo-management",
      },
      {
        label: "My Equipment Certificates",
        path: "/equipment-certificates",
      },
      {
        label: "My Operator Licenses",
        path: "/operator-licenses",
      },
      {
        label: "My EJOs & ESRs",
        subItems: [
          {
            label: "EJOs Pending Approval",
            path: "/my-ejo-and-esr/ejo-pending",
          },
          {
            label: "EJOs Approved, ESR Uncreated",
            path: "/my-ejo-and-esr/ejo-approved-and-esr-uncreated",
          },
          {
            label: "ESR Created, Pending Approval",
            path: "/my-ejo-and-esr/esr-created-and-pending",
          },
          {
            label: "ESR Approved, Unstarted",
            path: "/my-ejo-and-esr/esr-approved-and-unstarted",
          },
          {
            label: "ESRs WIP",
            path: "/my-ejo-and-esr/esr-wip",
          },
        ],
      },
    ],
  },
  {
    label: "Get My Reports",
    icon: <ReportIcon fontSize="small" />,
    subItems: [
      {
        label: "Equipment Use & Performance Reports",
        subItems: [
          {
            label: "Down, Availability Utilization, Idle (DAIU) Report",
            path: "/equipment-use-and-performance-reports/daui",
          },
          {
            label: "Historical Rentals",
            path: "/equipment-use-and-performance-reports/historical-rentals",
          },
        ],
      },
      {
        label: "Equipment Cost Reports",
        subItems: [
          {
            label: "Cost of Parts Consumed (CPC) Report",
            path: "/equipment-cost-reports/cpc",
          },
          {
            label: "Cost of Labor Consumed (CLC) report",
            path: "/equipment-cost-reports/clc",
          },
          {
            label: "Equipment Fuel Consumption",
            path: "/equipment-cost-reports/equipment-fuel-consumption",
          },
        ],
      },
    ],
  },
  {
    label: "Manage My Databases",
    icon: <DatabaseIcon fontSize="small" />,
    subItems: [
      {
        label: "Equipment Databases",
        subItems: [
          {
            label: "Equipment Master",
            path: "/equipment",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "HE Certificate Type Master",
            path: "/he-certificate-type",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
        ],
      },
      {
        label: "Servicing Databases",
        subItems: [
          {
            label: "PMS Code Master",
            path: "/pms-code",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "PMS Code + Task + Parts Master",
            path: "/pms-code-task-parts",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "Parts Master",
            path: "/parts",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "Service Request Type Master",
            path: "/service-request-type",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "Service Task Master",
            path: "/service-task",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "Labor Type + Rate Master",
            path: "/labor-type-rate",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
        ],
      },
      {
        label: "Project Databases",
        subItems: [
          {
            label: "Project Master",
            path: "/project",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "Project Activity + Cost Code Master",
            path: "/project-activity-cost-code",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "Equipment Planned Shift Hours",
            path: "/equipment-planned-shift-hours",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
        ],
      },
      {
        label: "Personnel Databases",
        subItems: [
          {
            label: "Mechanic Master",
            path: "/mechanic",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "HE Operator Master",
            path: "/he-operator",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "HE Operator Certificate Type Master",
            path: "/he-operator-certificate-type",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
        ],
      },
      {
        label: "Other Databases",
        subItems: [
          {
            label: "Meter Type + Interval Type Master",
            path: "/meter-type-interval-type",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "Fuel Type Master",
            path: "/fuel-type",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "Location Master",
            path: "/location",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "Rental Rates Master",
            path: "/rental-rates",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "Fuel (Per Liter) Update Master",
            path: "/fuel-update",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
          {
            label: "Fuel Cost Code Master",
            path: "/fuel-cost-code",
            icon: (
              <CircleIcon fontSize="small" sx={{ transform: "scale(0.4)" }} />
            ),
          },
        ],
      },
    ],
  },
];

export default menu;

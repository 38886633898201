import * as yup from 'yup';
import { ICreateLocation } from './Location-utils';

export const LocationInitialValues: ICreateLocation = {
  id: '',
  name: '',
  streetLocation: '',
  type: '',
  isActive: '',
};
export const LocationSchema = yup.object({
    name: yup.string()
    .trim('The Name cannot include leading and trailing spaces')
    .strict(true)
    .min(1,'The Name to be at least 1 Alphabet or Numerical')
    .required('The Name is required'),

    streetLocation: yup.string()
    .trim('The Street Location include leading and trailing spaces')
    .strict(true)
    .min(1,'The Street Location to be at least 1 Alphabet or Numerical')
    .required('The Street Location is required'),

    type: yup.string().required('The Type is required'),

    isActive: yup.boolean().required('Status is required'),
  });
import {
  apiClient,
  apiEndpoints,
  loginApiClient,
  changePasswordApiClient,
  forgotPasswordApiClient,
} from "./apiClient";
import { ResetPasswordField } from "../modules/Auth/utils/types";

export interface AuthUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  dateCreated: Date;
  accessToken: string;
  roleId: string;
}

type AuthResponse = {
  status: string;
  data: AuthUser;
};

type LoginResponse = {
  status: string;
  user: AuthUser;
};

const login = async (email: string, password: string) => {
  const response = await loginApiClient.post<LoginResponse>(
    apiEndpoints.login,
    {
      email,
      password,
    }
  );

  localStorage.setItem(
    "accessToken",
    JSON.stringify(response.data.user.accessToken)
  );

  return response.data.user;
};

const getLoggedInUser = async (id: string, accessToken: string) => {
  if (!id) return null;
  const response = await apiClient.get<AuthResponse>(
    `${apiEndpoints.users}/${id}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return {
    ...response.data.data,
    accessToken,
  };
};

const logout = async () => {
  await apiClient.get("/logout");
  localStorage.removeItem("accessToken");
};

// password-related
const forgotPassword = async (email: string) => {
  const response = await forgotPasswordApiClient.put<{ data: string }>(
    apiEndpoints.forgotPassword,
    { email }
  );

  return response.data.data;
};

const setNewPassword = async (
  passwordData: ResetPasswordField,
  token: string | undefined
) => {
  const response = await changePasswordApiClient.put<{ message: string }>(
    `${apiEndpoints.resetPassword}/${token}`,
    passwordData
  );
  return response.data.message;
};

const checkPasswordToken = async (token?: string) => {
  console.log(token);
  const response = await forgotPasswordApiClient.post<{ token: string }>(
    apiEndpoints.checkpasswordToken,
    { token }
  );

  return response.data;
};

const authApi = {
  login,
  logout,
  getLoggedInUser,
  setNewPassword,
  forgotPassword,
  checkPasswordToken,
};

export default authApi;

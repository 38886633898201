import { useQuery, useMutation } from '@tanstack/react-query';
import { ITaskTypes, ExportTaskType, IImportTaskType, ICreateTaskType, DeleteTaskType } from '../modules/DatabaseManagement/ServicingDBs/ServiceTask/utils/taskTypes-utils';
import tasktypeApi from '../services/tasktypes';
import { queryClient } from '../react-query/queryClient';
import { useSnackBar } from '../components/ToastProvider';
import { useNavigate, useParams } from 'react-router';
import { AxiosError } from 'axios';

interface ApiError {
  status: number;
  message: string;
}
interface useGetTaskTypeOptions {
  isActive?: boolean;
  taskType?: string;
}

export function useGetTaskTypesQuery(options?: useGetTaskTypeOptions) {
  return useQuery<ITaskTypes[]>(['TaskTypes'], () => tasktypeApi.getTaskTypes(), {
    onError(err) {
      console.log(err);
    },
    select(TaskTypes) {
      if (options?.isActive) {
        const uniqueTaskTypes = TaskTypes.filter(
          (obj, idx) => TaskTypes.findIndex((eq) => eq.isActive === obj.isActive) === idx
        )
        return uniqueTaskTypes;
      } else {
        return TaskTypes;
      }
    }
  });
}


export function useGetTaskTypesById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`tasktypes-${id}`, id], () => tasktypeApi.getTaskTypesById(id!));
}

export const useExportTaskTypes = (data: ExportTaskType[]) => {
  const newTaskTypesList = [];
  
  const TaskTypesList = data;

  for(let i in TaskTypesList){
    let json = {
      taskTypeNo: TaskTypesList[i].taskTypeNo,
      taskType: TaskTypesList[i].taskType,
      isActive: TaskTypesList[i].isActive,
  
    }
    newTaskTypesList.push(json);
  }
  return newTaskTypesList;
}

export function useImportTaskTypes() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((newTaskTypesData: IImportTaskType[]) => tasktypeApi.importTaskTypes(newTaskTypesData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['TaskTypes'] }).then(() => {
        
        if( data[1].invalidData.length >= 1){
             showSnackBar(data[1].invalidData[0].errorReason, 'error');
        }
        else{
          showSnackBar('Successfully Inserted Task Types', 'success');
          navigate('/service-task', { replace: true });
          window.location.reload();
        }
     
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}


export function useUpdateTaskTypes() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newTaskTypesData: Partial<ICreateTaskType>) => tasktypeApi.updateTaskTypes(id!, newTaskTypesData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['TaskTypes'] }).then(() => {
        showSnackBar('Successfully Updated Task Type', 'success');
        navigate('/service-task', { replace: true });
   
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useDeleteTaskTypes(data: any, id: string) {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((data: any) => tasktypeApi.updateTaskTypes(id!, data), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['TaskTypes'] }).then(() => {        
        showSnackBar('Successfully Deleted Task Type', 'success');
        navigate('/service-task', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useCreateTaskTypes() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newTaskTypesData: Partial<ICreateTaskType>) => tasktypeApi.createTaskTypes(newTaskTypesData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['TaskTypes'] }).then(() => {
          showSnackBar('Successfully Inserted Task Type', 'success');
          setTimeout(() => {
            navigate('/service-task', { replace: true });
          }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}
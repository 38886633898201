import PageContainer from '../../../../components/common/PageContainer';
import ServiceRequestTypeTable from './ServiceRequestTypeTable';

function ServiceRequestTypePage() {
  return (
    <>
      <PageContainer title='Servicing Databases' subTitle='Service Request Type Master' showDate>
        Service Request Type Master
        <ServiceRequestTypeTable />
      </PageContainer>
    </>
  );
}

export default ServiceRequestTypePage;

import { useQuery, useMutation } from '@tanstack/react-query';
import { ISRT, ISRTExport } from '../modules/DatabaseManagement/ServicingDBs/ServiceRequestType/utils/serviceRequestType-utils';
import serviceRequestTypeApi from '../services/dataBaseManagement/servicingDb/serviceRequestType';
import { useSnackBar } from '../components/ToastProvider';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router';

interface ApiError {
    status: number;
    message: string;
}

export function useGetServiceRequestTypeQuery() {
    return useQuery<ISRT[]>(['serviceRequestType'], () => serviceRequestTypeApi.getServiceRequestType(), {
      onError(err) {
        console.log(err);
      },
      select(parts) {
          return parts;
      }
    });
}

export function useGetServiceRequestTypeById() {
    const { id } = useParams<{ id: string }>();
    return useQuery([`serviceRequestType-${id}`, id], () => serviceRequestTypeApi.getServiceRequestTypeById(id!));
}

export function useUpdateServiceRequestType() {
    const { showSnackBar } = useSnackBar();
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
  
    return useMutation((newServiceRequestTypeData: Partial<ISRT>) => serviceRequestTypeApi.updateServiceRequestType(id!, newServiceRequestTypeData), {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ['serviceRequestType'] }).then(() => {
          showSnackBar('Successfully Updated Service Request Type', 'success');
          setTimeout(() => {
            navigate('/service-request-type', { replace: true });
          }, 200);
        });
      },
      onError(err) {
        const errorData = (err as AxiosError<{ error: string }>).response?.data.error;
  
        if (err && errorData) {
          showSnackBar(errorData, 'error');
        }
      },
    });
}

export function useDeleteServiceRequestType(data: any, id: string) {
    const { showSnackBar } = useSnackBar();
    const navigate = useNavigate();
  
    return useMutation((data: any) => serviceRequestTypeApi.updateServiceRequestType(id!, data), {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ['serviceRequestType'] }).then(() => {
            showSnackBar('Successfully Deleted Service Request Type', 'success');
            navigate('/service-request-type', { replace: true });
        });
      },
      onError(err) {
        const errorData = (err as AxiosError<{ error: string }>).response?.data.error;
  
        if (err && errorData) {
          showSnackBar(errorData, 'error');
        }
      },
    });
  }

export function useCreateServiceRequestType() {
  
    const { showSnackBar } = useSnackBar();
    const navigate = useNavigate();
    
    return useMutation((newServiceRequestTypeData: Partial<ISRT>) => serviceRequestTypeApi.createServiceRequestType(newServiceRequestTypeData), {
     
      onSuccess(data: any) {
        queryClient.invalidateQueries({ queryKey: ['serviceRequestType'] }).then(() => {
            showSnackBar('Successfully Inserted Service Request Type', 'success');
            setTimeout(() => {
                navigate('/service-request-type', { replace: true });
            }, 200);
        });
      },
      onError(err) {
        const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;
        let errorType: any;
        errorType = errorData
        if(errorType[0].message == undefined){
          showSnackBar(errorType, 'error');
        }
        else{
          showSnackBar(errorType[0].message, 'error');
        }
      },
    });
}

export const useExportServiceRequestType = (data: ISRTExport[]) => {
    const newServiceRequestTypeList = [];
    const ServiceRequestTypeList = data;
  
    for(let i in ServiceRequestTypeList){
      let json = {
        requestName : ServiceRequestTypeList[i].requestName,
        requestType : ServiceRequestTypeList[i].requestType,
        isActive : ServiceRequestTypeList[i].isActive ? 'Active' : 'Inactive'
      }
      newServiceRequestTypeList.push(json);
    }
    return newServiceRequestTypeList;
}

export function useImportServiceRequestType() {

    const { showSnackBar } = useSnackBar();

    return useMutation((newServiceRequestTypeData: ISRT[]) => serviceRequestTypeApi.importServiceRequestType(newServiceRequestTypeData), {
        
        onSuccess(data: any) {
            queryClient.invalidateQueries({ queryKey: ['serviceRequestType'] }).then(() => {
                if( data[1].invalidData.length >= 1){
                    showSnackBar(data[1].invalidData[0].errorReason, 'error');
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
                else{
                    showSnackBar('Successfully Inserted Service Request Type', 'success');
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            });
        },
        onError(err) {
        const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

        if (err && errorData) {
            showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
        }
        },
    });
}
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  IEJO,
  IESR,
} from "../modules/MyDashboardsPlusTasks/EJO-ESR/utils/types";
import {
  EJOManagementColumns,
  EJOManagementFields,
  EJORequestBody,
} from "../modules/MyDashboardsPlusTasks/EJO-ESR/EJOManagement/utils/types";
import { dateDifference } from "../utils/helpers";
import ejoAPI from "../services/dashboardPlusTasks/ejo";
import { CollectionsBookmarkRounded } from "@mui/icons-material";
import { useSnackBar } from "../components/ToastProvider";
import { useNavigate, useParams } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { queryClient } from "../react-query/queryClient";
import ejoApi from "../services/dashboardPlusTasks/ejo";
import { EJOSR } from "../modules/MyDashboardsPlusTasks/EJO-ESR/EJOManagement/EJO-SR/utils/types";
import { useEJOManagementContext } from "../modules/MyDashboardsPlusTasks/EJO-ESR/EJOManagement/Context/EJOManagementContext";
import { useAuth } from "../components/AuthProvider";

interface ErrorResponseData {
  error: string;
}

export function useGetEJOQueryPerStatus(status?: string) {
  return useQuery<IEJO[]>(["ejos"], () => ejoAPI.getAllOperableEJO(status), {
    onError(err) {
      console.log(err);
    },
  });
}

export const useFormatGetEJOQuery = (data: IEJO[], status: string) => {
  const newEJOList = [];

  const EJOList = data.map((e) =>
    e.serviceRequestList.map((sr) => {
      const daysElapsedVariable =
        status === "Approved" ? e.dateApproved : e.dateCreated;

      return {
        ejoNumber: e.ejoNumber,
        equipmentNo: e.equipmentNo,
        projectLocation: e.projectLocation.name,
        serviceRequestNo: sr.serviceRequestNo,
        serviceRequestType: sr.serviceRequest,
        remarks: sr.remarks,
        daysElapsed: dateDifference(new Date(), daysElapsedVariable),
      };
    })
  );

  for (const n of EJOList) {
    for (const ne of n) {
      newEJOList.push(ne);
    }
  }

  return newEJOList;
};

export const useFormatGetEJOWithoutESRQuery = (
  ejoData: IEJO[],
  esrData: IESR[],
  status: string
) => {
  // * kunin lahat ng ejo na walang esr
  const ejoWithoutESR = ejoData.filter(
    (e) => !esrData.map((esr) => esr.ejoId).includes(e.id)
  );

  console.log(
    "EJO List: ",
    ejoData.map((ejo) => {
      return {
        id: ejo.id,
        ejoNo: ejo.ejoNumber,
        sr: ejo.serviceRequestList,
        srCount: ejo.serviceRequestList.length,
      };
    })
  );
  console.log(
    "ESR List: ",
    esrData.map((esr) => {
      return {
        id: esr.id,
        ejoId: esr.ejoId,
        ejoNo: esr.ejoNumber,
        sr: esr.serviceRequestId,
      };
    })
  );
  console.log(
    "EJO w/o ESR List: ",
    ejoWithoutESR.map((ejo) => {
      return {
        id: ejo.id,
        ejoNo: ejo.ejoNumber,
        sr: ejo.serviceRequestList,
        srCount: ejo.serviceRequestList.length,
      };
    })
  );

  const newEJOList = [];

  const EJOList = ejoWithoutESR.map((e) =>
    e.serviceRequestList.map((sr) => {
      const daysElapsedVariable =
        status === "Approved" ? e.dateApproved : e.dateCreated;

      return {
        ejoNumber: e.ejoNumber,
        equipmentNo: e.equipmentNo,
        projectLocation: e.projectLocation.name,
        serviceRequestNo: sr.serviceRequestNo,
        serviceRequestType: sr.serviceRequest,
        remarks: sr.remarks,
        daysElapsed: dateDifference(new Date(), daysElapsedVariable),
      };
    })
  );

  for (const n of EJOList) {
    for (const ne of n) {
      newEJOList.push(ne);
    }
  }

  return newEJOList;
};

export const useFormatGetAllEJOQuery = (ejoData: IEJO[]) => {
  //* Create new array to match column type
  const tableColumnDatas = ejoData.map((data) => {
    // ? If approved na yung EJO Request sa dateApproved na titingin for daysElapsed
    const daysElapsedVariable =
      data.status === "Approved" ? data.dateApproved : data.dateCreated;

    const formattedData: EJOManagementColumns = {
      id: data.id,
      ejoNumber: data.ejoNumber,
      equipmentNo: data.equipmentNo,
      projectLocation: data.projectLocation?.name,
      daysElapsed: dateDifference(new Date(), daysElapsedVariable),
    };

    return formattedData;
  });

  return tableColumnDatas;
};

//* Used in EJO-ESR
export const useGetEJOById = () => {
  const { id } = useParams<{ id: string }>();

  return useQuery<IEJO>([`ejo-${id}`, id], () => ejoApi.getEJOById(id!), {
    enabled: id ? true : false,
  });
};

/**
 *  * function used for EJO MANAGEMENT
 *  * Used for getting all EJO Approved and Unapproved
 */
export function useGetAllEJOQuery() {
  return useQuery<IEJO[]>(["ejos"], () => ejoAPI.getAllOperableEJO(), {
    onError(err) {
      console.log(err);
    },
  });
}

/**
 *  @params id: data from ejo management table
 *  * function used for EJO MANAGEMENT
 */
export const useGetCurrentEJOSelected = (id: string | undefined) => {
  return useQuery<EJORequestBody>(
    [`curr-ejo-${id}`, id],
    () => ejoApi.getCurrentEJOById(id!),
    {
      enabled: id ? true : false,
    }
  );
};

/**
 *  @params id: data from ejo management table
 *  * function used for EJO MANAGEMENT
 */
export const useGetAllServiceRequests = (id: string | undefined) => {
  return useQuery<EJOSR[]>(
    [`get-all-${id}`, id],
    () => ejoApi.getAllServiceRequests(id!),
    {
      enabled: id ? true : false,
    }
  );
};

/**
 * For validation, use case: When in edit page and reload suddenly, this function will help to refetch the datas
 * ? Created custom hook to reuse in EJOManagementForm
 * @returns goToEditEJO function
 */
export const useNavigateToEdit = () => {
  const { setCurrentEJOSelected, setServiceRequestsArray } =
    useEJOManagementContext();
  const navigate = useNavigate();

  /**
   * @param id : Parameter id/EJO id
   * @param currentEjo : Current Ejo selected
   * @param currentSrList : Current Service requests of that Ejo
   */
  const goToEditEJO = (
    id: string | undefined,
    currentEjo: EJORequestBody | undefined,
    currentSrList: EJOSR[] | undefined
  ) => {
    //* Update the context state to current EJO selected
    if (currentEjo && currentSrList) {
      const formattedServiceRequests: EJOSR[] = currentSrList.map(
        (sr, index) => {
          //* Format to match the type of the useGetAllServiceRequests state
          const formattedServiceRequest: EJOSR = {
            id: sr.id,
            serviceRequest: sr.serviceRequest,
            serviceRequestTypeId: sr.serviceRequestTypeId,
            remarks: sr.remarks,
            pmCodeId: sr.pmCodeId && sr.pmCodeId,
            pmCode: sr.pmCode && sr.pmCode,
          };

          return formattedServiceRequest;
        }
      );

      //* Assign the values to use in EJOManagementForm(Edit)
      setCurrentEJOSelected(currentEjo);
      setServiceRequestsArray(formattedServiceRequests);
    }
    navigate(`/ejo-management/edit/${id}`);
  };

  return { goToEditEJO };
};

/**
 *  * function used for EJO MANAGEMENT
 */
export function useCreateEJO() {
  const { serviceRequestsArray, setServiceRequestsArray } =
    useEJOManagementContext();

  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  return useMutation(
    (newEJOData: EJORequestBody) => {
      return ejoApi.createEJO(newEJOData);
    },
    {
      onSuccess(data) {
        queryClient.setQueryData<IEJO[]>(
          ["ejos"],
          (oldQueryData) => oldQueryData && [...oldQueryData, data]
        );

        showSnackBar(
          `${data.ejoNumber} has been successfully created`,
          "success"
        );
        setServiceRequestsArray([]);

        localStorage.removeItem("ErrorActivityData");
        setTimeout(() => {
          navigate("/ejo-management", { replace: true });
        }, 400);
      },
      onError(err) {
        const errorData = (err as AxiosError<{ error: string }>).response?.data
          .error;

        if (err && errorData) {
          showSnackBar(errorData, "error");
          localStorage.setItem(
            "ErrorActivityData",
            JSON.stringify(serviceRequestsArray)
          );
        }
      },
    }
  );
}

export function useUpdateEJO() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { serviceRequestsArray, setServiceRequestsArray } =
    useEJOManagementContext();

  return useMutation(
    (updatedEJOData: EJORequestBody) => {
      if (updatedEJOData.serviceRequestList.length <= 0) {
        // If the length is empty, throw an error
        throw new Error("Service Request List cannot be empty");
      }

      return ejoAPI.updateEJO(id!, updatedEJOData);
    },
    {
      onSuccess(data) {
        queryClient.invalidateQueries({ queryKey: ["ejos"] }).then(() => {
          showSnackBar(
            `${data.ejoNumber} has been successfully updated`,
            "success"
          );
          setServiceRequestsArray([]);
          navigate("/ejo-management", { replace: true });
        });
      },
      onError(err: unknown) {
        let errorMessage = "An unexpected error occurred.";

        // Type guard for AxiosError
        if (err && axios.isAxiosError(err)) {
          const axiosError = err as AxiosError<ErrorResponseData>;
          // Safely access response.data with type assertion
          errorMessage = axiosError.response?.data.error || axiosError.message;
        } else if (err instanceof Error) {
          // General Error case
          errorMessage = err.message;
        }

        // Show error message and store data if errorMessage is not the default message
        if (errorMessage !== "An unexpected error occurred.") {
          showSnackBar(errorMessage, "error");
          localStorage.setItem(
            "ErrorActivityData",
            JSON.stringify(serviceRequestsArray)
          );
        }
      },
    }
  );
}

import PageContainer from '../../../../components/common/PageContainer';
import PMSCodeTaskPartsTable from './PMSCodeTaskPartsTable';

function PMSCodeTaskPartsPage() {
  return (
    <>
      <PageContainer title='Servicing Databases' subTitle='PMS Code + Task + Parts Master' showDate>
        PMS Code + Task + Parts Master
        <PMSCodeTaskPartsTable />
      </PageContainer>
    </>
  );
}

export default PMSCodeTaskPartsPage;

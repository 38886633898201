import PageContainer from '../../../../components/common/PageContainer';
import OperatorTable from './HEOperatorTable';

function HEOperatorPage() {
  return (
    <>
      <PageContainer title='Personnel Databases' subTitle='HE Operator Master' showDate>
        <OperatorTable />
      </PageContainer>
    </>
  );
}

export default HEOperatorPage;

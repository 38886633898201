import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { IMechanic } from './Mechanic-utils';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import MechanicDeleteModal from './MechanicDeleteModal';

interface Ownprops {
  Mechanics: IMechanic;
}

const MechanicActionButton = ({ Mechanics }: Ownprops) => {
  const { id } = Mechanics;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const goToMechanicForm = () => navigate(`/mechanic/${id}`);
  const goToModalDelete = () => setOpen(true);
  
  return (
    <Stack direction='row' gap={1}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goToMechanicForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top'>
        <IconButton color='warning' aria-label='delete' onClick={goToModalDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
      <MechanicDeleteModal setOpen={setOpen} open={open} Mechanics={Mechanics} />
    </Stack>
  );
};

export default MechanicActionButton;

import { useMutation, useQuery } from "@tanstack/react-query";
import {
  IESRApproval,
  IEjoManPowerApproval,
} from "../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/utils/types";
import { dateDifference } from "../utils/helpers";
import esrAPI from "../services/dashboardPlusTasks/esrApproval";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ESR,
  ESRColumns,
} from "../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/shared/ESR/utils/types";
import { useSnackBar } from "../components/ToastProvider";
import { queryClient } from "../react-query/queryClient";
import axios, { AxiosError } from "axios";
import esrApi from "../services/dashboardPlusTasks/esrApproval";

interface ErrorResponseData {
  error: string;
}

// FOR APPROVAL MODULE
/**
 * @param status: Unapproved | Approved | Started | Completed
 * @return all esr along with their manpower and es
 * ? Used for getting all esr data
 * * This is called in table
 */
export function useGetAllESR(status?: string) {
  return useQuery<ESR[]>(["esr"], () => esrAPI.getAllESRForApproval(status), {
    onError(err) {
      console.log(err);
    },
  });
}

/**
 * @param ESR[]: esr data from useGetAllESR (all data included)
 * @param status: Unapproved | Approved | Started | Completed
 * @return formatted esr array that will match the ESRColumn type for table in UI
 * ? Used for getting all esr data formatted
 * * This is called in table
 */
export const useFormatESRData = (data: ESR[], status: string) => {
  try {
    const formattedESRArray: ESRColumns[] = data.map((d) => {
      const daysElapsedVariable =
        status === "Approved"
          ? d.dateApproved
          : status === "Started"
          ? d.dateStarted
          : d.dateCreated;

      const computedDaysElapsed: number | string = dateDifference(
        new Date(),
        String(daysElapsedVariable)
      );

      return {
        id: d.id,
        esrNumber: d.esrNumber,
        equipmentNo: d.equipmentNo,
        projectLocation: d.projectLocation,
        daysElapsed: computedDaysElapsed,
      };
    });

    return formattedESRArray;
  } catch (e) {
    return [];
  }
};

/**
 * @return the current esr selected
 * ? Used for getting the esr,
 * * value:
 * - id,
 * - serviceRequestId,
 * - equipmentId,
 * - isWarrantyJob,
 * - esrNumber,
 * - dateCreated,
 * - dateNeeded
 * * This is called in ESRApprovalForm
 */
export function useGetESRByIDForApproval() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`esr-${id}`, id], () => esrAPI.getESRByIdForApproval(id!), {
    enabled: id ? true : false,
  });
}

/**
 * @param id: serviceRequestId that comes from the return of useGetESRByID
 * @return the service requested of the current esr
 * ? Used for getting the service requested,
 * * values:
 * - serviceRequested
 * - remarks
 * * This is called in ESRApprovalForm
 */
export function useGetServiceRequestByESRId(id: string) {
  return useQuery(
    [`service-requests-by-esr${id}`, id],
    () => esrAPI.getServiceRequestByESRId(id!),
    {
      enabled: id ? true : false,
    }
  );
}

/**
 * @return all equipment services of the current esr selected
 * ? Used for getting the equipment services
 * * This is called in ESRApprovalForm
 */
export function useGetESREquipmentServices() {
  const { id } = useParams<{ id: string }>();
  return useQuery(
    [`esr-equipment-services-${id}`, id],
    () => esrAPI.getESREquipmentServices(id!),
    {
      enabled: id ? true : false,
    }
  );
}

/**
 * @return all manpower of the current esr selected
 * ? Used for getting all the manpower
 * * This is called in ESRApprovalForm
 */
export function useGetESRManpower() {
  const { id } = useParams<{ id: string }>();
  return useQuery(
    [`esr-manpower-${id}`, id],
    () => esrAPI.getESRManpower(id!),
    {
      enabled: id ? true : false,
    }
  );
}

export function useUpdateESR() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);

  return useMutation(
    (updatedESRData: ESR) => {
      // TODO FORMAT THE ESR, EQUIPMENT SERVICE ARRAY, AND MANPOWER ARRAY TO MATCH REQUEST BODY

      // if (updatedESRData.serviceRequestList.length <= 0) {
      //   throw new Error("Service Request list cannot be empty");
      // }

      return esrApi.updateESR(id!, updatedESRData);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ["ejo", "esr"] }).then(() => {
          showSnackBar("Successfully Approved ESR", "success");
          navigate(`/${pathSegments[0]}/${pathSegments[1]}`);
        });
      },
      onError(err: unknown) {
        let errorMessage = "An unexpected error occurred.";

        if (err && axios.isAxiosError(err)) {
          const axiosError = err as AxiosError<ErrorResponseData>;
          errorMessage = axiosError.response?.data.error || axiosError.message;
        } else if (err instanceof Error) {
          errorMessage = err.message;
        }

        if (errorMessage !== "An unexpected error occurred.") {
          showSnackBar(errorMessage, "error");
        }
      },
    }
  );
}

// ? OLD HOOKS

export function useGetESRQuery(status?: string) {
  return useQuery<IESRApproval[]>(["esr"], () => esrAPI.getAllESR(status), {
    onError(err) {
      console.log(err);
    },
  });
}

export const useFormatGetESRQuery = (data: IESRApproval[], status: string) => {
  const newESRList = data.map((d) => {
    const daysElapsedVariable =
      status === "Approved"
        ? d.dateApproved
        : status === "Started"
        ? d.dateStarted
        : d.dateCreated;

    return {
      id: d.id,
      esrNumber: d.esrNumber,
      equipmentNo: d.equipmentNo,
      projectLocation: d.projectLocation,
      daysElapsed: dateDifference(new Date(), daysElapsedVariable),
    };
  });

  return newESRList;
};

export function useGetEjoManpowerQuery() {
  return useQuery<IEjoManPowerApproval[]>(
    ["EjoManPower"],
    () => esrAPI.getEjoManpower(),
    {
      onError(err) {
        console.log(err);
      },
    }
  );
}

export const useExportEjoManpower = (data: IEjoManPowerApproval[]) => {
  const newEjoManpowerList = [];

  const EjoManpowerList = data;

  for (let i in EjoManpowerList) {
    let json = {
      daysSinceEjoOpened: EjoManpowerList[i].daysSinceEjoOpened,
      ejoNumber: EjoManpowerList[i].ejoNumber,
      equipmentNo: EjoManpowerList[i].equipmentNo,
      esrDateApproved: EjoManpowerList[i].esrDateApproved
        ? moment(EjoManpowerList[i].esrDateApproved).format("MM/DD/yyyy")
        : "N/A",
      esrDateCreated: EjoManpowerList[i].esrDateCreated
        ? moment(EjoManpowerList[i].esrDateCreated).format("MM/DD/yyyy")
        : "N/A",
      ejoDateApproved: EjoManpowerList[i].ejoDateApproved
        ? moment(EjoManpowerList[i].ejoDateApproved).format("MM/DD/yyyy")
        : "N/A",
      ejoDateNeeded: EjoManpowerList[i].ejoDateNeeded
        ? moment(EjoManpowerList[i].ejoDateNeeded).format("MM/DD/yyyy")
        : "N/A",
      esrNumber: EjoManpowerList[i].esrNumber,
      manPower: EjoManpowerList[i].manPower
        ? EjoManpowerList[i].manPower
        : "N/A",
      pmCode: EjoManpowerList[i].pmCode
        ? EjoManpowerList[i].pmCode
        : EjoManpowerList[i].srRemarks,
      projectLocation: EjoManpowerList[i].projectLocation,
      roleType: EjoManpowerList[i].roleType,
      serviceRequest: EjoManpowerList[i].serviceRequest
        ? EjoManpowerList[i].serviceRequest
        : "N/A",
      serviceRequestNo: EjoManpowerList[i].serviceRequestNo,
    };
    newEjoManpowerList.push(json);
  }
  return newEjoManpowerList;
};

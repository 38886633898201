import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { IPMSCodeTaskParts} from './PMSCodeTaskParts-utils';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import PMSCodeTaskPartsDeleteModal from './PMSCodeTaskPartsDeleteModal';

interface Ownprops {
  PMSCodeTaskParts: IPMSCodeTaskParts;
}

const PMSCodeTaskPartsActionButton = ({ PMSCodeTaskParts }: Ownprops) => {
  const { id } = PMSCodeTaskParts;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const goToPMSCodeTaskPartsForm = () => navigate(`/pms-code-task-parts/${id}`);
  const goToModalDelete = () => setOpen(true);
  
  return (
    <Stack direction='row' gap={1}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goToPMSCodeTaskPartsForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top'>
        <IconButton color='warning' aria-label='delete' onClick={goToModalDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
      <PMSCodeTaskPartsDeleteModal setOpen={setOpen} open={open} PMSCodeTaskParts={PMSCodeTaskParts} />
    </Stack>
  );
};

export default PMSCodeTaskPartsActionButton;

import { useEffect, useState } from "react";
import { useNavigate, useParams, Outlet } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AppTextField from "../../../../components/common/forms/AppTextField";
import AppDatePicker from "../../../../components/common/forms/AppDatePicker";
import AppSelect from "../../../../components/common/forms/AppSelect";
import PageContainer from "../../../../components/common/PageContainer";
import { IEquipments } from "./utils/equipment-utils";
import { SelectOptionType } from "../../../../components/common/forms/form-types";
import { equipmentInitialValues, EquipmentSchema } from "./utils/schema";

import {
  useGetEquipmentById,
  useGetProductFamilyQuery,
  useUpdateEquipment,
  useCreateEquipment,
} from "../../../../hooks/useEquipments";
import { appColors, appStyles } from "../../../../theme";

const EquipmentForm = () => {
  const navigate = useNavigate();
  const currentEquipment = useGetEquipmentById();
  const productFamilyData = useGetProductFamilyQuery();
  const updateEquipment = useUpdateEquipment();
  const createEquipment = useCreateEquipment();

  const ownershipTypeOptions: SelectOptionType[] = [
    { id: "Rental", label: "Rental", value: "Rental" },
    { id: "Owned", label: "Owned", value: "Owned" },
  ];
  const statusOptions: SelectOptionType[] = [
    { id: "true", label: "Active", value: "true" },
    { id: "false", label: "Inactive", value: "false" },
  ];
  // DITO MO KUNIN OPTIONS
  const productFamilyOptions: SelectOptionType[] =
    productFamilyData?.data === undefined
      ? []
      : productFamilyData.data.map((e) => {
          return {
            id: e.id,
            label: e.categoryName,
            value: e.id,
          };
        });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm<IEquipments>({
    defaultValues: currentEquipment.data!
      ? currentEquipment.data
      : equipmentInitialValues,
    mode: "onChange",
    resolver: yupResolver(EquipmentSchema),
  });

  useEffect(() => {
    if (!currentEquipment.data) return;
    setValue("equipmentNo", currentEquipment.data?.equipmentNo);
    setValue("category", currentEquipment.data?.category);
    setValue("description", currentEquipment.data?.description);
    setValue("make", currentEquipment.data?.make);
    setValue("model", currentEquipment.data?.model);
    setValue("capacity", currentEquipment.data?.capacity);
    setValue("class", currentEquipment.data?.class);
    setValue("serialNumber", currentEquipment.data?.serialNumber);
    setValue("engineNumber", currentEquipment.data?.engineNumber);
    setValue("productFamilyId", currentEquipment.data?.productFamilyId);
    setValue("chassisNumber", currentEquipment.data?.chassisNumber);
    setValue("supplier", currentEquipment.data?.supplier);
    setValue("conductionSticker", currentEquipment.data?.conductionSticker);
    setValue("plateNumber", currentEquipment.data?.plateNumber);
    setValue("yearModel", currentEquipment.data?.yearModel);
    setValue("deliveryDate", currentEquipment.data?.deliveryDate);
    setValue("ownershipType", currentEquipment.data?.ownershipType);
    setValue("acquisitionCost", currentEquipment.data?.acquisitionCost);
    setValue("isActive", currentEquipment.data?.isActive ? "true" : "false");
  }, [currentEquipment.data, setValue]);

  const onError = (err: typeof errors) => {
    console.error(err);
  };

  const onSubmit = (data: IEquipments) => {
    if (currentEquipment.data) {
      data.isActive = data.isActive == "true" ? true : false;
      updateEquipment.mutate(data);
    } else {
      delete data.isActive;
      createEquipment.mutate(data);
    }
  };

  const backTo = () => navigate("/equipment");

  return (
    <>
      <PageContainer
        title="Manage My Databases"
        subTitle="Equipment Master"
        controls={
          <Stack direction="row" gap={2}>
            <Button
              sx={{ backgroundColor: appColors.lightGray, color: "black" }}
              onClick={backTo}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disabled={!isValid}
              disableElevation
              variant="contained"
              sx={{ textTransform: "capitalize", borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
        <Divider color="black" />
        <Stack
          component="form"
          height="100%"
          bgcolor="white"
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height="100%" overflow="auto" gap={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              mt={3}
            >
              <Stack direction="column" gap={1.5} width="45%">
                <AppTextField
                  label="Equipment Number"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Equipment Number"
                  error={!!errors.equipmentNo}
                  helperText={errors.equipmentNo?.message}
                  {...register("equipmentNo")}
                />
                <AppTextField
                  label="Category"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Category"
                  error={!!errors.category}
                  helperText={errors.category?.message}
                  {...register("category")}
                />
                <AppTextField
                  label="Description"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Description"
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  {...register("description")}
                />
                <AppTextField
                  label="Make"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Make"
                  error={!!errors.make}
                  helperText={errors.make?.message}
                  {...register("make")}
                />
                <AppTextField
                  label="Model"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Model"
                  error={!!errors.model}
                  helperText={errors.model?.message}
                  {...register("model")}
                />
                <AppTextField
                  label="Capacity"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Capacity"
                  error={!!errors.capacity}
                  helperText={errors.capacity?.message}
                  {...register("capacity")}
                />
                <AppTextField
                  label="Class"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Class"
                  error={!!errors.class}
                  helperText={errors.class?.message}
                  {...register("class")}
                />
                <AppTextField
                  label="Serial Number"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Serial Number"
                  error={!!errors.serialNumber}
                  helperText={errors.serialNumber?.message}
                  {...register("serialNumber")}
                />
                <AppTextField
                  label="Engine Number"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Engine Number"
                  error={!!errors.engineNumber}
                  helperText={errors.engineNumber?.message}
                  {...register("engineNumber")}
                />
                <Controller
                  control={control}
                  name="productFamilyId"
                  render={({ field }) => (
                    <AppSelect
                      label="Product Family"
                      labelAlignment="left"
                      labelWidth="33%"
                      fieldWidth="67%"
                      fontWeight={400}
                      placeholder="Select here"
                      error={!!errors.productFamilyId}
                      helperText={errors.productFamilyId?.message}
                      options={productFamilyOptions}
                      {...register("productFamilyId")}
                      {...field}
                    />
                  )}
                />
              </Stack>
              <Stack direction="column" gap={1.5} width="45%">
                <AppTextField
                  label="Chassis Number"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Chassis Number"
                  error={!!errors.chassisNumber}
                  helperText={errors.chassisNumber?.message}
                  {...register("chassisNumber")}
                />
                <AppTextField
                  label="Supplier"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Supplier"
                  error={!!errors.supplier}
                  helperText={errors.supplier?.message}
                  {...register("supplier")}
                />
                <AppTextField
                  label="Conduction Sticker"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Conduction Sticker"
                  error={!!errors.conductionSticker}
                  helperText={errors.conductionSticker?.message}
                  {...register("conductionSticker")}
                />
                <AppTextField
                  label="Plate Number"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Plate Number"
                  error={!!errors.plateNumber}
                  helperText={errors.plateNumber?.message}
                  {...register("plateNumber")}
                />
                <AppTextField
                  label="Year Model"
                  type="number"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Year Model"
                  error={!!errors.yearModel}
                  helperText={errors.yearModel?.message}
                  {...register("yearModel")}
                />
                <Controller
                  control={control}
                  name="deliveryDate"
                  render={({ field: { value, onChange } }) => (
                    <AppDatePicker
                      name="deliveryDate"
                      value={value}
                      onChange={onChange}
                      label="Delivery Date"
                      labelAlignment="left"
                      labelWidth="33%"
                      fieldWidth="67%"
                      fontWeight={400}
                      error={!!errors.deliveryDate}
                      helperText={errors.deliveryDate?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="ownershipType"
                  render={({ field }) => (
                    <AppSelect
                      label="Ownership Type"
                      labelAlignment="left"
                      labelWidth="33%"
                      fieldWidth="67%"
                      fontWeight={400}
                      placeholder="Select here"
                      error={!!errors.ownershipType}
                      helperText={errors.ownershipType?.message}
                      options={ownershipTypeOptions}
                      {...register("ownershipType")}
                      {...field}
                    />
                  )}
                />
                <AppTextField
                  label="Acquisition Cost"
                  type="number"
                  labelAlignment="left"
                  labelWidth="33%"
                  fieldWidth="67%"
                  fontWeight={400}
                  placeholder="Acquisition Cost"
                  error={!!errors.acquisitionCost}
                  helperText={errors.acquisitionCost?.message}
                  {...register("acquisitionCost")}
                />
                <Controller
                  control={control}
                  name="isActive"
                  render={({ field }) => (
                    <AppSelect
                      label="Status"
                      disabled={currentEquipment.data ? false : true}
                      labelAlignment="left"
                      labelWidth="33%"
                      fieldWidth="67%"
                      fontWeight={400}
                      placeholder="Select here"
                      error={!!errors.isActive}
                      helperText={errors.isActive?.message}
                      options={statusOptions}
                      {...register("isActive")}
                      {...field}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </PageContainer>
      <Outlet />
    </>
  );
};

export default EquipmentForm;

import { useQuery, useMutation } from '@tanstack/react-query';
import { Equipment } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import { IEquipments, IProductFamily } from '../modules/DatabaseManagement/EquipmentDBs/Equipment/utils/equipment-utils';
import equipmentApi from '../services/equipments';
import productFamilyApi from '../services/productFamily';
import { queryClient } from '../react-query/queryClient';
import { useSnackBar } from '../components/ToastProvider';
import { useNavigate, useParams } from 'react-router';
import { AxiosError } from 'axios';

interface ApiError {
  status: number;
  message: string;
}
interface useGetEquipmentOptions {
  dropdown?: boolean;
  productFamilyId?: string;
}

export function useGetEquipmentsQuery(options?: useGetEquipmentOptions) {
  return useQuery<Equipment[]>(['equipments'], () => equipmentApi.getEquipments(), {
    onError(err) {
      console.log(err);
    },
    select(equipments) {
      if (options?.dropdown) {
        const uniqueEquipments = equipments.filter(
          (obj, idx) => equipments.findIndex((eq) => eq.productFamily.categoryName === obj.productFamily.categoryName) === idx
        )

        return uniqueEquipments;
      } else {
        return equipments;
      }
    }
  });
}

export function useGetEquipmentsRatingQuery(options?: useGetEquipmentOptions) {
  return useQuery<Equipment[]>(['equipments'], () => equipmentApi.getEquipments(), {
    onError(err) {
      console.log(err);
    },
    select(equipments) {
      if (options?.productFamilyId && options?.productFamilyId.trim().length !== 0) {
        const equipment = equipments.filter(eq => eq.id === options?.productFamilyId);
        const productFamilyId = equipment[0].productFamilyId;
        const filteredEqByProductFamily = equipments.filter(e => e.productFamilyId === productFamilyId);

        const uniqueEquipmentsByCapacity = filteredEqByProductFamily.filter(
          (obj, idx) => filteredEqByProductFamily.findIndex((eq) => eq.capacity === obj.capacity) === idx
        )
        
        return uniqueEquipmentsByCapacity;
      } else {
        return equipments;
      }

      return equipments;
    }
  });
}

export function useGetEquipmentById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`equipments-${id}`, id], () => equipmentApi.getEquipmentsById(id!));
}

export const useExportEquipment = (data: IEquipments[]) => {
  const newEquipmentList = [];
  
  const EquipmentList = data;

  for(let i in EquipmentList){
    let json = {
      equipmentNo: EquipmentList[i].equipmentNo,
      category: EquipmentList[i].category,
      description: EquipmentList[i].description,
      make: EquipmentList[i].make,
      model: EquipmentList[i].model,
      capacity: EquipmentList[i].capacity,
      class: EquipmentList[i].class,
      serialNumber: EquipmentList[i].serialNumber,
      engineNumber: EquipmentList[i].engineNumber,
      chassisNumber: EquipmentList[i].chassisNumber,
      supplier: EquipmentList[i].supplier,
      conductionSticker: EquipmentList[i].conductionSticker,
      plateNumber: EquipmentList[i].plateNumber,
      yearModel: EquipmentList[i].yearModel,
      deliveryDate: EquipmentList[i].deliveryDate,
      ownershipType: EquipmentList[i].ownershipType,
      acquisitionCost: EquipmentList[i].acquisitionCost,
      isActive: EquipmentList[i].isActive,
      productFamily: EquipmentList[i].productFamily.categoryName,
    }
    newEquipmentList.push(json);
  }
  return newEquipmentList;
}

export function useImportEquipment() {
  
  const { showSnackBar } = useSnackBar();

  return useMutation((newEquipmentData: IEquipments[]) => equipmentApi.importEquipment(newEquipmentData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['Equipment'] }).then(() => {
        if( data[1].invalidData.length >= 1){
             showSnackBar(data[1].invalidData[0].errorReason, 'error');
             setTimeout(() => {
              window.location.reload();
            }, 500);
        }
        else{
          showSnackBar('Successfully Inserted Equipment', 'success');
          setTimeout(() => {
            window.location.reload();
          }, 500);
        
        }
     
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}

export function useGetProductFamilyQuery(options?: useGetEquipmentOptions) {
  return useQuery<IProductFamily[]>(['productFamily'], () => productFamilyApi.getProductFamily(), {
    onError(err) {
      console.log(err);
    },
    select(productFamily) {
      return productFamily;
    }
  });
}

export function useUpdateEquipment() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newEquipmentData: Partial<IEquipments>) => equipmentApi.updateEquipment(id!, newEquipmentData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['equipment'] }).then(() => {
        showSnackBar('Successfully Updated equipment', 'success');
        setTimeout(() => {
          navigate('/equipment', { replace: true });
        }, 100);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}

export function useCreateEquipment() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newEquipmentData: Partial<IEquipments>) => equipmentApi.createEquipment(newEquipmentData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['Equipment'] }).then(() => {
          showSnackBar('Successfully Inserted Equipment', 'success');
          setTimeout(() => {
            navigate('/equipment', { replace: true });
          }, 100);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}

export function useGetModelLists() {
  return useQuery<IEquipments[]>(['partnumber'], () => equipmentApi.getModelLists(), {
    onError(err) {
      console.log(err);
    },
    select(parts) {
        return parts;
    }
  });
}
import * as XLSX from "xlsx";
import { format, isValid } from "date-fns";
import { ICLCColumns, ICPCColumns, EFCR, EET } from "./types";
import { IEjoManPower } from '../../../../modules/MyDashboardsPlusTasks/EJO-ESR/utils/types';

const CLCMap = {
  "ESR Completion Date": "dateCompleted",
  "ESR No.": "esrNumber",
  "Equipment No.": "equipmentNo",
  Category: "category",
  "Product Family": "productFamily",
  Description: "description",
  Make: "make",
  Model: "model",
  Capacity: "capacity",
  Project: "project",
  Warranty: "warranty",
  "Technician Name": "technicianName",
  "Technician Type": "technicianType",
  "Man Hours": "manHours",
  "Unit Cost": "unitCost",
  "Total Labor Cost": "laborCost",
};

const CPCMap = {
  "ESR Completion Date": "dateCompleted",
  "ESR No.": "esrNumber",
  "Equipment No.": "equipmentNo",
  Category: "category",
  "Product Family": "productFamily",
  Description: "description",
  Make: "make",
  Model: "model",
  Capacity: "capacity",
  Project: "project",
  Warranty: "warranty",
  "Part No.": "partNumber",
  System: "system",
  "Sub System": "subSystem",
  Quantity: "quantity",
  "Unit Cost": "unitCost",
  "Total Parts Cost": "totalPartsCost",
};

const efcrMap = {
  "FRIS Date": "frisDate",
  "FRIS No.": "frisNo",
  "Equipment No.": "equipmentNo",
  Category: "category",
  "Product Family": "productFamily",
  Description: "description",
  Make: "make",
  Model: "model",
  Capacity: "capacity",
  Project: "project",
  "FRIS Completion Date": "completionDate",
  "FRIS Time": "time",
  Quantity: "quantity",
  "Meter Reading": "meterReading",
  "Fuel Truck No.": "fuelTruckNo",
  "Operator Name": "operatorName",
  "Unit Cost": "unitCost",
  "Total Parts Cost": "totalPartsCost",
  "Ownership Type": "ownershipType",
};

const EETMap = {
  "EJO Number": "ejoNumber",
  "EJO Creation Date": "ejoCreatedDate",
  "EJO Approval Date": "ejoApprovalDate",
  "ESR Number": "esrNumber",
  "ESR Creation Date": "esrCreatedDate",
  "ESR Approval Date": "esrApprovalDate",
  "ESR Start Date": "esrStartDate",
  "ESR Completion Date": "esrCompletionDate",
  "ESR Date Needed" : "dateNeeded",
  "ESR Service Request": "serviceRequest",
  "ESR Remarks": "remarks",
  "Equipment No": "equipmentNumber",
  Model: "model",
  "Product Family": "productFamily",
  Make: "make",
  Project: "project",
  "Ownership Type": "ownedOrRented",
};

const EjoManpowerMap = {
  "Equipment Number": "equipmentNo",
  "Project / Location": "projectLocation",
  "EJO Number": "ejoNumber",
  "SR Number": "serviceRequestNo",
  "Service Requested": "serviceRequest",
  "Remarks / PM Code": "pmCode",
  "ESR Number": "esrNumber",
  "Manpower": "manPower",
  "Role": "roleType",
  "Days Since EJO Opened": "daysSinceEjoOpened",
  "EJO Date Needed": "ejoDateNeeded",
  "EJO Approval Date": "ejoDateApproved",
  "ESR Created Date": "esrDateCreated",
  "ESR Approval Date": "esrDateApproved" 
};

export const exportCLCToExcel = (data: ICLCColumns[], filename: string) => {
  const headings = [Object.keys(CLCMap)];
  const columnHeaders = [...Object.values(CLCMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  const formattedData = data.map((item) => {
    const formatDate = (date: string | number | Date | null) => {
      if (date && isValid(new Date(date))) {
        return format(new Date(date), "MM/dd/yy HH:mm:ss");
      }
      return null;
    };

    return {
      ...item,
      dateCompleted:
        formatDate(item.dateCompleted) !== null
          ? {
              z: "yyyy-MM-dd HH:mm:ss",
              t: "d",
              v: formatDate(item.dateCompleted),
            }
          : "",
    };
  });
  XLSX.utils.sheet_add_json(worksheet, formattedData, {
    origin: "A2",
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), "MM_dd_yyyy");
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet["!cols"] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet["!cols"] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportCPCToExcel = (data: ICPCColumns[], filename: string) => {
  const headings = [Object.keys(CPCMap)];
  const columnHeaders = [...Object.values(CPCMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();

  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);

  const formattedData = data.map((item) => {
    const formatDate = (date: string | number | Date | null) => {
      if (date && isValid(new Date(date))) {
        return format(new Date(date), "MM/dd/yy HH:mm:ss");
      }
      return null;
    };

    return {
      ...item,
      dateCompleted:
        formatDate(item.dateCompleted) !== null
          ? {
              z: "yyyy-MM-dd HH:mm:ss",
              t: "d",
              v: formatDate(item.dateCompleted),
            }
          : "",
    };
  });

  XLSX.utils.sheet_add_json(worksheet, formattedData, {
    origin: "A2",
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), "MM_dd_yyyy");
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet["!cols"] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet["!cols"] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportEFCRToExcel = (data: EFCR[], filename: string) => {
  const headings = [Object.keys(efcrMap)];
  const columnHeaders = [...Object.values(efcrMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);

  const formattedData = data.map((item) => {
    const formatDate = (date: string | number | Date | null) => {
      if (date && isValid(new Date(date))) {
        return format(new Date(date), "MM/dd/yy");
      }
      return null;
    };

    return {
      ...item,
      frisDate:
        formatDate(item.frisDate) !== null
          ? { z: "yyyy-MM-dd", t: "d", v: formatDate(item.frisDate) }
          : "",
    };
  });

  XLSX.utils.sheet_add_json(worksheet, formattedData, {
    origin: "A2",
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), "MM_dd_yyyy");
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet["!cols"] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet["!cols"] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportEETToExcel = (data: EET[], filename: string) => {
  const headings = [Object.keys(EETMap)];
  const columnHeaders = [...Object.values(EETMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);

  const formattedData = data.map((item) => {
    const formatDate = (date: string | number | Date | null) => {
      if (date && isValid(new Date(date))) {
        return format(new Date(date), "MM/dd/yy HH:mm:ss");
      }
      return null;
    };

    return {
      ...item,
      ejoApprovalDate:
        formatDate(item.ejoApprovalDate) !== null
          ? {
              z: "yyyy-MM-dd HH:mm:ss",
              t: "d",
              v: formatDate(item.ejoApprovalDate),
            }
          : "",
      ejoCreatedDate:
        formatDate(item.ejoCreatedDate) !== null
          ? {
              z: "yyyy-MM-dd HH:mm:ss",
              t: "d",
              v: formatDate(item.ejoApprovalDate),
            }
          : "",
      esrApprovalDate:
        formatDate(item.esrApprovalDate) !== null
          ? {
              z: "yyyy-MM-dd HH:mm:ss",
              t: "d",
              v: formatDate(item.esrApprovalDate),
            }
          : "",
      esrCreatedDate:
        formatDate(item.esrCreatedDate) !== null
          ? {
              z: "yyyy-MM-dd HH:mm:ss",
              t: "d",
              v: formatDate(item.esrCreatedDate),
            }
          : "",
      esrCompletionDate:
        formatDate(item.esrCompletionDate) !== null
          ? {
              z: "yyyy-MM-dd HH:mm:ss",
              t: "d",
              v: formatDate(item.esrCompletionDate),
            }
          : "",
      esrStartDate:
        formatDate(item.esrStartDate) !== null
          ? {
              z: "yyyy-MM-dd HH:mm:ss",
              t: "d",
              v: formatDate(item.esrStartDate),
            }
          : "",
      dateNeeded:
          formatDate(item.dateNeeded) !== null
            ? {
                z: "yyyy-MM-dd HH:mm:ss",
                t: "d",
                v: formatDate(item.dateNeeded),
              }
            : "",
    };
  });

  XLSX.utils.sheet_add_json(worksheet, formattedData, {
    origin: "A2",
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), "MM_dd_yyyy");
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet["!cols"] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet["!cols"] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportEjoManpowerToExcel = (data: IEjoManPower[], filename: string) => {
  const headings = [Object.keys(EjoManpowerMap)];
  const columnHeaders = [...Object.values(EjoManpowerMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { AccountsExport } from './accounts-utils';

const AccountMap = {
    'First Name' : 'firstName',
    'Last Name' : 'lastName',
    'Status' :  'isActive',
    'Role' : 'roleName',
    'Date Created' : 'dateCreated',
    'Date Updated' : 'dateUpdated'
  }


  export const exportAccountToExcel = (data: AccountsExport[], filename: string) => {
    const headings = [Object.keys(AccountMap)];
    const columnHeaders = [...Object.values(AccountMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };
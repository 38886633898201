import * as yup from 'yup';
import { SelectOptionType } from '../../../components/common/forms/form-types';

export type IAttachment = {
  fileName: string;
  source: File;
};

export type EmailAttachment = {
  filename: string;
  path: string;
};
export interface IOperatorLicenses {
  id: string;
  operatorId: string;
  operatorName: string;
  operatorType: string;
  licenseTypeId: string;
  licenseType: string;
  certificateName: string;
  certificateNo: string;
  governmentBody: string;
  issuingAgency: string;
  issuanceDate: Date;
  validityDate: Date;
  media: string;
}

export interface IOperatorLicenseForm {
  operatorName: string;
  operatorType: string;
  licenseType: string;
  certificateName: string;
  certificateNo: string;
  governmentBody: string;
  issuingAgency: string;
  issuanceDate: Date;
  validityDate: Date;
  media: IAttachment[];
}

export interface IEmailForm {
  toemail: string[];
  ccemail: string[];
  subject: string;
  message: string;
  media: IAttachment[];
}

export type operatorLicenseFields = Omit<IOperatorLicenses, 'id'>;
export const operatorNameList: SelectOptionType[] = [
  {
    id: 'BL-01',
    label: 'Alvin Garcia',
    value: 'BL-01',
  },
  {
    id: 'BL-02',
    label: 'Ariel Zonio',
    value: 'BL-02',
  },
];

export const emailSchema = yup.object({
  toemail:  yup
  .array()
  .of(yup.string().trim().email('Enter a valid email').required('Email is required'))
  .min(1, 'There must be at least one email addresses'),
  ccemail: yup.array().of(yup.string().email('Enter a valid email')),
  subject: yup.string().trim().required('Subject is required'),
  message: yup.string().trim().required('Message is required'),
  media: yup.array().max(3, 'You can only upload a maximum of 3 files'),
});

export const emailInitialValues: IEmailForm = {
  toemail: [''],
  ccemail: [''],
  subject: '',
  message: '',
  media: [],
};

export const operatorLicenseSchema = yup.object({
  certificateName: yup.string().required(),
  certificateNo: yup.string().required(),
  governmentBody: yup.string().optional(),
  issuingAgency: yup.string().optional(),
  issuanceDate: yup.date().required(),
  validityDate:  yup.date().required(),
  media: yup.array().optional(),
});

export const operatorLicenseInitialValues: IOperatorLicenseForm = {
  certificateName: '',
  certificateNo: '',
  governmentBody: '',
  issuingAgency: '',
  licenseType: '',
  operatorName: '',
  operatorType: '',
  issuanceDate: new Date(Date.now()),
  validityDate: new Date(new Date().setDate(new Date().getDate() + 5)),
  media: [],
};
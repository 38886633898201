import { Box, Paper, Stack, TablePagination } from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../../components/common/app-table/ui';
import useDefaultTable from '../../../../components/common/app-table/useDefaultTable';
import FormAddButton from '../../../../components/common/FormAddButton';
import AppSearchField from '../../../../components/common/forms/AppSearchField';
import { ExportButton, ImportButton, GetTemplateButton } from '../../../../components/common/primebmd-ui/DataButtons';
import { ITaskTypes, ExportTaskType } from './utils/taskTypes-utils';
import taskTypesColumns from './TaskTypesColumn';
import { ExportTaskTypeToExcel, GetTemplateTaskType } from './utils/exporting';
import { useGetTaskTypesQuery, useExportTaskTypes, useImportTaskTypes } from '../../../../hooks/useTaskTypes';

import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { useSnackBar } from '../../../../components/ToastProvider';
import MultipleUploadButton from '../../../../components/common/MultipleUploadButton';
import * as xlsx from 'xlsx';




const TaskTypesTable = () => {
  const { showSnackBar } = useSnackBar();

  const tasktypesData = useGetTaskTypesQuery();
  const importData = useImportTaskTypes();
  const data: ITaskTypes[] = tasktypesData?.data === undefined ? [] : tasktypesData.data;
  const formattedData: ExportTaskType[] = useExportTaskTypes(data);


  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } = useDefaultTable<ITaskTypes>(
    data,
    taskTypesColumns
  );

 
  
  const navigate = useNavigate();

  const emptyText = searchFilter ? 'No data found' : 'No data yet';

  const gotoAddTaskTypes = () => navigate('/service-task-new');

  const handleExport = () => {
    ExportTaskTypeToExcel(formattedData, 'Task Type Database');
  }

  const handleTemplate = () => {
    GetTemplateTaskType([], 'Template Task Type Database');
  
  }

  const readUploadFile = (e : any) => {
    e.preventDefault();
    if(e.target.files[0] != undefined){
      const checkExcelName = e.target.files[0].name.split("_");
      if (checkExcelName[0].toUpperCase() == 'TEMPLATE TASK TYPE DATABASE') {
          const reader = new FileReader();
          reader.onload = (e) => {
              const data = e.target?.result;
              const workbook = xlsx.read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const json = xlsx.utils.sheet_to_json(worksheet);
              const jsonData: any =  json === undefined ? [] : json;
              const newData = jsonData.map((dataObject: any) => ({ taskType: dataObject["Task Type"] }));
              
              if(newData.length !== 0){
                importData.mutate(newData);
              }
              else showSnackBar('No data found on excel', 'error');
              setTimeout(() => {
                window.location.reload();
              }, 1000);
          };
          reader.readAsArrayBuffer(e.target.files[0]);
      }
      else showSnackBar('Invalid file upload please download Excel template', 'error');
    }
}

  if (tasktypesData?.isLoading) return <LoadingIndicator show />;

  return (
    <>
      <Stack direction='row'  justifyContent='flex-end' mb={2} gap={2}>
        <GetTemplateButton onClick={handleTemplate} />
        <MultipleUploadButton
          accept=".xlsx, .xls"
          onChange={readUploadFile}
        />
        <ExportButton onClick={handleExport} />
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
        <FormAddButton onClick={gotoAddTaskTypes} />
     
       
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody dataCount={dataCount} loading={tasktypesData?.isLoading} emptyText={emptyText}>
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
      {tasktypesData?.isError && showSnackBar('Network Error', 'error')}
    </>
  );
};

export default TaskTypesTable;


import {
  IPMSCode,
  ICreatePMSCode,
} from "../modules/DatabaseManagement/ServicingDBs/PMSCode/utils/PMSCode-utils";
import { apiClient, apiEndpoints } from "./apiClient";

export type GetManyReturnType = {
  status: string;
  data: IPMSCode[];
};
export type GetOneReturnType = {
  status: string;
  data: IPMSCode;
};

const getPartDescription = async () => {
  const response = await apiClient.get<GetManyReturnType>(
    apiEndpoints.pmsCodes
  );
  return response.data.data;
};

const getPartDescriptionByModel = async (model?: string) => {
  const url = `${apiEndpoints.pmsCodes}/per-model?model=${model}`;
  const response = await apiClient.get<GetManyReturnType>(url);

  return response.data.data;
};

const getPartDescriptionById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.pmsCodes}/${id}`
  );
  return response.data.data;
};

const importPartDescription = async (newPMSCodeTypeData: any) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.pmsCodes}/import-data`,
    newPMSCodeTypeData
  );
  return response.data.data;
};

const updatePMSCodeType = async (
  id: string,
  updatePMSCodeTypeData: Partial<ICreatePMSCode>
) => {
  delete updatePMSCodeTypeData.id;
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.pmsCodes}/${id}`,
    updatePMSCodeTypeData
  );

  return response.data.data;
};

const createPMSCodeType = async (
  newPMSCodeTypeData: Partial<ICreatePMSCode>
) => {
  delete newPMSCodeTypeData.id;
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.pmsCodes,
    newPMSCodeTypeData
  );
  return response.data.data;
};

const deletePMSCode = async (id: string) => {
  const response = await apiClient.delete(`${apiEndpoints.pmsCodes}/${id}`);
  return response.data.data;
};

export default {
  getPartDescription,
  getPartDescriptionByModel,
  getPartDescriptionById,
  importPartDescription,
  updatePMSCodeType,
  createPMSCodeType,
  deletePMSCode,
};

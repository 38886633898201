import {
  IESR,
  IEjoManPower,
} from "../../modules/MyDashboardsPlusTasks/EJO-ESR/utils/types";
import { apiClient, apiEndpoints } from "../apiClient";

export type GetOneReturnType = {
  status: string;
  data: IESR;
};

export type GetManyReturnType = {
  status: string;
  data: IESR[];
};

export type GetManyEJOManpowerReturnType = {
  data: IEjoManPower[];
};

const getAllESR = async (status?: string) => {
  const url = status
    ? `${apiEndpoints.esr}/AMIList?status=${status}`
    : `${apiEndpoints.esr}/AMIList`;
  const response = await apiClient.get<GetManyReturnType>(url);
  return response.data.data;
};

const getESRById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.esr}/${id}`
  );
  return response.data.data;
};

const getEjoManpower = async (status?: string) => {
  const url = `${apiEndpoints.ejoManpower}/getEjoManPower`;
  const response = await apiClient.get<GetManyEJOManpowerReturnType>(url);
  return response.data.data;
};

const ejoApi = {
  getAllESR,
  getESRById,
  getEjoManpower,
};

export default ejoApi;

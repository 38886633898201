import { useMutation, useQuery } from '@tanstack/react-query';
import operatorLicenseTypesAPI from '../services/operatorLicensesTypes';
import { useNavigate, useParams } from 'react-router-dom';
import { ExportOperetorLicenses, IOperatorLicenses } from '../modules/DatabaseManagement/PersonnelDBs/HEOperatorCertificateType/utils/HEOperatorCertificateType-utils';
import { useSnackBar } from '../components/ToastProvider';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';
import { ICreateOperetorLicenses } from '../modules/DatabaseManagement/PersonnelDBs/HEOperatorCertificateType/utils/HEOperatorCertificateType-utils';

interface ApiError {
  status: number;
  message: string;
}

interface useGetIOpeatorOptions {
  name?: string;
}

export function useGetOperatorsLicenseTypesQuery(options?: useGetIOpeatorOptions) {

  return useQuery<IOperatorLicenses[]>(['operator'], () => operatorLicenseTypesAPI.getOperatorsLicenseTypes(), {
    onError(err) {
      console.log(err);
    },
    select(Operator) {
      return options?.name ? Operator.filter(l => l.name === options?.name) : Operator;
    },
  });
}



export function useGetOperatorLicenseTypesById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`operator-${id}`, id], () => operatorLicenseTypesAPI.getOperatorsLicenseTypesById(id!));
}

export const useExportOperetorLicenseTypes = (data: ExportOperetorLicenses[]) => {
  const newOperatorList = [];
  
  const OperatorList = data;

  for(let i in OperatorList){
    let json = {
      name: OperatorList[i].name,
      operatorType: OperatorList[i].operatorType,
      validity: OperatorList[i].validity,
      isActive: OperatorList[i].isActive
    }
    newOperatorList.push(json);
  }
  return newOperatorList;
}

export function useImportOperatorLicenseTypes() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((newOperatorData: IOperatorLicenses[]) => operatorLicenseTypesAPI.importOperatorsLicenseTypes(newOperatorData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['operator'] }).then(() => {
        
        if( data[1].invalidData.length >= 1){
             showSnackBar(data[1].invalidData[0].errorReason, 'error');
             setTimeout(() => {
              window.location.reload();
            }, 500);
        }
        else{
          showSnackBar('Successfully Inserted Operator License Type', 'success');
          navigate('/he-operator-certificate-type', { replace: true });
          window.location.reload();
        }
     
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}


export function useUpdateOperatorLicenseTypes() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newOperatorData: Partial<ICreateOperetorLicenses>) => operatorLicenseTypesAPI.updateOperatorLicenseTypes(id!, newOperatorData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['operator'] }).then(() => {
        showSnackBar('Successfully Updated Operator License Type', 'success');
        setTimeout(() => {
          navigate('/he-operator-certificate-type', { replace: true });
        }, 200);
   
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useDeleteOperatorLicenseTypes(data: any, id: string) {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((data: any) => operatorLicenseTypesAPI.updateOperatorLicenseTypes(id!, data), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['operator'] }).then(() => {        
        showSnackBar('Successfully Deleted Operator License Type', 'success');
        setTimeout(() => {
          navigate('/he-operator-certificate-type', { replace: true });
        }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useCreateOperatorLicenseTypes() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newOperatorData: Partial<ICreateOperetorLicenses>) => operatorLicenseTypesAPI.createOperatorLicenseTypes(newOperatorData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['operator'] }).then(() => {
          showSnackBar('Successfully Inserted Operator License Type', 'success');
          setTimeout(() => {
            navigate('/he-operator-certificate-type', { replace: true });
          }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}
import { Stack, IconButton, Tooltip } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useNavigate } from 'react-router-dom';
import { IParts } from './parts-utils';

interface Ownprops {
  parts: IParts;
}

const PartsActionButtons = ({ parts }: Ownprops) => {
  const { id } = parts;

  const navigate = useNavigate();

  const goToPartsForm = () => navigate(`/parts-new/${id}`);

  return (
    <Stack direction='row' gap={1.5}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goToPartsForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default PartsActionButtons;

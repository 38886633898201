import * as yup from 'yup';
import { ICreateFueltype } from './FuelType-utils';

export const FuelInitialValues: ICreateFueltype = {
  id: '',
  name: '',
  isActive: '',
};
export const FuelSchema = yup.object({
    name: yup.string()
    .trim('The Name cannot include leading and trailing spaces')
    .strict(true)
    .min(1,'The Name to be at least 1 Alphabet or Numerical')
    .required('The Name  is required'),
    isActive: yup.boolean().required('Status is required'),
  });
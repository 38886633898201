import * as yup from 'yup';
import { 
  ICreateERFForm, 
  IAllocateFields, 
  ICreateDeliveryForm,
  ICreatePickUpForm,
  IEXFRemarksForm,
  IEXFApproveWtChangeForm 
} from './types';

const today = new Date();
const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);

export const CreateERFInitialValues: ICreateERFForm = {
  locationId : '',
  productFamilyId: '',
  capacityId: '',
  quantity: null,
  startDate: new Date(Date.now()),
  endDate: new Date(Date.now()),
  remarks: '',
};

export const CreateERFSchema = yup.object({
  locationId: yup.string().required('Project Name is required'),
  productFamilyId: yup.string().required('Product Family is required'),
  capacityId: yup.string().required('Capacity is required'),
  quantity: yup
    .number()
    .required('Quantity is required')
    .typeError("Must be a number"),
  startDate: yup.date().required('Start Date is required').typeError('Invalid Start Date Format'),
  endDate: yup.date().required('End Date is required').typeError('Invalid End Date Format')
});

export const AllocateInitialValues: IAllocateFields = {
  emStartDate: new Date(Date.now()),
  emEndDate: new Date(Date.now()),
};

export const EMDatesSchema = yup.object({
  emStartDate: yup.date()
  .required('EM Start Date is required')
  .typeError('Invalid EM Start Date Format')
  .min(yesterday, 'EM Start Date cannot be in the past'),
emEndDate: yup.date()
  .required('EM End Date is required')
  .typeError('Invalid EM End Date Format')
  .min(yesterday, 'EM End Date cannot be in the past')
});

export const CreateDeliveryInitialValues: ICreateDeliveryForm = {
  allocationETICNo : '',
  allocationETICAttachment: [],
  allocationPickUpLocationId: '',
  allocationTruckNo: '',
  allocationEPUD: new Date(Date.now()),
  allocationETA: new Date(Date.now()),
  allocationDeliveryRemarks: '',
};

export const CreateDeliverySchema = yup.object({
  allocationETICNo: yup.string().required('Allocation ETIC No. is required'),
  allocationETICAttachment: yup.mixed().nullable().required("Allocation ETIC Attachment is required"),
  allocationPickUpLocationId: yup.string().required('Allocation Pickup Location is required'),
  allocationTruckNo: yup.string().required('Allocation Truck No. is required'),
  allocationEPUD: yup.date().required('Allocation Estimated Pickup Date is required').typeError('Invalid Allocation Estimated Pickup Date Format'),
  allocationETA: yup.date().required('Allocation Estimated Time of Arrival is required').typeError('Invalid Allocation Estimated Time of Arrival Format')
});

export const CreatePickUpInitialValues: ICreatePickUpForm = {
  inSitu : "Yes",
  eorrPickUpLocationId: '',
  eorrETICNo: '',
  eorrETICAttachment: [],
  eorrDropOffLocationId: '',
  eorrTruckNo: '',
  eorrEPUD: new Date(Date.now()),
  eorrPickUpRemarks: '',
};

export const CreatePickUpSchema = yup.object({
  inSitu: yup.string().required('In Situ is required'),
  eorrETICNo: yup.string().required('EORR ETIC No. is required'),
  eorrETICAttachment: yup.mixed().nullable().required("EORR ETIC Attachment is required"),
  eorrPickUpLocationId: yup.string()
    .when('inSitu', {
      is: 'No',
      then: yup.string().required('EORR Pick Up Location is required'),
    }),
  eorrDropOffLocationId: yup.string()
    .when('inSitu', {
      is: 'No',
      then: yup.string().required('EORR Drop Off Location is required'),
    }),
  eorrTruckNo: yup.string()
    .when('inSitu', {
      is: 'No',
      then: yup.string().required('EORR Truck No is required'),
    }),
  eorrEPUD: yup.date()
    .when('inSitu', {
      is: 'No',
      then: 
    yup.date().
    required('EORR Estimated Pick Up Date is required')
    .transform((curr, orig) => orig === '' ? null : curr)
    .typeError("The EORR Estimated Date is Required"),
    })
});

export const EXFRemarksInitialValue: IEXFRemarksForm = {
  exfEMRemarks: ''
};

export const EXFRemarksSchema = yup.object({
  exfEMRemarks: yup.string().optional()
});

export const EXFApproveWtChangeInitialValue: IEXFApproveWtChangeForm = {
  exfEMEndDate: new Date(Date.now()),
  exfEMRemarks: ''
};

export const EXFApproveWtChangeSchema = yup.object({
  exfEMEndDate: yup.date().required('EXF EM End Date is required').typeError('Invalid EXF EM End Date Format'),
  exfEMRemarks: yup.string().optional()
});
import { 
  IERF, 
  ICreateERFForm, 
  IAllocateFields,
  ICreateDeliveryForm,
  IEquipmentDeploymentStatus,
  InTransits, 
  IEXF,
  IEORR,
  ICreatePickUpForm,
  IEXFRemarksForm
} from '../../modules/MyDashboardsPlusTasks/MyEquipmentRequests/utils/types';
import { apiClient, apiEndpoints } from '../apiClient';
import { uploadMedia } from '../../utils/upload';

export type GetManyReturnType = {
  status: string;
  data: IERF[];
};

type GetOneReturnType = {
  status: string;
  data: IERF;
};

type GetAvailableEquipmentReturnType = {
  status: string;
  data: IEquipmentDeploymentStatus[];
};

type GetInTransitEquipmentManyReturnType = {
  status: string;
  data: InTransits[];
};

type GetEXFManyReturnType = {
  status: string;
  data: IEXF[];
};

type GetEORRManyReturnType = {
  status: string;
  data: IEORR[];
};

const getEPFERF = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.epfErf);
  return response.data.data;
};

const getInTransitEquipments = async () => {
  const response = await apiClient.get<GetInTransitEquipmentManyReturnType>(
    `${apiEndpoints.epfErf}/in-transits`
  );
  return response.data.data;
};

const getEXF = async () => {
  const response = await apiClient.get<GetEXFManyReturnType>(
    `${apiEndpoints.epfErf}/exf`
  );
  return response.data.data;
};

const getEORR = async () => {
  const response = await apiClient.get<GetEORRManyReturnType>(
    `${apiEndpoints.epfErf}/eorr`
  );
  return response.data.data;
};

const getEPFERFById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.epfErf}/${id}`
  );
  return response.data.data;
};

const getAvailableEquipmentsForModificaiton = async (id: string) => {
  const response = await apiClient.get<GetAvailableEquipmentReturnType>(
    `${apiEndpoints.epfErf}/available-equipments/${id}?forModification=true`
  );
  return response.data.data;
};


const getAvailableEquipments = async (id: string) => {
  const response = await apiClient.get<GetAvailableEquipmentReturnType>(
    `${apiEndpoints.epfErf}/available-equipments/${id}`
  );
  return response.data.data;
};

const createEPFERF = async (newEPFERFData: ICreateERFForm) => {
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.epfErf,
    newEPFERFData
  );
  return response.data.data;
};

const allocateERF = async (id: string, allocatedData: IAllocateFields, types: any) => {
  const url = `${apiEndpoints.epfErf}/allocate-equipment/${id}${types == "my-allocations"  ? '?isModify=true' : ''}`;
  const response = await apiClient.patch<GetOneReturnType>(url, allocatedData);

  return response.data.data;
};

const createDelivery = async (id: string, data: Partial<ICreateDeliveryForm>) => {
  let mediaUrl = "";
  
  if (data && data.allocationETICAttachment) {
    const uploadRes = await uploadMedia(data.allocationETICAttachment[0].source, "headers");
    mediaUrl = uploadRes.path;
  }
  
  const newData = {
    ...data,
    allocationETICAttachment: mediaUrl
  }

  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.epfErf}/create-delivery/${id}`,
    newData
  );

  return response.data.data;
};

const arrivedOnSite = async (id: string) => {
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.epfErf}/arrived-on-site/${id}`
  );

  return response.data.data;
};

const createPickUp = async (id: string, data: Partial<ICreatePickUpForm>) => {
  let mediaUrl = "";

  if (data && data.eorrETICAttachment) {
    const uploadRes = await uploadMedia(data.eorrETICAttachment[0].source, "headers");
    mediaUrl = uploadRes.path;
  }

  const newData = {
    ...data,
    inSitu: data.inSitu === "Yes" ? true : false,
    eorrETICAttachment: mediaUrl
  }

  const url = newData.inSitu === true 
    ? `${apiEndpoints.epfErf}/off-rent/pickup-in-situ/${id}` 
    : `${apiEndpoints.epfErf}/off-rent/pickup-not-in-situ/${id}`;

  const response = await apiClient.patch<GetOneReturnType>(
    url,
    newData
  );

  return response.data.data;
};

const pickedUpFromSite = async (id: string) => {
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.epfErf}/off-rent/confirm-pickup/${id}`
  );

  return response.data.data;
};

const withdrawAllocation = async (id: string) => {
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.epfErf}/withdraw-equipment/${id}`
  );

  return response.data.data;
};

const approveEXF = async (id: string, data: IEXFRemarksForm) => {
  const newData = {
    ...data,
    exfStatus: "Approved"
  }

  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.epfErf}/extension/approve/${id}`,
    newData
  );

  return response.data.data;
};

const approveEXFWtChange = async (id: string, data: IEXFRemarksForm) => {
  const newData = {
    ...data,
    exfStatus: "Approved with Change"
  }

  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.epfErf}/arrived-on-site/${id}`,
    newData
  );

  return response.data.data;
};

const rejectEXF = async (id: string, data: IEXFRemarksForm) => {
  const newData = {
    ...data,
    exfStatus: "Rejected"
  }

  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.epfErf}/extension/reject/${id}`,
    newData
  );

  return response.data.data;
};

const deleteEPFERF = async (id: string) => {
  const response = await apiClient.delete(`${apiEndpoints.epfErf}/${id}`);
  return response.data.data;
};

const epfErfApi = {
  getEPFERF,
  getInTransitEquipments,
  getEXF,
  getEORR,
  getEPFERFById,
  getAvailableEquipments,
  createEPFERF,
  allocateERF,
  createDelivery,
  arrivedOnSite,
  createPickUp,
  pickedUpFromSite,
  withdrawAllocation,
  approveEXF,
  approveEXFWtChange,
  rejectEXF,
  deleteEPFERF,
  getAvailableEquipmentsForModificaiton
};

export default epfErfApi;
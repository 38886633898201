import PageContainer from '../../../../components/common/PageContainer';
import FuelTypeTable from './FuelTypeTable';

function FuelTypePage() {
  return (
    <>
      <PageContainer title='Other Databases' subTitle='Fuel Type Master' showDate>
        Fuel Type Master
        <FuelTypeTable />
      </PageContainer>
    </>
  );
}

export default FuelTypePage;

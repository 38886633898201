import { createColumnHelper } from "@tanstack/react-table";
import { ViewActionButton } from "../../../../../../components/common/primebmd-ui/EditActionButton";
import { ESRColumns } from "../utils/types";

export const useCreateESRUnstartedColumns = () => {
  const columnHelper = createColumnHelper<ESRColumns>();

  const esrUnstartedColumns = [
    columnHelper.accessor("esrNumber", {
      id: "esrNumber",
      header: "ESR No.",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("equipmentNo", {
      id: "equipmentNo",
      header: "Equipment No.",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor("projectLocation", {
      id: "projectLocation",
      header: "Project / Location",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor("daysElapsed", {
      id: "daysElapsed",
      header: "Days Elapsed",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.display({
      id: "actions",
      header: "Action",
      cell: ({ row }) => <ViewActionButton route={`view/${row.original.id}`} />,

      enableHiding: false,
    }),
  ];

  return esrUnstartedColumns;
};

import { RouteObject } from 'react-router-dom';

// root
import ReportsPage from './ReportsPage';

// pages
import EquipmentUseAndPerformanceReportsPage from './EquipmentUseAndPerformanceReports/EquipmentUseAndPerformanceReportsPage';
import EquipmentCostReportsPage from './EquipmentCostReports/EquipmentCostsReportsPage';
import DAUIPage from './EquipmentUseAndPerformanceReports/DAUI/DAUIPage';
import CPCPage from './EquipmentCostReports/CPC/CPCPage';
import CLCPage from './EquipmentCostReports/CLC/CLCPage';
import EquipmentFuelConsumptionPage from './EquipmentCostReports/EquipmenFuelConsumption/EquipmentFuelConsumptionPage';
import EURPage from './EquipmentUseAndPerformanceReports/EUR/EURPage';
import HistoricalRentalsPage from './EquipmentUseAndPerformanceReports/HistoricalRentals/HistoricalRentalsPage';
import EETPage from './EquipmentCostReports/EET/EETPage';
import EjoManpowerPage from './EquipmentCostReports/EjoManpower/EjoManPowerPage';

const ReportsRoutes: RouteObject = {
  path: '',
  element: <ReportsPage />,
  children: [
    {
      path: 'equipment-use-and-performance-reports',
      element: <EquipmentUseAndPerformanceReportsPage />,
      children: [
        {
          path: 'daui',
          element: <DAUIPage />
        },
        {
          path: 'historical-eurs',
          element: <EURPage />
        },
        {
          path: 'historical-rentals',
          element: <HistoricalRentalsPage />
        }
      ],
    },
    {
      path: 'equipment-cost-reports',
      element: <EquipmentCostReportsPage />,
      children: [
        {
          path: 'cpc',
          element: <CPCPage />
        },
        {
          path: 'clc',
          element: <CLCPage />
        },
        {
          path: 'equipment-fuel-consumption',
          element: <EquipmentFuelConsumptionPage />
        },
        {
          path: 'eet',
          element: <EETPage />
        },
        {
          path: 'ejo-manpower',
          element: <EjoManpowerPage />
        }
      ],
    },
  ],
};

export default ReportsRoutes;

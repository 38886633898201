import { useQuery } from '@tanstack/react-query';
import { ICLC, ICLCColumns } from '../modules/Reports/EquipmentCostReports/utils/types';
import clcAPI from '../services/reports/clc';

export function useGetCLCQuery() {
  return useQuery<ICLC[]>(['CLCs'], () => clcAPI.getAllCLC(), {
    onError(err) {
      console.log(err);
    }
  }); 
}

export const useExportCLC = (data: ICLC[]) => {
  const newCLCList = data.map(c => {
    const dateCompleted = c.status === 'Completed' 
                                ? c.dateUpdated 
                                    : '';

    return {
      equipmentNo: c.equipmentNo,
      category: c.category,
      productFamily: c.productFamily,
      description: c.description,
      make: c.make,
      model: c.model,
      capacity: c.capacity,
      project: c.project,
      esrNumber: c.esrNumber,
      dateCompleted: dateCompleted,
      warranty: c.warranty === true ? 'Y' : 'N',
      technicianName: c.technicianName,
      technicianType: c.technicianType,
      manHours: c.manHours,
      unitCost: c.unitCost,
      laborCost: c.laborCost
    }
  });

  return newCLCList;
}
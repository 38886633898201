import { EJOSR } from "../../modules/MyDashboardsPlusTasks/EJO-ESR/EJOManagement/EJO-SR/utils/types";
import { EJORequestBody } from "../../modules/MyDashboardsPlusTasks/EJO-ESR/EJOManagement/utils/types";
import { IEJO } from "../../modules/MyDashboardsPlusTasks/EJO-ESR/utils/types";
import { apiClient, apiEndpoints } from "../apiClient";

type GetOneReturnType = {
  status: string;
  data: IEJO;
};

type GetOneReturnTypeForManagement = {
  status: string;
  data: EJORequestBody;
};

export type GetManyReturnTypeServiceRequests = {
  status: string;
  data: EJOSR[];
};

export type GetManyReturnType = {
  status: string;
  data: IEJO[];
};

// GET ALL EJO BASED ON STATUS (APPROVED OR UNAPPROVED)
const getAllOperableEJO = async (status?: string) => {
  const url = status
    ? `${apiEndpoints.ejo}?status=${status}`
    : `${apiEndpoints.ejo}`;
  const response = await apiClient.get<GetManyReturnType>(url);

  return response.data.data;
};

//* USED FOR EJO ESR APPROVAL
const getEJOById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.ejo}/${id}`
  );
  return response.data.data;
};

//* FOR EJO MANAGEMENT (Returning current Ejo Selected)
const getCurrentEJOById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnTypeForManagement>(
    `${apiEndpoints.ejo}/${id}`
  );

  return response.data.data;
};

const getAllServiceRequests = async (id: string) => {
  const response = await apiClient.get<GetManyReturnTypeServiceRequests>(
    `${apiEndpoints.ejo}/get-all/${id}`
  );

  return response.data.data;
};

const createEJO = async (ejoData: EJORequestBody) => {
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.ejo,
    ejoData
  );

  return response.data.data;
};

const updateEJO = async (id: string, data: EJORequestBody) => {
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.ejo}/${id}`,
    data
  );

  return response.data.data;
};

const ejoApi = {
  // getAllEJO,
  getEJOById,
  getAllServiceRequests,
  getCurrentEJOById,
  getAllOperableEJO,
  createEJO,
  updateEJO,
};

export default ejoApi;

import { createColumnHelper } from "@tanstack/react-table";
import { IEURActivity } from "../utils/types";
import EditIcon from "@mui/icons-material/Edit";
import { useEURView } from "./useGetLocationOrParams";
import moment from "moment";
import { IconButton } from "@mui/material";
import { useSMRContext } from "../Context/SMRContext";
import { useParams } from "react-router-dom";

export const useCreateColumns = () => {
  const { setOpenDialog, setNewActivityEdit, setType } = useSMRContext();
  const { id } = useParams();
  const isViewing = useEURView();

  const columnHelper = createColumnHelper<IEURActivity>();
  const EURActivityColumns = [
    columnHelper.accessor("activity", {
      id: "activity",
      header: "Activity Log",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: false,
      enableHiding: true,
    }),
    columnHelper.accessor("startTime", {
      id: "startTime",
      header: "Start Time",
      cell: (row) => moment(row.getValue()).format("h:mm a"),
      enableGlobalFilter: true,
      enableColumnFilter: false,
      enableHiding: true,
    }),
    columnHelper.accessor("endTime", {
      id: "endTime",
      header: "End Time",
      cell: (row) => moment(row.getValue()).format("h:mm a"),
      enableGlobalFilter: true,
      enableColumnFilter: false,
      enableHiding: true,
    }),
    columnHelper.display({
      id: "actions",
      header: "Action",
      cell: ({ row }) => {
        return (
          <IconButton
            color="primary"
            onClick={() => {
              setNewActivityEdit([row.original]);
              setOpenDialog(true);
              setType(id ? "update" : "edit");
            }}
            disabled={isViewing ? true : false}
          >
            <EditIcon fontSize="medium" />
          </IconButton>
        );
      },
    }),
  ];

  return EURActivityColumns;
};

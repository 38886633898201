import { BaseSyntheticEvent, Dispatch, SetStateAction } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { appColors } from "../../../../../theme";

const EJOConfirmDialog = ({
  setOpenClearSrDialog,
  openClearSrDialog,
  handleSubmit,
  handleClose,
}: {
  setOpenClearSrDialog: Dispatch<SetStateAction<boolean>>;
  openClearSrDialog: boolean;
  handleSubmit: () => void;
  handleClose: () => void;
}) => {
  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={openClearSrDialog}>
      <DialogTitle>
        <Typography
          fontSize={22}
          fontWeight={500}
          color={appColors.primary}
          textAlign={"left"}
        >
          Changing Equipment No.
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: "2em" }}>
        <Box>
          <Typography variant="body1">
            Changing equipment will remove previously created Service Requests,
            would you like to proceed?
          </Typography>
        </Box>
        <Box sx={{ width: "100%", display: "flex" }}>
          <Button
            onClick={handleClose}
            sx={{ width: 150, marginLeft: "auto", marginTop: "1.5em" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            sx={{ width: 150, marginLeft: ".5em", marginTop: "1.5em" }}
          >
            Proceed
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EJOConfirmDialog;

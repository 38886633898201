import PageContainer from '../../../../components/common/PageContainer';
import PartsDescriptionTable from './PartsDescriptionTable';

function PartsDescriptionPage() {
  return (
    <>
      <PageContainer title='Servicing Databases' subTitle='Parts Description' showDate>
        Parts Description
        <PartsDescriptionTable />
      </PageContainer>
    </>
  );
}

export default PartsDescriptionPage;

import * as yup from 'yup';
import { ISRT } from './serviceRequestType-utils';

export const ServiceRequestTypeInitialValues: ISRT = {
  requestName: '',
  requestType: 'PM',
  isActive: 'true',
  id: ''
};
export const ServiceRequestTypeSchema = yup.object({
    requestName: yup.string()
    .trim('The Request Name cannot include leading and trailing spaces')
    .strict(true)
    .min(1,'The Request Name to be at least 1 Alphabet or Numerical')
    .required('The Request Name  is required'),

    requestType: yup.string().required('Request Type is required')
  });
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { appColors } from "../../theme";

export interface AppDialogProps extends DialogProps {
  title: string;
  titleAlignment?: "center" | "left" | "right";
  onDialogClose: () => void;
  isFormDelete?: boolean;
}

const AppDialog = (props: AppDialogProps) => {
  const {
    open,
    onDialogClose,
    title,
    titleAlignment,
    children,
    isFormDelete,
    ...rest
  } = props;
  return (
    <Dialog
      open={open}
      //   onClose={onDialogClose}
      fullWidth
      maxWidth="sm"
      {...rest}
    >
      <DialogContent>
        <Stack
          mt={1}
          mb={titleAlignment && titleAlignment === "center" ? 0 : 3}
        >
          <Typography
            fontSize={22}
            fontWeight={500}
            color={appColors.primary}
            textAlign={
              titleAlignment && titleAlignment === "center" ? "center" : "left"
            }
          >
            {title}
          </Typography>
          {isFormDelete ? null : (
            <IconButton
              onClick={onDialogClose}
              disableRipple
              aria-label="close"
              size="small"
              sx={{ position: "absolute", top: 12, right: 12 }}
            >
              <CloseIcon fontSize="small" sx={{ color: "#222" }} />
            </IconButton>
          )}
        </Stack>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default AppDialog;

import * as yup from 'yup';
import moment from 'moment';
import { IParts } from './parts-utils';

const currentDate = new Date(Date.now());

export const PartsInitialValues: IParts = {
  partNumber: "",
  model: "",
  system: "",
  subsystem: "",
  description: "",
  make: "",
  willfitOrEOM: "",
  supplier: "",
  unitOfMeasurement: "Piece",
  packageQty: null,
  packageCost: null,
  updatePeriod: moment(currentDate).format('YYYY-MM-DD'),
  isActive: 'true',
  partsDescriptionId: ""
};

export const PartsSchema = yup.object({
  partNumber: yup.string()
  .trim('The Part Number cannot include leading and trailing spaces')
  .strict(true)
  .min(1, 'The Part Number needs to be at least 1 Alphabet or Numerical')
  .required('The Part Number is required'),

  model: yup.string()
  .trim('The Model Number cannot include leading and trailing spaces')
  .strict(true)
  .min(1, 'The Model Number needs to be at least 1 Alphabet or Numerical')
  .required('The Model Number is required'),

  system: yup.string()
  .trim('The System cannot include leading and trailing spaces')
  .strict(true)
  .min(1, 'The System to be at least 1 Alphabet or Numerical')
  .required('The System is required'),

  subsystem: yup.string()
  .trim('The Sub System cannot include leading and trailing spaces')
  .strict(true)
  .min(1, 'The Sub System to be at least 1 Alphabet or Numerical')
  .required('The Sub System is required'),

  //description: yup.string().required('Description is required'),
  make: yup.string()
  .trim('The Make cannot include leading and trailing spaces')
  .strict(true)
  .min(1, 'The Make to be at least 1 Alphabet or Numerical')
  .required('The Make is required'),

  willfitOrEOM: yup.string()
  .trim('The Will Fit EOM cannot include leading and trailing spaces')
  .strict(true)
  .min(1, 'The Will Fit EOM to be at least 1 Alphabet or Numerical')
  .required('The Will Fit EOM is required'),
  
  supplier: yup.string()
  .trim('The Supplier cannot include leading and trailing spaces')
  .strict(true)
  .min(1, 'The Supplier to be at least 1 Alphabet or Numerical')
  .required('The Supplier is required'),

  unitOfMeasurement: yup.string()
  .trim('The Unit of Measurement cannot include leading and trailing spaces')
  .strict(true)
  .min(1, 'The Unit of Measurement  to be at least 1 Alphabet or Numerical')
  .required('The Unit of Measurement is required'),

  packageQty: yup.number().required('Package quantity is required'),
  packageCost: yup.number().required('Package cost is required'),

  updatePeriod: yup.string().required('Update period is required'),

  isActive: yup.string().required('Status is required'),

  partsDescriptionId: yup.string().required('Description is required'),
});
import PageContainer from '../../../../components/common/PageContainer';
import PartsTable from './PartsTable';
function PartsPage() {
  return (
    <>
      <PageContainer title='Servicing Databases' subTitle='Parts Master' showDate>
        Parts Master
        <PartsTable />
      </PageContainer>
    </>
  );
}

export default PartsPage;

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import { flexRender } from '@tanstack/react-table';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../../components/common/app-table/ui';
import useDefaultTable from '../../../../components/common/app-table/useDefaultTable';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import AppSearchField from '../../../../components/common/forms/AppSearchField';
import { useAuth } from '../../../../components/AuthProvider';
import { ExportButton } from '../../../../components/common/primebmd-ui/DataButtons';
import { IEORRColumns, IEORR, IEORRExport } from '../utils/types';
import { useGetEORRQuery, useFormatGetEORRQuery } from '../../../../hooks/useEPFERF';
import { useCreateEMEORRColumns, useCreatePMEORRColumns } from '../hooks/useEORRColumns';
import { exportEORRToExcel } from '../utils/exporting';

const EORRTable = () => {
  const user = useAuth();
  const role = user && user?.currentUserRole?.name;
  const pmColumns = useCreatePMEORRColumns();
  const emColumns = useCreateEMEORRColumns();
  const eorr = useGetEORRQuery();
  const data: IEORR[] = eorr?.data === undefined ? [] : eorr?.data;

  const formattedEORRData: IEORRExport[] = useFormatGetEORRQuery(data);

  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } =
  useDefaultTable<IEORRColumns>(
    data, 
    role === 'Project Manager' ? pmColumns : emColumns
  );

  const handleExport = () => {
    exportEORRToExcel(formattedEORRData, 'EORR Report');
  }

  const emptyText = searchFilter ? 'No data found' : 'No data yet';

  if (eorr?.isLoading) return <LoadingIndicator show />;

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" mb={2} gap={2}>
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
        <Stack direction="row" gap={.75}>
          <ExportButton onClick={handleExport} />
        </Stack>
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader sx={ role === 'Equipment Manager' ? { minWidth: '1300px' } : {}}>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody
              dataCount={dataCount}
              // loading={loading}
              emptyText={emptyText}
            >
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id} sx={{ height: '50px' }}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
    </>
  );
}

export default EORRTable;
import { Equipment } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import { IEquipments } from '../modules/DatabaseManagement/EquipmentDBs/Equipment/utils/equipment-utils';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
  status: string;
  data: Equipment[];
};

export type GetOneReturnType = {
  status: string;
  data: Equipment;
};

const getEquipments = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.equipment);
  return response.data.data;
};

const getModelLists = async () => {
  const response = await apiClient.get<GetManyReturnType>( `${apiEndpoints.equipment}/model/all`,);
  return response.data.data;
};

const getEquipmentsById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.equipment}/${id}`
  );
  return response.data.data;
};

const importEquipment = async (newEquipmentData: IEquipments[]) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.equipment}/import-data`,
    newEquipmentData
  );
  return response.data.data;
};

const updateEquipment = async (id: string, updateEquipmentData: Partial<IEquipments>) => {
  delete updateEquipmentData.productFamily;
  delete updateEquipmentData.id;
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.equipment}/${id}`,
    updateEquipmentData
  );

  return response.data.data;
};

const createEquipment = async (newEquipmentData: Partial<IEquipments>) => {
  delete newEquipmentData.id;
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.equipment,
    newEquipmentData
  );
  return response.data.data;
};

export default {
  getEquipments,
  getEquipmentsById,
  importEquipment,
  updateEquipment,
  createEquipment,
  getModelLists
};
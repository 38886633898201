import { useEffect, useState } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import AppSelect from '../../../../components/common/forms/AppSelect';
import PageContainer from '../../../../components/common/PageContainer';
import { ICreateMechanic } from './utils/Mechanic-utils';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { MechanicSchema, MechanicInitialValues} from './utils/schema';
import { useGetIMechanicById, useUpdateMechanic, useCreateMechanic } from '../../../../hooks/useMechanic';
import { appColors, appStyles } from '../../../../theme';
import { useGetLaborTypeQuery } from '../../../../hooks/useLaborType';
import { trimmedString } from '../../../../utils/trimmedString';

const MechanicForm = () => {
  const navigate = useNavigate();
  const typeData = useGetLaborTypeQuery();
  const currentMechanic = useGetIMechanicById();
  const updateMechanic = useUpdateMechanic();
  const createMechanic = useCreateMechanic();

  const statusOptions: SelectOptionType[] = [{'id': 'true', 'label': 'Active' , 'value': 'true'},{'id': 'false', 'label': 'Inactive' , 'value': 'false'} ]
  const typeOptions : SelectOptionType[] = 
  typeData?.data === undefined ? [] : typeData.data.map(e => {
                                              return { 
                                                id: e.id, 
                                                label: e.laborType, 
                                                value: e.id 
                                              }
                                             });

const { register, 
        handleSubmit,
        setValue,
        control,
        trigger,
        formState: { errors, isValid }
      } = useForm<ICreateMechanic>({
        defaultValues: currentMechanic.data! ? currentMechanic.data : MechanicInitialValues,
        mode: 'onChange',
        resolver: yupResolver(MechanicSchema),
      });

  useEffect(() => {

    if(currentMechanic.data && typeOptions){
      setValue("name", currentMechanic.data?.name);
      setValue("isActive", currentMechanic.data?.isActive ? 'true' : 'false');
      setValue("type", currentMechanic.data?.type);
      setValue("laborTypeID", currentMechanic.data?.laborTypeID);
    }
  }, [currentMechanic.data, setValue]);

  const onError = (err: typeof errors) => {
    console.error(err);
  };

  const onSubmit = (data : ICreateMechanic) => {

    data.name = trimmedString(data.name);
    if (currentMechanic.data) {
      updateMechanic.mutate(data);
    }
    else {
      createMechanic.mutate(data)
    }
  };

  const LaborTypeChange = (selectedOption : any) => {
    const selectedType = typeOptions.find(option => option.id === selectedOption);
    if (selectedType) {
      setValue('type', selectedType.label);
      setValue("laborTypeID", selectedType.id)
      trigger();

    }
};;

  const backTo = () => navigate('/mechanic');
  
  return (
    <>
      <PageContainer
        title='Manage My Databases'
        subTitle='Mechanic Master'
        controls={
          <Stack direction='row' gap={2}>
            <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disabled={!isValid }
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
      <Divider color='black' />
      <Stack
        component='form'
        height='100%'
        bgcolor='white'
        py={3}
        boxShadow={appStyles.shadow}
        borderRadius={1}
      >
        <Stack px={3} height='100%' overflow='auto' gap={1}>
        <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
          <Stack direction="column" gap={1.5} width="45%">   
            <AppTextField
              label="Name"
              labelAlignment="left"
              labelWidth='33%'
              fieldWidth= '67%'
              fontWeight={400}
              placeholder="Name"
              error={!!errors.name}
              helperText={errors.name?.message}
              {...register('name')}
            />
              <Controller
                  control={control}
                  name='laborTypeID'
                  render={({ field }) => (
                    <AppSelect
                      label="Labor Type"
                      labelAlignment="left"
                      labelWidth='33%'
                      fieldWidth= '67%'
                      fontWeight={400}
                      defaultValue=""
                      placeholder="Select here"
                      error={!!errors.laborTypeID}
                      helperText={errors.laborTypeID?.message}
                      options={typeOptions}
                      {...field}
                      
                      {...register('laborTypeID')}
                      onChange={(selectedOption: any) => {
                        
                        LaborTypeChange(selectedOption);
                      }}
                     
                    />
                )}
              />
              <Controller
                  control={control}
                  name='isActive'
                  render={({ field }) => (
                    <AppSelect
                      label="Status"
                      disabled= {currentMechanic.data ? false : true}
                      labelAlignment="left"
                      labelWidth='33%'
                      fieldWidth= '67%'
                      fontWeight={400}
                      placeholder="Select here"
                      error={!!errors.isActive}
                      helperText={errors.isActive?.message}
                      options={statusOptions}
                      {...register('isActive')}
                      {...field}
                     
                    />
                )}
              />

              

             
          </Stack>
        </Stack>
        </Stack>
      </Stack>
    </PageContainer>
    <Outlet />
    </>
  )
}

export default MechanicForm;

import { IPartsDescription, ICreatePartsDescription } from '../modules/DatabaseManagement/ServicingDBs/PartsDescription/utils/PartsDescription-utils';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
  status: string;
  data: IPartsDescription[];
};
export type GetOneReturnType = {
  status: string;
  data: IPartsDescription;
};

const getPartDescription = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.partsDescription);
  return response.data.data;
};

const getPartDescriptionById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.partsDescription}/${id}`
  );
  return response.data.data;
};

const importPartDescription = async (newpartsDescriptionTypeData: any) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.partsDescription}/import-data`,
    newpartsDescriptionTypeData
  );
  return response.data.data;
};

const updatepartsDescriptionType = async (id: string, updatepartsDescriptionTypeData: Partial<ICreatePartsDescription>) => {
  delete updatepartsDescriptionTypeData.id;
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.partsDescription}/${id}`,
    updatepartsDescriptionTypeData
  );

  return response.data.data;
};

const createpartsDescriptionType = async (newpartsDescriptionTypeData: Partial<ICreatePartsDescription>) => {
  delete newpartsDescriptionTypeData.id;
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.partsDescription,
    newpartsDescriptionTypeData
  );
  return response.data.data;
};

export default {
  getPartDescription,
  getPartDescriptionById,
  importPartDescription,
  updatepartsDescriptionType,
  createpartsDescriptionType
};
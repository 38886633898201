import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { ISRT, ISRTExport } from './serviceRequestType-utils'

const ServiceRequestTypeMap = {
    'Request Name' : 'requestName',
    'Request Type' : 'requestType',
    'Status' : 'isActive'
}

const SRTMapImport = {
    'requestName' : 'requestName',
    'requestType' : 'requestType'
  }

export const exportServiceRequestTypeToExcel = (data: ISRTExport[], filename: string) => {
    const headings = [Object.keys(ServiceRequestTypeMap)];
    const columnHeaders = [...Object.values(ServiceRequestTypeMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

  export const GetTemplateServiceRequestType = (data: ISRT[], filename: string) => {
    const headings = [Object.keys(SRTMapImport)];
    const columnHeaders = [...Object.values(SRTMapImport)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20
    }));
  
    worksheet['!cols'] = wscols;

    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };
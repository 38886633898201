import { createColumnHelper } from '@tanstack/react-table';
import { IEjoManPower } from '../../../../modules/MyDashboardsPlusTasks/EJO-ESR/utils/types';
import Checkbox from '@mui/material/Checkbox';
import HeaderCellCheckbox from '../../../../components/common/primebmd-ui/HeaderCellCheckbox';
import moment from 'moment';

const columnHelper = createColumnHelper<IEjoManPower>();

const EjoManpowerColumns = [
    columnHelper.display({
        id: 'select',
        header: ({ table }) => (
        <HeaderCellCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
        />
        ),
        cell: ({ row }) => (
        <Checkbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
        />
        ),
    }),
    columnHelper.accessor('equipmentNo', {
        id: 'equipmentNo',
        header: 'Equipment Number',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('projectLocation', {
        id: 'projectLocation',
        header: 'Project / Location',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('ejoNumber', {
        id: 'ejoNumber',
        header: 'EJO Number',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('serviceRequestNo', {
        id: 'serviceRequestNo',
        header: 'SR Number',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('serviceRequest', {
        id: 'serviceRequest',
        header: 'Service Requested',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('pmCode', {
        id: 'pmCode',
        header: 'Remarks / PM Code',
        cell: ({ row }) => {
          return row.original.pmCode ? row.original.pmCode : row.original.srRemarks
          },
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('esrNumber', {
        id: 'esrNumber',
        header: 'ESR Number',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('manPower', {
        id: 'manPower',
        header: 'Manpower',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('roleType', {
        id: 'roleType',
        header: 'Role',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('daysSinceEjoOpened', {
        id: 'daysSinceEjoOpened',
        header: 'Days Since EJO Opened',
        cell:  (row) => row.getValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('ejoDateNeeded', {
        id: 'ejoDateNeeded',
        header: 'EJO Date Needed',
        cell: (row) => row.getValue() != 'N/A' ? moment(row.getValue()).format('MM/DD/yyyy') : 'N/A',
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('ejoDateApproved', {
        id: 'ejoDateApproved',
        header: 'EJO Approval Date',
        cell: (row) => row.getValue() != 'N/A' ? moment(row.getValue()).format('MM/DD/yyyy') : 'N/A',
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('esrDateCreated', {
        id: 'esrDateCreated',
        header: 'ESR Created Date',
        cell: (row) => row.getValue() != 'N/A' ? moment(row.getValue()).format('MM/DD/yyyy') : 'N/A',
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('esrDateApproved', {
        id: 'esrDateApproved',
        header: 'ESR Approval Date',
        cell: (row) => row.getValue() != 'N/A' ? moment(row.getValue()).format('MM/DD/yyyy') : 'N/A',
        enableGlobalFilter: true,
        enableColumnFilter: true,
    })
];

export default EjoManpowerColumns;
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { ExportHEOperator} from './HEOperator-utils';

const ExportHEOperatorMap = {
    'Operator Name': 'operatorName',
    'Product Family': 'productFamily',
    'License Type': 'licenseType',
    'Date Received': 'dateReceived',
    'Status' : 'status'
}

const ExportHEOperatorMapImport = {
    'Operator Name': 'operatorName',
    'Product Family': 'productFamilyId',
    'License Type': 'licenseTypeId',
    'Date Received': 'dateReceived'
}


export const ExportHEOperatorToExcel = (data: ExportHEOperator[], filename: string) => {
    const headings = [Object.keys(ExportHEOperatorMap)];
    const columnHeaders = [...Object.values(ExportHEOperatorMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    const formattedData = data.map(({isActive, productFamilyId, licenseTypeId, ...item}) => {
      return {
        ...item,
        status : isActive ? 'Active' : 'Inactive'
      };
    });

    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
    console.log(formattedData)
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
     XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };

  export const GetTemplateExportHEOperator= (data: ExportHEOperator[], filename: string) => {
    const headings = [Object.keys(ExportHEOperatorMapImport)];
    const columnHeaders = [...Object.values(ExportHEOperatorMapImport)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };
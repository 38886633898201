import { IEJOApproval } from "../../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/utils/types";
import { apiClient, apiEndpoints } from "../apiClient";
import { EJOSR } from "../../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/shared/EJO/EJO-SR/utils/types";
import { EJORequestBodyApproval } from "../../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/shared/EJO/utils/types";

export type GetManyReturnType = {
  status: string;
  data: IEJOApproval[];
};

export type GetManyReturnTypeServiceRequests = {
  status: string;
  data: EJOSR[];
};

type GetOneReturnTypeForManagement = {
  status: string;
  data: EJORequestBodyApproval;
};

type GetOneReturnType = {
  status: string;
  data: IEJOApproval;
};

const getAllEJO = async (status?: string) => {
  const url = status
    ? `${apiEndpoints.ejo}?status=${status}`
    : `${apiEndpoints.ejo}`;
  const response = await apiClient.get<GetManyReturnType>(url);
  return response.data.data;
};

const getAllServiceRequests = async (id: string) => {
  const response = await apiClient.get<GetManyReturnTypeServiceRequests>(
    `${apiEndpoints.ejo}/get-all/${id}`
  );

  return response.data.data;
};

const getCurrentEJOById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnTypeForManagement>(
    `${apiEndpoints.ejo}/${id}`
  );

  return response.data.data;
};

const updateEJO = async (id: string, data: EJORequestBodyApproval) => {
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.ejo}/${id}`,
    data
  );

  return response.data.data;
};

const ejoApi = {
  getAllEJO,
  getAllServiceRequests,
  getCurrentEJOById,
  updateEJO,
};

export default ejoApi;

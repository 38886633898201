import { createColumnHelper } from "@tanstack/react-table";
import { IconButton, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { useEJOESRApprovalContext } from "../../Context/EJOESRApprovalContext";
import { ESREquipmentServiceColumns } from "../ESR-EquipmentService/utils/types";

export const useCreateESREquipmentServicesColumns = () => {
  const columnHelper = createColumnHelper<ESREquipmentServiceColumns>();

  const esrEquipmentServicesColumns = [
    columnHelper.accessor("id", {
      header: "",
      cell: () => null,
      meta: {
        hidden: true,
      },
    }),
    columnHelper.accessor("taskTypeItem", {
      id: "taskTypeItem",
      header: "Task Type",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor("taskItem", {
      id: "taskItem",
      header: "Item",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("partNumber", {
      id: "partNumber",
      header: "Part No.",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor("quantity", {
      id: "quantity",
      header: "Quantity",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),

    columnHelper.accessor("remarks", {
      id: "remarks",
      header: "Remarks",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
  ];

  return esrEquipmentServicesColumns;
};

import { RouteObject } from 'react-router-dom';

// Root
import DatabaseManagementPage from './DatabaseManagementPage';

// Equipment Databases
import EquipmentPage from './EquipmentDBs/Equipment/EquipmentPage';
import EquipmentForm from './EquipmentDBs/Equipment/EquipmentForm';
import HECertificateTypePage from './EquipmentDBs/HECertificateType/HECertificateTypePage';

// Servicing Databases
import PMSCodePage from './ServicingDBs/PMSCode/PMSCodePage';
import PMSCodeTaskPartsPage from './ServicingDBs/PMSCodeTaskParts/PMSCodeTaskPartsPage';
import PartsPage from './ServicingDBs/Parts/PartsPage';
import LaborTypePage from './ServicingDBs/LaborType/LaborTypePage';
import ServiceRequestTypePage from './ServicingDBs/ServiceRequestType/ServiceRequestTypePage';
import ServiceTaskPage from './ServicingDBs/ServiceTask/ServiceTaskPage';
import PartsForm from './ServicingDBs/Parts/PartsForm';
import TaskTypeForm from './ServicingDBs/ServiceTask/TaskTypesForm';
import ServiceRequestTypeForm from './ServicingDBs/ServiceRequestType/SrviceRequestTypeForm';
import PartsDescriptionForm from './ServicingDBs/PartsDescription/PartsDescriptionForm';

// Project Databases
import ProjectPage from './ProjectDBs/Project/ProjectPage';
import ProjectActivityPage from './ProjectDBs/ProjectActivity/ProjectActivityPage';
import EquipmentPlannedShiftHoursPage from './ProjectDBs/EquipmentPlannedShiftHours/EquipmentPlannedShiftHoursPage';

// Personnel Databases
import HEOperatorPage from './PersonnelDBs/HEOperator/HEOperatorPage';
import HEOperatorCertificateTypePage from './PersonnelDBs/HEOperatorCertificateType/HEOperatorCertificateTypePage';
import MechanicPage from './PersonnelDBs/Mechanic/MechanicPage';

// Other Databases
import FuelTypePage from './OtherDBs/FuelType/FuelTypePage';
import LocationPage from './OtherDBs/Location/LocationPage';
import MeterTypePage from './OtherDBs/MeterType/MeterTypePage';
import RentalRatesPage from './OtherDBs/RentalRates/RentalRatesPage';
import FuelUpdatePage from './OtherDBs/FuelUpdate/FuelUpdatePage';
import FuelCostCodePage from './OtherDBs/FuelCostCode/FuelCostCodePage';
import LocationForm from './OtherDBs/Location/LocationTypeForm';
import FuelTypeForm from './OtherDBs/FuelType/FuelTypeForm';
import MechanicForm from './PersonnelDBs/Mechanic/MechanicForm';
import HEOpartorForm from './PersonnelDBs/HEOperator/HEOperatorForm';
import HEOpartorLicenseTypeForm from './PersonnelDBs/HEOperatorCertificateType/HEOperatorCertificateTypeForm';
import LaborTypeForm from './ServicingDBs/LaborType/LaborTypeForm';
import HECertificateTypeForm from './EquipmentDBs/HECertificateType/HECertificateTypeForm';
import PartsDescriptionPage from './ServicingDBs/PartsDescription/PartsDescriptionPage';
import PMSCodeForm from './ServicingDBs/PMSCode/PMSCodeForm';
import PMSCodeTaskPartsForm from './ServicingDBs/PMSCodeTaskParts/PMSCodeTaskPartsForm';

const DatabaseManagementRoutes: RouteObject = {
  path: '',
  element: <DatabaseManagementPage />,
  children: [
    // Equipment Databases
    {
      path: 'equipment',
      element: <EquipmentPage />,
    },
    {
      path: 'he-certificate-type',
      element: <HECertificateTypePage />,
    },
    // Servicing Databases
    {
      path: 'pms-code',
      element: <PMSCodePage />,
    },
    {
      path: 'pms-code-task-parts',
      element: <PMSCodeTaskPartsPage />,
    },
    {
      path: 'parts-description',
      element: <PartsDescriptionPage />,
    },
    {
      path: 'parts',
      element: <PartsPage />,
    },
    {
      path: 'service-request-type',
      element: <ServiceRequestTypePage />,
    },
    {
      path: 'service-task',
      element: <ServiceTaskPage />,
    },
    {
      path: 'labor-type-rate',
      element: <LaborTypePage />,
    },
    // Project Databases
    {
      path: 'project',
      element: <ProjectPage />,
    },
    {
      path: 'project-activity-cost-code',
      element: <ProjectActivityPage />,
    },
    {
      path: 'equipment-planned-shift-hours',
      element: <EquipmentPlannedShiftHoursPage />,
    },

    // Personnel Databases
    {
      path: 'mechanic',
      element: <MechanicPage />,
    },
    {
      path: 'he-operator',
      element: <HEOperatorPage />,
    },
    {
      path: 'he-operator-certificate-type',
      element: <HEOperatorCertificateTypePage />,
    },
    // Other Databases
    {
      path: 'meter-type-interval-type',
      element: <MeterTypePage />,
    },
    {
      path: 'fuel-type',
      element: <FuelTypePage />,
    },
    {
      path: 'location',
      element: <LocationPage />,
    },
    {
      path: 'rental-rates',
      element: <RentalRatesPage />,
    },
    {
      path: 'fuel-update',
      element: <FuelUpdatePage />,
    },
    {
      path: 'fuel-cost-code',
      element: <FuelCostCodePage />,
    },
    {
      path: 'equipment-new',
      element: <EquipmentForm />,
    },
    {
      path: 'equipment-new/:id',
      element: <EquipmentForm />,
    },
    {
      path: 'parts-new',
      element: <PartsForm />,
    },
    {
      path: 'parts-new/:id',
      element: <PartsForm />,
    },
    { 
      path: 'service-task-new', 
      element: <TaskTypeForm /> 
    },
    { 
      path: 'service-task/:id', 
      element: <TaskTypeForm /> 
    },
    {
      path: 'serviceRequestType-new',
      element: <ServiceRequestTypeForm />,
    },
    {
      path: 'serviceRequestType-new/:id',
      element: <ServiceRequestTypeForm />,
    },

    {
      path: 'location-new',
      element: <LocationForm />,
    },
    {
      path: 'location/:id',
      element: <LocationForm />,
    },
    {
      path: 'fuel-type-new',
      element: <FuelTypeForm />,
    },
    {
      path: 'fuel-type/:id',
      element: <FuelTypeForm />,
    },
    {
      path: 'mechanic-new',
      element: <MechanicForm />,
    },
    {
      path: 'mechanic/:id',
      element: <MechanicForm />,
    },
    {
      path: 'he-operator-new',
      element: <HEOpartorForm />,
    },
    {
      path: 'he-operator/:id',
      element: <HEOpartorForm />,
    },
    {
      path: 'he-operator-certificate-type-new',
      element: <HEOpartorLicenseTypeForm />,
    },
    {
      path: 'he-operator-certificate-type/:id',
      element: <HEOpartorLicenseTypeForm />,
    },
    {
      path: 'labor-type-rate-new',
      element: <LaborTypeForm />,
    },
    {
      path: 'labor-type-rate/:id',
      element: <LaborTypeForm />,
    },
    {
      path: 'he-certificate-type-new',
      element: <HECertificateTypeForm />,
    },
    {
      path: 'he-certificate-type/:id',
      element: <HECertificateTypeForm />,
    },
    {
      path: 'parts-description-new',
      element: <PartsDescriptionForm />,
    },
    {
      path: 'parts-description/:id',
      element: <PartsDescriptionForm />,
    },
    {
      path: 'pms-code-new',
      element: <PMSCodeForm />,
    },
    {
      path: 'pms-code/:id',
      element: <PMSCodeForm />,
    },
        {
      path: 'pms-code-task-parts-new',
      element: <PMSCodeTaskPartsForm />,
    },
    {
      path: 'pms-code-task-parts/:id',
      element: <PMSCodeTaskPartsForm />,
    },
    
  ],
};

export default DatabaseManagementRoutes;

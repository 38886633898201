import { useMutation, useQuery } from '@tanstack/react-query';
import { Operator } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import operatorAPI from '../services/operators';
import { useNavigate, useParams } from 'react-router-dom';
import { ExportHEOperator, IHEOperator } from '../modules/DatabaseManagement/PersonnelDBs/HEOperator/utils/HEOperator-utils';
import { useSnackBar } from '../components/ToastProvider';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';
import { ICreateHEOperator } from '../modules/DatabaseManagement/PersonnelDBs/HEOperator/utils/HEOperator-utils';


interface ApiError {
  status: number;
  message: string;
}

interface useGetIOpeatorOptions {
  operatorName?: string;
}

export function useGetOperatorsQuery(options?: useGetIOpeatorOptions) {

  return useQuery<IHEOperator[]>(['operator'], () => operatorAPI.getOperators(), {
    onError(err) {
      console.log(err);
    },
    select(Operator) {
      return options?.operatorName ? Operator.filter(l => l.operatorName === options?.operatorName) : Operator;
    },
  });
}



export function useGetOperatorById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`operator-${id}`, id], () => operatorAPI.getOperatorsById(id!));
}

export const useExportHEOperator = (data: ExportHEOperator[]) => {
  const newOperatorList = [];
  
  const OperatorList = data;

  for(let i in OperatorList){
    let json = {
      operatorName: OperatorList[i].operatorName,
      productFamilyId: OperatorList[i].productFamilyId,
      productFamily: OperatorList[i].productFamily,
      licenseType: OperatorList[i].licenseType,
      dateReceived: OperatorList[i].dateReceived,
      licenseTypeId: OperatorList[i].licenseTypeId,
      isActive: OperatorList[i].isActive,
    }
    newOperatorList.push(json);
  }
  return newOperatorList;
}

export function useImportOperator() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((newOperatorData: IHEOperator[]) => operatorAPI.importOperators(newOperatorData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['operator'] }).then(() => {
        
        if( data[1].invalidData.length >= 1){
             showSnackBar(data[1].invalidData[0].errorReason, 'error');
             setTimeout(() => {
              window.location.reload();
            }, 500);
        }
        else{
          showSnackBar('Successfully Inserted Operators', 'success');
          navigate('/he-operator', { replace: true });
          window.location.reload();
        }
     
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}


export function useUpdateOperator() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newOperatorData: Partial<ICreateHEOperator>) => operatorAPI.updateOperator(id!, newOperatorData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['operator'] }).then(() => {
        showSnackBar('Successfully Updated Operator', 'success');
        setTimeout(() => {
          navigate('/he-operator', { replace: true });
        }, 500);
   
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useDeleteOperator( id: string) {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((data: any) => operatorAPI.deleteOperator(id), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['operator'] }).then(() => {        
        showSnackBar('Successfully Deleted Operator', 'success');
        setTimeout(() => {
          navigate('/he-operator', { replace: true });
        }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useCreateOperator() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newOperatorData: Partial<ICreateHEOperator>) => operatorAPI.createOperator(newOperatorData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['operator'] }).then(() => {
          showSnackBar('Successfully Inserted Operator', 'success');
          setTimeout(() => {
            navigate('/he-operator', { replace: true });
          }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}
import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { ILaborType } from './LaborType-utils';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import LaborTypeDeleteModal from './LaborTypeDeleteModal';

interface Ownprops {
  laborType: ILaborType;
}

const LaborTypeActionButton = ({ laborType }: Ownprops) => {
  const { id } = laborType;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const goTolaborTypeForm = () => navigate(`/labor-type-rate/${id}`);
  const goToModalDelete = () => setOpen(true);
  
  return (
    <Stack direction='row' gap={1}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goTolaborTypeForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top'>
        <IconButton color='warning' aria-label='delete' onClick={goToModalDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
      <LaborTypeDeleteModal setOpen={setOpen} open={open} laborTypes={laborType} />
    </Stack>
  );
};

export default LaborTypeActionButton;

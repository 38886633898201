import { useQuery } from '@tanstack/react-query';
import { EET } from '../modules/Reports/EquipmentCostReports/utils/types';
import eetAPI from '../services/reports/eet';

export function useGetEETQuery() {
  return useQuery<EET[]>(['EETs'], () => eetAPI.getAllEET(), {
    onError(err) {
      console.log(err);
    }
  }); 
}

export const useExportEET = (data: EET[]) => {
    const newEETList = [];
    
    const EETList = data;
  
    for(let i in EETList){
      let json = {
        ejoNumber: EETList[i].ejoNumber,
        ejoCreatedDate: EETList[i].ejoCreatedDate,
        ejoApprovalDate: EETList[i].ejoApprovalDate,
        esrNumber: EETList[i].esrNumber,
        esrCreatedDate: EETList[i].esrCreatedDate,
        esrApprovalDate: EETList[i].esrApprovalDate,
        esrStartDate: EETList[i].esrStartDate,
        esrCompletionDate: EETList[i].esrCompletionDate,
        equipmentNumber: EETList[i].equipmentNumber,
        model: EETList[i].model,
        productFamily: EETList[i].productFamily,
        make: EETList[i].make,
        project: EETList[i].project,
        ownedOrRented: EETList[i].ownedOrRented,
        remarks: EETList[i].remarks,
        serviceRequest: EETList[i].serviceRequest,
        dateNeeded: EETList[i].dateNeeded
      }
      newEETList.push(json);
    }
    return newEETList;
  }
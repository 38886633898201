import { useState } from 'react';
import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { ISRT } from './serviceRequestType-utils';
import SRTModal from './ServiceRequestTypeModal';

interface Ownprops {
  serviceRequestType: ISRT;
}

const ServiceRequestTypeActionButton = ({ serviceRequestType }: Ownprops) => {
  
    const { id } = serviceRequestType;

    const [open, setOpen] = useState(false)
    
    const navigate = useNavigate();

    const goToPartsForm = () => navigate(`/serviceRequestType-new/${id}`);

    const goToModalDelete = () => setOpen(true);

    return (
        <Stack direction='row' gap={1.5}>
        <Tooltip title='Edit' placement='top'>
            <IconButton color='primary' aria-label='edit' onClick={goToPartsForm}>
            <EditIcon />
            </IconButton>
        </Tooltip>
        
        <Tooltip title='Delete' placement='top'>
            <IconButton color='warning' aria-label='delete' onClick={goToModalDelete}>
            <DeleteIcon />
            </IconButton>
        </Tooltip>
       
        <SRTModal setOpen={setOpen} open={open} serviceRequestType={serviceRequestType} />
    
        </Stack>
  );
};

export default ServiceRequestTypeActionButton;

import { createColumnHelper } from '@tanstack/react-table';
import { HistoricalRentalsTracker } from '../../EquipmentUseAndPerformanceReports/utils/types';
import moment from 'moment';

const columnHelper = createColumnHelper<Partial<HistoricalRentalsTracker>>();

const HistoricalRentalsColumns = [
  columnHelper.accessor('erfDate', {
    id: 'erfDate',
    header: 'ERF Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('erfNo', {
    id: 'erfNo',
    header: 'ERF Number',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('productFamily', {
    id: 'productFamily',
    header: 'Product Family',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('make', {
    id: 'make',
    header: 'Make',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('model', {
    id: 'model',
    header: 'Model',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('capacity', {
    id: 'capacity',
    header: 'Capacity',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('project', {
    id: 'project',
    header: 'Project.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('arrivalDate', {
    id: 'arrivalDate',
    header: 'Date of Arrival.',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('eorrRequestedPUD', {
    id: 'eorrRequestedPUD',
    header: 'Requested Pick Up Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('pickupDate', {
    id: 'pickupDate',
    header: 'Pick Up Date',
    cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  }),
  columnHelper.accessor('ownershipType', {
    id: 'ownershipType',
    header: 'Ownership Type',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableHiding: true
  })
];

export default HistoricalRentalsColumns;
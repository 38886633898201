import { useEffect } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppSelect from '../../../../components/common/forms/AppSelect';
import PageContainer from '../../../../components/common/PageContainer';
import { ICreateLaborType } from './utils/LaborType-utils';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { LaborTypeSchema, LaborTypeInitialValues} from './utils/schema';
import { useGetLaborTypeById, useUpdateLaborTypes, useCreateLaborTypes } from '../../../../hooks/useLaborType';

import { appColors, appStyles } from '../../../../theme';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import { trimmedString } from '../../../../utils/trimmedString';

const LaborTypeForm = () => {
  const navigate = useNavigate();
  const currentLaborType = useGetLaborTypeById();
  const updateLaborType = useUpdateLaborTypes();
  const createLaborType = useCreateLaborTypes();
  const statusOptions: SelectOptionType[] = [{'id': 'true', 'label': 'Active' , 'value': 'true'},{'id': 'false', 'label': 'Inactive' , 'value': 'false'} ]
  const unitOfMeasurementOptions: SelectOptionType[] = [
    {'id': 'Hour', 'label': 'Hour' , 'value': 'Hour'},
    {'id': 'Day', 'label': 'Day' , 'value': 'Day'} 
  ]

const { register, 
        handleSubmit,
        setValue,
        control,
        formState: { errors, isValid }
      } = useForm<ICreateLaborType>({
        defaultValues: currentLaborType.data! ? currentLaborType.data : LaborTypeInitialValues,
        mode: 'onChange',
        resolver: yupResolver(LaborTypeSchema),
      });

  useEffect(() => {
    setValue("isActive", 'true');
    if (!currentLaborType.data) return;
    setValue("laborType", currentLaborType.data?.laborType);
    setValue("unitCost", currentLaborType.data?.unitCost);
    setValue("unitOfMeasurement", currentLaborType.data?.unitOfMeasurement);
    setValue("updatePeriod", currentLaborType.data?.updatePeriod ?? null);
    setValue("isActive", currentLaborType.data?.isActive ? 'true' : 'false');
  }, [currentLaborType.data, setValue]);

  const onError = (err: typeof errors) => {
    console.error(err);
  };

  const onSubmit = (data : ICreateLaborType) => {
    data.laborType = trimmedString(data.laborType);
    if (currentLaborType.data) {
      updateLaborType.mutate(data);
    }
    else {
      createLaborType.mutate(data)
    }
  };

  const backTo = () => navigate('/labor-type-rate');
  
  return (
    <>
      <PageContainer
        title='Manage My Databases'
        subTitle='Equipment Master'
        controls={
          <Stack direction='row' gap={2}>
            <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disabled={!isValid }
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
        <Divider color='black' />
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
          <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
            <Stack direction="column" gap={1.5} width="45%">   
              <AppTextField
                label="Labor Type"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Labor Type"
                error={!!errors.laborType}
                helperText={errors.laborType?.message}
                {...register('laborType')}
              />
              <AppTextField
                label="Unit Cost"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Unit Cost"
                error={!!errors.unitCost}
                helperText={errors.unitCost?.message}
                {...register('unitCost')}
              />
            
               <Controller
                    control={control}
                    name='unitOfMeasurement'
                    render={({ field }) => (
                      <AppSelect
                        label="Unit of Measurement"
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.unitOfMeasurement}
                        helperText={errors.unitOfMeasurement?.message}
                        options={unitOfMeasurementOptions}
                        {...register('unitOfMeasurement')}
                        {...field}
                      />
                  )}
                />
              
                 <Controller
                    control={control}
                    name='updatePeriod'
                    render={({ field: { value, onChange } }) => (
                      <AppDatePicker
                        name='updatePeriod'
                        value={value}
                        onChange={onChange}
                        label='Update period'
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        error={!!errors.updatePeriod}
                        helperText={errors.updatePeriod?.message}
                      />
                    )}
                  />

                 <Controller
                    control={control}
                    name='isActive'
                    render={({ field }) => (
                      <AppSelect
                        label="Status"
                        disabled= {currentLaborType.data ? false : true}
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.isActive}
                        helperText={errors.isActive?.message}
                        options={statusOptions}
                        {...register('isActive')}
                        {...field}
                      />
                  )}
                />
               
            </Stack>
          </Stack>
          </Stack>
        </Stack>
      </PageContainer>
      <Outlet />
    </>
  )
}

export default LaborTypeForm;
import { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AppTextField from "../../../../../components/common/forms/AppTextField";
import AppDatePicker from "../../../../../components/common/forms/AppDatePicker";
import AppSelect from "../../../../../components/common/forms/AppSelect";
import { useGetEquipmentsQuery } from "../../../../../hooks/useEquipments";
import { appColors } from "../../../../../theme";
import { Box, Grid, Switch, Typography } from "@mui/material";
import { useAuth } from "../../../../../components/AuthProvider";
import {
  useGetESRByIDForApproval,
  useUpdateESR,
} from "../../../../../hooks/useESRApproval";
import { ESRFormFields } from "./utils/types";
import { ESRApprovalInitialValues } from "./utils/schema";
import ESRManpowerTable from "./ESR-Manpower/ESRManpowerTable";
import ESREquipmentServicesTable from "./ESR-EquipmentService/ESREquipmentServiceTable";
import LoadingIndicator from "../../../../../components/common/LoadingIndicator";
import { useESRApprovalContext } from "../Context/ESRApprovalContext";

const ESRApprovalForm = () => {
  const { id: paramsId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const updateESR = useUpdateESR();
  const currUser = useAuth();
  const equipmentData = useGetEquipmentsQuery();
  const { data: currentESRSelected, isLoading: isESRLoading } =
    useGetESRByIDForApproval();
  const {
    setEsrManpowerArray,
    setEsrEquipmentServicesArray,
    esrActionType,
    setESRActionType,
  } = useESRApprovalContext();
  const {
    register,
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ESRFormFields>({
    defaultValues: ESRApprovalInitialValues,
    mode: "onChange",
    // TODO CREATE SCHEMA FOR ESR VALIDATION
    // resolver: yupResolver(EJOManagementSchema),
  });

  const pathSegments = location.pathname.split("/").filter(Boolean);
  const rolePermissions = currUser && currUser.currentUserRole?.permissions;
  const equipmentOptions: any[] =
    equipmentData?.data === undefined
      ? []
      : equipmentData.data.map((e) => {
          return {
            id: e.id,
            label: e.equipmentNo,
            value: e.id,
          };
        });

  // ? MIGHT USE: GET SERVICE REQUEST, EQUIPMENT SERVICES, AND MANPOWER PER ESR
  // const {
  //   data: serviceRequested,
  //   isLoading: isSRLoading,
  //   isFetching: isSRFetching,
  // } = useGetServiceRequestByESRId(String(currentESRSelected?.serviceRequestId));

  // TYPE ESREquipmentServices
  // const {
  //   data: equipmentServices,
  //   isLoading: isEquipmentServicesLoading,
  //   isFetching: isEquipmentServicesFetching,
  // } = useGetESREquipmentServices();
  // console.log("logs for equipment services", equipmentServices);

  // TYPE ESRManpower
  // const {
  //   data: manPower,
  //   isLoading: isManpowerLoading,
  //   isFetching: isManpowerFetching,
  // } = useGetESRManpower();
  // console.log("logs for MANPOWER", manPower);

  const onSubmit = (esrData: any) => {
    // TODO CREATE MANPOWER AND EQUIPMENT SERVICE FORM FIELDS ALSO

    if (esrActionType == "approval") {
      esrData.status = "Approved";
      updateESR.mutate(esrData);
    }
  };

  const backTo = () => {
    //* To clear the fields
    reset();
    navigate(`/${pathSegments[0]}/${pathSegments[1]}`);
  };

  useEffect(() => {
    if (paramsId) {
      if (currentESRSelected) {
        // TODO GIVE TYPE OF ESR FORM FIELDS
        const valuesOfCurrentESRSelected: ESRFormFields = {
          id: currentESRSelected.id,
          esrNumber: currentESRSelected.esrNumber,
          equipmentId: currentESRSelected.equipmentId,
          dateNeeded: currentESRSelected.dateNeeded,
          dateCreated: currentESRSelected.dateCreated,
          serviceRequested: String(currentESRSelected?.serviceRequestType),
          remarks: currentESRSelected.remarks,
          pmCode: currentESRSelected.pmCode,
          isWarrantyJob: currentESRSelected?.isWarrantyJob,
        };

        //* Set the array for further editing
        setEsrManpowerArray(currentESRSelected.esrManHourList);
        setEsrEquipmentServicesArray(currentESRSelected.equipmentServiceList);

        //* Use reset to set the form values to the esr current values
        reset(valuesOfCurrentESRSelected);

        //* Set the action type to give each action different validation if needed
        if (pathSegments[2] == "approve") {
          setESRActionType("approval");
        }

        if (pathSegments[2] == "view") {
          setESRActionType("viewing");
        }
      }
    }
  }, [currentESRSelected, paramsId, setValue]);

  if (isESRLoading) {
    if (paramsId) {
      return <LoadingIndicator show />;
    }
  }

  //* Used for validating. Did not use isValid because isValid still return false even though the data is valid
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <>
      <Stack direction="row" gap={2} justifyContent={"flex-end"} mb={1}>
        <Button
          sx={{ backgroundColor: appColors.lightGray, color: "black" }}
          disabled={updateESR.isLoading}
          onClick={backTo}
        >
          {esrActionType == "viewing" ? "Back" : "Cancel"}
        </Button>

        {esrActionType != "viewing" && (
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={hasErrors || updateESR.isLoading || updateESR.isSuccess}
            disableElevation
            variant="contained"
            sx={{ textTransform: "capitalize", borderRadius: 1.5 }}
          >
            {!updateESR.isLoading && esrActionType == "approval"
              ? "Approve"
              : null}
            {updateESR.isLoading && "Approving..."}
          </Button>
        )}
      </Stack>
      <Divider color="black" />
      <Grid container spacing={2} sx={{ marginTop: "1em" }}>
        {/* TITLE EJO NUMBER */}
        <Grid container spacing={2} sx={{ marginLeft: ".14em" }}>
          <Grid item sm={12} md={12} lg={6}>
            <AppTextField
              label="ESR NO."
              labelAlignment="left"
              labelWidth="25%"
              fieldWidth="75%"
              fontWeight={400}
              placeholder="0"
              value={currentESRSelected?.esrNumber}
              disabled={true}
            />
          </Grid>
          {pathSegments[1] == "esr-wip" && (
            <Grid item sm={12} md={12} lg={6}>
              <AppDatePicker
                name="dateStarted"
                value={currentESRSelected?.dateStarted ?? new Date()}
                onChange={() => {}}
                label="Date Started"
                labelAlignment="left"
                labelWidth="25%"
                fieldWidth="75%"
                fontWeight={400}
                disabled={true}
              />
            </Grid>
          )}
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <AppDatePicker
            name="dateCreated"
            value={currentESRSelected?.dateCreated ?? new Date()}
            onChange={() => {}}
            label="Date Created"
            labelAlignment="left"
            labelWidth="25%"
            fieldWidth="75%"
            fontWeight={400}
            disabled={true}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Controller
            control={control}
            name="dateNeeded"
            render={({ field: { value, onChange } }) => (
              <AppDatePicker
                name="dateNeeded"
                value={new Date(value)}
                onChange={onChange}
                label="Date Needed"
                labelAlignment="left"
                labelWidth="25%"
                fieldWidth="75%"
                fontWeight={400}
                error={!!errors.dateNeeded}
                helperText={errors.dateNeeded?.message}
                minDate={new Date()}
                disabled={true}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Controller
            control={control}
            name="equipmentId"
            render={({ field }) => (
              <AppSelect
                label="Equipment No."
                labelAlignment="left"
                labelWidth="25%"
                fieldWidth="75%"
                fontWeight={400}
                placeholder="Select here"
                error={!!errors.equipmentId}
                helperText={errors.equipmentId?.message}
                options={equipmentOptions}
                {...register("equipmentId")}
                {...field}
                disabled={true}
              />
            )}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <AppTextField
            label="Service Requested"
            labelAlignment="left"
            labelWidth="25%"
            fieldWidth="75%"
            fontWeight={400}
            placeholder="0"
            value={currentESRSelected?.serviceRequestType}
            disabled={true}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <AppTextField
            label={!currentESRSelected?.remarks ? "PM Code" : "Remarks"}
            labelAlignment="left"
            labelWidth="25%"
            fieldWidth="75%"
            fontWeight={400}
            placeholder="0"
            value={
              !currentESRSelected?.remarks
                ? currentESRSelected?.pmCode
                : currentESRSelected?.remarks
            }
            disabled={true}
          />
        </Grid>
        {/* Is warranty can only be seen in Create ESR Form */}
        {pathSegments[1] == "lagay yung route ni create esr dito" && (
          <Grid item sm={12} md={12} lg={6}>
            <Controller
              control={control}
              name="isWarrantyJob"
              render={({ field: { value, onChange } }) => (
                <Stack direction="row" alignContent="center">
                  <Box sx={{ width: "25%" }}>
                    <Typography
                      color={appColors.formText}
                      whiteSpace="nowrap"
                      fontSize={15}
                      sx={{
                        paddingTop: ".5em",
                        marginRight: "2.5em",
                      }}
                    >
                      Warranty Job?
                    </Typography>
                  </Box>
                  <Box sx={{ width: "75%", display: "flex" }}>
                    <Switch
                      checked={getValues().isWarrantyJob}
                      {...register("isWarrantyJob")}
                      value={value}
                      disabled={esrActionType == "viewing"}
                      onChange={onChange}
                    />
                    <Typography
                      color={appColors.formText}
                      whiteSpace="nowrap"
                      fontSize={15}
                      sx={{
                        paddingTop: ".5em",
                      }}
                    >
                      {!value ? "No" : "Yes"}
                    </Typography>
                  </Box>
                </Stack>
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <ESRManpowerTable />
        </Grid>
        <Grid item xs={12}>
          <ESREquipmentServicesTable />
        </Grid>
      </Grid>
      {/* TODO CREATE MANPOWER AND EQUIPMENT SERVICE FORM */}
      {/* <EJOSRManagementForm id={""} esrNumber={""} /> */}
    </>
  );
};

export default ESRApprovalForm;

import { createColumnHelper } from "@tanstack/react-table";
import { EditActionButton } from "../../../../../../components/common/primebmd-ui/EditActionButton";
import { IEJOColumnsApproval } from "../../../utils/types";

export const useCreateEJOPendingApprovalColumns = () => {
  const columnHelper = createColumnHelper<IEJOColumnsApproval>();

  const ejoPendingApprovalColumns = [
    columnHelper.accessor("ejoNumber", {
      id: "ejoNumber",
      header: "EJO No.",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor("equipmentNo", {
      id: "equipmentNo",
      header: "Equipment No.",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor("projectLocation", {
      id: "projectLocation",
      header: "Project / Location",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor("daysElapsed", {
      id: "daysElapsed",
      header: "Days Elapsed",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.display({
      id: "actions",
      header: "Action",
      cell: ({ row }) => (
        <>
          <EditActionButton route={`edit/${row.original.id}`} />
        </>
      ),
      enableHiding: false,
    }),
  ];

  return ejoPendingApprovalColumns;
};

import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { IFueltype } from './FuelType-utils';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import FuelTypeDeleteModal from './FuelTypeDeleteModal';

interface Ownprops {
  fueltypes: IFueltype;
}

const FuelTypeActionButton = ({ fueltypes }: Ownprops) => {
  const { id } = fueltypes;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const goToFuelTypeForm = () => navigate(`/fuel-type/${id}`);
  const goToModalDelete = () => setOpen(true);
  
  return (
    <Stack direction='row' gap={1}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goToFuelTypeForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top'>
        <IconButton color='warning' aria-label='delete' onClick={goToModalDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
      <FuelTypeDeleteModal setOpen={setOpen} open={open} fueltypes={fueltypes} />
    </Stack>
  );
};

export default FuelTypeActionButton;

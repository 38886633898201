import { Box, Paper, Stack, TablePagination } from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../../components/common/app-table/ui';
import useDefaultTable from '../../../../components/common/app-table/useDefaultTable';
import FormAddButton from '../../../../components/common/FormAddButton';
import AppSearchField from '../../../../components/common/forms/AppSearchField';
import { ExportButton, ImportButton, GetTemplateButton } from '../../../../components/common/primebmd-ui/DataButtons';
import { ILaborType, ExportLaborType  } from './utils/LaborType-utils';
import LaborTypeColumns from './LaborTypeColumn';
import { ExportLaborTypeToExcel, GetTemplateLaborType } from './utils/exporting';
import { useGetLaborTypeQuery, useExportLaborTypes, useImportLaborTypes } from '../../../../hooks/useLaborType';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { useSnackBar } from '../../../../components/ToastProvider';
import MultipleUploadButton from '../../../../components/common/MultipleUploadButton';
import * as xlsx from 'xlsx';




const LaborTypeTable = () => {
  const { showSnackBar } = useSnackBar();

  const laborTypeData = useGetLaborTypeQuery();
  const importData = useImportLaborTypes();
  const data: ILaborType[] = laborTypeData?.data === undefined ? [] : laborTypeData.data;
  const formattedData: ExportLaborType[] = useExportLaborTypes(data);


  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } = useDefaultTable<ILaborType>(
    data,
    LaborTypeColumns
  );

  
  const navigate = useNavigate();

  const emptyText = searchFilter ? 'No data found' : 'No data yet';

  const gotoAddlaborType = () => navigate('/labor-type-rate-new');

  const handleExport = () => {
    ExportLaborTypeToExcel(formattedData, 'Labor Type Database');
  }

  const handleTemplate = () => {
    GetTemplateLaborType([], 'Template Labor Type Database');
  
  }

  const readUploadFile = (e : any) => {
    e.preventDefault();
    if(e.target.files[0] != undefined){
      const checkExcelName = e.target.files[0].name.split("_");
      if (checkExcelName[0].toUpperCase() == 'TEMPLATE LABOR TYPE DATABASE') {
          const reader = new FileReader();
          reader.onload = (e) => {
              const data = e.target?.result;
              const workbook = xlsx.read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const json = xlsx.utils.sheet_to_json(worksheet);
              const jsonData: any =  json === undefined ? [] : json;
              const newData = jsonData.map((dataObject: any) => ({ 
                laborType: dataObject["Labor Type"],
                unitCost: dataObject["Unit Cost"],
                unitOfMeasurement: dataObject["Unit of Measurement"],
                updatePeriod: dataObject["Update Period"],
              }));
              
              if(newData.length !== 0){
                importData.mutate(newData);
              }
              else showSnackBar('No data found on excel', 'error');
              setTimeout(() => {
                window.location.reload();
              }, 1000);
          };
          reader.readAsArrayBuffer(e.target.files[0]);
      }
      else showSnackBar('Invalid file upload please download Excel template', 'error');
    }
}

  if (laborTypeData?.isLoading) return <LoadingIndicator show />;

  return (
    <>
      <Stack direction='row'  justifyContent='flex-end' mb={2} gap={2}>
        <GetTemplateButton onClick={handleTemplate} />
        <MultipleUploadButton
          accept=".xlsx, .xls"
          onChange={readUploadFile}
        />
        <ExportButton onClick={handleExport} />
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
        <FormAddButton onClick={gotoAddlaborType} />
       
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody dataCount={dataCount} loading={laborTypeData?.isLoading} emptyText={emptyText}>
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
      {laborTypeData?.isError && showSnackBar('Network Error', 'error')}
    </>
  );
};

export default LaborTypeTable;


import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import HeaderCellCheckbox from '../../../components/common/primebmd-ui/HeaderCellCheckbox';
import { dateDifference } from '../../../utils/helpers';
import { createColumnHelper } from '@tanstack/react-table';
import { appColors } from '../../../theme';

export interface AllItem {
  equipmentNo: string;
  productFamily: string;
  model: string;
  location: string;
  capacity: string;
  deploymentStatus: string;
  readyToworkStatus: string;
  make: string;
}

const columnHelper = createColumnHelper<AllItem>();

export const allItemColumns = [
  columnHelper.display({
    id: 'select',
    header: ({ table }) => (
      <HeaderCellCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('productFamily', {
    id: 'productFamily',
    header: 'Product Family',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),

  columnHelper.accessor('make', {
    id: 'make',
    header: 'Make',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),

  columnHelper.accessor('capacity', {
    id: 'capacity',
    header: 'Capacity',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),

  columnHelper.accessor('model', {
    id: 'model',
    header: 'Model',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('deploymentStatus', {
    id: 'deploymentStatus',
    header: 'Deployment Status',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('location', {
    id: 'location',
    header: 'Location',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('readyToworkStatus', {
    id: 'readyToworkStatus',
    header: 'Ready to work Status',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  })
];

import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AppTimePicker from "../../../../components/common/forms/AppTimePicker";
import AppTextField from "../../../../components/common/forms/AppTextField";
import AppDialog from "../../../../components/common/AppDialog";
import LoadingIndicator from "../../../../components/common/LoadingIndicator";
import { EURActivitylInitialValues, EURActivitySchema } from "../utils/schema";
import { EURActivityFields, IEURActivity } from "../utils/types";
import {
  useCreateEURActivity,
  useGetEURActivitiesById,
  useUpdateEURActivity,
} from "../../../../hooks/useEURActivities";
import { useSMRContext } from "../Context/SMRContext";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getValue } from "@testing-library/user-event/dist/utils";

interface EURActivityFormProps {
  id: string;
  activityId: string;
}

const EURActivityForms: React.FC<EURActivityFormProps> = ({
  id,
  activityId,
}) => {
  const {
    opendialog,
    setOpenDialog,
    newActivityEdit,
    newActivity,
    setNewActivity,
    type,
    setIsEditting,
  } = useSMRContext();
  const { ids } = useParams();

  const createEURActivity = useCreateEURActivity();
  //const updateEURActivity = useUpdateEURActivity();
  const activityToEdit = useGetEURActivitiesById();

  const handleClose = () => {
    ClearData();
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    formState: { errors, isValid },
  } = useForm<EURActivityFields>({
    defaultValues: activityToEdit
      ? activityToEdit.data
      : EURActivitylInitialValues,
    mode: "onChange",
    resolver: yupResolver(EURActivitySchema),
  });

  useEffect(() => {
    if (!newActivityEdit) return;
    setValue("activity", newActivityEdit[0]?.activity);
    setValue("startTime", new Date(newActivityEdit[0]?.startTime));
    setValue("endTime", new Date(newActivityEdit[0]?.endTime));
    setValue("id", newActivityEdit[0]?.id);
  }, [newActivityEdit, setValue]);

  useEffect(() => {
    ClearData();
  }, []);

  const onSubmit = (formData: EURActivityFields) => {
    if (type == "new") {
      const newDatas: IEURActivity = {
        activity: formData.activity,
        startTime: new Date(formData.startTime),
        endTime: new Date(formData.endTime),
        id: ids ? formData.id : newActivity.length.toString(),
        eurSMRId: "",
      };
      setNewActivity((prevActivity: IEURActivity[]) => {
        const newData: IEURActivity = newDatas;
        return [...prevActivity, newData];
      });
      setIsEditting(true);
      ClearData();
    } else if (type == "edit" || type == "update") {
      const newDatas: IEURActivity = {
        activity: formData.activity,
        startTime: new Date(formData.startTime),
        endTime: new Date(formData.endTime),
        id: formData.id,
        eurSMRId: "",
      };
      setNewActivity((prevState) =>
        prevState.map((activity) =>
          activity.id == formData.id ? { ...activity, ...newDatas } : activity
        )
      );
      setIsEditting(true);
      ClearData();
    }
  };

  const ClearData = () => {
    setValue("activity", "");
    setValue("startTime", new Date(Date.now()));
    setValue("endTime", new Date(Date.now()));
    setValue("id", "");
    setOpenDialog(false);
  };

  if (createEURActivity.isLoading) {
    return <LoadingIndicator show />;
  }

  return (
    <AppDialog
      open={opendialog}
      onDialogClose={handleClose}
      title={type == "new" ? "Add Activity" : "Update Activity"}
      maxWidth="sm"
      sx={{ textAlign: "center" }}
    >
      <Stack
        component="form"
        height="100%"
        bgcolor="white"
        py={3}
        borderRadius={1}
      >
        <Stack height="100%" overflow="auto" gap={5}>
          <Stack
            direction="column"
            gap={3}
            width={550}
            justifyContent="center"
            margin="auto"
          >
            <AppTextField
              label="Activity Log"
              required={true}
              error={!!errors.activity}
              helperText={errors.activity?.message}
              {...register("activity")}
            />
            <div style={{ display: "none" }}>
              <AppTextField
                label="id"
                required={true}
                error={!!errors.id}
                helperText={errors.activity?.message}
                hidden={true}
                {...register("id")}
              />
            </div>

            <Controller
              control={control}
              name="startTime"
              render={({ field: { value, onChange } }) => (
                <AppTimePicker
                  name="startTime"
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    if (moment(e).isBefore(moment(getValues("endTime")))) {
                      setError("endTime", { message: "" });
                    }
                  }}
                  required
                  label="Start Time"
                  error={!!errors.startTime}
                  helperText={errors.startTime?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="endTime"
              render={({ field: { value, onChange } }) => (
                <AppTimePicker
                  name="endTime"
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    if (moment(e).isAfter(moment(getValues("startTime")))) {
                      setError("startTime", { message: "" });
                    }
                  }}
                  required
                  label="End Time"
                  error={!!errors.endTime}
                  helperText={errors.endTime?.message}
                />
              )}
            />
            <Stack direction="row" justifyContent="center">
              <Button
                disabled={!isValid}
                onClick={handleSubmit(onSubmit)}
                sx={{ width: 150 }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AppDialog>
  );
};

export default EURActivityForms;

import { createColumnHelper } from '@tanstack/react-table';
import { IPMSCodeTaskParts } from './utils/PMSCodeTaskParts-utils';
import PMSCodeTaskPartsActionButton from './utils/PMSCodeTaskPartsActionsButtons';
import Chip from '@mui/material/Chip';

const columnHelper = createColumnHelper<IPMSCodeTaskParts>();

const PMSCodeTaskPartsColumns = [
    columnHelper.accessor('id', {
        id: 'id',
        header: 'Item No',
        cell: ({row}) => row.index +1,
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('pmCode', {
        id: 'pmCode',
        header: 'pmCode',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('taskItem', {
        id: 'taskItem',
        header: 'Task Item',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('partNumber', {
        id: 'partNumber',
        header: 'Part Number',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('quantity', {
        id: 'quantity',
        header: 'Quantity',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('taskType', {
        id: 'taskType',
        header: 'Task Type',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('partDescription', {
        id: 'partDescription',
        header: 'Part Description',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),

    columnHelper.accessor('isActive', {
        id: 'isActive',
        header: 'Status',
        cell: (row) => (
            <Chip
                label={row.getValue() ? 'Active' : 'Inactive'}
                color={row.getValue() ? 'primary' : 'warning'}
                style={{ color: row.getValue() ? 'white' : 'white', height: '25px' }}
            />
        ),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.display({
        id: 'actions',
        header: 'Action',
        cell: ({ row }) => (
            <PMSCodeTaskPartsActionButton PMSCodeTaskParts={row.original} />
        ), 
        enableHiding: false,
    }),
    
    
];

export default PMSCodeTaskPartsColumns;

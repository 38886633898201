import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { ILocation} from './Location-utils';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import LocationDeleteModal from './LocationDeleteModal';

interface Ownprops {
  location: ILocation;
}

const LocationActionButton = ({ location }: Ownprops) => {
  const { id } = location;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const goToLocationForm = () => navigate(`/location/${id}`);
  const goToModalDelete = () => setOpen(true);
  
  return (
    <Stack direction='row' gap={1}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goToLocationForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top'>
        <IconButton color='warning' aria-label='delete' onClick={goToModalDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
      <LocationDeleteModal setOpen={setOpen} open={open} locations={location} />
    </Stack>
  );
};

export default LocationActionButton;

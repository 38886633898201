import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { ISRT } from './utils/serviceRequestType-utils';
import ServiceRequestTypeActionButton from './utils/ServiceRequestTypeActionButton';
import Chip from '@mui/material/Chip';

const columnHelper = createColumnHelper<ISRT>();

const ServiceRequestTypeColumn = [
    columnHelper.accessor('requestName', {
        id: 'requestName',
        header: 'Item No.',
        cell: ({row}) => row.index +1,
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('requestName', {
        id: 'requestName',
        header: 'Request Name',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('requestType', {
        id: 'requestType',
        header: 'Request Type',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('isActive', {
        id: 'isActive',
        header: 'Status',
        cell: (row) => (
            <Chip
                label={row.getValue() ? 'Active' : 'Inactive'}
                color={row.getValue() ? 'primary' : 'warning'}
                style={{ color: row.getValue() ? 'white' : 'white', height: '25px' }}
            />
        ),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.display({
        id: 'actions',
        header: 'Action',
        cell: ({ row }) => <ServiceRequestTypeActionButton serviceRequestType={row.original} />,
        enableHiding: false,
    })

];

export default ServiceRequestTypeColumn;

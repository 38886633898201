import { createColumnHelper } from '@tanstack/react-table';
import { IPMSCode } from './utils/PMSCode-utils';
import PMSCodeActionButton from './utils/PMSCodeActionsButtons';
import Chip from '@mui/material/Chip';

const columnHelper = createColumnHelper<IPMSCode>();

const PMSCodeColumns = [
    columnHelper.accessor('id', {
        id: 'id',
        header: 'Item No',
        cell: ({row}) => row.index +1,
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('model', {
        id: 'model',
        header: 'model',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('smrOdometerInterval', {
        id: 'smrOdometerInterval',
        header: 'SMR Odemeter Interval',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('pmCodeDescription', {
        id: 'pmCodeDescription',
        header: 'PMCode Description',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('isActive', {
        id: 'isActive',
        header: 'Status',
        cell: (row) => (
            <Chip
                label={row.getValue() ? 'Active' : 'Inactive'}
                color={row.getValue() ? 'primary' : 'warning'}
                style={{ color: row.getValue() ? 'white' : 'white', height: '25px' }}
            />
        ),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.display({
        id: 'actions',
        header: 'Action',
        cell: ({ row }) => (
            <PMSCodeActionButton PMSCode={row.original} />
        ), 
        enableHiding: false,
    }),
    
    
];

export default PMSCodeColumns;

import { EJOESRAssignee } from "../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/shared/EJO/utils/types";
import { apiClient, apiEndpoints } from "./apiClient";

export type GetManyReturnType = {
  status: string;
  data: EJOESRAssignee[];
};

const getAssignees = async () => {
  const response = await apiClient.get<GetManyReturnType>(
    `${apiEndpoints.users}/permission/ESR::MANAGEMENT`
  );
  return response.data.data;
};

export default {
  getAssignees,
};

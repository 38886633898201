import { Link, LinkProps } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ListIcon from '@mui/icons-material/List';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { appColors } from '../../../theme';

interface EditActionButtonProps {
  route: LinkProps['to'];
  label?: string;
  disabled?: boolean;
}

export const LinkActionButton = (props: EditActionButtonProps) => {
  return (
    <Link to={props.route}>{props.label}</Link>
  );
};

export const ViewActionButton = (props: EditActionButtonProps) => {
  return (
    <IconButton color='primary' component={Link} to={props.route}>
      <VisibilityOutlinedIcon fontSize='medium' />
    </IconButton>
  );
};

export const LogActionButton = (props: EditActionButtonProps) => {
  return (
    <IconButton color='primary' component={Link} to={props.route}>
      <OpenInNewIcon fontSize='medium' />
    </IconButton>
  );
};

export const EditActionButton = (props: EditActionButtonProps) => {
  return (
    <IconButton 
      color='primary' 
      component={Link} 
      to={props.route} 
      disabled={props.disabled ? props.disabled : false}
    >
      <EditIcon fontSize='medium' />
    </IconButton>
  );
};

export const ListActionButton = (props: EditActionButtonProps) => {
  return (
    <IconButton 
      color='primary' 
      component={Link} 
      to={props.route} 
      disabled={props.disabled ? props.disabled : false}
    >
      <ListIcon fontSize='medium' />
    </IconButton>
  );
};

export const AllocateActionButton = (props: EditActionButtonProps) => {
  return (
    <Button 
      variant="contained"
      component={Link} 
      to={props.route} 
      disabled={props.disabled ? props.disabled : false}
      sx={{ 
        width: '85px',
        height: '30px', 
        fontSize: '13px',
        backgroundColor: appColors.warning,
        ":hover" : {
          backgroundColor: appColors.warningDark,
        }
      }}
    >
      Allocate
    </Button>
  );
};

export const CreatePRActionButton = (props: EditActionButtonProps) => {
  return (
    <Button 
      variant="contained"
      component={Link} 
      to={props.route} 
      sx={{ 
        width: '85px',
        height: '30px', 
        fontSize: '13px',
        backgroundColor: appColors.error,
        ":hover" : {
          backgroundColor: appColors.errorDark,
        }
      }}
    >
      Create PR
    </Button>
  );
};

export const ModifyActionButton = (props: EditActionButtonProps) => {
  return (
    <Button 
      variant="contained"
      component={Link} 
      to={props.route} 
      sx={{ 
        width: '110px',
        height: '30px', 
        fontSize: '13px',
        backgroundColor: appColors.warning,
        ":hover" : {
          backgroundColor: appColors.warningDark,
        }
      }}
    >
      Modify
    </Button>
  );
};

export const WithdrawActionButton = (props: EditActionButtonProps) => {
  return (
    <Button 
      variant="contained"
      component={Link} 
      to={props.route} 
      sx={{ 
        width: '110px',
        height: '30px', 
        fontSize: '13px',
        backgroundColor: appColors.error,
        ":hover" : {
          backgroundColor: appColors.errorDark,
        }
      }}
    >
      Withdraw
    </Button>
  );
};

export const CreateDeliveryActionButton = (props: EditActionButtonProps) => {
  return (
    <Button 
      variant="contained"
      component={Link} 
      to={props.route} 
      sx={{ 
        width: '110px',
        height: '30px', 
        fontSize: '13px',
        backgroundColor: appColors.success,
        ":hover" : {
          backgroundColor: appColors.successDark,
        }
      }}
    >
      Create Delivery
    </Button>
  );
};

export const ExtendActionButton = (props: EditActionButtonProps) => {
  return (
    <Button 
      variant="contained"
      component={Link} 
      to={props.route} 
      sx={{ 
        width: '110px',
        height: '30px', 
        fontSize: '13px',
        backgroundColor: appColors.warning,
        ":hover" : {
          backgroundColor: appColors.warningDark,
        }
      }}
    >
      Extend
    </Button>
  );
};

export const OffRentActionButton = (props: EditActionButtonProps) => {
  return (
    <Button 
      variant="contained"
      component={Link} 
      to={props.route} 
      sx={{ 
        width: '110px',
        height: '30px', 
        fontSize: '13px',
        backgroundColor: appColors.error,
        ":hover" : {
          backgroundColor: appColors.errorDark,
        }
      }}
    >
      Off Rent
    </Button>
  );
};

export const CloseActionButton = (props: EditActionButtonProps) => {
  return (
    <Button 
      variant="contained"
      component={Link} 
      to={props.route} 
      sx={{ 
        width: '110px',
        height: '30px', 
        fontSize: '13px',
        backgroundColor: appColors.warning,
        ":hover" : {
          backgroundColor: appColors.warningDark,
        }
      }}
    >
      Close
    </Button>
  );
};

export const CreatePickUpActionButton = (props: EditActionButtonProps) => {
  return (
    <Button 
      variant="contained"
      component={Link} 
      to={props.route} 
      disabled={props.disabled}
      sx={{ 
        width: '120px',
        height: '30px', 
        fontSize: '13px',
        backgroundColor: appColors.warning,
        ":hover" : {
          backgroundColor: appColors.warningDark,
        }
      }}
    >
      {props.label}
    </Button>
  );
};

export const ApproveActionButton = (props: EditActionButtonProps) => {
  return (
    <Button 
      variant="contained"
      component={Link} 
      to={props.route} 
      disabled={props.disabled}
      sx={{ 
        width: '150px',
        height: '30px', 
        fontSize: '13px',
        backgroundColor: appColors.success,
        ":hover" : {
          backgroundColor: appColors.successDark,
        }
      }}
    >
      Approve
    </Button>
  );
};

export const ApproveWtChangeActionButton = (props: EditActionButtonProps) => {
  return (
    <Button 
      variant="contained"
      component={Link} 
      to={props.route} 
      disabled={props.disabled}
      sx={{ 
        width: '150px',
        height: '30px', 
        fontSize: '13px',
        backgroundColor: appColors.warning,
        ":hover" : {
          backgroundColor: appColors.warningDark,
        }
      }}
    >
      Approve with Change
    </Button>
  );
};

export const RejectActionButton = (props: EditActionButtonProps) => {
  return (
    <Button 
      variant="contained"
      component={Link} 
      to={props.route} 
      disabled={props.disabled}
      sx={{ 
        width: '150px',
        height: '30px', 
        fontSize: '13px',
        backgroundColor: appColors.error,
        ":hover" : {
          backgroundColor: appColors.errorDark,
        }
      }}
    >
      Reject
    </Button>
  );
};
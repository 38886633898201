import * as yup from 'yup';
import { ICreatePMSCode } from './PMSCode-utils';

export const PMSCodeInitialValues: ICreatePMSCode = {
  id: '',
  model: '',
  pmCodeDescription: '',
  isActive: '',
};
export const PMSCodeSchema = yup.object({
    model: yup.string().trim().required('Model is required'),
    smrOdometerInterval: yup.number().required('SMR Odometer Interval is required').typeError('SMR Odometer Interval must be a number'),
    pmCodeDescription: yup.string().trim().required('PMCode Description is required'),
    isActive: yup.boolean().required('Status is required'),
  });
import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { IPartsDescription} from './PartsDescription-utils';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import PartsDescriptionDeleteModal from './PartsDescriptionDeleteModal';

interface Ownprops {
  PartsDescription: IPartsDescription;
}

const PartsDescriptionActionButton = ({ PartsDescription }: Ownprops) => {
  const { id } = PartsDescription;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const goToPartsDescriptionForm = () => navigate(`/parts-description/${id}`);
  const goToModalDelete = () => setOpen(true);
  
  return (
    <Stack direction='row' gap={1}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goToPartsDescriptionForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top'>
        <IconButton color='warning' aria-label='delete' onClick={goToModalDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
      <PartsDescriptionDeleteModal setOpen={setOpen} open={open} PartsDescriptions={PartsDescription} />
    </Stack>
  );
};

export default PartsDescriptionActionButton;

import { Location } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import { apiClient, apiEndpoints } from './apiClient';
import { ICreateLocation } from '../modules/DatabaseManagement/OtherDBs/Location/utils/Location-utils';
export type GetManyReturnType = {
  status: string;
  data: Location[];
};
export type GetOneReturnType = {
  status: string;
  data: Location;
};

const getLocations = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.location);
  return response.data.data;
};

const geLocationsById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.location}/${id}`
  );
  return response.data.data;
};

const importLocations = async (newLocationTypeData: any) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.location}/import-data`,
    newLocationTypeData
  );
  return response.data.data;
};

const updateLocationType = async (id: string, updateLocationTypeData: Partial<ICreateLocation>) => {
  delete updateLocationTypeData.id;
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.location}/${id}`,
    updateLocationTypeData
  );

  return response.data.data;
};

const createLocationType = async (newLocationTypeData: Partial<ICreateLocation>) => {
  delete newLocationTypeData.id;
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.location,
    newLocationTypeData
  );
  return response.data.data;
};

export default {
  getLocations,
  geLocationsById,
  importLocations,
  updateLocationType,
  createLocationType
};
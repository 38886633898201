import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResetPasswordField } from './utils/types';
import { ResetPasswordInitialValues, ResetPasswordSchema } from './utils/schema';
import { useCheckPasswordToken, useResetPassword } from '../../hooks/useAuth';
import { SxProps } from "@mui/system";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PrimeLogo from '../../assets/images/logo-full.jpeg';
import SuccessIcon from "../../assets/icons/SuccessIcon";
import AuthActionButton from '../../components/common/AuthActionButton';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
const guidelineStyle: SxProps = {
  fontSize: "12px",
};

const SetPasswordPage = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const resetPassword = useResetPassword();
  const checkToken = useCheckPasswordToken();
  
  useEffect(() => {
    checkToken.mutate();
    console.log(checkToken)
  }, []);
  
  
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ResetPasswordField>({
    mode: "onChange",
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: ResetPasswordInitialValues,
  });

  const onSubmit = (values : ResetPasswordField) => {
    resetPassword.mutate(values);
    setIsSuccess(true)
  };

  const goToLogin = () => navigate('/auth');

  return (
    <Box
      sx={{
        padding: 6,
        boxShadow: "0px 0px 15px rgba(0,0,0,0.15)",
        margin: 10,
        borderRadius: 2,
        bgcolor: "white",
      }}
    >
      <Stack mb={4}>
        <img
          src={PrimeLogo}
          alt="primebmd"
          width="auto"
          height={75}
          style={{ alignSelf: "center" }}
        />
      </Stack>
      {(!isSuccess && !resetPassword.isSuccess && checkToken.data) || resetPassword.isLoading ? (
        <Stack
          component="form"
          direction="column"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ width: "600px" }}
          spacing={2}
        >
          <Stack display="flex" direction="row" spacing={5}>
            <Stack spacing={1} width="300px" mb={1}>
              <Typography fontSize={13} component="label" htmlFor="email">
                Password
              </Typography>
              <TextField
                size="small"
                type="password"
                placeholder="Type here"
                {...register("password")}
                error={!!errors.password}
                helperText={errors.password?.message}
                inputProps={{ sx: { fontSize: 14 } }}
              />
              <Typography fontSize={13} component="label" htmlFor="email">
                Confirm Password
              </Typography>
              <TextField
                size="small"
                type="password"
                placeholder="Type here"
                {...register("confirmPassword")}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                inputProps={{ sx: { fontSize: 14 } }}
              />
              <Stack gap={2} alignItems="center">
                <AuthActionButton
                  label="Change Password"
                  isForLogin={false}
                  isLoading={resetPassword.isLoading}
                  disabled={!isValid || isSubmitting}
                  width={180}
                />
              </Stack>
            </Stack>
            <Stack>
              <Typography
                fontWeight="bold"
                fontSize={14}
                paddingBottom="10px"
                borderBottom="1px solid #aaa"
              >
                Reset Password Guidelines
              </Typography>
              <Stack component="ul" paddingLeft={2}>
                <Typography component="li" sx={guidelineStyle}>
                  Passwords must be 8-36 characters in length.
                </Typography>
                <Typography component="li" sx={guidelineStyle}>
                  Passwords must contain at least one (1) uppercase letter.
                </Typography>
                <Typography component="li" sx={guidelineStyle}>
                  Passwords must contain at least one (1) lowercase letter.
                </Typography>
                <Typography component="li" sx={guidelineStyle}>
                  Passwords must contain at least one (1) number.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : isSuccess && resetPassword.isSuccess ? (
        <Stack alignItems="center" spacing={5}>
          <SuccessIcon />
          <Typography fontSize={13} textAlign="center">
            Password has been successfully changed.
          </Typography>
          <Button disableElevation variant="contained" onClick={goToLogin}>
            Go to Login
          </Button>
        </Stack>
      ) : !checkToken.data  ? (
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineOutlined style={{ fontSize: 40, color: "red" }} />
          <Typography variant="h6">
            Token is already used
          </Typography>
          <Button disableElevation variant="contained" onClick={goToLogin}>
            Go to Login
          </Button>
        </div>
      ): <></>}
    </Box>
  );
};

export default SetPasswordPage;

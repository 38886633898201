
import { IPMSCodeTaskParts, ICreatePMSCodeTaskParts } from '../modules/DatabaseManagement/ServicingDBs/PMSCodeTaskParts/utils/PMSCodeTaskParts-utils';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
  status: string;
  data: IPMSCodeTaskParts[];
};
export type GetOneReturnType = {
  status: string;
  data: IPMSCodeTaskParts;
};

const getPartDescription = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.pmsCodesTaskPart);
  return response.data.data;
};

const getPartDescriptionById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.pmsCodesTaskPart}/${id}`
  );
  return response.data.data;
};

const importPartDescription = async (newPMSCodeTaskPartsTypeData: any) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.pmsCodesTaskPart}/import-data`,
    newPMSCodeTaskPartsTypeData
  );
  return response.data.data;
};

const updatePMSCodeTaskPartsType = async (id: string, updatePMSCodeTaskPartsTypeData: Partial<ICreatePMSCodeTaskParts>) => {
  delete updatePMSCodeTaskPartsTypeData.id;
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.pmsCodesTaskPart}/${id}`,
    updatePMSCodeTaskPartsTypeData
  );

  return response.data.data;
};

const createPMSCodeTaskPartsType = async (newPMSCodeTaskPartsTypeData: Partial<ICreatePMSCodeTaskParts>) => {
  delete newPMSCodeTaskPartsTypeData.id;
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.pmsCodesTaskPart,
    newPMSCodeTaskPartsTypeData
  );
  return response.data.data;
};

const deletePMSCodeTaskParts = async (id: string) => {
  const response = await apiClient.delete(`${apiEndpoints.pmsCodesTaskPart}/${id}`);
  return response.data.data;
};

export default {
  getPartDescription,
  getPartDescriptionById,
  importPartDescription,
  updatePMSCodeTaskPartsType,
  createPMSCodeTaskPartsType,
  deletePMSCodeTaskParts
};
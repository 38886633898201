import { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppSelect from '../../../../components/common/forms/AppSelect';
import PageContainer from '../../../../components/common/PageContainer';
import { ICreatePMSCodeTaskParts } from './utils/PMSCodeTaskParts-utils';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { PMSCodeTaskPartsSchema, PMSCodeTaskPartsInitialValues } from './utils/schema';
import { useGetsPMSCodeTaskPartsById, useUpdatePMSCodeTaskPartss, useCreatePMSCodeTaskPartss } from '../../../../hooks/usePMSCodeTaskParts';
import { appColors, appStyles } from '../../../../theme';
import { useGetPMSCodesQuery } from '../../../../hooks/usePMSCode';
import { useGetTaskTypesQuery } from '../../../../hooks/useTaskTypes';
import { useGetPartsDescriptionsQuery } from '../../../../hooks/usePartsDescription';

const PMSCodeTaskPartsForm = () => {
  const navigate = useNavigate();
  const currentPMSCodeTaskParts = useGetsPMSCodeTaskPartsById();
  const updatePMSCodeTaskParts = useUpdatePMSCodeTaskPartss();
  const createPMSCodeTaskParts = useCreatePMSCodeTaskPartss();
  const statusOptions: SelectOptionType[] = [{'id': 'true', 'label': 'Active' , 'value': 'true'},{'id': 'false', 'label': 'Inactive' , 'value': 'false'} ]
  const PMCodeLists = useGetPMSCodesQuery();
  const TaskTypeLists = useGetTaskTypesQuery();
  const PartsDescriptionLists = useGetPartsDescriptionsQuery();

  const PMCodeListsptions : SelectOptionType[] = 
  PMCodeLists?.data === undefined ? [] : PMCodeLists.data.map(e => {
                                              return { 
                                                id: e.id, 
                                                label: e.pmCode, 
                                                value: e.id 
                                              }
                                             });

  const TaskTypeListsptions : SelectOptionType[] = 
  TaskTypeLists?.data === undefined ? [] : TaskTypeLists.data.map(e => {
                                              return { 
                                                id: e.id, 
                                                label: e.taskType, 
                                                value: e.id 
                                              }
                                             });

 const PartsDescriptionListsptions : SelectOptionType[] = 
 PartsDescriptionLists?.data === undefined ? [] : PartsDescriptionLists.data.map(e => {
                                              return { 
                                                id: e.id, 
                                                label: e.description, 
                                                value: e.id 
                                              }
                                             });



  const { register, 
          handleSubmit,
          setValue,
          control,
          formState: { errors, isValid }
        } = useForm<ICreatePMSCodeTaskParts>({
          defaultValues: currentPMSCodeTaskParts.data! ? currentPMSCodeTaskParts.data : PMSCodeTaskPartsInitialValues,
          mode: 'onChange',
          resolver: yupResolver(PMSCodeTaskPartsSchema),
        });

  useEffect(() => {
    setValue("isActive", 'true');
    if (!currentPMSCodeTaskParts.data) return;
    setValue("pmCodeId", currentPMSCodeTaskParts.data?.pmCodeId);
    setValue("taskTypeId", currentPMSCodeTaskParts.data?.taskTypeId);
    setValue("taskItem", currentPMSCodeTaskParts.data?.taskItem);

    setValue("partNumber", currentPMSCodeTaskParts.data?.partNumber);
    setValue("quantity", currentPMSCodeTaskParts.data?.quantity);
    setValue("partsDescriptionId", currentPMSCodeTaskParts.data?.partsDescriptionId);


    setValue("isActive", currentPMSCodeTaskParts.data?.isActive ? 'true' : 'false');
  }, [currentPMSCodeTaskParts.data, setValue]);

  const onError = (err: typeof errors) => {
    console.error(err);
  };

  const onSubmit = (data : ICreatePMSCodeTaskParts) => {
    if (currentPMSCodeTaskParts.data) {
      data.quantity =  data.quantity?.toString();
      updatePMSCodeTaskParts.mutate(data);
    }
    else {
      data.quantity =  data.quantity?.toString();
      createPMSCodeTaskParts.mutate(data)
    }
  };

  const backTo = () => navigate('/pms-code-task-parts');
  
  return (
    <>
      <PageContainer
        title='Manage My Databases'
        subTitle='PMS Code + Task + Parts Master'
        controls={
          <Stack direction='row' gap={2}>
            <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disabled={!isValid }
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
        <Divider color='black' />
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
          <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
            <Stack direction="column" gap={1.5} width="45%">   
            <Controller
                    control={control}
                    name='pmCodeId'
                    render={({ field }) => (
                      <AppSelect
                        label="PMCode"
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.pmCodeId}
                        helperText={errors.pmCodeId?.message}
                        options={PMCodeListsptions}
                        {...register('pmCodeId')}
                        {...field}
                      />
                  )}
                />
                <Controller
                    control={control}
                    name='taskTypeId'
                    render={({ field }) => (
                      <AppSelect
                        label="Task Type"
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.taskTypeId}
                        helperText={errors.taskTypeId?.message}
                        options={TaskTypeListsptions}
                        {...register('taskTypeId')}
                        {...field}
                      />
                  )}
                />
               <AppTextField
                label="Task Item"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Task Item"
                error={!!errors.taskItem}
                helperText={errors.taskItem?.message}
                {...register('taskItem')}
              />
                <AppTextField
                label="Part Number"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Part Number"
                error={!!errors.partNumber}
                helperText={errors.partNumber?.message}
                {...register('partNumber')}
              />
                <AppTextField
                label="Quantity"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Quantity"
                error={!!errors.quantity}
                helperText={errors.quantity?.message}
                {...register('quantity')}
              />
               <Controller
                    control={control}
                    name='partsDescriptionId'
                    render={({ field }) => (
                      <AppSelect
                        label="Part Description"
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.partsDescriptionId}
                        helperText={errors.partsDescriptionId?.message}
                        options={PartsDescriptionListsptions}
                        {...register('partsDescriptionId')}
                        {...field}
                      />
                  )}
                />
                <Controller
                    control={control}
                    name='isActive'
                    render={({ field }) => (
                      <AppSelect
                        label="Status"
                        disabled= {currentPMSCodeTaskParts.data ? false : true}
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.isActive}
                        helperText={errors.isActive?.message}
                        options={statusOptions}
                        {...register('isActive')}
                        {...field}
                      />
                  )}
                />

               
            </Stack>
          </Stack>
          </Stack>
        </Stack>
      </PageContainer>
      <Outlet />
    </>
  )
}

export default PMSCodeTaskPartsForm;
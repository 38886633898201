import { useParams } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router';
import { useSnackBar } from '../components/ToastProvider';
import { ResetPasswordField } from '../modules/Auth/utils/types';
import authApi from '../services/auth';

interface CheckPasswordTokenProps {
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccess: boolean;
}

export function useCheckPasswordToken() {
  const { showSnackBar } = useSnackBar();
  const params = useParams();
  const { token } = params;

  return useMutation(() => authApi.checkPasswordToken(token), {
    onSuccess() {
     // showSnackBar('Please check your email to reset password', 'success');

     return true;
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
  
        showSnackBar(errorData, 'error');
        return false;
      }
    },
  });
}

export function useForgotPassword() {
  const { showSnackBar } = useSnackBar();

  
  return useMutation((email: string) => authApi.forgotPassword(email), {
    onSuccess() {
      showSnackBar('Please check your email to reset password', 'success');
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}

export function useResetPassword() {
  const { showSnackBar } = useSnackBar();
  const params = useParams();
  const { token } = params;

  return useMutation((values: ResetPasswordField) => authApi.setNewPassword(values, token), {
    onSuccess() {
      showSnackBar('Password has been updated successfully', 'success');
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IHEOperator } from './HEOperator-utils';
import { useDeleteOperator } from '../../../../../hooks/useOperators';

interface Ownprops {
    HEOperators: IHEOperator;
  }
  
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const HEOperatorDeleteModal = ({ HEOperators, setOpen, open }: Ownprops & { setOpen: React.Dispatch<React.SetStateAction<boolean>>, open: boolean }) => {

    const {id, operatorName} = HEOperators;
    
  const deleteMechanic = useDeleteOperator(id);

  const handleClose = () => setOpen(false);
  const handleDelete = () => {
    deleteMechanic.mutate(id);
    setOpen(false);

  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}

      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" style={{textAlign: 'center', marginBottom: '10px'}}>
              Are you sure you want to delete {operatorName} ? 
            </Typography>
            <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button style={{marginRight: '10px'}} color='primary' onClick={handleClose}>CANCEL</Button>
                <Button color='warning' onClick={()=> handleDelete()}>DELETE</Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default HEOperatorDeleteModal;

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import { flexRender } from '@tanstack/react-table';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../../components/common/app-table/ui';
import useDefaultTable from '../../../../components/common/app-table/useDefaultTable';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import AppSearchField from '../../../../components/common/forms/AppSearchField';
import { ExportButton } from '../../../../components/common/primebmd-ui/DataButtons';
import { useAuth } from '../../../../components/AuthProvider';
import { IEXFColumns, IEXF, IEXFExport } from '../utils/types';
import { useGetEXFQuery, useFormatGetEXFQuery } from '../../../../hooks/useEPFERF';
import { exfPMColumns, exfEMColumns } from './Columns';
import { exportEXFToExcel } from '../utils/exporting';

const EXFTable = () => {
  const user = useAuth();
  const role = user && user?.currentUserRole?.name;
  const exf = useGetEXFQuery();
  const data: IEXF[] = exf?.data === undefined ? [] : exf?.data;

  const formattedEXFData: IEXFExport[] = useFormatGetEXFQuery(data);

  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } =
  useDefaultTable<IEXFColumns>(
    data, 
    role === 'Project Manager' ? exfPMColumns : exfEMColumns
  );

  const handleExport = () => {
    exportEXFToExcel(formattedEXFData, 'EXF Report');
  }

  const emptyText = searchFilter ? 'No data found' : 'No data yet';

  if (exf?.isLoading) return <LoadingIndicator show />;

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" mb={2} gap={2}>
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
        <Stack direction="row" gap={.75}>
          <ExportButton onClick={handleExport} />
        </Stack>
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody
              dataCount={dataCount}
              // loading={loading}
              emptyText={emptyText}
            >
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id} sx={{ height: '50px' }}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
    </>
  );
}

export default EXFTable;
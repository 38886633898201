import { forwardRef } from "react";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import Autocomplete from "@mui/material/Autocomplete";

import ArrowDownIcon from "@mui/icons-material/ExpandMoreOutlined";

import { SxProps } from "@mui/system";
import { appColors } from "../../../theme";
import { SelectOptionType } from "../forms/form-types";
import { Stack, TextField } from "@mui/material";

interface OptionType {
  id: string;
  value: string;
  label: string;
}
interface OwnSelectProps {
  label: string;
  labelPosition?: "top" | "left";
  labelAlignment?: "center" | "inherit" | "right" | "left" | "justify";
  labelWidth?: string;
  fieldWidth?: string;
  fontWeight?: number;
  options?: OptionType[];
  placeholderColor?: string;
  helperText?: string;
  containerStyles?: SxProps;
}

type AppSelectProps = OwnSelectProps & Omit<any, "label | containerStyles">;

const AppSelect = forwardRef<HTMLInputElement, AppSelectProps>(
  (
    {
      label,
      options,
      placeholderColor,
      helperText,
      containerStyles,
      labelWidth,
      fieldWidth,
      labelPosition,
      labelAlignment,
      fontWeight,
      ...rest
    },
    ref
  ) => {
    const defaultOption = rest.value
      ? options?.find((option) => option.id === rest.value)
      : null;

    return (
      <FormControl sx={{ ...containerStyles, width: "100%" }}>
        <Stack
          direction={
            labelPosition && labelPosition === "top" ? "column" : "row"
          }
          gap={labelPosition && labelPosition === "top" ? 0.5 : 1.5}
          justifyContent="space-between"
          alignItems={
            labelPosition && labelPosition === "top" ? "flex-start" : "center"
          }
        >
          <Typography
            color={appColors.formText}
            component="label"
            htmlFor={rest.name}
            // mt={1.5}
            fontSize={labelPosition && labelPosition === "top" ? 13 : 15}
            width={labelWidth ? labelWidth : 130}
            fontWeight={fontWeight ? fontWeight : 600}
            align={labelAlignment ? labelAlignment : "right"}
            whiteSpace="nowrap"
          >
            {label}
          </Typography>
          <Stack width={fieldWidth ? fieldWidth : 400}>
            <Autocomplete
              disablePortal
              disabled={rest.disabled}
              id={rest.name}
              ref={ref}
              value={defaultOption}
              defaultValue={defaultOption}
              fullWidth={true}
              onChange={(event, newValue) => {
                rest.onChange(newValue?.value);
              }}
              options={
                options
                  ? options.sort((a, b) => a.label.localeCompare(b.label))
                  : []
              }
              sx={{
                "& .MuiAutocomplete-clearIndicator": {
                  display: "none", // Hide the clear (x) button
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={rest.placeholder}
                  name={rest.name}
                  InputLabelProps={{
                    style: { fontSize: "14px", marginTop: "-3px" }, // Adjust font size as needed
                  }}
                />
              )}
              renderOption={(props, option) => (
                <MenuItem
                  {...props}
                  key={option.id}
                  sx={{ width: "100%", fontSize: 14 }}
                >
                  {option.label}
                </MenuItem>
              )}
            />

            {/* <Autocomplete
              id={rest.name}
              ref={ref}
              {...rest}
              size='small'
    
              IconComponent={ArrowDownIcon}
              sx={{
                fontSize: 14,
                '& .MuiSvgIcon-root': { color: 'primary.main' },
                ...rest.sx,
              }}
              renderInput={(params) => <TextField {...params} label="Movie" />
            >
              <MenuItem disabled value=''>
                <Typography
                  component='small'
                  fontSize={14}
                  fontWeight={400}
                  color={placeholderColor ? placeholderColor : '#000'}
                >
                  {rest.placeholder}
                </Typography>
              </MenuItem>
              {options &&
                  options
                    .sort((a, b) => a.label.localeCompare(b.label)) // Sorting the options array by item.label
                    .map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.value}
                        sx={{ width: '100%', fontSize: 14 }}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
            </Autocomplete> */}
            {rest.error && (
              <FormHelperText
                sx={{ color: (theme) => theme.palette.error.main }}
              >
                {helperText}
              </FormHelperText>
            )}
          </Stack>
        </Stack>
      </FormControl>
    );
  }
);

export default AppSelect;

import { ESREquipmentServiceColumns } from "../../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/shared/ESR/ESR-EquipmentService/utils/types";
import { ESRManpowerColumns } from "../../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/shared/ESR/ESR-Manpower/utils/types";
import { ESR } from "../../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/shared/ESR/utils/types";
import {
  IESRApproval,
  IEjoManPowerApproval,
} from "../../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/utils/types";
import { EJOSR } from "../../modules/MyDashboardsPlusTasks/EJO-ESR/EJOManagement/EJO-SR/utils/types";
import { apiClient, apiEndpoints } from "../apiClient";

export type GetManyReturnType = {
  status: string;
  data: IESRApproval[];
};

export type GetOneReturnType = {
  status: string;
  data: IESRApproval;
};

export type GetManyEJOManpowerReturnType = {
  data: IEjoManPowerApproval[];
};

// FOR APPROVAL
export type GetManyESRReturnType = {
  status: string;
  data: ESR[];
};

export type GetOneESRReturnType = {
  status: string;
  data: ESR;
};

export type GetManyESRManpowerReturnType = {
  status: string;
  data: ESRManpowerColumns[];
};

export type GetManyESREquipmentServicesReturnType = {
  status: string;
  data: ESREquipmentServiceColumns[];
};

export type GetServiceRequestedReturnType = {
  status: string;
  data: EJOSR;
};

// FOR APPROVAL API

const getAllESRForApproval = async (status?: string) => {
  const url = status
    ? `${apiEndpoints.esr}/AMIList?status=${status}`
    : `${apiEndpoints.esr}/AMIList`;

  const response = await apiClient.get<GetManyESRReturnType>(url);

  return response.data.data;
};

const getESRByIdForApproval = async (id: string) => {
  const response = await apiClient.get<GetOneESRReturnType>(
    `${apiEndpoints.esr}/${id}`
  );
  return response.data.data;
};

const getServiceRequestByESRId = async (id: string) => {
  const response = await apiClient.get<GetServiceRequestedReturnType>(
    `${apiEndpoints.serviceRequest}/${id}`
  );

  return response.data.data;
};

const getESREquipmentServices = async (id: string) => {
  const response = await apiClient.get<GetManyESREquipmentServicesReturnType>(
    `${apiEndpoints.esr}/${id}/equipment-services`
  );
  return response.data.data;
};

const getESRManpower = async (id: string) => {
  const response = await apiClient.get<GetManyESRManpowerReturnType>(
    `${apiEndpoints.esr}/${id}/esr-man-hour-list`
  );
  return response.data.data;
};

const updateESR = async (id: string, data: any) => {
  const response = await apiClient.patch<GetOneESRReturnType>(
    `${apiEndpoints.esr}/${id}`,
    data
  );

  return response.data.data;
};

// UNTOUCHED
const getAllESR = async (status?: string) => {
  const url = status
    ? `${apiEndpoints.esr}/AMIList?status=${status}`
    : `${apiEndpoints.esr}/AMIList`;
  const response = await apiClient.get<GetManyReturnType>(url);

  // console.log("get all esr response", response.data.data);

  return response.data.data;
};

const getEjoManpower = async (status?: string) => {
  const url = `${apiEndpoints.ejoManpower}/getEjoManPower`;
  const response = await apiClient.get<GetManyEJOManpowerReturnType>(url);
  return response.data.data;
};

const esrApi = {
  getESRByIdForApproval,
  getAllESRForApproval,
  getESREquipmentServices,
  getESRManpower,
  getServiceRequestByESRId,
  getAllESR,
  getEjoManpower,
  updateESR,
};

export default esrApi;

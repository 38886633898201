import * as yup from "yup";

// TODO: CHANGE TYPE AND DETERMINE INITIAL VALUES
export const ESRApprovalInitialValues: any = {
  id: "",
  esrNumber: "",
  equipmentNo: "",
  dateNeeded: "",
  dateCreated: "",
  serviceRequested: "",
  remarks: "",
  equipmentServiceList: [],
  esrManHourList: [],
  status: "",
  isWarrantyJob: false,
};

// TODO CREATE ESR SCHEMA FOR VALIDATION
// export const EJOManagementSchema = yup.object({
//   dateCreated: yup.mixed().required("Date Created is required"),
//   dateNeeded: yup.mixed().required("Please set a date when it's needed."),
//   projectLocationId: yup.string().required("Project Location ID is required"),
//   equipmentId: yup.string().required("Equipment ID is required"),
//   assigneeId: yup.string().required("PIC is required"),
//   smrMileage: yup
//     .number()
//     .required("SMR Mileage is required")
//     .typeError("SMR Mileage must be a number")
//     .positive("SMR Mileage must be a positive number"),
//   isOperable: yup.boolean().required("Operability status is required"),
//   serviceRequestsList: yup
//     .array()
//     .of(EJOSRSchema)
//     .min(1, "At least one service request is required.")
//     .nullable(),
// });

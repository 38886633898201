import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router';
import { useSnackBar } from '../components/ToastProvider';
import { 
  IERF, 
  ICreateERFForm, 
  IAllocateFields,
  IEquipmentDeploymentStatus,
  ICreateDeliveryForm,
  InTransits,
  IEXF,
  IEORR,
  ICreatePickUpForm,
  IEXFRemarksForm,
  AllocationExport,
  InTransitsExport,
  IEXFExport,
  IEORRExport
} from '../modules/MyDashboardsPlusTasks/MyEquipmentRequests/utils/types';
import epfErfApi from '../services/dashboardPlusTasks/epfErf';
import { dateDifference } from '../utils/helpers';
import { useRef } from 'react';

interface useGetEPFERFOptions {
  role?: string;
  page?: string;
}

export const useGetEPFERFQuery = (options?: useGetEPFERFOptions) => {
  return useQuery<IERF[]>(['epfErfs'], () => epfErfApi.getEPFERF(), {
    onError(err) {
      console.log(err);
    },
    select(epfErfs) {
      console.log(options)
      if (options?.role && options?.page) {
        if (options?.role === 'Equipment Manager' && options?.page === 'ERF') {
          return epfErfs.filter(e => e.status === "Requested");
        } else if (options?.role === 'Equipment Manager' && options?.page === 'Allocations') {
          return epfErfs.filter(e => e.status === "Allocated" && e.allocationStatus === "Open");
        } 
        else {
          return epfErfs.filter(e => e.allocationStatus !== "Withdrawn" && e.status !== "On Rent" && e.status !== "Closed");
        } 
      
      } 
     
      else {
        return epfErfs.filter(e => e.status !== "On Rent");
      }
    }
  });
}

export const useGetInTransitQuery = () => {
  return useQuery<InTransits[]>(['inTransitERFs'], () => epfErfApi.getInTransitEquipments(), {
    onError(err) {
      console.log(err);
    }
  });
}

export const useGetEXFQuery = () => {
  return useQuery<IEXF[]>(['exfs'], () => epfErfApi.getEXF(), {
    onError(err) {
      console.log(err);
    }
  });
}

export const useGetEORRQuery = () => {
  return useQuery<IEORR[]>(['eorrs'], () => epfErfApi.getEORR(), {
    onError(err) {
      console.log(err);
    }
  });
}

export const useGetEPFERFById = () => {
  const { id } = useParams<{ id: string}>();

  return useQuery<IERF>([`epfErf-${id}`, id], () => epfErfApi.getEPFERFById(id!));
}

export const useGetAvailableEquipments = (type: any) => {
  const { id } = useParams<{ id: string }>();
  console.log(type)
  const queryKey = type == "my-allocations" ?  epfErfApi.getAvailableEquipmentsForModificaiton(id!) :  epfErfApi.getAvailableEquipments(id!);
 
  return useQuery<IEquipmentDeploymentStatus[]>([`epfErf-${id}`, id], () => queryKey);
};




export const useCreateEPFERF = () => {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newEPFERFData: ICreateERFForm) => epfErfApi.createEPFERF(newEPFERFData), {
    onSuccess(data) {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Successfully Created EPF/ERF', 'success');
        navigate('/my-equipment-requests/my-erf', { replace: true });
        window.location.reload();
      });
      
      // queryClient.setQueryData<IEPFERF[]>(['epfErfs'], oldQueryData => oldQueryData && [
      //   ...oldQueryData,
      //   data,
      // ]);

      // showSnackBar('Successfully Created EPF/ERF', 'success');
      // navigate('/my-equipment-requests/my-epf-and-erf', { replace: true });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  }) 
}

export function useAllocateEquipment(types: any) {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const type = useRef<string | undefined>(undefined);

     // Function to remove certain fields from newERFData
  const modifyERFData = (newERFData: IAllocateFields) => {
      const modifiedERFData = { ...newERFData };
      type.current = modifiedERFData.type;
      delete modifiedERFData.type;

      return modifiedERFData;

    };

  return useMutation((newERFData: IAllocateFields) => {
  
    const modifiedERFData = modifyERFData(newERFData);

    return epfErfApi.allocateERF(id!, modifiedERFData, types);
    
  }, {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Successfully Allocated Equipment', 'success');
        if (type.current && type.current === "my-erf") {
          navigate('/my-equipment-requests/my-erf', { replace: true }); 
        } else if (type.current && type.current === "my-allocations") {
          navigate('/my-equipment-requests/my-allocations', { replace: true }); 
        }
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useCreateDelivery() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newERFData: Partial<ICreateDeliveryForm>) => epfErfApi.createDelivery(id!, newERFData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Successfully Created a Delivery', 'success');
        navigate('/my-equipment-requests/my-allocations', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useArrivedOnSite() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation(() => epfErfApi.arrivedOnSite(id!), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Confirmed Arrived on Site', 'success');
        navigate('/my-equipment-requests/my-erf ', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useCreatePickUp() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newPickUpData: Partial<ICreatePickUpForm>) => epfErfApi.createPickUp(id!, newPickUpData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['eorrs'] }).then(() => {
        showSnackBar('Successfully Created a Pick Up', 'success');
        navigate('/my-equipment-requests/my-eorr', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function usePickedUpFromSite() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation(() => epfErfApi.pickedUpFromSite(id!), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['eorrs'] }).then(() => {
        showSnackBar('Confirmed Picked Up from Site', 'success');
        navigate('/my-equipment-requests/my-eorr', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useWithdrawAllocation() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation(() => epfErfApi.withdrawAllocation(id!), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Successfully Withdrawn Allocation', 'success');
        navigate('/my-equipment-requests/my-allocations', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useEXFApproval() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const status = location.pathname.split('/')[3];
  const url = status === 'approve' 
    ? epfErfApi.approveEXF 
    : (status === 'reject' ? epfErfApi.rejectEXF : epfErfApi.approveEXFWtChange);
    
  const successMessage = status === 'reject' 
    ? 'Successfully Rejected EXF'
    : 'Successfully Approved EXF';

  return useMutation((data: IEXFRemarksForm) => url(id!, data), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['exfs'] }).then(() => {
        showSnackBar(successMessage, 'success');
        navigate('/my-equipment-requests/my-exf', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export const useDeleteUser = () => {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((id: string) => epfErfApi.deleteEPFERF(id), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['epfErfs'] }).then(() => {
        showSnackBar('Successfully Deleted EPF/ERF', 'success');
        navigate('/my-equipment-requests/my-erf', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}

export const useFormatGetIERFQuery = (data: IERF[]) => {
  const newIERFList = [];
  
  const IERFList = data;

  for(let i in IERFList){
    let json = {
      project: IERFList[i].project,
      erfNo : IERFList[i].erfNo,
      erfDate : IERFList[i].erfDate,
      serfNo : IERFList[i].serfNo,
      productFamily : IERFList[i].productFamily,
      capacity : IERFList[i].capacity,
      startDate : IERFList[i].startDate,
      endDate : IERFList[i].endDate
    }
    newIERFList.push(json);
  }
  return newIERFList;
}

export const useFormatGetAllocationQuery = (data: AllocationExport[]) => {
  const newAllocationList = [];
  const AllocationList = data;
  
  for(let i in AllocationList){
    let json = {
      allocationNo : AllocationList[i].allocationNo,
      allocationDate : AllocationList[i].startDate,
      project : AllocationList[i].project,
      serfNo : AllocationList[i].serfNo,
      productFamily : AllocationList[i].productFamily,
      capacity : AllocationList[i].capacity,
      equipmentNo : AllocationList[i].equipmentNo,
      startDate : AllocationList[i].startDate,
      endDate : AllocationList[i].endDate
    }
    newAllocationList.push(json);
  }
  return newAllocationList;
}

export const useFormatGetInTransitQuery = (data: InTransitsExport[]) => {
  const newInTransit = [];
  const InTransitList = data;
  
  for(let i in InTransitList){
    let json = {
      equipmentNo : InTransitList[i].equipmentNo,
      allocationDeliveryNo : InTransitList[i].allocationDeliveryNo,
      allocationDeliveryDate : InTransitList[i].allocationDeliveryDate,
      allocationPickUpLocation : InTransitList[i].allocationPickUpLocation,
      allocationTruckNo : InTransitList[i].allocationTruckNo,
      allocationEPUD : InTransitList[i].allocationEPUD,
      allocationDropOffLocation : InTransitList[i].allocationDropOffLocation,
      allocationETA : InTransitList[i].allocationETA,
      daysOutstanding : String(dateDifference(InTransitList[i].allocationETA, new Date().toISOString())),
    }
    newInTransit.push(json);
  }
  return newInTransit;
}

export const useFormatGetEXFQuery = (data: IEXFExport[]) => {
  const newEXF = [];
  const EXFList = data;
  
  for(let i in EXFList){
    let json = {
      exfNo : EXFList[i].exfNo,
      exfDate : EXFList[i].exfDate,
      project : EXFList[i].project,
      equipmentNo : EXFList[i].equipmentNo,
      emEndDate : EXFList[i].emEndDate,
      exfRequestedEndDate : EXFList[i].exfRequestedEndDate,
      exfStatus : EXFList[i].exfStatus ? EXFList[i].exfStatus : 'Requested',
      exfEMEndDate : EXFList[i].exfEMEndDate ?   EXFList[i].exfEMEndDate : '-',
      exfEMRemarks : EXFList[i].exfEMRemarks ? EXFList[i].exfEMRemarks : '-'
    }
    newEXF.push(json);
  }
  return newEXF;
}

export const useFormatGetEORRQuery = (data: IEORRExport[]) => {
  const newEORR = [];
  const EORRList = data;
  
  for(let i in EORRList){
    let json = {
      eorrNo : EORRList[i].eorrNo,
      eorrDate : EORRList[i].eorrDate,
      project : EORRList[i].project,
      equipmentNo : EORRList[i].equipmentNo,
      eorrRequestedPUD : EORRList[i].eorrRequestedPUD,
      daysSinceEorrCreated : String(dateDifference(new Date(), EORRList[i].eorrDate)), 
      eorrPickUpNo : EORRList[i].eorrPickUpNo,
      eorrTruckNo : EORRList[i].eorrTruckNo,
      eorrEPUD  : EORRList[i].eorrEPUD
    }
    newEORR.push(json);
  }
  return newEORR;
}


import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { ExportFueltype } from './FuelType-utils';

const FuelTypeMap = {
    'Name': 'name',
    'Status': 'status'
}

const FuelTypeMapImport = {
    'Name': 'name',
}


export const ExportFueltypeToExcel = (data: ExportFueltype[], filename: string) => {
    const headings = [Object.keys(FuelTypeMap)];
    const columnHeaders = [...Object.values(FuelTypeMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);

    const formattedData = data.map(({isActive, ...item}) => {
      return {
        ...item,
        status : isActive ? 'Active' : 'Inactive'
      };
    });

    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
    console.log(formattedData)
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
     XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };

  export const GetTemplateFuelType = (data: ExportFueltype[], filename: string) => {
    const headings = [Object.keys(FuelTypeMapImport)];
    const columnHeaders = [...Object.values(FuelTypeMapImport)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };
import { IEquipmentDeploymentStatus, IEquipmentStatusData, IEquipmentReadyToWorkData, IAllEquipmentData } from '../modules/Dashboard/db-utils';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
  status: string;
  data: IEquipmentDeploymentStatus[];
};

export type GetManyByStatusReturnType = {
  status: string;
  data: IEquipmentStatusData[];
};

export type GetManyReadyToWorkStatusReturnType = {
  status: string;
  data: IEquipmentReadyToWorkData[];
  count: number
};

export type GetManyByAllEquipmentReturnType = {
  status: string;
  data: IAllEquipmentData[];
};

const getEquipmentStatus = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.equipmentStatus);
  return response.data.data;
};

const getOnRentEquipmentStatus = async () => {
  const response = await apiClient.get<GetManyByStatusReturnType>(
    `${apiEndpoints.equipmentStatus}/on-rent`
  );
  return response.data.data;
};

const getAvailableEquipmentStatus = async () => {
  const response = await apiClient.get<GetManyByStatusReturnType>(
    `${apiEndpoints.equipmentStatus}/available`
  );
  return response.data.data;
};

const getOffRentEquipmentStatus = async () => {
  const response = await apiClient.get<GetManyByStatusReturnType>(
    `${apiEndpoints.equipmentStatus}/off-rent`
  );
  return response.data.data;
};

const getInTransitEquipmentStatus = async () => {
  const response = await apiClient.get<GetManyByStatusReturnType>(
    `${apiEndpoints.equipmentStatus}/in-transit`
  );
  return response.data.data;
};

const getOperationalEquipment = async () => {
  const response = await apiClient.get<GetManyReadyToWorkStatusReturnType>(
    `${apiEndpoints.equipmentStatus}/operational`
  );
  return response.data.data;
};

const getUnderServicingEquipment = async () => {
  const response = await apiClient.get<GetManyReadyToWorkStatusReturnType>(
    `${apiEndpoints.equipmentStatus}/under-servicing`
  );
  return response.data;
};

const getAllEquipment = async () => {
  const response = await apiClient.get<GetManyByAllEquipmentReturnType>(
    `${apiEndpoints.equipmentStatus}/operational?getAllDashboard=true`
  );
  return response.data.data;
};

const homeApi = {
  getEquipmentStatus,
  getOnRentEquipmentStatus,
  getAvailableEquipmentStatus,
  getOffRentEquipmentStatus,
  getInTransitEquipmentStatus,
  getOperationalEquipment,
  getUnderServicingEquipment,
  getAllEquipment
};

export default homeApi;
import { IMechanic } from '../modules/DatabaseManagement/PersonnelDBs/Mechanic/utils/Mechanic-utils';
import { apiClient, apiEndpoints } from './apiClient';
import { ICreateMechanic } from '../modules/DatabaseManagement/PersonnelDBs/Mechanic/utils/Mechanic-utils';

export type GetManyReturnType = {
  status: string;
  data: IMechanic[];
};
export type GetOneReturnType = {
  status: string;
  data: IMechanic;
};

const getMechanics = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.mechanic);
  return response.data.data;
};

const geMechanicsById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.mechanic}/${id}`
  );
  return response.data.data;
};

const importMechanics = async (newMechanicData: any) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.mechanic}/import-data`,
    newMechanicData
  );
  return response.data.data;
};

const updateMechanic = async (id: string, updateMechanicData: Partial<ICreateMechanic>) => {
  delete updateMechanicData.id;
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.mechanic}/${id}`,
    updateMechanicData
  );

  return response.data.data;
};

const createMechanic = async (newMechanicData: Partial<ICreateMechanic>) => {
  delete newMechanicData.id;
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.mechanic,
    newMechanicData
  );
  return response.data.data;
};

const MechanicService = {
  getMechanics,
  geMechanicsById,
  importMechanics,
  updateMechanic,
  createMechanic
};

export default MechanicService;

import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { IPMSCode} from './PMSCode-utils';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import PMSCodeDeleteModal from './PMSCodeDeleteModal';

interface Ownprops {
  PMSCode: IPMSCode;
}

const PMSCodeActionButton = ({ PMSCode }: Ownprops) => {
  const { id } = PMSCode;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const goToPMSCodeForm = () => navigate(`/pms-code/${id}`);
  const goToModalDelete = () => setOpen(true);
  
  return (
    <Stack direction='row' gap={1}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goToPMSCodeForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top'>
        <IconButton color='warning' aria-label='delete' onClick={goToModalDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
      <PMSCodeDeleteModal setOpen={setOpen} open={open} PMSCode={PMSCode} />
    </Stack>
  );
};

export default PMSCodeActionButton;

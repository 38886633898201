import { ICreateHECertificateType } from '../modules/DatabaseManagement/EquipmentDBs/HECertificateType/utils/HECertificateType-utils';
import { apiClient, apiEndpoints } from './apiClient';
import { IHECertificateType } from '../modules/DatabaseManagement/EquipmentDBs/HECertificateType/utils/HECertificateType-utils';

export type GetManyReturnType = {
  status: string;
  data: IHECertificateType[];
};

export type GetOneReturnType = {
  status: string;
  data: IHECertificateType;
};

const getCertificateType = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.certifcateTypes);
  return response.data.data;
};

const getCertificateTypeById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.certifcateTypes}/${id}`
  );
  return response.data.data;
};


const importCertificateType = async (newCertificateTypeData: any) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.certifcateTypes}/import-data`,
    newCertificateTypeData
  );
  return response.data.data;
};

const updateCertificateType = async (id: string, updateCertificateTypeData: Partial<ICreateHECertificateType>) => {
  delete updateCertificateTypeData.id;
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.certifcateTypes}/${id}`,
    updateCertificateTypeData
  );

  return response.data.data;
};

const createCertificateType = async (newCertificateTypeData: Partial<ICreateHECertificateType>) => {
  delete newCertificateTypeData.id;
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.certifcateTypes,
    newCertificateTypeData
  );
  return response.data.data;
};
const deleteCertificateType = async (id: string) => {
  const response = await apiClient.delete(`${apiEndpoints.certifcateTypes}/${id}`);
  return response.data.data;
};
export default {
  getCertificateType,
  getCertificateTypeById,
  importCertificateType,
  updateCertificateType,
  createCertificateType,
  deleteCertificateType
};
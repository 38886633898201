import { BaseSyntheticEvent, Dispatch, SetStateAction } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { appColors } from "../../../../../theme";
import { useEJOManagementContext } from "../Context/EJOManagementContext";

const EJOSRConfirmDeleteDialog = () => {
  const {
    openConfirmDeleteSRDialog,
    setOpenConfirmDeleteSRDialog,
    currentSRSelected,
    serviceRequestsArray,
    setServiceRequestsArray,
    setCurrentSRSelected,
  } = useEJOManagementContext();

  const handleClose = (): void => {
    setCurrentSRSelected({
      id: "",
      serviceRequest: "",
      serviceRequestTypeId: "",
      remarks: "",
    });

    setOpenConfirmDeleteSRDialog(false);
  };

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={openConfirmDeleteSRDialog}>
      <DialogTitle>
        <Typography
          fontSize={22}
          fontWeight={500}
          color={appColors.primary}
          textAlign={"left"}
        >
          Delete
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: "2em" }}>
        <Box>
          <Typography variant="body1">
            Do you want to delete this request?
          </Typography>
        </Box>
        <Box sx={{ width: "100%", display: "flex" }}>
          <Button
            onClick={() => {
              handleClose();
            }}
            sx={{ width: 150, marginLeft: "auto", marginTop: "1.5em" }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              // * currentSRSelected.id value is index if deleting to delete accurately
              const newArray = serviceRequestsArray.filter(
                (sr, index) => String(index) != currentSRSelected.id
              );

              setServiceRequestsArray(newArray);
              handleClose();
            }}
            sx={{ width: 150, marginLeft: ".5em", marginTop: "1.5em" }}
          >
            Yes
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EJOSRConfirmDeleteDialog;

import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { IOperatorLicenses } from './HEOperatorCertificateType-utils';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import HEOpratorDeleteModal from './HEOperatorCertificateTypeDeleteModal';

interface Ownprops {
  IOperatorLicenses: IOperatorLicenses;
}

const HEOperatorCertificateTypeActionsButton = ({ IOperatorLicenses }: Ownprops) => {
  const { id } = IOperatorLicenses;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const goToHEOperatorForm = () => navigate(`/he-operator-certificate-type/${id}`);
  const goToModalDelete = () => setOpen(true);
  
  return (
    <Stack direction='row' gap={1}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goToHEOperatorForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top'>
        <IconButton color='warning' aria-label='delete' onClick={goToModalDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
      <HEOpratorDeleteModal setOpen={setOpen} open={open} IOperatorLicenses={IOperatorLicenses} />
    </Stack>
  );
};

export default HEOperatorCertificateTypeActionsButton;

import { createColumnHelper } from '@tanstack/react-table';
import { ILaborType } from './utils/LaborType-utils';
import LaborTypeActionButton from './utils/LaborTypeActionsButtons';
import Chip from '@mui/material/Chip';
import moment from 'moment';

const columnHelper = createColumnHelper<ILaborType>();

const LaborTypeColumns = [
    columnHelper.accessor('laborTypeId', {
        id: 'laborTypeId',
        header: 'Labor Type ID',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('laborType', {
        id: 'laborType',
        header: 'Labor Type',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('unitCost', {
        id: 'unitCost',
        header: 'Unit Cost',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('unitOfMeasurement', {
        id: 'unitOfMeasurement',
        header: 'Unit of Measurement',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('updatePeriod', {
        id: 'updatePeriod',
        header: 'Update Period',
        cell: (row) => row.getValue() ? moment(row.getValue()).format('MM/DD/yyyy') : row.getValue(),
        enableGlobalFilter: true, 
        enableColumnFilter: true,
    }),
    columnHelper.accessor('isActive', {
        id: 'isActive',
        header: 'Status',
        cell: (row) => (
            <Chip
                label={row.getValue() ? 'Active' : 'Inactive'}
                color={row.getValue() ? 'primary' : 'warning'}
                style={{ color: row.getValue() ? 'white' : 'white', height: '25px' }}
            />
        ),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.display({
        id: 'actions',
        header: 'Action',
        cell: ({ row }) => (
            <LaborTypeActionButton laborType={row.original} />
        ), 
        enableHiding: false,
    }),
    
    
];

export default LaborTypeColumns;

import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { ExportPMSCode } from './PMSCode-utils';

const DescriptionMap = {
    'Model': 'model',
    'SMR Odemeter Interval': 'smrOdometerInterval',
    'PMCode Description': 'pmCodeDescription',
    'Status': 'status'
}

const DescriptionMapImport = {
    'Model': 'model',
    'SMR Odemeter Interval': 'smrOdometerInterval',
    'PMCode Description': 'pmCodeDescription',
}


export const ExportPMSCodeToExcel = (data: ExportPMSCode[], filename: string) => {
    const headings = [Object.keys(DescriptionMap)];
    const columnHeaders = [...Object.values(DescriptionMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    
    const formattedData = data.map(({isActive, ...item}) => {
      return {
        ...item,
        status : isActive ? 'Active' : 'Inactive'
      };
    });

    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
    console.log(formattedData)
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
     XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };

  export const GetTemplatePartsDecription = (data: ExportPMSCode[], filename: string) => {
    const headings = [Object.keys(DescriptionMapImport)];
    const columnHeaders = [...Object.values(DescriptionMapImport)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };
import { INonExistencePartNumber, IParts } from '../../../modules/DatabaseManagement/ServicingDBs/Parts/utils/parts-utils';
import { apiClient, apiEndpoints } from '../../apiClient';

export type GetManyReturnType = {
  status: string;
  data: IParts[];
};

export type GetOneReturnType = {
  status: string;
  data: IParts;
};

export type NonExistencePartNumberType = {
  data: INonExistencePartNumber[];
};


const getParts= async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.parts);
  return response.data.data;
};

const getNonExistencePartNumber= async () => {
  const response = await apiClient.get<NonExistencePartNumberType>(   
    `${apiEndpoints.parts}/part-number/get-nonexistent-part-number`);
  return response.data.data;
};


const importParts = async (newPartsData: any) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.parts}/import-data`,
    newPartsData
  );
  return response.data.data;
};

const createPart = async (newPartData: Partial<IParts>) => {
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.parts,
    newPartData
  );
  return response.data.data;
};

const getPartById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.parts}/${id}`
  );
  return response.data.data;
};

const updatePart = async (id: string, updatePartData: Partial<IParts>) => {
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.parts}/${id}`,
    updatePartData
  );

  return response.data.data;
};

export default {
getParts,
importParts,
createPart,
getPartById,
updatePart,
getNonExistencePartNumber
};
import { Outlet } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import { flexRender } from "@tanstack/react-table";
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from "../../../../../../components/common/app-table/ui";
import useDefaultTable from "../../../../../../components/common/app-table/useDefaultTable";
import { useEJOESRApprovalContext } from "../../Context/EJOESRApprovalContext";
import React from "react";
import { EJOSR } from "./utils/types";
import { Button } from "@mui/material";
import { useCreateSRColumns } from "./hooks/useCreateSRColumns";

const EJOSRTable = () => {
  const { setOpenDialog, setSrActionType, serviceRequestsArray, isViewOnly } =
    useEJOESRApprovalContext();
  const ejoSRManagementColumns = useCreateSRColumns();

  const { table, dataCount, pageIndex, pageSize } = useDefaultTable<EJOSR>(
    serviceRequestsArray,
    ejoSRManagementColumns
  );

  const goToAddSR = () => {
    setSrActionType("new");
    setOpenDialog(true);
  };

  return (
    <>
      <Stack direction="column" mb={2} mt={5} gap={3}>
        <Stack direction="row" justifyContent="space-between">
          {!isViewOnly && (
            <Button
              disableElevation
              sx={{
                textTransform: "capitalize",
                fontSize: "9pt",
                bgcolor: "primary",
                color: "white",
                padding: 0.5,
                paddingX: 2.5,
                minWidth: "auto",
                borderRadius: "3px",
                "&:hover": {
                  bgcolor: "primary",
                },
              }}
              onClick={() => goToAddSR()}
            >
              Add Service Request
            </Button>
          )}
        </Stack>
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align="center">
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody dataCount={dataCount}>
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align="center">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position="relative">
          <TablePagination
            sx={{ bgcolor: "white", borderTop: "1px solid #eee" }}
            component="div"
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) =>
              table.setPageSize(Number(e.target.value))
            }
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
      <Outlet />
    </>
  );
};

export default React.memo(EJOSRTable);

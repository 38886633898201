import * as yup from 'yup';
import { ICreateTaskType } from './taskTypes-utils';

export const TypePartsInitialValues: ICreateTaskType = {
  id: '',
  taskType: '',
  isActive: 'true'
};
export const TaskTypeSchema = yup.object({
    taskType: yup.string()
    .trim('The Task Type cannot include leading and trailing spaces')
    .strict(true)
    .min(1,'The Task Type needs to be at least 1 Alphabet or Numerical')
    .required('The Task Type is required'),
    isActive: yup.boolean().required('Status is required'),
  });
import { useMutation, useQuery } from '@tanstack/react-query';
import { IPartsDescription } from '../modules/DatabaseManagement/ServicingDBs/PartsDescription/utils/PartsDescription-utils';
import PartsDescriptionApi from '../services/partsDescription';
import { useSnackBar } from '../components/ToastProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { IImportPartsDescription, ICreatePartsDescription, ExportPartsDescription  } from '../modules/DatabaseManagement/ServicingDBs/PartsDescription/utils/PartsDescription-utils';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';

interface ApiError {
  status: number;
  message: string;
}

interface useGetPartsDescriptionOptions {
  type?: string;
}

export function useGetPartsDescriptionsQuery(options?: useGetPartsDescriptionOptions) {
  return useQuery<IPartsDescription[]>(['PartsDescriptions'], () => PartsDescriptionApi.getPartDescription(), {
    onError(err) {
      console.log(err);
    },
    select(PartsDescriptions) {
      return options?.type ? PartsDescriptions.filter(l => l.type === options?.type) : PartsDescriptions;
    },
  });
}


export function useGetsPartsDescriptionById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`PartsDescriptions-${id}`, id], () => PartsDescriptionApi.getPartDescriptionById(id!));
}

export const useExportPartsDescriptions = (data: ExportPartsDescription[]) => {
  const newPartsDescriptionsList = [];
  
  const PartsDescriptionsList = data;

  for(let i in PartsDescriptionsList){
    let json = {
      description: PartsDescriptionsList[i].description,
      isActive: PartsDescriptionsList[i].isActive,
    }
    newPartsDescriptionsList.push(json);
  }
  return newPartsDescriptionsList;
}

export function useImportPartsDescriptions() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((newPartsDescriptionsData: IImportPartsDescription[]) => PartsDescriptionApi.importPartDescription(newPartsDescriptionsData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['PartsDescriptions'] }).then(() => {
        
        if( data[1].invalidData.length >= 1){
             showSnackBar(data[1].invalidData[0].errorReason, 'error');
             setTimeout(() => {
              window.location.reload();
            }, 500);
        }
        else{
          showSnackBar('Successfully Inserted Parts Description', 'success');
          navigate('/parts-description', { replace: true });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
     
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: ApiError }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData.message.split(':').slice(1).join(' '), 'error');
      }
    },
  });
}


export function useUpdatePartsDescriptions() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((newPartsDescriptionsData: Partial<ICreatePartsDescription>) => PartsDescriptionApi.updatepartsDescriptionType(id!, newPartsDescriptionsData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['PartsDescriptions'] }).then(() => {
        showSnackBar('Successfully Updated Parts Description', 'success');
        setTimeout(() => {
          navigate('/parts-description', { replace: true });
        }, 500);
   
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useDeletePartsDescriptions(data: any, id: string) {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((data: any) => PartsDescriptionApi.updatepartsDescriptionType(id!, data), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['PartsDescriptions'] }).then(() => {        
        showSnackBar('Successfully Deleted Parts Description', 'success');
        setTimeout(() => {
          navigate('/parts-description', { replace: true });
        }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}


export function useCreatePartsDescriptions() {
  
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  
  return useMutation((newPartsDescriptionsData: Partial<ICreatePartsDescription>) => PartsDescriptionApi.createpartsDescriptionType(newPartsDescriptionsData), {
   
    onSuccess(data: any) {
      queryClient.invalidateQueries({ queryKey: ['PartsDescriptions'] }).then(() => {
          showSnackBar('Successfully Inserted Parts Description', 'success');
          setTimeout(() => {
            navigate('/parts-description', { replace: true });
          }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    },
  });
}
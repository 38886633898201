import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { IAllEquipmentData, IAvailableExportData, IOnRentExportData, IOffRentExportData } from '../db-utils';
import { dateDifference } from '../../../utils/helpers';
import { OffRentItem } from '../OffRent/columns';
import { InTransitItem } from '../InTransit/columns';

const AllEquipmentMap = {
    'Equipment Number' : 'equipmentNo',
    'Product Family' : 'productFamily',
    'Capacity' :  'capacity',
    'Model' : 'model',
    'Deployment Status' : 'deploymentStatus',
    'Location' : 'location',
    'Ready to work status' : 'readyToworkStatus'
}

const AvailableEquipmentMap = {
    'Equipment Number' : 'equipmentNo',
    'Product Family' : 'productFamily',
    'Make' : 'make',
    'Ready to work status' : 'readyToworkStatus',
    'Alerts' : 'alert',
    'Number of Days since Alert Started' : 'alertDate',
}

const OnRentEquipmentMap = {
    'Equipment Number' : 'equipmentNo',
    'Project' : 'project',
    'Product Family' : 'productFamily',
    'Make' : 'make',
    'Start Date' : 'startDate',
    'End Date' : 'endDate',
    'EM End Date' : 'emEndDate',
    'Ready to work status' : 'readyToworkStatus',
    'Alert' : 'alert',
    'Number of Days since Alert Started' : 'alertDate',
}

const OffRentEquipmentMap = {
    'Equipment Number' : 'equipmentNo',
    'Product Family' : 'productFamily',
    'Make' : 'make',
    'Project' : 'project',
    'Number of days since EOrr created' : 'eorrDate',
    'Ready to work status' : 'readyToworkStatus',
    'Alerts' : 'alert',
    'Number of Days since Alert Started' : 'alertDate',
}

const InTransitEquipmentMap = {
    'Equipment Number' : 'equipmentNo',
    'Product Family' : 'productFamily',
    'Make' : 'make',
    'Origin' : 'allocationPickUpLocation',
    'Destination':  'project',
    'Number of Days in transit' : 'allocationDeliveryDate',
}


export const exportAllEquipmentDataToExcel = (data: IAllEquipmentData[], filename: string) => {
    const headings = [Object.keys(AllEquipmentMap)];
    const columnHeaders = [...Object.values(AllEquipmentMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportAvailableDataToExcel = (data: IAvailableExportData[], filename: string) => {
    const headings = [Object.keys(AvailableEquipmentMap)];
    const columnHeaders = [...Object.values(AvailableEquipmentMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    const formattedData = data.map(({...item}) => {
        return {
            equipmentNo : item.equipmentNo,
            productFamily: item.productFamily,
            make: item.make,
            readyToworkStatus: item.readyToworkStatus ? item.readyToworkStatus : '-',
            alert: item.alert ?  item.alert : '-',
            alertDate: item.alert ?  String(dateDifference(new Date(), item.alertDate)) : '0',
        };
    });

    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportOnRentDataToExcel = (data: IOnRentExportData[], filename: string) => {
    const headings = [Object.keys(OnRentEquipmentMap)];
    const columnHeaders = [...Object.values(OnRentEquipmentMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);

   const emEndDateConverter = (type: Date ) => {
    const dateNow = new Date(Date.now());
    const totalTime = type.getTime() - dateNow.getTime();
    return Math.round(totalTime / (1000 * 3600 * 24));
   }

    const formattedData = data.map(({...item}) => {
        let tmpEmEndDate = item.emEndDate ? item.emEndDate : item.endDate

        return {
            equipmentNo : item.equipmentNo,
            project: item.project,
            productFamily: item.productFamily,
            make: item.make,
            startDate: item.startDate,
            endDate: item.endDate,
            emEndDate: tmpEmEndDate ? emEndDateConverter(new Date(tmpEmEndDate)) : '0',
            readyToworkStatus: item.readyToworkStatus ? item.readyToworkStatus : '-',
            alert: item.alert ?  item.alert : '-',
            alertDate: item.alert ?  String(dateDifference(new Date(), item.alertDate)) : '0',
        };
    });

    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportOffRentDataToExcel = (data: OffRentItem[], filename: string) => {
    const headings = [Object.keys(OffRentEquipmentMap)];
    const columnHeaders = [...Object.values(OffRentEquipmentMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    const formattedData = data.map(({...item}) => {
       
        return {
            equipmentNo : item.equipmentNo,
            project: item.project,
            productFamily: item.productFamily,
            make: item.make,
            eorrDate: item.eorrDate ?  String(dateDifference(new Date(), item.eorrDate)) : '0',
            readyToworkStatus: item.readyToworkStatus ? item.readyToworkStatus : '-',
            alert: item.alert ?  item.alert : '-',
            alertDate: item.alert ?  String(dateDifference(new Date(), item.alertDate)) : '0',
        };
    });

    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportInTransitDataToExcel = (data: InTransitItem[], filename: string) => {
    const headings = [Object.keys(InTransitEquipmentMap)];
    const columnHeaders = [...Object.values(InTransitEquipmentMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    const formattedData = data.map(({...item}) => {
       
        return {
            equipmentNo : item.equipmentNo,
            project: item.project,
            productFamily: item.productFamily,
            make: item.make,
            allocationPickUpLocation: item.allocationPickUpLocation,
            allocationDeliveryDate: item.allocationDeliveryDate ?  String(dateDifference(new Date(), item.allocationDeliveryDate)) : '0',
        };
    });

    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};
import { useQuery } from "@tanstack/react-query";
import assigneeAPI from "../services/assignees";
import { EJOESRAssignee } from "../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/shared/EJO/utils/types";

interface useGetAssigneeOptions {
  name?: string;
}

export function useGetAssigneesQuery(options?: useGetAssigneeOptions) {
  return useQuery<EJOESRAssignee[]>(
    ["assignee"],
    () => assigneeAPI.getAssignees(),
    {
      onError(err) {
        console.log(err);
      },
      select(Assignee) {
        return options?.name
          ? Assignee.filter((l) => l.name === options?.name)
          : Assignee;
      },
    }
  );
}

import { useEffect, useState } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import AppSelect from '../../../../components/common/forms/AppSelect';
import PageContainer from '../../../../components/common/PageContainer';
import { ICreateHECertificateType } from './utils/HECertificateType-utils';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { HECertificateTypeSchema, HECertificateTypeInitialValues } from './utils/schema';
import { useGetCertificateTypeById, useUpdateCertificateType, useCreateCertificateType } from '../../../../hooks/useCertificateType';
import { appColors, appStyles } from '../../../../theme';
import { trimmedString } from '../../../../utils/trimmedString';
import { useGetProductFamilyQuery } from '../../../../hooks/useProductFamily';

const HECertificateTypeForm = () => {
  const navigate = useNavigate();
  const productFamily = useGetProductFamilyQuery();
  const currentCertificateType = useGetCertificateTypeById();
  const updateCertificateType = useUpdateCertificateType();
  const createCertificateType = useCreateCertificateType();

  const statusOptions: SelectOptionType[] = [{'id': 'true', 'label': 'Active' , 'value': 'true'},{'id': 'false', 'label': 'Inactive' , 'value': 'false'} ]
  const typeOptions : SelectOptionType[] = 
  productFamily?.data === undefined ? [] : productFamily.data.map(e => {
                                              return { 
                                                id: e.id, 
                                                label: e.categoryName, 
                                                value: e.id 
                                              }
                                             });


const { register, 
        handleSubmit,
        setValue,
        control,
        formState: { errors, isValid }
      } = useForm<ICreateHECertificateType>({
        defaultValues: currentCertificateType.data! ? currentCertificateType.data : HECertificateTypeInitialValues,
        mode: 'onChange',
        resolver: yupResolver(HECertificateTypeSchema),
      });

  useEffect(() => {

    if(currentCertificateType.data && typeOptions){
      setValue("certificateType", currentCertificateType.data?.certificateType);
      setValue("isActive", currentCertificateType.data?.isActive ? 'true' : 'false');
      setValue("productFamilyId", currentCertificateType.data?.productFamilyId);
        }
      }, [currentCertificateType.data, setValue]);

  const onError = (err: typeof errors) => {
    console.error(err);
  };

  const onSubmit = (data : ICreateHECertificateType) => {

    data.certificateType = trimmedString(data.certificateType);
    if (currentCertificateType.data) {
      updateCertificateType.mutate(data);
    }
    else {
      createCertificateType.mutate(data)
    }
  };


  const backTo = () => navigate('/he-certificate-type');
  
  return (
    <>
      <PageContainer
        title='Manage My Databases'
        subTitle='HE Certificate Type Master'
        controls={
          <Stack direction='row' gap={2}>
            <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disabled={!isValid }
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
      <Divider color='black' />
      <Stack
        component='form'
        height='100%'
        bgcolor='white'
        py={3}
        boxShadow={appStyles.shadow}
        borderRadius={1}
      >
        <Stack px={3} height='100%' overflow='auto' gap={1}>
        <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
          <Stack direction="column" gap={1.5} width="45%">   
            <AppTextField
              label="Certificate Type"
              labelAlignment="left"
              labelWidth='33%'
              fieldWidth= '67%'
              fontWeight={400}
              placeholder="Name"
              error={!!errors.certificateType}
              helperText={errors.certificateType?.message}
              {...register('certificateType')}
            />
             <Controller
                    control={control}
                    name='productFamilyId'
                    render={({ field }) => (
                      <AppSelect
                        label="Product Family"
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.productFamilyId}
                        helperText={errors.productFamilyId?.message}
                        options={typeOptions}
                        {...register('productFamilyId')}
                        {...field}
                      />
                  )}
                />
              <Controller
                  control={control}
                  name='isActive'
                  render={({ field }) => (
                    <AppSelect
                      label="Status"
                      disabled= {currentCertificateType.data ? false : true}
                      labelAlignment="left"
                      labelWidth='33%'
                      fieldWidth= '67%'
                      fontWeight={400}
                      placeholder="Select here"
                      error={!!errors.isActive}
                      helperText={errors.isActive?.message}
                      options={statusOptions}
                      {...register('isActive')}
                      {...field}
                     
                    />
                )}
              />
             
          </Stack>
        </Stack>
        </Stack>
      </Stack>
    </PageContainer>
    <Outlet />
    </>
  )
}

export default HECertificateTypeForm;
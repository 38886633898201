import * as yup from "yup";
import { EJOManagementFields } from "./types";
import { EJOSRSchema } from "../EJO-SR/utils/schema";
import moment from "moment";

/**
 * ? For validation only, will be used if the user
 * ? will update and data is already available through getting the data to be updated
 */
export const EJOManagementInitialValues: EJOManagementFields = {
  dateCreated: Date(),
  dateNeeded: Date(),
  projectLocationId: "",
  equipmentId: "",
  smrMileage: 0,
  isOperable: false,
  serviceRequestList: [],
};

export const EJOManagementSchema = yup.object({
  dateCreated: yup.mixed().required("Date Created is required"),
  dateNeeded: yup
    .mixed()
    .required("Please set a date when it's needed.")
    .test(
      "after-today",
      "Invalid date. Please choose a date that is after than the current day.",
      (value) => {
        const selectedDate = moment(value);
        return selectedDate.isAfter(moment().startOf("day"));
      }
    ),
  projectLocationId: yup.string().required("Project Location ID is required"),
  equipmentId: yup.string().required("Equipment ID is required"),
  smrMileage: yup
    .number()
    .required("SMR Mileage is required")
    .typeError("SMR Mileage must be a number")
    .positive("SMR Mileage must be a positive number")
    .test(
      "maxDigits",
      "SMR Mileage must not exceed 9 digits",
      (value) => value == null || (value >= 0 && value <= 999999999) // Checks if the number is between 0 and 999999999
    ),
  isOperable: yup.boolean().required("Operability status is required"),
  serviceRequestsList: yup
    .array()
    .of(EJOSRSchema)
    .min(1, "At least one service request is required")
    .nullable(),
});

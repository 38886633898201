import { createColumnHelper } from "@tanstack/react-table";
import { IEJOColumnsApproval } from "../../utils/types";
import {
  ViewActionButton,
  EditActionButton,
} from "../../../../../components/common/primebmd-ui/EditActionButton";
import { useAuth } from "../../../../../components/AuthProvider";

const columnHelper = createColumnHelper<IEJOColumnsApproval>();

export const useCreateEJOColumns = () => {
  const currUser = useAuth();
  const hasESRManagementPermission =
    currUser &&
    currUser?.currentUserRole?.permissions.includes("ESR::MANAGEMENT");

  const ejoPendingApprovalColumns = [
    columnHelper.accessor("ejoNumber", {
      id: "ejoNumber",
      header: "EJO No.",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("equipmentNo", {
      id: "equipmentNo",
      header: "Equipment No.",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor("projectLocation", {
      id: "projectLocation",
      header: "Project / Location",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.accessor("daysElapsed", {
      id: "daysElapsed",
      header: "Days Elapsed",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.display({
      id: "actions",
      header: "Action",
      cell: ({ row }) => (
        <>
          {hasESRManagementPermission ? (
            <EditActionButton route={`edit/${row.original.id}`} />
          ) : (
            <ViewActionButton route={`view/${row.original.id}`} />
          )}
        </>
      ),
      enableHiding: false,
    }),
  ];
  return ejoPendingApprovalColumns;
};
export default useCreateEJOColumns;

import { useMutation, useQuery } from "@tanstack/react-query";
import { IPMSCode } from "../modules/DatabaseManagement/ServicingDBs/PMSCode/utils/PMSCode-utils";
import PMSCodeApi from "../services/pMSCode";
import { useSnackBar } from "../components/ToastProvider";
import { useNavigate, useParams } from "react-router-dom";
import {
  IImportPMSCode,
  ICreatePMSCode,
  ExportPMSCode,
} from "../modules/DatabaseManagement/ServicingDBs/PMSCode/utils/PMSCode-utils";
import { queryClient } from "../react-query/queryClient";
import { AxiosError } from "axios";

interface ApiError {
  status: number;
  message: string;
}

interface useGetPMSCodeOptions {
  type?: string;
}

export function useGetPMSCodesQuery(options?: useGetPMSCodeOptions) {
  return useQuery<IPMSCode[]>(
    ["PMSCodes"],
    () => PMSCodeApi.getPartDescription(),
    {
      onError(err) {
        console.log(err);
      },
      select(PMSCodes) {
        return options?.type
          ? PMSCodes.filter((l) => l.model === options?.type)
          : PMSCodes;
      },
    }
  );
}

export function useGetPMSCodesByModel(model: string) {
  return useQuery([`PMSCodes-${model}`, model], () =>
    PMSCodeApi.getPartDescriptionByModel(model)
  );
}

export function useGetsPMSCodeById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`PMSCodes-${id}`, id], () =>
    PMSCodeApi.getPartDescriptionById(id!)
  );
}

export const useExportPMSCodes = (data: ExportPMSCode[]) => {
  const newPMSCodesList = [];

  const PMSCodesList = data;

  for (let i in PMSCodesList) {
    let json = {
      model: PMSCodesList[i].model,
      smrOdometerInterval: PMSCodesList[i].smrOdometerInterval,
      pmCodeDescription: PMSCodesList[i].pmCodeDescription,
      isActive: PMSCodesList[i].isActive,
    };
    newPMSCodesList.push(json);
  }
  return newPMSCodesList;
};

export function useImportPMSCodes() {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation(
    (newPMSCodesData: IImportPMSCode[]) =>
      PMSCodeApi.importPartDescription(newPMSCodesData),
    {
      onSuccess(data: any) {
        queryClient.invalidateQueries({ queryKey: ["PMSCodes"] }).then(() => {
          if (data[1].invalidData.length >= 1) {
            showSnackBar(data[1].invalidData[0].errorReason, "error");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            showSnackBar("Successfully Inserted PMS Code", "success");
            navigate("/pms-code", { replace: true });
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        });
      },
      onError(err) {
        const errorData = (err as AxiosError<{ error: ApiError }>).response
          ?.data.error;

        if (err && errorData) {
          showSnackBar(
            errorData.message.split(":").slice(1).join(" "),
            "error"
          );
        }
      },
    }
  );
}

export function useUpdatePMSCodes() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation(
    (newPMSCodesData: Partial<ICreatePMSCode>) =>
      PMSCodeApi.updatePMSCodeType(id!, newPMSCodesData),
    {
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ["PMSCodes"] }).then(() => {
          showSnackBar("Successfully Updated PMS Code", "success");
          setTimeout(() => {
            navigate("/pms-code", { replace: true });
          }, 500);
        });
      },
      onError(err) {
        const errorData = (err as AxiosError<{ error: string }>).response?.data
          .error;

        if (err && errorData) {
          showSnackBar(errorData, "error");
        }
      },
    }
  );
}

export function useDeletePMSCodes(id: string) {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation((data: any) => PMSCodeApi.deletePMSCode(id), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["PMSCodes"] }).then(() => {
        showSnackBar("Successfully Deleted PMS Code", "success");
        setTimeout(() => {
          navigate("/pms-code", { replace: true });
        }, 500);
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data
        .error;

      if (err && errorData) {
        showSnackBar(errorData, "error");
      }
    },
  });
}

export function useCreatePMSCodes() {
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  return useMutation(
    (newPMSCodesData: Partial<ICreatePMSCode>) =>
      PMSCodeApi.createPMSCodeType(newPMSCodesData),
    {
      onSuccess(data: any) {
        queryClient.invalidateQueries({ queryKey: ["PMSCodes"] }).then(() => {
          showSnackBar("Successfully Inserted PMS Code", "success");
          setTimeout(() => {
            navigate("/pms-code", { replace: true });
          }, 500);
        });
      },
      onError(err) {
        const errorData = (err as AxiosError<{ error: string }>).response?.data
          .error;

        if (err && errorData) {
          showSnackBar(errorData, "error");
        }
      },
    }
  );
}

import { useEffect, useState } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import AppSelect from '../../../../components/common/forms/AppSelect';
import PageContainer from '../../../../components/common/PageContainer';
import { ICreateOperetorLicenses } from './utils/HEOperatorCertificateType-utils';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { OperetorLicensesSchema, InitialOperetorLicensesInitialValues } from './utils/schema';
import { useGetOperatorLicenseTypesById, useUpdateOperatorLicenseTypes, useCreateOperatorLicenseTypes } from '../../../../hooks/useOperatorLicensesTypes';
import { appColors, appStyles } from '../../../../theme';
import { trimmedString } from '../../../../utils/trimmedString';

const HEOpartorLicenseTypeForm = () => {
  const navigate = useNavigate();
  const currentHEOperator = useGetOperatorLicenseTypesById();
  const updateHEOperator = useUpdateOperatorLicenseTypes();
  const createHEOperator = useCreateOperatorLicenseTypes();

  const statusOptions: SelectOptionType[] = [{'id': 'true', 'label': 'Active' , 'value': 'true'},{'id': 'false', 'label': 'Inactive' , 'value': 'false'} ]


const { register, 
        handleSubmit,
        setValue,
        control,
        formState: { errors, isValid }
      } = useForm<ICreateOperetorLicenses>({
        defaultValues: currentHEOperator.data! ? currentHEOperator.data : InitialOperetorLicensesInitialValues,
        mode: 'onChange',
        resolver: yupResolver(OperetorLicensesSchema),
      });

  useEffect(() => {

    if(currentHEOperator.data){
      setValue("name", currentHEOperator.data?.name);
      setValue("isActive", currentHEOperator.data?.isActive ? 'true' : 'false');
      setValue("operatorType", currentHEOperator.data?.operatorType);
      setValue("validity", currentHEOperator.data?.validity);
    }
})
  const onError = (err: typeof errors) => {
    console.error(err);
  };
  

  const onSubmit = (data : ICreateOperetorLicenses) => {

    data.name = trimmedString(data.name);
    data.operatorType = trimmedString(data.operatorType);
    if (currentHEOperator.data) {
      updateHEOperator.mutate(data);
    }
    else {
      createHEOperator.mutate(data)
    }
  };


  const backTo = () => navigate('/he-operator-certificate-type');
  
  return (
    <>
      <PageContainer
        title='Manage My Databases'
        subTitle='HE Operator Certificate Type Master'
        controls={
          <Stack direction='row' gap={2}>
            <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disabled={!isValid }
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
      <Divider color='black' />
      <Stack
        component='form'
        height='100%'
        bgcolor='white'
        py={3}
        boxShadow={appStyles.shadow}
        borderRadius={1}
      >
        <Stack px={3} height='100%' overflow='auto' gap={1}>
        <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
          <Stack direction="column" gap={1.5} width="45%">   
            <AppTextField
              label="Name"
              labelAlignment="left"
              labelWidth='33%'
              fieldWidth= '67%'
              fontWeight={400}
              placeholder="Name"
              error={!!errors.name}
              helperText={errors.name?.message}
              {...register('name')}
            />
              <AppTextField
              label="Operator Type"
              labelAlignment="left"
              labelWidth='33%'
              fieldWidth= '67%'
              fontWeight={400}
              placeholder="Operator Type"
              error={!!errors.operatorType}
              helperText={errors.operatorType?.message}
              {...register('operatorType')}
            />
          <AppTextField
              label="Validity"
              labelAlignment="left"
              labelWidth='33%'
              fieldWidth= '67%'
              fontWeight={400}
              placeholder="Validity"
              error={!!errors.validity}
              helperText={errors.validity?.message}
              {...register('validity')}
            />
  
              <Controller
                  control={control}
                  name='isActive'
                  render={({ field }) => (
                    <AppSelect
                      label="Status"
                      disabled= {currentHEOperator.data ? false : true}
                      labelAlignment="left"
                      labelWidth='33%'
                      fieldWidth= '67%'
                      fontWeight={400}
                      placeholder="Select here"
                      error={!!errors.isActive}
                      helperText={errors.isActive?.message}
                      options={statusOptions}
                      {...register('isActive')}
                      {...field}
                     
                    />
                )}
              />
             
          </Stack>
        </Stack>
        </Stack>
      </Stack>
    </PageContainer>
    <Outlet />
    </>
  )
}

export default HEOpartorLicenseTypeForm;
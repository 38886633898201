import Checkbox from '@mui/material/Checkbox';
import { dateDifference } from '../../../utils/helpers';
import { createColumnHelper } from '@tanstack/react-table';
import HeaderCellCheckbox from '../../../components/common/primebmd-ui/HeaderCellCheckbox';

export interface InTransitItem {
  id: string;
  equipmentNo: string;
  productFamily: string;
  make: string;
  model: string;
  capacity: string;
  allocationPickUpLocation: string;
  project: string;
  allocationDeliveryDate: Date | string;
}

const columnHelper = createColumnHelper<InTransitItem>();

export const inTransitItemColumns = [
  columnHelper.display({
    id: 'select',
    header: ({ table }) => (
      <HeaderCellCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('productFamily', {
    id: 'productFamily',
    header: 'Product Family',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('make', {
    id: 'make',
    header: 'Make',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),

  columnHelper.accessor('capacity', {
    id: 'capacity',
    header: 'Capacity',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  
  columnHelper.accessor('model', {
    id: 'model',
    header: 'Model',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),

  columnHelper.accessor('allocationPickUpLocation', {
    id: 'allocationPickUpLocation',
    header: 'Origin',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('project', {
    id: 'project',
    header: 'Destination',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.display({
    id: 'daysInTransit',
    header: '# of Days in Transit',
    cell: ({row}) => dateDifference(new Date(), row.original.allocationDeliveryDate),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
];

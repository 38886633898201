import { Stack, IconButton, Tooltip } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import { useNavigate } from 'react-router-dom';
import { ITaskTypes } from './taskTypes-utils';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import TaskTypeDeleteModal from './TaskTypesDeleteModal';

interface Ownprops {
  taskTypes: ITaskTypes;
}

const TaskTypesActionButton = ({ taskTypes }: Ownprops) => {
  const { id } = taskTypes;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const goToTaskTypesForm = () => navigate(`/service-task/${id}`);
  const goToModalDelete = () => setOpen(true);
  
  return (
    <Stack direction='row' gap={1}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goToTaskTypesForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top'>
        <IconButton color='warning' aria-label='delete' onClick={goToModalDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
      <TaskTypeDeleteModal setOpen={setOpen} open={open} taskTypes={taskTypes} />
    </Stack>
  );
};

export default TaskTypesActionButton;

import { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppSelect from '../../../../components/common/forms/AppSelect';
import PageContainer from '../../../../components/common/PageContainer';
import { ICreatePMSCode } from './utils/PMSCode-utils';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { PMSCodeSchema, PMSCodeInitialValues } from './utils/schema';
import { useGetsPMSCodeById, useUpdatePMSCodes, useCreatePMSCodes } from '../../../../hooks/usePMSCode';
import { appColors, appStyles } from '../../../../theme';
import { useGetModelLists } from '../../../../hooks/useEquipments';

const PMSCodeForm = () => {
  const navigate = useNavigate();
  const currentPMSCode = useGetsPMSCodeById();
  const updatePMSCode = useUpdatePMSCodes();
  const createPMSCode = useCreatePMSCodes();
  const statusOptions: SelectOptionType[] = [{'id': 'true', 'label': 'Active' , 'value': 'true'},{'id': 'false', 'label': 'Inactive' , 'value': 'false'} ]
  const modelLists = useGetModelLists();

  const modelListsptions : SelectOptionType[] = 
  modelLists?.data === undefined ? [] : modelLists.data.map(e => {
                                              return { 
                                                id: e.model, 
                                                label: e.model, 
                                                value: e.model 
                                              }
                                             });

  const { register, 
          handleSubmit,
          setValue,
          control,
          formState: { errors, isValid }
        } = useForm<ICreatePMSCode>({
          defaultValues: currentPMSCode.data! ? currentPMSCode.data : PMSCodeInitialValues,
          mode: 'onChange',
          resolver: yupResolver(PMSCodeSchema),
        });

  useEffect(() => {
    setValue("isActive", 'true');
    if (!currentPMSCode.data) return;
    setValue("model", currentPMSCode.data?.model);
    setValue("smrOdometerInterval", currentPMSCode.data?.smrOdometerInterval);
    setValue("pmCodeDescription", currentPMSCode.data?.pmCodeDescription);
    setValue("isActive", currentPMSCode.data?.isActive ? 'true' : 'false');
  }, [currentPMSCode.data, setValue]);

  const onError = (err: typeof errors) => {
    console.error(err);
  };

  const onSubmit = (data : ICreatePMSCode) => {
    if (currentPMSCode.data) {
      updatePMSCode.mutate(data);
    }
    else {
      createPMSCode.mutate(data)
    }
  };

  const backTo = () => navigate('/pms-code');
  
  return (
    <>
      <PageContainer
        title='Manage My Databases'
        subTitle='PMS Code Master'
        controls={
          <Stack direction='row' gap={2}>
            <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disabled={!isValid }
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
        <Divider color='black' />
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
          <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
            <Stack direction="column" gap={1.5} width="45%">   
            <Controller
                    control={control}
                    name='model'
                    render={({ field }) => (
                      <AppSelect
                        label="Model"
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.model}
                        helperText={errors.model?.message}
                        options={modelListsptions}
                        {...register('model')}
                        {...field}
                      />
                  )}
                />
               <AppTextField
                label="SMS Odometer Interval"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="SMS Odometer Interval"
                error={!!errors.smrOdometerInterval}
                helperText={errors.smrOdometerInterval?.message}
                {...register('smrOdometerInterval')}
              />
                <AppTextField
                label="PMCode Description"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="PMCode Description"
                error={!!errors.pmCodeDescription}
                helperText={errors.pmCodeDescription?.message}
                {...register('pmCodeDescription')}
              />
                <Controller
                    control={control}
                    name='isActive'
                    render={({ field }) => (
                      <AppSelect
                        label="Status"
                        disabled= {currentPMSCode.data ? false : true}
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.isActive}
                        helperText={errors.isActive?.message}
                        options={statusOptions}
                        {...register('isActive')}
                        {...field}
                      />
                  )}
                />

               
            </Stack>
          </Stack>
          </Stack>
        </Stack>
      </PageContainer>
      <Outlet />
    </>
  )
}

export default PMSCodeForm;
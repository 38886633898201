import * as yup from 'yup';
import moment from 'moment';
import { EURActivityFields, EURSMRFields } from './types';

export const EURActivitylInitialValues: EURActivityFields = {
  id: '',
  activity: '',
  startTime: new Date(Date.now()),
  endTime: new Date(Date.now()),
};

export const EURSMRlInitialValues: EURSMRFields = {
  equipmentId: '',
  operatorId: '',
  locationId: '',
  date: new Date(Date.now()),
  downHours: null,
  startingReading: null,
  endingReading: null,
  activities: null
};

export const EURActivitySchema = yup.object({
  activity: yup.string().required('Activity is required'),
  startTime: yup
  .date()
  .required('Start Time is required')
  .test("is-lesser", "Start time should be less than End Time", function(value) {
    const { endTime } = this.parent;
    return moment(value).isBefore(moment(endTime));
  })
  .typeError("Invalid Time Format"),
  endTime: yup
  .date()
  .required('End Time is required')
  .test("is-greater", "End time should be greater than Start Time", function(value) {
    const { startTime } = this.parent;
    return moment(value).isAfter(moment(startTime));
  })
  .typeError("Invalid Time Format"),
});

export const EURSMRSchema = yup.object({
  equipmentId: yup.string().required('Equipment No. is required'),
  operatorId: yup.string().required('Operator is required'),
  locationId: yup.string().required('Location is required'),
  date: yup.date().required('Date is required').typeError('Invalid Date Format'),
  downHours: yup
  .number()
  .required('Down Hours is required')
  .typeError("Must be a number"),
  startingReading: yup
  .number()
  .required('Starting Reading is required')
  .typeError("Must be a number"),
  endingReading: yup
  .number()
  .required('Ending Reading is required')
  .typeError("Must be a number")
});
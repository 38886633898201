import { useEffect, useState } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import AppSelect from '../../../../components/common/forms/AppSelect';
import PageContainer from '../../../../components/common/PageContainer';
import { ICreateHEOperator } from './utils/HEOperator-utils';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { HEOperatorSchema, HEOperatorInitialValues} from './utils/schema';
import { useGetOperatorById, useUpdateOperator, useCreateOperator } from '../../../../hooks/useOperators';
import { appColors, appStyles } from '../../../../theme';
import { useGetLaborTypeQuery } from '../../../../hooks/useLaborType';
import { trimmedString } from '../../../../utils/trimmedString';
import { useGetProductFamilyQuery } from '../../../../hooks/useProductFamily';
import { useGetOperatorsLicenseTypesQuery } from '../../../../hooks/useOperatorLicensesTypes';

const HEOpartorForm = () => {
  const navigate = useNavigate();
  const productFamily = useGetProductFamilyQuery();
  const operatorLicenseTypes = useGetOperatorsLicenseTypesQuery();
  const currentHEOperator = useGetOperatorById();
  const updateHEOperator = useUpdateOperator();
  const createHEOperator = useCreateOperator();

  const statusOptions: SelectOptionType[] = [{'id': 'true', 'label': 'Active' , 'value': 'true'},{'id': 'false', 'label': 'Inactive' , 'value': 'false'} ]
  const typeOptions : SelectOptionType[] = 
  productFamily?.data === undefined ? [] : productFamily.data.map(e => {
                                              return { 
                                                id: e.id, 
                                                label: e.categoryName, 
                                                value: e.id 
                                              }
                                             });
  const operatorLicensetypesptions : SelectOptionType[] = 
  operatorLicenseTypes?.data === undefined ? [] : operatorLicenseTypes.data.map(e => {
                                              return { 
                                                id: e.id, 
                                                label: e.name + " - " + e.operatorType, 
                                                value: e.id 
                                              }
                                             });


const { register, 
        handleSubmit,
        setValue,
        control,
        formState: { errors, isValid }
      } = useForm<ICreateHEOperator>({
        defaultValues: currentHEOperator.data! ? currentHEOperator.data : HEOperatorInitialValues,
        mode: 'onChange',
        resolver: yupResolver(HEOperatorSchema),
      });

  useEffect(() => {

    if(currentHEOperator.data && typeOptions){
      setValue("operatorName", currentHEOperator.data?.operatorName);
      setValue("isActive", currentHEOperator.data?.isActive ? 'true' : 'false');
      setValue("productFamilyId", currentHEOperator.data?.productFamilyId);
      setValue("licenseTypeId", currentHEOperator.data?.licenseTypeId);
      const dateReceivedValue = currentHEOperator.data?.dateReceived 
      ? new Date(currentHEOperator.data?.dateReceived)
      : undefined;
          setValue("dateReceived", dateReceivedValue);
        }
      }, [currentHEOperator.data, setValue]);

  const onError = (err: typeof errors) => {
    console.error(err);
  };

  const onSubmit = (data : ICreateHEOperator) => {

    data.operatorName = trimmedString(data.operatorName);
    if (data.dateReceived !== undefined) {
      data.dateReceived = new Date(data.dateReceived);
    }
    if (currentHEOperator.data) {
      updateHEOperator.mutate(data);
    }
    else {
      createHEOperator.mutate(data)
    }
  };


  const backTo = () => navigate('/he-operator');
  
  return (
    <>
      <PageContainer
        title='Manage My Databases'
        subTitle='Operator Master'
        controls={
          <Stack direction='row' gap={2}>
            <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disabled={!isValid }
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
      <Divider color='black' />
      <Stack
        component='form'
        height='100%'
        bgcolor='white'
        py={3}
        boxShadow={appStyles.shadow}
        borderRadius={1}
      >
        <Stack px={3} height='100%' overflow='auto' gap={1}>
        <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
          <Stack direction="column" gap={1.5} width="45%">   
            <AppTextField
              label="Operator Name"
              labelAlignment="left"
              labelWidth='33%'
              fieldWidth= '67%'
              fontWeight={400}
              placeholder="Name"
              error={!!errors.operatorName}
              helperText={errors.operatorName?.message}
              {...register('operatorName')}
            />
             <Controller
                    control={control}
                    name='productFamilyId'
                    render={({ field }) => (
                      <AppSelect
                        label="Product Family"
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.productFamilyId}
                        helperText={errors.productFamilyId?.message}
                        options={typeOptions}
                        {...register('productFamilyId')}
                        {...field}
                      />
                  )}
                />
              <Controller
                  control={control}
                  name='licenseTypeId'
                  render={({ field }) => (
                    <AppSelect
                      label="License Type"
                      labelAlignment="left"
                      labelWidth='33%'
                      fieldWidth= '67%'
                      fontWeight={400}
                      defaultValue=""
                      placeholder="Select here"
                      error={!!errors.licenseTypeId}
                      helperText={errors.licenseTypeId?.message}
                      options={operatorLicensetypesptions}
                      {...field}
                      {...register('licenseTypeId')}
                    />
                )}
              />
              <Controller
                    control={control}
                    name='dateReceived'
                    render={({ field: { value, onChange } }) => (
                      <AppDatePicker
                        name='dateReceived'
                        value={value || null} // Ensure value is not undefined
                        onChange={onChange}
                        label='Delivery Date'
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        error={!!errors.dateReceived}
                        helperText={errors.dateReceived?.message}
                      />
                    )}
                  />
              <Controller
                  control={control}
                  name='isActive'
                  render={({ field }) => (
                    <AppSelect
                      label="Status"
                      disabled= {currentHEOperator.data ? false : true}
                      labelAlignment="left"
                      labelWidth='33%'
                      fieldWidth= '67%'
                      fontWeight={400}
                      placeholder="Select here"
                      error={!!errors.isActive}
                      helperText={errors.isActive?.message}
                      options={statusOptions}
                      {...register('isActive')}
                      {...field}
                     
                    />
                )}
              />
             
          </Stack>
        </Stack>
        </Stack>
      </Stack>
    </PageContainer>
    <Outlet />
    </>
  )
}

export default HEOpartorForm;
import * as yup from 'yup';
import PersonIcon from '@mui/icons-material/Person';

import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import BarChartIcon from '@mui/icons-material/BarChart';
import EvStationIcon from '@mui/icons-material/EvStation';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

export type Access = {
  ALL: boolean;
  VIEW: boolean;
  ADD: boolean;
  EDIT: boolean;
  DELETE: boolean;
  EXPORT: boolean;
  IMPORT: boolean;
  APPROVE: boolean;
  SUBMIT_APPROVAL: boolean;
  ACKNOWLEDGE: boolean;
  COMPLETE: boolean;
  SEND_EMAIL: boolean;
  START: boolean;
};

export interface Role {
  id: string;
  name: string;
  description: string;
  permissions: string[];
  // meta fields
  isActive: boolean;
  isDefault: boolean;
  createdBy: string;
  updatedBy: string;
  dateCreated: string; //change to Date
  dateUpdated: string;
}

export const AccessOptions: Array<{
  label: string;
  accessKey: keyof Access;
}> = [
  { accessKey: 'ALL', label: 'All' },
  { accessKey: 'ADD', label: 'Add' },
  { accessKey: 'EDIT', label: 'Edit' },
  { accessKey: 'DELETE', label: 'Delete' },
  { accessKey: 'VIEW', label: 'View' },
  { accessKey: 'IMPORT', label: 'Import' },
  { accessKey: 'EXPORT', label: 'Export' },
  { accessKey: 'APPROVE', label: 'Approve' },
  { accessKey: 'COMPLETE', label: 'Complete' },
  { accessKey: 'SEND_EMAIL', label: 'Send Email' },
  { accessKey: 'START', label: 'Start' },
  { accessKey: 'ACKNOWLEDGE', label: 'Acknowledge' },
  { accessKey: 'SUBMIT_APPROVAL', label: 'Sumbit Approval' },
];
export const roleSchema = yup.object({
  name: yup.string().required('Role name is required').trim(),
  description: yup.string().required('Role description is required').trim(),
});

export type RoleFields = Omit<
  Role,
  | 'id'
  | 'isActive'
  | 'createdBy'
  | 'updatedBy'
  | 'dateCreated'
  | 'dateUpdated'
  | 'isDefault'
>;

export const roleInitialValues: RoleFields = {
  name: '',
  description: '',
  permissions: ['EJO::VIEW'],
};

export const PermissionTypes = [
  'VIEW',
  'ADD',
  'EDIT',
  'DELETE',
  'EXPORT',
  'IMPORT',
  'APPROVE',
  'SUBMIT_APPROVAL',
  'ACKNOWLEDGE',
  'COMPLETE',
  'SEND_EMAIL',
  'START',
] as const;

export const MODULES = [
  {
    modules: ['USER', 'ROLE'],
    title: 'User Management',
    icon: <PersonIcon />,
    isCommon: true,
  },
  {
    modules: ['EJO'],
    title: 'Equipment Job Order',
    icon: <NoteAltIcon />,
    isCommon: false,
  },
  {
    modules: ['EUR'],
    title: 'Equipment Utilization Report',
    icon: <BarChartIcon />,
    isCommon: false,
  },
  {
    modules: ['ESR'],
    title: 'Equipment Service Report',
    icon: <HomeRepairServiceIcon />,
    isCommon: false,
  },
  {
    modules: ['FRIS'],
    title: 'Fuel Request Issuance',
    icon: <EvStationIcon />,
    isCommon: false,
  },
  {
    modules: ['EPF & ERF', 'EXF', 'EORR'],
    title: 'Equipment Requests',
    icon: <LocalShippingIcon />,
    isCommon: true,
  },
]; // add more

export const AppAccessOptions: Array<{
  label: string;
  accessKey: string;
}> = [
  { accessKey: 'EJO::MANAGEMENT', label: 'EJO Management' },
  { accessKey: 'EJO::ESR::APPROVAL', label: 'EJO/ESR Approval' },
  { accessKey: 'ESR::MANAGEMENT', label: 'ESR Management' },
  { accessKey: 'FRIS::MANAGEMENT', label: 'FRIS Management' },
];
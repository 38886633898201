import { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppSelect from '../../../../components/common/forms/AppSelect';
import PageContainer from '../../../../components/common/PageContainer';
import { ICreateFueltype } from './utils/FuelType-utils';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { FuelSchema, FuelInitialValues} from './utils/schema';
import { useGetIFueltypeById, useUpdateFuelType, useCreateFuelType } from '../../../../hooks/useFuelType';
import { appColors, appStyles } from '../../../../theme';

const FuelTypeForm = () => {
  const navigate = useNavigate();
  const currentFuelType = useGetIFueltypeById();
  const updateFuelType = useUpdateFuelType();
  const createFuelType = useCreateFuelType();
  const statusOptions: SelectOptionType[] = [{'id': '1', 'label': 'Active' , 'value': 'true'},{'id': '2', 'label': 'Inactive' , 'value': 'false'} ]

const { register, 
        handleSubmit,
        setValue,
        control,
        formState: { errors, isValid }
      } = useForm<ICreateFueltype>({
        defaultValues: currentFuelType.data! ? currentFuelType.data : FuelInitialValues,
        mode: 'onChange',
        resolver: yupResolver(FuelSchema),
      });

  useEffect(() => {
    setValue("isActive", 'true');
    if (!currentFuelType.data) return;
    setValue("name", currentFuelType.data?.name);
    setValue("isActive", currentFuelType.data?.isActive ? 'true' : 'false');
  }, [currentFuelType.data, setValue]);

  const onError = (err: typeof errors) => {
    console.error(err);
  };

  const onSubmit = (data : ICreateFueltype) => {
    if (currentFuelType.data) {
      updateFuelType.mutate(data);
    }
    else {
      console.log(data)
      createFuelType.mutate(data)
    }
  };

  const backTo = () => navigate('/fuel-type');
  
  return (
    <>
      <PageContainer
        title='Manage My Databases'
        subTitle='Fuel Type Master'
        controls={
          <Stack direction='row' gap={2}>
            <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              disabled={!isValid }
              disableElevation
              variant='contained'
              sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
            >
              Save
            </Button>
          </Stack>
        }
      >
        <Divider color='black' />
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
          <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
            <Stack direction="column" gap={1.5} width="45%">   
              <AppTextField
                label="Name"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Name"
                error={!!errors.name}
                helperText={errors.name?.message}
                {...register('name')}
              />
                <Controller
                    control={control}
                    name='isActive'
                    render={({ field }) => (
                      <AppSelect
                        label="Status"
                        disabled= {currentFuelType.data ? false : true}
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.isActive}
                        helperText={errors.isActive?.message}
                        options={statusOptions}
                        {...register('isActive')}
                        {...field}
                      />
                  )}
                />

               
            </Stack>
          </Stack>
          </Stack>
        </Stack>
      </PageContainer>
      <Outlet />
    </>
  )
}

export default FuelTypeForm;
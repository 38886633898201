import { EJOSR } from "./types";
import * as yup from "yup";

/**
 * ? For validation only, will be used if the user
 * ? will update and data is already available through getting the data to be updated
 */
export const EJOSRManagementInitialValues: EJOSR = {
  id: "",
  serviceRequest: "",
  serviceRequestTypeId: "",
  remarks: "",
};

export const EJOSRSchema = yup.object({
  id: yup.string().nullable(),
  serviceRequestTypeId: yup.string(),
  serviceRequest: yup.string().required("Service Request is required"),
  remarks: yup.string().nullable(),
  pmCodeId: yup.string().nullable(),
  pmCode: yup.string().nullable(),
});

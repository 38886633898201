import { ICreateHEOperator } from '../modules/DatabaseManagement/PersonnelDBs/HEOperator/utils/HEOperator-utils';
import { apiClient, apiEndpoints } from './apiClient';
import { IHEOperator } from '../modules/DatabaseManagement/PersonnelDBs/HEOperator/utils/HEOperator-utils';

export type GetManyReturnType = {
  status: string;
  data: IHEOperator[];
};

export type GetOneReturnType = {
  status: string;
  data: IHEOperator;
};

const getOperators = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.operator);
  return response.data.data;
};

const getOperatorsById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.operator}/${id}`
  );
  return response.data.data;
};


const importOperators = async (newOperatorData: any) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.operator}/import-data`,
    newOperatorData
  );
  return response.data.data;
};

const updateOperator = async (id: string, updateOperatorData: Partial<ICreateHEOperator>) => {
  delete updateOperatorData.id;
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.operator}/${id}`,
    updateOperatorData
  );

  return response.data.data;
};

const createOperator = async (newOperatorData: Partial<ICreateHEOperator>) => {
  delete newOperatorData.id;
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.operator,
    newOperatorData
  );
  return response.data.data;
};
const deleteOperator = async (id: string) => {
  const response = await apiClient.delete(`${apiEndpoints.operator}/${id}`);
  return response.data.data;
};
export default {
  getOperators,
  getOperatorsById,
  importOperators,
  updateOperator,
  createOperator,
  deleteOperator
};
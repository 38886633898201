import * as yup from 'yup';
import moment from 'moment';
import { InitalEquipments } from './equipment-utils';
import { number } from 'yup/lib/locale';


export const equipmentInitialValues: InitalEquipments = {
  id: '',
  acquisitionCost: null,
  capacity: '',
  category: '',
  chassisNumber: '',
  class: '',
  conductionSticker: '',
  deliveryDate: new Date(Date.now()),
  description: '',
  engineNumber: '',
  equipmentNo: '',
  make: '',
  model: '',
  ownershipType: 'Rental',
  plateNumber: '',
  productFamilyId: '',
  serialNumber: '',
  supplier: '',
  yearModel: null,
  isActive: 'true'
};

export const EquipmentSchema = yup.object({
    equipmentNo: yup.string()
    .trim('The Equipment Number cannot include leading and trailing spaces')
    .strict(true)
    .min(1, 'The Equipment Number needs to be at least 1 Alphabet or Numerical')
    .required('The Equipment Number is required'),

    category: yup.string()
    .trim('The Category cannot include leading and trailing spaces')
    .strict(true)
    .min(1, 'The Category needs to be at least 1 Alphabet or Numerical')
    .required('The Category is required'),

    description: yup.string()
    .trim('The Description cannot include leading and trailing spaces')
    .strict(true)
    .min(1, 'The Description needs to be at least 1 Alphabet or Numerical')
    .required('The Description is required'),

    make: yup.string()
    .trim('The Make cannot include leading and trailing spaces')
    .strict(true)
    .min(1, 'The Make needs to be at least 1 Alphabet or Numerical')
    .required('The Make is required'),

    model: yup.string()
    .trim('The Model cannot include leading and trailing spaces')
    .strict(true)
    .min(1, 'The Model needs to be at least 1 Alphabet or Numerical')
    .required('The Model is required'),

    capacity: yup.string()
    .trim('The Capacity cannot include leading and trailing spaces')
    .strict(true)
    .min(1, 'The Capacity needs to be at least 1 Alphabet or Numerical')
    .required('The Capacity is required'),

    class: yup.string()
    .trim('The Class cannot include leading and trailing spaces')
    .strict(true)
    .min(1,'The Class needs to be at least 1 Alphabet or Numerical')
    .required('The Class is required'),

    serialNumber: yup.string()
    .trim('The Serial Number cannot include leading and trailing spaces')
    .strict(true)
    .min(1,'The Serial Number needs to be at least 1 Alphabet or Numerical')
    .required('The Serial Number is required'),

    engineNumber: yup.string()
    .trim('The Engine Number cannot include leading and trailing spaces')
    .strict(true)
    .min(1,'The Engine Number needs to be at least 1 Alphabet or Numerical')
    .required('The Engine Number is required'),

    productFamilyId: yup.string().
    required('Product family is required'),

    supplier: yup.string()
    .trim('The Supplier cannot include leading and trailing spaces')
    .strict(true)
    .min(1,'The Supplier needs to be at least 1 Alphabet or Numerical')
    .required('The Supplier number is required'),
    
    conductionSticker: yup.string()
    .trim('The Conduction Sticker cannot include leading and trailing spaces')
    .strict(true)
    .min(1,'The Conduction Sticker needs to be at least 1 Alphabet or Numerical')
    .required('The Conduction Sticker is required'),

    plateNumber: yup.string()
    .trim('The Plate Number cannot include leading and trailing spaces')
    .strict(true)
    .min(1,'The Plate Number needs to be at least 1 Alphabet or Numerical')
    .required('The Plate Number is required'),

    // deliveryDate: yup.string().required('Delivery date is required'),

    deliveryDate:yup.date().
    required('EORR Estimated Pick Up Date is required')
    .transform((curr, orig) => orig === '' ? null : curr)
    .typeError("The Delivery Date is Required"),
    
    ownershipType: yup.string().required('Ownership type is required'),

    chassisNumber: yup.string()
    .trim('The Chassis Number cannot include leading and trailing spaces')
    .strict(true)
    .min(1,'The Chassis Number needs to be at least 1 Alphabet or Numerical')
    .required('The Chassis Number is required'),

    yearModel: yup.number()
    .nullable()  // Allows the field to be null
    .transform((value, originalValue) => {
      // Treat empty strings as undefined
      return originalValue === '' ? undefined : value;
    })
    .required('This Year Model is not empty.')
    .test('is-integer', 'The Year Model must be an integer', (val) => Number.isInteger(val))
    .test('len', 'The Year Model is Invalid', (val : any) => val >= 2000 && val <= 3000),

    acquisitionCost: yup
    .number()
    .typeError("Acquisition cost must be a number")
    .min(1,'Please enter valid Acquisition cost')
    .transform((_, val) => (val !== "" ? Number(val) : null))
  });
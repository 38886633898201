import { IFueltype } from '../modules/DatabaseManagement/OtherDBs/FuelType/utils/FuelType-utils';
import { apiClient, apiEndpoints } from './apiClient';
import { ICreateFueltype } from '../modules/DatabaseManagement/OtherDBs/FuelType/utils/FuelType-utils';

export type GetManyReturnType = {
  status: string;
  data: IFueltype[];
};
export type GetOneReturnType = {
  status: string;
  data: IFueltype;
};

const getFuelTypes = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.fuelType);
  return response.data.data;
};

const geFuelTypesById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.fuelType}/${id}`
  );
  return response.data.data;
};

const importFuelTypes = async (newFuelTypeData: any) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.fuelType}/import-data`,
    newFuelTypeData
  );
  return response.data.data;
};

const updateFuelType = async (id: string, updateFuelTypeData: Partial<ICreateFueltype>) => {
  delete updateFuelTypeData.id;
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.fuelType}/${id}`,
    updateFuelTypeData
  );

  return response.data.data;
};

const createFuelType = async (newFuelTypeData: Partial<ICreateFueltype>) => {
  delete newFuelTypeData.id;
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.fuelType,
    newFuelTypeData
  );
  return response.data.data;
};

const fuelTypeService = {
  getFuelTypes,
  geFuelTypesById,
  importFuelTypes,
  updateFuelType,
  createFuelType
};

export default fuelTypeService;

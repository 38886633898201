import { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import AppSelect from '../../../../components/common/forms/AppSelect';
import PageContainer from '../../../../components/common/PageContainer';
import { IParts } from './utils/parts-utils';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { PartsInitialValues, PartsSchema } from './utils/schema';
import { useCreateParts, useGetPartById, useUpdatePart } from '../../../../hooks/useParts';
import { appColors, appStyles } from '../../../../theme';
import { useGetPartsDescriptionsQuery } from '../../../../hooks/usePartsDescription';
const PartsForm = () => {
    const navigate = useNavigate();
    const currentPart = useGetPartById();
    const updatePart = useUpdatePart();
    const createPart = useCreateParts();
    const partsDescription = useGetPartsDescriptionsQuery();
    

    const statusOptions: SelectOptionType[] = [{'id': 'true', 'label': 'Active' , 'value': 'true'},{'id': 'false', 'label': 'Inactive' , 'value': 'false'} ]
    const unitOfMeasurementOptions: SelectOptionType[] = [{'id': 'Piece', 'label': 'Piece' , 'value': 'Piece'},{'id': 'Liters', 'label': 'Liters' , 'value': 'Liters'} ]
    const partsDescriptionOptions : SelectOptionType[] = 
    partsDescription?.data === undefined ? [] : partsDescription.data.map(e => {
                                              return { 
                                                id: e.id, 
                                                label: e.description, 
                                                value: e.id 
                                              }
                                             });
    const { register, 
            handleSubmit,
            setValue,
            control,
            formState: { errors, isValid }
        } = useForm<IParts>({
            defaultValues: currentPart.data! ? currentPart.data : PartsInitialValues,
            mode: 'onChange',
            resolver: yupResolver(PartsSchema),
    });

    useEffect(() => {
    
        if (!currentPart.data) return;
       
        setValue("partNumber", currentPart.data?.partNumber);
        setValue("model", currentPart.data?.model);
        setValue("make", currentPart.data?.make);
        setValue("partsDescriptionId", currentPart.data?.partsDescriptionId);
        setValue("system", currentPart.data?.system);
        setValue("subsystem", currentPart.data?.subsystem);
        setValue("packageQty", currentPart.data?.packageQty);
        setValue("packageCost", currentPart.data?.packageCost);
        setValue("unitOfMeasurement", currentPart.data?.unitOfMeasurement);
        setValue("updatePeriod", currentPart.data?.updatePeriod);
        setValue("supplier", currentPart.data?.supplier);
        setValue("willfitOrEOM", currentPart.data?.willfitOrEOM);
        setValue("isActive", currentPart.data?.isActive ? 'true' : 'false');

    }, [currentPart.data, setValue]);

    const onError = (err: typeof errors) => {
        console.error(err);
    };

    const onSubmit = (data : IParts) => {
        if (currentPart.data) {
            data.isActive = data.isActive == 'true' ? true : false;
            delete data.description;
            updatePart.mutate(data);
        }
        else {
            delete data.isActive;
            delete data.description;
            console.log(data)
            createPart.mutate(data)
        }
    };
    const backTo = () => navigate('/parts');
    return (
        <>
        <PageContainer
            title='Manage My Databases'
            subTitle='Parts Master'
            controls={
            <Stack direction='row' gap={2}>
                <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
                Cancel
                </Button>
                <Button
                onClick={handleSubmit(onSubmit, onError)}
                disabled={!isValid }
                disableElevation
                variant='contained'
                sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
                >
                Save
                </Button>
            </Stack>
            }
        >
        <Divider color='black' />
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
          <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
            <Stack direction="column" gap={1.5} width="45%">   
              <AppTextField
                label="Part Number"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Part Number"
                error={!!errors.partNumber}
                helperText={errors.partNumber?.message}
                {...register('partNumber')}
              />
               <AppTextField
                label="Model"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Model"
                error={!!errors.model}
                helperText={errors.model?.message}
                {...register('model')}
              />
               <AppTextField
                label="System"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="System"
                error={!!errors.system}
                helperText={errors.system?.message}
                {...register('system')}
              />
               <AppTextField
                label="Sub System"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Subsytem"
                error={!!errors.subsystem}
                helperText={errors.subsystem?.message}
                {...register('subsystem')}
              />
               {/* <AppTextField
                label="Description"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Description"
                error={!!errors.description}
                helperText={errors.description?.message}
                {...register('description')}
              /> */}
               <Controller
                    control={control}
                    name='partsDescriptionId'
                    render={({ field }) => (
                      <AppSelect
                        label="Description"
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.partsDescriptionId}
                        helperText={errors.partsDescriptionId?.message}
                        options={partsDescriptionOptions}
                        {...register('partsDescriptionId')}
                        {...field}
                      />
                  )}
                />
               <AppTextField
                label="Make"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="Make"
                error={!!errors.make}
                helperText={errors.make?.message}
                {...register('make')}
              />
               <AppTextField
                label="WillFit or EOM"
                labelAlignment="left"
                labelWidth='33%'
                fieldWidth= '67%'
                fontWeight={400}
                placeholder="willfit Or EOM"
                error={!!errors.willfitOrEOM}
                helperText={errors.willfitOrEOM?.message}
                {...register('willfitOrEOM')}
              />
            </Stack>
            <Stack direction="column" gap={1.5} width="45%">
                 <AppTextField
                    label="Supplier"
                    labelAlignment="left"
                    labelWidth='33%'
                    fieldWidth= '67%'
                    fontWeight={400}
                    placeholder="Supplier"
                    error={!!errors.supplier}
                    helperText={errors.supplier?.message}
                    {...register('supplier')}
                />
                <Controller
                    control={control}
                    name='unitOfMeasurement'
                    render={({ field }) => (
                      <AppSelect
                        label="Unit of Measurement"
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.unitOfMeasurement}
                        helperText={errors.unitOfMeasurement?.message}
                        options={unitOfMeasurementOptions}
                        {...register('unitOfMeasurement')}
                        {...field}
                      />
                  )}
                />
                <AppTextField
                    label="Package cost"
                    labelAlignment="left"
                    labelWidth='33%'
                    fieldWidth= '67%'
                    fontWeight={400}
                    placeholder="Package cost"
                    error={!!errors.packageCost}
                    helperText={errors.packageCost?.message}
                    {...register('packageCost')}
                />
                <AppTextField
                    label="Package quantity"
                    labelAlignment="left"
                    labelWidth='33%'
                    fieldWidth= '67%'
                    fontWeight={400}
                    placeholder="Package quantity"
                    error={!!errors.packageQty}
                    helperText={errors.packageQty?.message}
                    {...register('packageQty')}
                />        
                 <Controller
                    control={control}
                    name='updatePeriod'
                    render={({ field: { value, onChange } }) => (
                      <AppDatePicker
                        name='updatePeriod'
                        value={value}
                        onChange={onChange}
                        label='Update period'
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        error={!!errors.updatePeriod}
                        helperText={errors.updatePeriod?.message}
                      />
                    )}
                  />
                <Controller
                    control={control}
                    name='isActive'
                    render={({ field }) => (
                      <AppSelect
                        label="Status"
                        disabled= {currentPart.data ? false : true}
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.isActive}
                        helperText={errors.isActive?.message}
                        options={statusOptions}
                        {...register('isActive')}
                        {...field}
                      />
                  )}
                />
            </Stack>
          </Stack>
          </Stack>
        </Stack>
      </PageContainer>
      <Outlet />
    </>
  )
}

export default PartsForm;

import { ICreateLaborType, ILaborType } from '../modules/DatabaseManagement/ServicingDBs/LaborType/utils/LaborType-utils';
import { apiClient, apiEndpoints } from './apiClient';

export type GetManyReturnType = {
  status: string;
  data: ILaborType[];
};

export type GetOneReturnType = {
  status: string;
  data: ILaborType;
};

const getLaborType = async () => {
  const response = await apiClient.get<GetManyReturnType>(apiEndpoints.laborType);
  return response.data.data;
};

const getLaborTypeById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.laborType}/${id}`
  );
  return response.data.data;
};

const importLaborType = async (newLaborTypeData: any) => {
  const response = await apiClient.post<GetOneReturnType>(
    `${apiEndpoints.laborType}/import-data`,
    newLaborTypeData
  );
  return response.data.data;
};

const updateLaborType = async (id: string, updateLaborTypeData: Partial<ICreateLaborType>) => {
  delete updateLaborTypeData.id;
  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.laborType}/${id}`,
    updateLaborTypeData
  );

  return response.data.data;
};

const createLaborType = async (newLaborTypeData: Partial<ICreateLaborType>) => {
  delete newLaborTypeData.id;
  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.laborType,
    newLaborTypeData
  );
  return response.data.data;
};

export default {
  getLaborType,
  getLaborTypeById,
  importLaborType,
  updateLaborType,
  createLaborType
};
import { 
  ICertificateTracker, 
  logCertificateFields, 
  IEmailForm,
  EmailAttachment 
} from '../../modules/MyDashboardsPlusTasks/CertificateTracker/ct-utils';
import { apiClient, apiEndpoints } from '../apiClient';
import { uploadMedia, uploadMultipleMedia } from '../../utils/upload';

export type GetManyReturnType = {
  status: string;
  data: ICertificateTracker[];
};

export type GetOneReturnType = {
  status: string;
  data: ICertificateTracker;
};

const getCertificateStatus = async () => {
  const response = await apiClient.get<GetManyReturnType>(`${apiEndpoints.certificateTracker}`);
  return response.data.data;
};

const getCertificateStatusById = async (id: string) => {
  const response = await apiClient.get<GetOneReturnType>(
    `${apiEndpoints.certificateTracker}/${id}`
  );
  return response.data.data;
};

const createCertificateStatus = async (newCertificateStatusData: Partial<logCertificateFields>) => {
  let mediaUrl = "";

  if (newCertificateStatusData 
    && newCertificateStatusData.media 
    && newCertificateStatusData.media.length > 0) {
    const uploadRes = await uploadMedia(newCertificateStatusData.media[0].source, "equipment-certificate");
    mediaUrl = uploadRes.path;
  }

  const newData = {
    ...newCertificateStatusData,
    media: mediaUrl
  }

  const response = await apiClient.post<GetOneReturnType>(
    apiEndpoints.certificateTracker,
    newData
  );

  return response.data.data;
};

const updateCertificateStatus = async (id: string, updateCertificateStatusData: Partial<logCertificateFields>) => {
  let mediaUrl = "";

  if (updateCertificateStatusData 
      && updateCertificateStatusData.media 
      && updateCertificateStatusData.media.length > 0
    ) {
    const uploadRes = await uploadMedia(updateCertificateStatusData.media[0].source, "equipment-certificate");
    mediaUrl = uploadRes.path;
  } else {
    const response = await apiClient.get<GetOneReturnType>(
      `${apiEndpoints.certificateTracker}/${id}`
    );

    mediaUrl = response.data.data.media;
  }

  const newData = {
    ...updateCertificateStatusData,
    media: mediaUrl
  }

  const response = await apiClient.patch<GetOneReturnType>(
    `${apiEndpoints.certificateTracker}/${id}`,
    newData
  );

  return response.data.data;
};

const sendEmail = async (emailData: IEmailForm) => {
  let mediaUrl: EmailAttachment[] = [];
  let newData:any = {}
  const mediaFile = emailData.media.map(e => e.source);

  if (emailData && emailData.media.length > 0 ) {
    const uploadRes = await uploadMultipleMedia(mediaFile, "equipment-certificate-email");
    
    mediaUrl = uploadRes.map(u => ({
      "filename": u.filename,
      "path": u.path
    }));
  }
  newData = {
    to: emailData.toemail,
    cc: emailData.ccemail,
    subject: emailData.subject,
    message: emailData.message
  }
  if(emailData.media.length > 0) newData.attachments = mediaUrl;
  
  const response = await apiClient.post(
    `${apiEndpoints.certificateTracker}/send-email`,
    newData
  );

  return response.data.data;
}

const certificateTrackerApi = {
  getCertificateStatus,
  getCertificateStatusById,
  createCertificateStatus,
  updateCertificateStatus,
  sendEmail
};

export default certificateTrackerApi;
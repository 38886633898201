import PageContainer from '../../../../components/common/PageContainer';
import TaskTypesTable from './ServiceTaskTable';
function ServiceTaskPage() {
  return (
    <>
      <PageContainer title='Servicing Databases' subTitle='Service Task Master' showDate>
        Service Task Master
        <TaskTypesTable />
      </PageContainer>
    </>
  );
}

export default ServiceTaskPage;

import { createColumnHelper } from '@tanstack/react-table';
import { IOnRent } from './utils/types';
import { dateDifference } from '../../../utils/helpers';
import { ExtendActionButton, OffRentActionButton } from '../../../components/common/primebmd-ui/EditActionButton';
import { appColors } from '../../../theme';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import CtStatus from '../../../components/common/CtStatus';

const columnHelper = createColumnHelper<IOnRent>();

export const pmOnRentColumns = [
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row ) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('productFamily', {
    id: 'productFamily',
    header: 'Product Family',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('capacity', {
    id: 'capacity',
    header: 'Capacity',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.display({
    id: 'daysOnRent',
    header: 'Days On Rent',
    cell: ({ row }) => dateDifference(new Date(), row.original.arrivalDate),
    enableHiding: false,
  }),
  columnHelper.display({
    id: 'daysToEndDate',
    header: 'Days to End Date',
    cell: ({ row }) => {
      let emEndDate = row.original.emEndDate === "" ? row.original.endDate : row.original.emEndDate;;

      if (row.original.exfStatus) {
        if (row.original.exfStatus === 'Approved') {
          emEndDate = row.original.exfRequestedEndDate;
        } else if (row.original.exfStatus === 'Approved with Change') {
          emEndDate = row.original.exfEMEndDate;
        }
      } 
      return emEndDate ? <CtStatus type={new Date(emEndDate)} /> : '';
    },
    enableHiding: false,
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Actions',
    cell: ({ row }) => (
      <Stack direction="row" gap={1} alignItems="center">
        <ExtendActionButton route={`/my-on-rent-equipment/create-exf/${row.original.id}`} />
        <OffRentActionButton route={`/my-on-rent-equipment/create-eorr/${row.original.id}`} />
      </Stack>
    ),
    enableHiding: false,
  }),
];

export const emOnRentColumns = [
  columnHelper.accessor('project', {
    id: 'project',
    header: 'Project',
    cell: (row ) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row ) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('productFamily', {
    id: 'productFamily',
    header: 'Product Family',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('capacity', {
    id: 'capacity',
    header: 'Capacity',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.accessor('make', {
    id: 'make',
    header: 'Make',
    cell: (row) => row.renderValue(),
    enableGlobalFilter: true,
    enableColumnFilter: true,
  }),
  columnHelper.display({
    id: 'daysOnRent',
    header: 'Days On Rent',
    cell: ({ row }) => dateDifference(new Date(), row.original.arrivalDate),
    enableHiding: false,
  }),
  columnHelper.display({
    id: 'daysToEndRental',
    header: '# of Days to End of Rental',
    cell: ({ row }) => {
      let emEndDate = row.original.emEndDate === "" ? row.original.endDate : row.original.emEndDate;

      if (row.original.exfStatus) {
        if (row.original.exfStatus === 'Approved') {
          emEndDate = row.original.exfRequestedEndDate;
        } else if (row.original.exfStatus === 'Approved with Change') {
          emEndDate = row.original.exfEMEndDate;
        }
      }
      return emEndDate ? <CtStatus type={new Date(emEndDate)} /> : '';
    },
    enableHiding: false,
  }),
  columnHelper.display({
    id: 'readyToWorkStatus',
    header: 'Ready to Work Status',
    cell: ({ row }) => '-',
    enableHiding: false,
  }),
  columnHelper.accessor('alert', {
    id: 'alert',
    header: 'PM Alerts',
    cell: (row) => {
      return row.getValue() 
              ? <Chip 
                  label={row.getValue() ? row.getValue() : '-'} 
                  sx={{ background: appColors.errorDark, color: "#fff" }}
                /> 
              : '-'
    },
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('alertDate', {
    id: 'daysSinceAlertStarted',
    header: '# of Days since Alert Started',
    cell: (row) => row.getValue() !== null ? dateDifference(new Date(), row.getValue()) : '-',
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
];
import { useQuery } from "@tanstack/react-query";
import {
  IESR,
  IEjoManPower,
} from "../modules/MyDashboardsPlusTasks/EJO-ESR/utils/types";
import { dateDifference } from "../utils/helpers";
import esrAPI from "../services/dashboardPlusTasks/esr";
import moment from "moment";
import { useParams } from "react-router-dom";

export function useGetESRQuery(status?: string) {
  return useQuery<IESR[]>(["esr"], () => esrAPI.getAllESR(status), {
    onError(err) {
      console.log(err);
    },
  });
}

export function useGetESRByID() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`eurActivity-${id}`, id], () => esrAPI.getESRById(id!), {
    enabled: id ? true : false,
  });
}

export const useFormatGetESRQuery = (data: IESR[], status: string) => {
  const newESRList = data.map((d) => {
    const daysElapsedVariable =
      status === "Approved"
        ? d.dateApproved
        : status === "Started"
        ? d.dateStarted
        : d.dateCreated;

    return {
      esrNumber: d.esrNumber,
      equipmentNo: d.equipmentNo,
      projectLocation: d.projectLocation,
      serviceRequestType: d.serviceRequestType,
      remarks: d.remarks,
      daysElapsed: dateDifference(new Date(), daysElapsedVariable),
    };
  });

  return newESRList;
};

export function useGetEjoManpowerQuery() {
  return useQuery<IEjoManPower[]>(
    ["EjoManPower"],
    () => esrAPI.getEjoManpower(),
    {
      onError(err) {
        console.log(err);
      },
    }
  );
}

export const useExportEjoManpower = (data: IEjoManPower[]) => {
  const newEjoManpowerList = [];

  const EjoManpowerList = data;

  for (let i in EjoManpowerList) {
    let json = {
      daysSinceEjoOpened: EjoManpowerList[i].daysSinceEjoOpened,
      ejoNumber: EjoManpowerList[i].ejoNumber,
      equipmentNo: EjoManpowerList[i].equipmentNo,
      esrDateApproved: EjoManpowerList[i].esrDateApproved
        ? moment(EjoManpowerList[i].esrDateApproved).format("MM/DD/yyyy")
        : "N/A",
      esrDateCreated: EjoManpowerList[i].esrDateCreated
        ? moment(EjoManpowerList[i].esrDateCreated).format("MM/DD/yyyy")
        : "N/A",
      ejoDateApproved: EjoManpowerList[i].ejoDateApproved
        ? moment(EjoManpowerList[i].ejoDateApproved).format("MM/DD/yyyy")
        : "N/A",
      ejoDateNeeded: EjoManpowerList[i].ejoDateNeeded
        ? moment(EjoManpowerList[i].ejoDateNeeded).format("MM/DD/yyyy")
        : "N/A",
      esrNumber: EjoManpowerList[i].esrNumber,
      manPower: EjoManpowerList[i].manPower
        ? EjoManpowerList[i].manPower
        : "N/A",
      pmCode: EjoManpowerList[i].pmCode
        ? EjoManpowerList[i].pmCode
        : EjoManpowerList[i].srRemarks,
      projectLocation: EjoManpowerList[i].projectLocation,
      roleType: EjoManpowerList[i].roleType,
      serviceRequest: EjoManpowerList[i].serviceRequest
        ? EjoManpowerList[i].serviceRequest
        : "N/A",
      serviceRequestNo: EjoManpowerList[i].serviceRequestNo,
    };
    newEjoManpowerList.push(json);
  }
  return newEjoManpowerList;
};

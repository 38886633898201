import * as yup from 'yup';
import { InitialOperetorLicenses } from './HEOperatorCertificateType-utils';


export const InitialOperetorLicensesInitialValues: InitialOperetorLicenses = {
  id: '',
  name: '',
  operatorType: '',
  isActive: 'true',
};

export const OperetorLicensesSchema = yup.object({
    name: yup.string().trim().required('Name is required'),
    operatorType: yup.string().trim().required('Operator Type is required'),
    validity: yup.number().required('Validity is required'),
    isActive: yup.boolean().required('Status is required'),
  });
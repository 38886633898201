import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { IOnRentExport } from '../utils/types';

const MyOnRentEquipmentMap = {
    'Project Name': 'project',
    'Equipment Number' : 'equipmentNo',
    'Product Family': 'productFamily',
    'Capacity' : 'capacity',
    'Make' : 'make',
    'Days On Rent' : 'arrivalDate',
    'Number of days to expire' : 'emEndDate',
    'Ready to Work Status' : 'readyToWorkStatus',
    'PM Alert' : 'alert',
    'Number of Days Since Alert Started' : 'alertDate'
}

export const exportOnRentToExcel = (data: IOnRentExport[], filename: string) => {
    const headings = [Object.keys(MyOnRentEquipmentMap)];
    const columnHeaders = [...Object.values(MyOnRentEquipmentMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };
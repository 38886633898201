import { useEffect } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AppTextField from '../../../../components/common/forms/AppTextField';
import AppDatePicker from '../../../../components/common/forms/AppDatePicker';
import AppSelect from '../../../../components/common/forms/AppSelect';
import PageContainer from '../../../../components/common/PageContainer';
import { ISRT } from './utils/serviceRequestType-utils';
import { SelectOptionType } from '../../../../components/common/forms/form-types';
import { ServiceRequestTypeSchema, ServiceRequestTypeInitialValues } from './utils/schema';
import { useGetServiceRequestTypeById, useUpdateServiceRequestType, useCreateServiceRequestType } from '../../../../hooks/useServiceRequestType';  

import { appColors, appStyles } from '../../../../theme';

const SrviceRequestTypeForm = () => {
    const navigate = useNavigate();
    const currentServiceRequestType = useGetServiceRequestTypeById();
    const updateServiceRequestType = useUpdateServiceRequestType();
    const createServiceRequestType = useCreateServiceRequestType();
    const statusOptions: SelectOptionType[] = [{'id': 'true', 'label': 'Active' , 'value': 'true'},{'id': 'false', 'label': 'Inactive' , 'value': 'false'} ];
    const requestTypeOptions: SelectOptionType[] = [{'id': 'PM', 'label': 'PM' , 'value': 'PM'},{'id': 'Non-PM', 'label': 'Non-PM' , 'value': 'Non-PM'} ];

    const { register, 
            handleSubmit,
            setValue,
            control,
            formState: { errors, isValid }
        } = useForm<ISRT>({
            defaultValues: currentServiceRequestType.data! ? currentServiceRequestType.data : ServiceRequestTypeInitialValues,
            mode: 'onChange',
            resolver: yupResolver(ServiceRequestTypeSchema),
    });

    useEffect(() => {
    
        if (!currentServiceRequestType.data) return;
        setValue("requestName", currentServiceRequestType.data?.requestName);
        setValue("requestType", currentServiceRequestType.data?.requestType);
        setValue("isActive", currentServiceRequestType.data?.isActive ? 'true' : 'false');
        
    }, [currentServiceRequestType.data, setValue]);

    const onError = (err: typeof errors) => {
        console.error(err);
    };

    const onSubmit = (data : ISRT) => {
        if (currentServiceRequestType.data) {
            data.isActive = data.isActive == 'true' ? true : false;
            updateServiceRequestType.mutate(data);
        }
        else {
            delete data.isActive;
            createServiceRequestType.mutate(data)
        }
    };

    const backTo = () => navigate('/service-request-type');
  
    return (
        <>
        <PageContainer
            title='Manage My Databases'
            subTitle='Service Request Type Master'
            controls={
            <Stack direction='row' gap={2}>
                <Button sx={{ backgroundColor: appColors.lightGray, color: 'black' }} onClick={backTo}>
                Cancel
                </Button>
                <Button
                onClick={handleSubmit(onSubmit, onError)}
                disabled={!isValid }
                disableElevation
                variant='contained'
                sx={{ textTransform: 'capitalize', borderRadius: 1.5 }}
                >
                Save
                </Button>
            </Stack>
            }
        >
        <Divider color='black' />
        <Stack
          component='form'
          height='100%'
          bgcolor='white'
          py={3}
          boxShadow={appStyles.shadow}
          borderRadius={1}
        >
          <Stack px={3} height='100%' overflow='auto' gap={1}>
          <Stack direction="row" justifyContent="space-between" width="100%" mt={3}>
            <Stack direction="column" gap={1.5} width="45%">   
                <AppTextField
                    label="Request Name"
                    labelAlignment="left"
                    labelWidth='33%'
                    fieldWidth= '67%'
                    fontWeight={400}
                    placeholder="Request Name"
                    error={!!errors.requestName}
                    helperText={errors.requestName?.message}
                    {...register('requestName')}
                />
                {/* <AppTextField
                    label="Request Type"
                    labelAlignment="left"
                    labelWidth='33%'
                    fieldWidth= '67%'
                    fontWeight={400}
                    placeholder="Request Type"
                    error={!!errors.requestType}
                    helperText={errors.requestType?.message}
                    {...register('requestType')}
                /> */}
                 <Controller
                    control={control}
                    name='requestType'
                    render={({ field }) => (
                        <AppSelect
                        label="Request Type"
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.requestType}
                        helperText={errors.requestType?.message}
                        options={requestTypeOptions}
                        {...register('requestType')}
                        {...field}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name='isActive'
                    render={({ field }) => (
                        <AppSelect
                        label="Status"
                        disabled= {currentServiceRequestType.data ? false : true}
                        labelAlignment="left"
                        labelWidth='33%'
                        fieldWidth= '67%'
                        fontWeight={400}
                        placeholder="Select here"
                        error={!!errors.isActive}
                        helperText={errors.isActive?.message}
                        options={statusOptions}
                        {...register('isActive')}
                        {...field}
                        />
                    )}
                />
            </Stack>
          </Stack>
          </Stack>
        </Stack>
      </PageContainer>
      <Outlet />
    </>
  )
}

export default SrviceRequestTypeForm;
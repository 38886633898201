import * as yup from 'yup';
import { ICreateMechanic } from './Mechanic-utils';



export const MechanicInitialValues: ICreateMechanic = {
  id: '',
  name: '',
  laborTypeID: '',
  isActive: 'true',
};
export const MechanicSchema = yup.object({
    name: yup.string().trim().required('Name is required'),
    laborTypeID: yup.string().trim().required("Labor Type is required"),
    isActive: yup.boolean().required('Status is required'),
  });
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { ExportLaborType } from './LaborType-utils';
import moment from 'moment';

const LaborTypeMap = {
    'Labor Type ID': 'laborTypeId',
    'Labor Type': 'laborType',
    'Unit Cost': 'unitCost',
    'Unit of Measurement' : 'unitOfMeasurement',
    'Update Period' : 'updatePeriod',
    'Status': 'status'
}

const LaborTypeMapImport = {
    'Labor Type': 'laborType',
    'Unit Cost': 'unitCost',
    'Unit of Measurement' : 'unitOfMeasurement',
    'Update Period' : 'updatePeriod'
}


export const ExportLaborTypeToExcel = (data: ExportLaborType[], filename: string) => {
    const headings = [Object.keys(LaborTypeMap)];
    const columnHeaders = [...Object.values(LaborTypeMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);

    const formattedData = data.map(({isActive,updatePeriod, ...item}) => {
      return {
        ...item,
        status : isActive ? 'Active' : 'Inactive',
        updatePeriod: updatePeriod ? moment(updatePeriod).format('MM/DD/yyyy') : '',
      };
    });

    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
    console.log(formattedData)
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
     XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };

  export const GetTemplateLaborType = (data: ExportLaborType[], filename: string) => {
    const headings = [Object.keys(LaborTypeMapImport)];
    const columnHeaders = [...Object.values(LaborTypeMapImport)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };
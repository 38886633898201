import PageContainer from '../../../../components/common/PageContainer';
import CertificateTypeTable from './HECertificateTypeTable';

function HECertificateTypePage() {
  return (
    <>
      <PageContainer title='Equipment Databases' subTitle='HE Certificate Type Master' showDate>
        <CertificateTypeTable />
      </PageContainer>
    </>
  );
}

export default HECertificateTypePage;

import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AppTextField from "../../../../components/common/forms/AppTextField";
import AppDatePicker from "../../../../components/common/forms/AppDatePicker";
import AppSelect from "../../../../components/common/forms/AppSelect";
import { useGetEquipmentsQuery } from "../../../../hooks/useEquipments";
import { useGetLocationsQuery } from "../../../../hooks/useLocations";
import { appColors } from "../../../../theme";
import { useEJOManagementContext } from "./Context/EJOManagementContext";
import { EJOManagementFields, EJORequestBody } from "./utils/types";
import {
  EJOManagementInitialValues,
  EJOManagementSchema,
} from "./utils/schema";
import {
  useCreateEJO,
  useGetAllServiceRequests,
  useGetCurrentEJOSelected,
  useUpdateEJO,
} from "../../../../hooks/useEJO";
import { Box, Grid, Switch, Typography } from "@mui/material";
import EJOSRTable from "./EJO-SR/EJOSRTable";
import EJOSRManagementForm from "./EJO-SR/EJOSRManagementForm";
import { useAuth } from "../../../../components/AuthProvider";
import LoadingIndicator from "../../../../components/common/LoadingIndicator";
import EJOConfirmDialog from "./EJOConfirmDialog";
import EJOSRConfirmDeleteDialog from "./EJO-SR/EJOSRConfirmDeleteDialog";

const EJOManagementForm = () => {
  const { id: paramsId } = useParams();
  const navigate = useNavigate();
  const currUser = useAuth()?.authUser;

  const [openClearSrDialog, setOpenClearSrDialog] = useState<boolean>(false);
  const { data: ejo, isLoading: isEJOLoading } =
    useGetCurrentEJOSelected(paramsId);
  const { data: srList, isLoading: isSRLoading } =
    useGetAllServiceRequests(paramsId);

  const createEJO = useCreateEJO();
  const updateEJO = useUpdateEJO();
  const {
    serviceRequestsArray,
    setServiceRequestsArray,
    currentEJOSelected,
    setCurrentEJOSelected,
    setEjoActionType,
    ejoActionType,
  } = useEJOManagementContext();

  const equipmentData = useGetEquipmentsQuery();

  const locationData = useGetLocationsQuery();
  const equipmentOptions: any[] =
    equipmentData?.data === undefined
      ? []
      : equipmentData.data.map((e) => {
          return {
            id: e.id,
            label: e.equipmentNo,
            value: e.id,
          };
        });
  const locationOptions: any[] =
    locationData?.data === undefined
      ? []
      : locationData.data.map((e) => {
          return {
            id: e.id,
            label: e.name,
            value: e.id,
          };
        });

  const {
    register,
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EJOManagementFields>({
    defaultValues: EJOManagementInitialValues,
    mode: "onChange",
    resolver: yupResolver(EJOManagementSchema),
  });

  const [currentEquipmentId, setCurrentEquipmentId] = useState<string>(
    watch("equipmentId")
  );

  const equipmentModel = useMemo(() => {
    if (!equipmentData.data) {
      return;
    }

    const foundEquipment = equipmentData.data.find(
      (i) => watch("equipmentId") == i.id
    );

    return foundEquipment?.model;
  }, [watch("equipmentId"), equipmentData]);

  const onSubmit = (ejoData: EJOManagementFields) => {
    function formatDate(date: Date) {
      // Extract date and time components
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

      // Extract timezone offset
      const timezoneOffset = date.getTimezoneOffset();
      const sign = timezoneOffset > 0 ? "-" : "+";
      const absOffset = Math.abs(timezoneOffset);
      const offsetHours = String(Math.floor(absOffset / 60)).padStart(2, "0");
      const offsetMinutes = String(absOffset % 60).padStart(2, "0");

      // Format offset
      const offsetString = `${sign}${offsetHours}${offsetMinutes}`;

      // Format final date string
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${offsetString}`;
    }

    const formattedDateNeeded = formatDate(new Date(ejoData.dateNeeded));
    const formattedSRList = serviceRequestsArray.map((sr) => {
      const isExistingInDb = sr.id.length > 5;

      if (!isExistingInDb) {
        return {
          ...sr,
          id: "",
        };
      }

      return sr;
    });

    const formattedEJOData: EJORequestBody = {
      ejoCreator: !currUser ? "" : currUser.id,
      warranty: true,
      isOperable: Boolean(ejoData.isOperable),
      dateNeeded: formattedDateNeeded,
      dateCreated: String(ejoData.dateCreated),
      smrMileage: Number(ejoData.smrMileage),
      equipmentId: ejoData.equipmentId,
      projectLocationId: ejoData.projectLocationId,
      serviceRequestList: formattedSRList,
    };

    // TODO add validation
    if (ejoActionType === "new") {
      createEJO.mutate(formattedEJOData);
    }

    if (ejoActionType === "update") {
      updateEJO.mutate(formattedEJOData);
    }
  };

  const onProceedEquipmentAction = (): void => {
    setCurrentEquipmentId(watch("equipmentId"));
    setServiceRequestsArray([]);
    setOpenClearSrDialog(false);
  };

  const onCancelEquipmentAction = (): void => {
    setValue("equipmentId", currentEquipmentId);
    setOpenClearSrDialog(false);
  };

  const onError = (err: typeof errors) => {
    console.error(err);
  };

  const backTo = () => {
    localStorage.removeItem("ErrorActivityData");
    //* To clear the fields
    setServiceRequestsArray([]);
    setCurrentEJOSelected({
      ejoCreator: "",
      warranty: true,
      dateCreated: Date(),
      dateNeeded: Date(),
      projectLocationId: "",
      equipmentId: "",
      smrMileage: 0,
      isOperable: false,
      serviceRequestList: [],
    });
    navigate("/ejo-management");
  };

  useEffect(() => {
    if (paramsId) {
      if (ejo && srList) {
        setValue("isOperable", ejo.isOperable);
        setValue("dateNeeded", ejo.dateNeeded);
        setValue("dateCreated", ejo.dateCreated);
        setValue("smrMileage", ejo.smrMileage);
        setValue("equipmentId", ejo.equipmentId);
        setValue("projectLocationId", ejo.projectLocationId);
        setValue("serviceRequestList", srList);
        setServiceRequestsArray(srList);
        setEjoActionType("update");
        setCurrentEquipmentId(ejo.equipmentId);
      }
    } else {
      setValue("serviceRequestList", []);
    }
  }, [ejo, srList, paramsId, setValue]);

  useEffect(() => {
    //* If both the current selected and the recent value is same
    if (currentEquipmentId == watch("equipmentId")) {
      return;
    }

    //* Use-case: Create, when the currentEquipmentId is empty
    if (!currentEquipmentId) {
      setCurrentEquipmentId(watch("equipmentId"));
    }

    if (serviceRequestsArray.length != 0) {
      setOpenClearSrDialog(true);
    }
  }, [watch("equipmentId")]);

  if (isEJOLoading || isSRLoading) {
    if (paramsId) {
      return <LoadingIndicator show />;
    }
  }

  //* Used for validating. Did not use isValid because isValid still return false even though the data is valid
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <>
      <Stack direction="row" gap={2} justifyContent={"flex-end"} mb={1}>
        <Button
          sx={{ backgroundColor: appColors.lightGray, color: "black" }}
          onClick={backTo}
          disabled={createEJO.isLoading || updateEJO.isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit, onError)}
          disabled={
            hasErrors ||
            serviceRequestsArray.length === 0 ||
            createEJO.isLoading ||
            updateEJO.isLoading ||
            createEJO.isSuccess ||
            updateEJO.isSuccess
          }
          disableElevation
          variant="contained"
          sx={{ textTransform: "capitalize", borderRadius: 1.5 }}
        >
          {!createEJO.isLoading && ejoActionType == "new" ? "Create" : null}
          {!updateEJO.isLoading && ejoActionType == "update" ? "Save" : null}
          {createEJO.isLoading && "Creating..."}
          {updateEJO.isLoading && "Saving..."}
        </Button>
      </Stack>
      <Divider color="black" />
      <Grid container spacing={2} sx={{ marginTop: "1em" }}>
        {ejoActionType == "update" && (
          <Grid container spacing={2} sx={{ marginLeft: ".14em" }}>
            <Grid item sm={12} md={12} lg={6}>
              <AppTextField
                label="EJO NO."
                labelAlignment="left"
                labelWidth="20%"
                fieldWidth="80%"
                fontWeight={400}
                placeholder="0"
                value={ejo?.ejoNumber}
                disabled={true}
              />
            </Grid>
          </Grid>
        )}
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <AppDatePicker
              name="dateCreated"
              value={
                !currentEJOSelected.dateCreated
                  ? new Date()
                  : currentEJOSelected.dateCreated
              }
              onChange={() => {}}
              label="Date Created"
              labelAlignment="left"
              labelWidth="20%"
              fieldWidth="80%"
              fontWeight={400}
              disabled={true}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <Controller
              control={control}
              name="dateNeeded"
              render={({ field: { value, onChange } }) => (
                <AppDatePicker
                  name="dateNeeded"
                  value={new Date(value)}
                  onChange={onChange}
                  label="Date Needed"
                  labelAlignment="left"
                  labelWidth="20%"
                  fieldWidth="80%"
                  fontWeight={400}
                  error={!!errors.dateNeeded}
                  helperText={errors.dateNeeded?.message}
                  minDate={new Date()}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <Controller
              control={control}
              name="equipmentId"
              render={({ field }) => (
                <AppSelect
                  label="Equipment No."
                  labelAlignment="left"
                  labelWidth="20%"
                  fieldWidth="80%"
                  fontWeight={400}
                  placeholder="Select here"
                  error={!!errors.equipmentId}
                  helperText={errors.equipmentId?.message}
                  options={equipmentOptions}
                  {...register("equipmentId")}
                  {...field}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <Controller
              control={control}
              name="projectLocationId"
              render={({ field }) => (
                <AppSelect
                  label="Location"
                  labelAlignment="left"
                  labelWidth="20%"
                  fieldWidth="80%"
                  fontWeight={400}
                  placeholder="Select here"
                  options={locationOptions}
                  error={!!errors.projectLocationId}
                  helperText={errors.projectLocationId?.message}
                  {...register("projectLocationId")}
                  {...field}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <AppTextField
              label="SMR/Mileage"
              labelAlignment="left"
              labelWidth="20%"
              fieldWidth="80%"
              fontWeight={400}
              placeholder="0"
              error={!!errors.smrMileage}
              helperText={errors.smrMileage?.message}
              {...register("smrMileage")}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          <Box>
            <Controller
              control={control}
              name="isOperable"
              render={({ field: { value, onChange } }) => (
                <Stack direction="row" alignContent="center">
                  <Box sx={{ width: "20%" }}>
                    {" "}
                    <Typography
                      color={appColors.formText}
                      whiteSpace="nowrap"
                      fontSize={15}
                      sx={{
                        paddingTop: ".5em",
                        marginRight: "2.5em",
                      }}
                    >
                      Operable?
                    </Typography>
                  </Box>
                  <Box sx={{ width: "80%", display: "flex" }}>
                    <Switch
                      checked={getValues().isOperable}
                      {...register("isOperable")}
                      value={value}
                      onChange={onChange}
                    />
                    <Typography
                      color={appColors.formText}
                      whiteSpace="nowrap"
                      fontSize={15}
                      sx={{
                        paddingTop: ".5em",
                      }}
                    >
                      {!value ? "No" : "Yes"}
                    </Typography>
                  </Box>
                </Stack>
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <EJOSRTable equipmentId={watch("equipmentId")} />
        </Grid>
      </Grid>
      <EJOSRManagementForm equipmentModel={equipmentModel} />
      <EJOConfirmDialog
        openClearSrDialog={openClearSrDialog}
        setOpenClearSrDialog={setOpenClearSrDialog}
        handleSubmit={onProceedEquipmentAction}
        handleClose={onCancelEquipmentAction}
      />
      <EJOSRConfirmDeleteDialog />
    </>
  );
};

export default EJOManagementForm;

import * as yup from 'yup';
import { ICreatePMSCodeTaskParts } from './PMSCodeTaskParts-utils';

export const PMSCodeTaskPartsInitialValues: ICreatePMSCodeTaskParts = {
    id: '',
    pmCodeId: '',
    taskTypeId: '',
   // taskItem: '',
    partNumber: '',
    quantity: '',
    pmCode: '',
    taskType: '',
    //taskTypeItem: '',
    partsDescriptionId: '',
    intervalType: 'Periodic',
    isActive: '',
};
export const PMSCodeTaskPartsSchema = yup.object({
    pmCodeId: yup.string().trim().required('PM Code ID is required'),
    taskTypeId: yup.string().required('Task Type ID is required'),
    partNumber: yup.string().trim().required('Part Number is required'),
    quantity: yup.number().min(1).typeError("Quantity must be a number").required('Quantity is required'),
    //pmCode: yup.string().trim().required('PMCode is required'),
    //taskType: yup.string().trim().required('Task Type is required'),
    // taskTypeItem: yup.string().trim().required('Task Type Item is required'),
    partsDescriptionId: yup.string().trim().required('Part Description ID is required'),
    isActive: yup.boolean().required('Status is required'),
  });
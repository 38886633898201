import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { INonExistencePartNumber, IParts } from './parts-utils'

const PartsMap = {
    'Part Number' : 'partNumber',
    'Model' : 'model',
    'System' : 'system',
    'Sub-system' : 'subsystem',
    'Description' : 'description',
    'Make' : 'make',
    'Will fit or EOM' : 'willfitOrEOM',
    'Supplier' : 'supplier',
    'Unit of measurement' : 'unitOfMeasurement',
    'Package Cost' : 'packageCost',
    'Package Quantity' : 'packageQty',
    'Unit Cost' : 'unitCost',
    'Update Period' : 'updatePeriod',
    'Status' : 'status'
}

const PartsMapImport = {
    'partNumber' : 'partNumber',
    'model' : 'model',
    'system' : 'system',
    'subsystem' : 'subsystem',
    'description' : 'description',
    'make' : 'make',
    'willfitOrEOM' : 'willfitOrEOM',
    'supplier' : 'supplier',
    'unitOfMeasurement' : 'unitOfMeasurement',
    'packageCost' : 'packageCost',
    'packageQty' : 'packageQty',
    'updatePeriod' : 'updatePeriod',
  }

export const exportPartsToExcel = (data: IParts[], filename: string) => {
    const headings = [Object.keys(PartsMap)];
    const columnHeaders = [...Object.values(PartsMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    const formattedData = data.map(({isActive, partsDescriptionId, ...item}) => {
      return {
        ...item,
        status : isActive ? 'Active' : 'Inactive'
      };
    });
    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };

  export const GetTemplateParts = (data: any, filename: string) => {
    const headings = [Object.keys(PartsMapImport)];
    const columnHeaders = [...Object.values(PartsMapImport)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    const formattedData = data.data.map(({...item}) => {
      return {
        ...item,
        partNumber : item.partNumber
      };
    });
    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20
    }));
  
    worksheet['!cols'] = wscols;

    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { EURActivityFields, IEURActivity } from "../utils/types";

interface SMRContextType {
  opendialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  newActivity: IEURActivity[]; // Specify the type as an array of IEURActivity
  setNewActivity: Dispatch<SetStateAction<IEURActivity[]>>;
  newActivityEdit: EURActivityFields[];
  setNewActivityEdit: Dispatch<SetStateAction<EURActivityFields[]>>;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  isEditting: boolean;
  setIsEditting: Dispatch<SetStateAction<boolean>>;
}

const SMRContext = createContext<SMRContextType | undefined>(undefined);

export const SMRProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [opendialog, setOpenDialog] = useState<boolean>(false);
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [newActivity, setNewActivity] = useState<IEURActivity[]>([]);
  const [newActivityEdit, setNewActivityEdit] = useState<EURActivityFields[]>(
    []
  );
  const [type, setType] = useState<string>("new");

  return (
    <SMRContext.Provider
      value={{
        opendialog,
        setOpenDialog,
        newActivity,
        setNewActivity,
        newActivityEdit,
        setNewActivityEdit,
        type,
        setType,
        isEditting,
        setIsEditting,
      }}
    >
      {children}
    </SMRContext.Provider>
  );
};

export const useSMRContext = (): SMRContextType => {
  const context = useContext(SMRContext);
  if (!context) {
    throw new Error("useSMRContext must be used within a SMRProvider");
  }
  return context;
};

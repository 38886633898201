import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../react-query/queryClient';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router';
import { useSnackBar } from '../components/ToastProvider';
import { 
  IOnRent,
  IEXFForm,
  IEORRForm,
  IOnRentExport
} from '../modules/MyDashboardsPlusTasks/MyOnRentEquipments/utils/types';
import onRentApi from '../services/dashboardPlusTasks/onRentEquipments';
import { dateDifference } from '../utils/helpers';

export const useGetEquipmentsOnRentQuery = () => {
  return useQuery<IOnRent[]>(['onRents'], () => onRentApi.getOnRentEquipments(), {
    onError(err) {
      console.log(err);
    }
  });
}

export function useCreateEXF() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((exfData: Partial<IEXFForm>) => onRentApi.createEXF(id!, exfData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['onRents'] }).then(() => {
        showSnackBar('Successfully Created an EXF', 'success');
        navigate('/my-on-rent-equipment', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export function useCreateEORR() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  return useMutation((eorrData: Partial<IEORRForm>) => onRentApi.createEORR(id!, eorrData), {
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['onRents'] }).then(() => {
        showSnackBar('Successfully Created an EORR', 'success');
        navigate('/my-on-rent-equipment', { replace: true });
      });
    },
    onError(err) {
      const errorData = (err as AxiosError<{ error: string }>).response?.data.error;

      if (err && errorData) {
        showSnackBar(errorData, 'error');
      }
    }
  });
}

export const useFormatGetOnRentQuery = (data: IOnRentExport[]) => {
  const newOnRent = [];
  const OnRentList = data;
  const dateNow = new Date(Date.now());

  for(let i in OnRentList){
    let paramDate = new Date(OnRentList[i].emEndDate);
  
    let totalTime =  paramDate.getTime() - dateNow.getTime();
    let daysToExpire = Math.round(totalTime / (1000 * 3600 * 24));

    let json = {
      project : OnRentList[i].project,
      equipmentNo : OnRentList[i].equipmentNo,
      productFamily : OnRentList[i].productFamily,
      capacity : OnRentList[i].capacity,
      make: OnRentList[i].make,
      alert : OnRentList[i].alert ? OnRentList[i].alert : '-',
      arrivalDate : String(dateDifference(new Date(), OnRentList[i].arrivalDate)), //daysOnRent
      emEndDate: String(daysToExpire), //# of days to expire
      readyToWorkStatus : '-',
      alertDate : String(dateDifference(new Date(), OnRentList[i].alertDate)) // numberOfDaysSinceAlertStarted
    }
    newOnRent.push(json);
  }
  return newOnRent;
}
import { QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AuthProvider from './components/AuthProvider';
import RootLayout from './components/RootLayout';
import { SnackBarProvider } from './components/ToastProvider';
import AuthPage from './modules/Auth';
import ForgotPasswordPage from './modules/Auth/ForgotPasswordPage';
import LoginPage from './modules/Auth/LoginPage';
import SetPasswordPage from './modules/Auth/SetPasswordPage';
import { queryClient } from './react-query/queryClient';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Users
import AccountsPage from './modules/Users/Accounts/AccountsPage';
import AccountsList from './modules/Users/Accounts/AccountsList';
import AccountDeleteDialog from './modules/Users/Accounts/AccountDeleteDialog';
import AccountsForm from './modules/Users/Accounts/AccountsForm';

// Roles
import RolesPage from './modules/Users/Roles/RolesPage';
import RolesList from './modules/Users/Roles/RolesList';
import RoleForm from './modules/Users/Roles/RoleForm';
import RoleDeleteDialog from './modules/Users/Roles/RoleDeleteDialog';

// Dashboard + Tasks
import MyDashboardsPlusTasksRoutes from './modules/MyDashboardsPlusTasks/MyDashboardsPlusTasksRoutes';

// Reports
import ReportsRoutes from './modules/Reports/ReportsRoutes';

// Manage My Databases
import DatabaseManagementRoutes from './modules/DatabaseManagement/DatabaseManagementRouter';
import EquipmentForm from  './modules/DatabaseManagement/EquipmentDBs/Equipment/EquipmentForm';

// Home Page
import DashboardRoutes from './modules/Dashboard/DashboardRoutes';

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    path: '',
    children: [
      DashboardRoutes,
      {
        path: 'users',
        element: <AccountsPage />,
        children: [
          {
            path: '',
            element: <AccountsList />,
            children: [{ path: 'delete/:id', element: <AccountDeleteDialog /> }],
          },
        ],
      },
      { path: 'users/new', element: <AccountsForm /> },
      { path: 'users/edit/:id', element: <AccountsForm /> },
      {
        path: 'roles',
        element: <RolesPage />,
        children: [
          {
            path: '',
            element: <RolesList />,
            children: [{ path: 'delete/:id', element: <RoleDeleteDialog /> }],
          },
        ],
      },
      { path: 'roles/new', element: <RoleForm /> },
      { path: 'roles/edit/:id', element: <RoleForm /> },

      // My Dashboard + Tasks
      MyDashboardsPlusTasksRoutes,
      // Reports
      ReportsRoutes,
      // Manage databases
      DatabaseManagementRoutes,
      { path: 'equipment-new', element: <EquipmentForm /> },
      { path: 'equipment-new/:id', element: <EquipmentForm /> },
    
    ],
  },
  {
    path: '/auth/*',
    element: <AuthPage />,
    children: [
      {
        path: '',
        element: <LoginPage />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'reset-password/:token',
        element: <SetPasswordPage />,
      },
    ],
  },
]);

export const App = () => {
  return (
    <SnackBarProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </SnackBarProvider>
  );
};

import { Stack, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { IHECertificateType } from './HECertificateType-utils';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import HEOpratorDeleteModal from './HECertificateTypeDeleteModal';

interface Ownprops {
  HECertificateType: IHECertificateType;
}

const HECertificateTypeActionButton = ({ HECertificateType }: Ownprops) => {
  const { id } = HECertificateType;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false)

  const goToHECertificateTypeForm = () => navigate(`/he-certificate-type/${id}`);
  const goToModalDelete = () => setOpen(true);
  
  return (
    <Stack direction='row' gap={1}>
      <Tooltip title='Edit' placement='top'>
        <IconButton color='primary' aria-label='edit' onClick={goToHECertificateTypeForm}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Delete' placement='top'>
        <IconButton color='warning' aria-label='delete' id="test-gomadaldelete" onClick={goToModalDelete}>
          <Delete />
        </IconButton>
      </Tooltip>
      <HEOpratorDeleteModal setOpen={setOpen} open={open} HECertificateTypes={HECertificateType} />
    </Stack>
  );
};

export default HECertificateTypeActionButton;

import { Outlet } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import { flexRender } from "@tanstack/react-table";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from "../../../../../components/common/app-table/ui";
import useDefaultTable from "../../../../../components/common/app-table/useDefaultTable";
import { useEJOManagementContext } from "../Context/EJOManagementContext";
import React, { useState } from "react";
import { EJOSR } from "./utils/types";
import { Button } from "@mui/material";
import { useCreateSRColumns } from "./hooks/useCreateSRColumns";
import { appColors } from "../../../../../theme";

interface EJOSRTableProps {
  equipmentId: string;
}

const EJOSRTable: React.FC<EJOSRTableProps> = ({ equipmentId }) => {
  const { setOpenDialog, setSrActionType, serviceRequestsArray } =
    useEJOManagementContext();
  const ejoSRManagementColumns = useCreateSRColumns();

  const { table, dataCount, pageIndex, pageSize } = useDefaultTable<EJOSR>(
    serviceRequestsArray,
    ejoSRManagementColumns
  );

  const [openWarningSrDialog, setOpenWarningSrDialog] =
    useState<boolean>(false);

  const goToAddSr = () => {
    if (!equipmentId) {
      setOpenWarningSrDialog(true);
    } else {
      setOpenDialog(true);
      setSrActionType("new");
    }
  };

  return (
    <>
      <Stack direction="column" mb={2} mt={5} gap={3}>
        <Stack direction="row" justifyContent="space-between">
          <Button
            disableElevation
            sx={{
              textTransform: "capitalize",
              fontSize: "9pt",
              bgcolor: "primary",
              color: "white",
              padding: 0.5,
              paddingX: 2.5,
              minWidth: "auto",
              borderRadius: "3px",
              "&:hover": {
                bgcolor: "primary",
              },
            }}
            onClick={() => goToAddSr()}
          >
            Add Service Request
          </Button>
        </Stack>
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align="center">
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody dataCount={dataCount}>
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align="center">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position="relative">
          <TablePagination
            sx={{ bgcolor: "white", borderTop: "1px solid #eee" }}
            component="div"
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) =>
              table.setPageSize(Number(e.target.value))
            }
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
      <Outlet />
      {/* NO EQUIPMENT WARNING */}
      <Dialog fullWidth={true} maxWidth={"sm"} open={openWarningSrDialog}>
        <DialogTitle>
          <Typography
            fontSize={22}
            fontWeight={500}
            color={appColors.primary}
            textAlign={"left"}
          >
            Equipment is empty
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: "2em" }}>
          <Box>
            <Typography variant="body1">
              Please select equipment before adding service requests!
            </Typography>
          </Box>
          <Box sx={{ width: "100%", display: "flex" }}>
            <Button
              onClick={() => setOpenWarningSrDialog(false)}
              sx={{ width: 150, marginLeft: "auto", marginTop: "1.5em" }}
            >
              Ok
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(EJOSRTable);

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { EJOSR } from "../EJO/EJO-SR/utils/types";
import { EJORequestBodyApproval } from "../EJO/utils/types";

interface EJOESRApproval {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  openConfirmDeleteSRDialog: boolean;
  setOpenConfirmDeleteSRDialog: Dispatch<SetStateAction<boolean>>;
  serviceRequestsArray: EJOSR[];
  setServiceRequestsArray: Dispatch<SetStateAction<EJOSR[]>>;
  currentSRSelected: EJOSR;
  setCurrentSRSelected: Dispatch<SetStateAction<EJOSR>>;
  currentEJOSelected: EJORequestBodyApproval;
  setCurrentEJOSelected: Dispatch<SetStateAction<EJORequestBodyApproval>>;
  srActionType: string;
  setSrActionType: Dispatch<SetStateAction<string>>;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  isViewOnly: boolean;
  setViewOnly: Dispatch<SetStateAction<boolean>>;
}

const EJOESRApprovalContext = createContext<EJOESRApproval | undefined>(
  undefined
);

export const EJOESRApprovalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openConfirmDeleteSRDialog, setOpenConfirmDeleteSRDialog] =
    useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isViewOnly, setViewOnly] = useState<boolean>(true);
  const [serviceRequestsArray, setServiceRequestsArray] = useState<EJOSR[]>([]);
  const [currentSRSelected, setCurrentSRSelected] = useState<EJOSR>({
    id: "",
    serviceRequest: "",
    serviceRequestTypeId: "",
    remarks: "",
  });
  const [currentEJOSelected, setCurrentEJOSelected] =
    useState<EJORequestBodyApproval>({
      ejoCreator: "",
      dateCreated: Date(),
      dateNeeded: Date(),
      projectLocationId: "",
      equipmentId: "",
      smrMileage: 0,
      isOperable: false,
      serviceRequestList: [],
    });
  const [srActionType, setSrActionType] = useState<string>("new");

  return (
    <EJOESRApprovalContext.Provider
      value={{
        openDialog,
        setOpenDialog,
        openConfirmDeleteSRDialog,
        setOpenConfirmDeleteSRDialog,
        serviceRequestsArray,
        setServiceRequestsArray,
        currentSRSelected,
        setCurrentSRSelected,
        currentEJOSelected,
        setCurrentEJOSelected,
        srActionType,
        setSrActionType,
        isEditing,
        setIsEditing,
        isViewOnly,
        setViewOnly,
      }}
    >
      {children}
    </EJOESRApprovalContext.Provider>
  );
};

export const useEJOESRApprovalContext = (): EJOESRApproval => {
  const context = useContext(EJOESRApprovalContext);
  if (!context) {
    throw new Error(
      "useEJOESRApprovalContext must be used within a EJOESRApprovalProvider"
    );
  }
  return context;
};

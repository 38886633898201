import PageContainer from '../../../../components/common/PageContainer';
import EquipmentTable from './EquipmentTable';

function EquipmentPage() {
  return (
    <>
      <PageContainer title='Equipment Databases' subTitle='Equipment Master' showDate>
        Equipment Master
        <EquipmentTable />
      </PageContainer>
    </>
  );
}

export default EquipmentPage;

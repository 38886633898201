import React from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Stack, TextField, Typography } from "@mui/material";
import { appColors } from "../../../theme";

interface AppDatePickerProps {
  error?: boolean;
  helperText?: string;
  label: string;
  labelPosition?: "top" | "left";
  labelAlignment?: "center" | "inherit" | "right" | "left" | "justify";
  labelWidth?: string;
  fieldWidth?: string;
  fontWeight?: number;
  name: string;
  required?: boolean;
  disabled?: boolean;
  value: Date | null | string;
  minDate?: Date | undefined;
  onChange: (value: Date | null) => void;
}

const AppDatePicker = (props: AppDatePickerProps) => {
  const {
    error,
    helperText,
    value,
    onChange,
    label,
    labelPosition,
    labelAlignment,
    labelWidth,
    fieldWidth,
    required,
    name,
    fontWeight,
    disabled,
    minDate,
  } = props;
  const dateValue = typeof value === "string" ? new Date(value) : value;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack
        direction={labelPosition && labelPosition === "top" ? "column" : "row"}
        gap={labelPosition && labelPosition === "top" ? 0.5 : 1.5}
        justifyContent="space-between"
        alignItems={
          labelPosition && labelPosition === "top" ? "flex-start" : "center"
        }
        width="100%"
      >
        <Typography
          htmlFor={name}
          aria-required={required}
          color={appColors.formText}
          component="label"
          // mt={1.5}
          fontWeight={fontWeight ? fontWeight : 600}
          fontSize={labelPosition && labelPosition === "top" ? 13 : 15}
          width={labelWidth ? labelWidth : 130}
          align={labelAlignment ? labelAlignment : "right"}
          whiteSpace="nowrap"
        >
          {label}&nbsp;
          {required && (
            <Typography component="small" color="error.main">
              *
            </Typography>
          )}
        </Typography>
        <Stack width={fieldWidth ? fieldWidth : 400}>
          <DesktopDatePicker
            value={dateValue}
            disableOpenPicker={disabled ? disabled : false}
            disabled={disabled ? disabled : false}
            onChange={onChange}
            minDate={minDate}
          />
          <div style={{ width: "100%", marginLeft: "10px" }}>
            {error && (
              <Typography
                component="small"
                color="error.main"
                style={{ fontSize: "14px", textAlign: "left", float: "left" }}
              >
                {helperText}
              </Typography>
            )}
          </div>
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
};

export default AppDatePicker;

import { createColumnHelper } from "@tanstack/react-table";
import { EJOSRManagementColumns } from "../utils/types";
import { IconButton, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEJOManagementContext } from "../../Context/EJOManagementContext";

export const useCreateSRColumns = () => {
  const {
    setOpenDialog,
    setCurrentSRSelected,
    setSrActionType,
    setOpenConfirmDeleteSRDialog,
  } = useEJOManagementContext();

  const columnHelper = createColumnHelper<EJOSRManagementColumns>();

  const ejoSRManagementColumns = [
    columnHelper.accessor("serviceRequestTypeId", {
      header: "",
      cell: () => null,
      meta: {
        hidden: true,
      },
    }),

    columnHelper.accessor("id", {
      header: "No.",
      cell: ({ row }) => row.index + 1,
      meta: {
        hidden: true,
      },
    }),
    columnHelper.accessor("serviceRequest", {
      id: "serviceRequest",
      header: "Service Requested",
      cell: (row) => row.getValue(),
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),

    //* Will render pmCode if remarks is null
    columnHelper.display({
      id: "remarks",
      header: "Remarks",
      cell: ({ row }) => {
        const remarks = row.original.remarks; // Get the value of remarks
        const pmCode = row.original.pmCode; // Get the value of pmCode

        // Conditionally render remarks or pmCode
        return remarks === null || remarks === "" ? pmCode : remarks;
      },
      enableGlobalFilter: true,
      enableColumnFilter: true,
    }),
    columnHelper.display({
      id: "actions",
      header: "Action",
      cell: ({ row }) => (
        <Stack direction="row" gap="2">
          <IconButton
            color="primary"
            onClick={() => {
              const tempId = String(row.index);
              setCurrentSRSelected({
                id: row.original.id ? row.original.id : tempId,
                pmCode: row.original.pmCode,
                pmCodeId: row.original.pmCodeId,
                remarks: row.original.remarks,
                serviceRequest: row.original.serviceRequest,
                serviceRequestTypeId: row.original.serviceRequestTypeId,
              });
              setOpenDialog(true);
              setSrActionType("update");
            }}
          >
            <EditIcon fontSize="medium" />
          </IconButton>

          <IconButton
            color="primary"
            onClick={() => {
              const tempId = String(row.index);

              setCurrentSRSelected({
                id: tempId,
                pmCode: row.original.pmCode,
                pmCodeId: row.original.pmCodeId,
                remarks: row.original.remarks,
                serviceRequest: row.original.serviceRequest,
                serviceRequestTypeId: row.original.serviceRequestTypeId,
              });
              setOpenConfirmDeleteSRDialog(true);
            }}
          >
            <DeleteIcon fontSize="medium" />
          </IconButton>
        </Stack>
      ),
      enableHiding: false,
    }),
  ];

  return ejoSRManagementColumns;
};

import { Outlet } from "react-router-dom";
import PageContainer from "../../../../components/common/PageContainer";

const EJOManagementPage = () => {
  return (
    <>
      <PageContainer
        title="My Dashboard + Tasks"
        subTitle="EJO Management"
        showDate
      >
        <Outlet />
      </PageContainer>
    </>
  );
};

export default EJOManagementPage;

import { createColumnHelper } from '@tanstack/react-table';
import { IFueltype } from './utils/FuelType-utils';
import FuelTypeActionButton from './utils/FuelTypeActionsButtons';
import Chip from '@mui/material/Chip';

const columnHelper = createColumnHelper<IFueltype>();

const FuelTypeColumn = [
    columnHelper.accessor('id', {
        id: 'id',
        header: 'Item No',
        cell: ({row}) => row.index +1,
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('isActive', {
        id: 'isActive',
        header: 'Status',
        cell: (row) => (
            <Chip
                label={row.getValue() ? 'Active' : 'Inactive'}
                color={row.getValue() ? 'primary' : 'warning'}
                style={{ color: row.getValue() ? 'white' : 'white', height: '25px' }}
            />
        ),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.display({
        id: 'actions',
        header: 'Action',
        cell: ({ row }) => (
            <FuelTypeActionButton fueltypes={row.original} />
        ), 
        enableHiding: false,
    }),
    
    
];

export default FuelTypeColumn;

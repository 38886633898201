import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import { flexRender } from '@tanstack/react-table';
import {
  AppTableContainer,
  AppTable,
  AppTableHead,
  AppTableRow,
  AppTableCell,
  AppTableSort,
  AppTableBody,
} from '../../../components/common/app-table/ui';
import useDefaultTable from '../../../components/common/app-table/useDefaultTable';
import AppSearchField from '../../../components/common/forms/AppSearchField';
import LoadingIndicator from '../../../components/common/LoadingIndicator';
import { ExportButton } from '../../../components/common/primebmd-ui/DataButtons';
import { onRentColumns, OnRentItem } from './columns';
import { useGetOnRentEquipmentQuery } from '../../../hooks/useHome'; 
import ColumFilterMenu from '../../../components/common/primebmd-ui/ColumnFilterMenu';
import { exportOnRentDataToExcel } from '../export/export';

const OnRentListing = () => {
  const onRent = useGetOnRentEquipmentQuery();
  const data = onRent.data ? onRent.data : [];

  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } =
    useDefaultTable<OnRentItem>(data, onRentColumns);

  const emptyText = 'No available data';

  const handleExport = () => {    
    const selectedRows = table.getSelectedRowModel().rows;
    const hasSelectedRows = selectedRows.length > 0;
    const dataToExport = hasSelectedRows ? selectedRows.map((row) => row.original) : data;
    exportOnRentDataToExcel(dataToExport, 'On Rent Equipment Report');
  };

  if (onRent?.isFetching || onRent?.isLoading) return <LoadingIndicator show />;

  return (
    <>
      <Stack direction='row' justifyContent='space-between' gap={2} my={2}>
        <AppSearchField searchKeyword={searchFilter} onSearchKeywordChange={setSearchFilter} />
        <Stack direction='row' gap={2}>
          <ColumFilterMenu columns={table.getAllLeafColumns()} />
          <ExportButton onClick={handleExport} />
        </Stack>
      </Stack>
      <Paper elevation={0} sx={{ border: '1px solid #eee' }}>
        <AppTableContainer>
          <AppTable stickyHeader>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody
              dataCount={dataCount}
              // loading={loading}
              emptyText={emptyText}
            >
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      <Stack direction='row' gap={2}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Stack>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
    </>
  );
}

export default OnRentListing;

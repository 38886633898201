import Stack from "@mui/material/Stack";

import { appColors } from "../../theme";
import Drawer from "../../components/layout-ui/Drawer";
import MiniLogo from "../../assets/images/logo-full.jpeg";

import PrimeBMDLogo from "../../assets/images/logo-full.jpeg";
import { useLayout } from "../../components/layout-ui/layoutContext";
import SideNavItem from "../../components/sidebar/new/SideNavItem";
import { Box } from "@mui/material";
import { SideNavProvider } from "../../components/sidebar/new/SideNavContext";
import useSidebarMenuItems from "../../hooks/useSidebarMenu";
import { useAuth } from "../../components/AuthProvider";

const AppSidebarHeader = () => {
  const { sidebarOpen, collapseToWidth } = useLayout();

  const smallLogoShown = !sidebarOpen && collapseToWidth;
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      bgcolor="white"
      height={64}
    >
      {smallLogoShown ? (
        <Stack height={32} p={0.5}>
          <img src={MiniLogo} alt="PrimeBMD" height={24} />
        </Stack>
      ) : (
        <Stack>
          <img src={PrimeBMDLogo} alt="PrimeBMD" height={56} width={170} />
        </Stack>
      )}
    </Stack>
  );
};

const AppSidebar = () => {
  const menu = useSidebarMenuItems();

  return (
    <Drawer bgcolor={appColors.sidebarBg}>
      <AppSidebarHeader />
      <SideNavProvider>
        <Box pt={3} maxHeight="calc(100vh - 64px)" overflow="auto">
          {menu.map((menuItem) => (
            <SideNavItem key={menuItem.label} menuItem={menuItem} />
          ))}
        </Box>
      </SideNavProvider>
    </Drawer>
  );
};

export default AppSidebar;

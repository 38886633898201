import { createColumnHelper } from '@tanstack/react-table';
import { IHEOperator } from './utils/HEOperator-utils';
import HEOperatorActionButton from './utils/HEOperatorActionsButtons';
import Chip from '@mui/material/Chip';

const columnHelper = createColumnHelper<IHEOperator>();

const HEOperatorColumn = [
    columnHelper.accessor('id', {
        id: 'id',
        header: 'Item No',
        cell: ({row}) => row.index +1,
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('operatorName', {
        id: 'operatorName',
        header: 'Operator Name',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('productFamily', {
        id: 'productFamily',
        header: 'Product Family',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('licenseType', {
        id: 'licenseType',
        header: 'License Type',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('dateReceived', {
        id: 'dateReceived',
        header: 'Date Received',
        cell: (row) => {
            const value = row.getValue();
            if (value instanceof Date) {
                return value.toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
            } else if (typeof value === 'string' && !isNaN(Date.parse(value))) {
                const date = new Date(value);
                return date.toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
            } else {
                return '--';
            }
        },
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('isActive', {
        id: 'isActive',
        header: 'Status',
        cell: (row) => (
            <Chip
                label={row.getValue() ? 'Active' : 'Inactive'}
                color={row.getValue() ? 'primary' : 'warning'}
                style={{ color: row.getValue() ? 'white' : 'white', height: '25px' }}
            />
        ),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.display({
        id: 'actions',
        header: 'Action',
        cell: ({ row }) => (
            <HEOperatorActionButton HEOperator={row.original} />
        ), 
        enableHiding: false,
    }),
    
    
];

export default HEOperatorColumn;

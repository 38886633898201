import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AppTextField from "../../../../../../components/common/forms/AppTextField";
import { useEJOESRApprovalContext } from "../../Context/EJOESRApprovalContext";
import { useGetServiceRequestTypeQuery } from "../../../../../../hooks/useServiceRequestType";
import { useGetESRByID } from "../../../../../../hooks/useESR";
import { EJOSRManagementInitialValues, EJOSRSchema } from "./utils/schema";
import { EJOSR, EJOSRFormFields } from "./utils/types";
import {
  useGetPMSCodesByModel,
  useGetPMSCodesQuery,
} from "../../../../../../hooks/usePMSCode";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { appColors } from "../../../../../../theme";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackBar } from "../../../../../../components/ToastProvider";

interface EJOSRProp {
  equipmentModel: string | undefined;
}

const EJOSRManagementForm: React.FC<EJOSRProp> = ({ equipmentModel }) => {
  const { showSnackBar } = useSnackBar();
  const {
    openDialog,
    setOpenDialog,
    serviceRequestsArray,
    setServiceRequestsArray,
    currentSRSelected,
    setCurrentSRSelected,
    srActionType,
    setIsEditing,
  } = useEJOESRApprovalContext();
  const srDataForEdit = useGetESRByID();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<EJOSRFormFields>({
    defaultValues: srDataForEdit
      ? srDataForEdit.data
      : EJOSRManagementInitialValues,
    mode: "onChange",
    resolver: yupResolver(EJOSRSchema),
  });

  const currentSRTypeSelected: string = watch("serviceRequest");
  const [isPmServiceSelected, setIsPmServiceSelected] = useState(false);

  const serviceRequests = useGetServiceRequestTypeQuery();
  const pmsCodeData = useGetPMSCodesByModel(String(equipmentModel));

  const serviceRequestOptions: any[] = useMemo(() => {
    if (serviceRequests?.data === undefined) {
      return [];
    }

    const serviceRequestOptionsArray = serviceRequests.data
      .map((e) => {
        //* If there are no pm code found
        if (pmsCodeData.data?.length == 0) {
          //* Filter the Service Request Type PM Service to prevent PM Code select from appearing
          if (e.requestName === "PM Service") {
            return null;
          } else {
            return {
              id: e.id,
              label: e.requestName,
              value: e.id,
            };
          }
        } else {
          //* If there's a pm code found, just return the data as usual
          return {
            id: e.id,
            label: e.requestName,
            value: e.id,
          };
        }
      })
      .filter((option) => option !== null)
      .sort((a, b) => {
        // Default to empty string if A OR B is null or undefined
        const labelA = a?.label ?? "";
        const labelB = b?.label ?? "";
        return labelA.localeCompare(labelB);
      });

    return serviceRequestOptionsArray;
  }, [pmsCodeData.data]);

  const pmsCodeOptions: any[] =
    pmsCodeData?.data === undefined
      ? []
      : pmsCodeData.data.map((e) => {
          return {
            id: e.id,
            label: e.pmCode,
            value: e.id,
          };
        });

  const handleClose = () => {
    setValue("remarks", "");
    setValue("serviceRequest", "");
    setCurrentSRSelected({
      id: "",
      serviceRequest: "",
      serviceRequestTypeId: "",
      remarks: "",
    });
    setOpenDialog(false);
  };

  const onSubmit = (formData: EJOSRFormFields) => {
    const number = serviceRequestsArray.length + 1;
    const serviceRequested = serviceRequestOptions.find(
      (service) => formData.serviceRequest == service.id
    );

    let pmCodeFound = pmsCodeOptions.find((pm) => formData.pmCodeId == pm.id);

    const addToServiceRequestsArray = () => {
      const newSr: EJOSR = {
        id: number.toString(),
        serviceRequest: serviceRequested.label,
        serviceRequestTypeId: serviceRequested.id,
        remarks: !formData.remarks ? "" : formData.remarks,
        pmCodeId: !pmCodeFound ? "" : pmCodeFound.id,
        pmCode: !pmCodeFound ? "" : pmCodeFound.label,
      };
      if (!formData.remarks && !newSr.pmCodeId && !newSr.pmCode) {
        showSnackBar("Please fill out all the fields", "error");
      } else {
        setServiceRequestsArray((prev: EJOSR[]) => {
          return [...prev, newSr];
        });

        setIsEditing(true);
        handleClose();
      }
    };

    const updateServiceRequestArray = (index: number, newValue: EJOSR) => {
      if (!newValue.remarks && !newValue.pmCode && !newValue.pmCodeId) {
        showSnackBar("Please fill out all the fields", "error");
      } else {
        const newArray: EJOSR[] = [...serviceRequestsArray];

        newArray[index] = newValue;

        setServiceRequestsArray(newArray);
        setIsEditing(true);
        setCurrentSRSelected({
          id: "",
          serviceRequest: "",
          serviceRequestTypeId: "",
          remarks: "",
        });
        handleClose();
      }
    };

    if (srActionType == "new") {
      addToServiceRequestsArray();
    }

    if (srActionType == "update") {
      const newSr: EJOSR = {
        id: currentSRSelected.id,
        serviceRequest: serviceRequested.label,
        serviceRequestTypeId: serviceRequested.id,
        remarks: !formData.remarks ? "" : formData.remarks,
        pmCodeId: !pmCodeFound ? "" : pmCodeFound.id,
        pmCode: !pmCodeFound ? "" : pmCodeFound.label,
      };

      serviceRequestsArray.forEach((sr, index) => {
        if (sr.id == newSr.id) {
          updateServiceRequestArray(index, newSr);
        }
      });
    }
  };

  //* Change the remarks text field into PM Code select if PM Service is selected
  useEffect(() => {
    const srSelected = serviceRequestOptions.find(
      (sr) => sr.id == currentSRTypeSelected
    );

    if (srSelected) {
      // For changing ui, from textfield to select if pm service is selected
      if (srSelected.label.toLowerCase().trim() == "pm service") {
        setIsPmServiceSelected(true);
      } else {
        setIsPmServiceSelected(false);
        //* If not remove the value of pmCode and pmCodeId to avoid passing to request body
        setValue("pmCode", "");
        setValue("pmCodeId", "");
        //* Clear the textfield if you change service request type
        if (currentSRSelected.remarks != watch("remarks")) {
          setValue("remarks", "");
        }
      }
    }
  }, [currentSRTypeSelected]);

  useEffect(() => {
    if (!currentSRSelected) {
      console.log("Show the error");
      return;
    } else {
      setValue("serviceRequest", currentSRSelected.serviceRequestTypeId);
      setValue("serviceRequestTypeId", currentSRSelected.serviceRequestTypeId);
      setValue("pmCodeId", currentSRSelected.pmCodeId);
      setValue("pmCode", currentSRSelected.pmCodeId);
      setValue("remarks", currentSRSelected.remarks);
    }
  }, [currentSRSelected]);

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={openDialog}>
      <DialogTitle>
        <Typography
          fontSize={22}
          fontWeight={500}
          color={appColors.primary}
          textAlign={"left"}
        >
          Service Request
        </Typography>

        <IconButton
          onClick={handleClose}
          disableRipple
          aria-label="close"
          size="small"
          sx={{ position: "absolute", top: 12, right: 12 }}
        >
          <CloseIcon fontSize="small" sx={{ color: "#222" }} />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: "2em" }}>
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".50em",
            m: "auto",
            width: "auto",
          }}
        >
          <Controller
            control={control}
            name="serviceRequest"
            render={({ field }) => (
              <>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  sx={{
                    width: "100%",
                    "& .MuiStack-root": {
                      marginBottom: ".25em",
                    },
                  }}
                >
                  <Typography
                    color={appColors.formText}
                    component="label"
                    htmlFor={"serviceRequest"}
                    fontSize={15}
                    width={"40%"}
                    align={"right"}
                    whiteSpace="nowrap"
                    maxWidth="sm"
                    fontWeight={600}
                    sx={{ paddingRight: ".9em" }}
                  >
                    Service Request Type
                  </Typography>
                  <Select
                    {...field}
                    id="serviceRequest"
                    label="serviceRequest"
                    placeholder="Select here"
                    error={!!errors.serviceRequest}
                    sx={{
                      width: "60%",
                      fontSize: "10pt",
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200, // Set the maximum height for the dropdown
                          width: 60, // Set the width of the dropdown
                        },
                      },
                    }}
                  >
                    <MenuItem disabled>Select here</MenuItem>
                    {serviceRequestOptions.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.value}
                        sx={{ fontSize: "9pt" }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
                <FormHelperText
                  sx={{
                    color: (theme) => theme.palette.error.main,
                    marginLeft: "auto",
                    paddingLeft: "1em",
                    width: "60%",
                    my: ".20em",
                  }}
                >
                  {errors.serviceRequest?.message}
                </FormHelperText>
              </>
            )}
          />

          {isPmServiceSelected === false ? (
            <AppTextField
              label="Remarks"
              labelWidth="39%"
              fieldWidth="61%"
              error={!!errors.remarks}
              helperText={errors.remarks?.message}
              {...register("remarks")}
            />
          ) : (
            <Controller
              control={control}
              name="pmCodeId"
              render={({ field }) => (
                <>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={{
                      width: "100%",
                      "& .MuiStack-root": {
                        marginBottom: ".25em",
                      },
                    }}
                  >
                    <Typography
                      color={appColors.formText}
                      component="label"
                      htmlFor={"pmCodeId"}
                      fontSize={15}
                      width={"40%"}
                      align={"right"}
                      whiteSpace="nowrap"
                      maxWidth="sm"
                      fontWeight={600}
                      sx={{ paddingRight: ".9em" }}
                    >
                      PM Code
                    </Typography>
                    <Select
                      {...field}
                      id="pmCodeId"
                      label="pmCodeId"
                      placeholder="Select here"
                      error={!!errors.pmCode}
                      sx={{
                        width: "60%",
                        fontSize: "10pt",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            width: 60,
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select here
                      </MenuItem>
                      {pmsCodeOptions.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.value}
                          sx={{ fontSize: "9pt" }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                  <FormHelperText
                    sx={{
                      color: (theme) => theme.palette.error.main,
                      marginLeft: "auto",
                      paddingLeft: "1em",
                      width: "60%",
                      my: ".20em",
                    }}
                  >
                    {errors.remarks?.message}
                  </FormHelperText>
                </>
              )}
            />
          )}

          <div style={{ display: "none" }}>
            <AppTextField
              label="serviceRequestTypeId"
              required={true}
              error={!!errors.serviceRequestTypeId}
              helperText={errors.serviceRequestTypeId?.message}
              hidden={true}
              {...register("serviceRequestTypeId")}
            />
          </div>
        </Box>
        <Box sx={{ width: "100%", display: "flex" }}>
          <Button
            onClick={handleSubmit(onSubmit)}
            sx={{ width: 150, marginLeft: "auto", marginTop: "1.5em" }}
          >
            Save
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EJOSRManagementForm;

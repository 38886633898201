import { useState } from 'react';
import { useGetAllEquipmentQuery, useGetEquipmentStatusQuery } from '../../hooks/useHome';

import { Box, ButtonBase, Collapse, Stack, Typography } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

import DeploymentStatusCard from './DeploymentStatusCard';
import { appColors } from '../../theme';

function DeploymentStatusSection() {
  const [expanded, setExpanded] = useState(true);

  const available = useGetEquipmentStatusQuery({ status: 'Available'});
  const onRent = useGetEquipmentStatusQuery({ status: 'On Rent'});
  const offRent = useGetEquipmentStatusQuery({ status: 'Off Rent'});
  const inTransit = useGetEquipmentStatusQuery({ status: 'In Transit'});
  const allEquipment = useGetAllEquipmentQuery();
  
  const availableData = available.data ? available.data : [];
  const onRentData = onRent.data ? onRent.data : [];
  const offRentData = offRent.data ? offRent.data : [];
  const inTransitData = inTransit.data ? inTransit.data : [];
  const allEquipmentData = allEquipment.data ? allEquipment.data : [];
  

  const totalLength = availableData.length + onRentData.length + offRentData.length + inTransitData.length;

  const toggleExpanded = () => setExpanded((state) => !state);

  return (
    <Box mt={2}>
      <ButtonBase
        onClick={toggleExpanded}
        sx={{
          color: appColors.titleColor,
          padding: '4px 8px',
          borderRadius: '4px',
          marginBottom: '16px',
        }}
      >
        <Typography fontSize={20} fontWeight='bold'>
          Deployment Status
        </Typography>
        {expanded ? <ChevronRight /> : <ExpandMore />}
      </ButtonBase>
      <Collapse in={expanded}>
        <Stack direction='row' gap={4}>
        <DeploymentStatusCard
            status='All'
            value={allEquipmentData.length}
            path='/home/all'
          />
          <DeploymentStatusCard 
            status='On Rent' 
            value={onRentData.length} 
            percentage={Math.round((100/totalLength) * onRentData.length)*100/100} 
            path='/home/on-rent' 
          />
          <DeploymentStatusCard
            status='Available'
            value={availableData.length}
            percentage={Math.round((100/totalLength) * availableData.length)*100/100}
            path='/home/available'
          />
          <DeploymentStatusCard 
            status='Off Rent'
            value={offRentData.length} 
            percentage={Math.round((100/totalLength) * offRentData.length)*100/100} 
            path='/home/off-rent' 
          />
          <DeploymentStatusCard
            status='In Transit'
            value={inTransitData.length}
            percentage={Math.round((100/totalLength) * inTransitData.length)*100/100}
            path='/home/in-transit'
          />
        </Stack>
      </Collapse>
    </Box>
  );
}

export default DeploymentStatusSection;

import { useMutation, useQuery } from "@tanstack/react-query";
import {
  IEJOApproval,
  IESRApproval,
} from "../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/utils/types";
import { dateDifference } from "../utils/helpers";
import ejoAPI from "../services/dashboardPlusTasks/ejoApproval";
import { EJOSR } from "../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/shared/EJO/EJO-SR/utils/types";
import { EJORequestBodyApproval } from "../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/shared/EJO/utils/types";
import { useSnackBar } from "../components/ToastProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useEJOESRApprovalContext } from "../modules/MyDashboardsPlusTasks/EJO-ESR-Approval/shared/Context/EJOESRApprovalContext";
import { queryClient } from "../react-query/queryClient";
import axios, { AxiosError } from "axios";

interface ErrorResponseData {
  error: string;
}

export function useGetEJOQuery(status?: string) {
  return useQuery<IEJOApproval[]>(["ejos"], () => ejoAPI.getAllEJO(status), {
    onError(err) {
      console.log(err);
    },
  });
}

export const useFormatGetEJOQuery = (data: IEJOApproval[], status: string) => {
  const newEJOList = [];

  const EJOList = data.map((e) => {
    const daysElapsedVariable =
      status === "Approved" ? e.dateApproved : e.dateCreated;

    return {
      id: e.id,
      ejoNumber: e.ejoNumber,
      equipmentNo: e.equipmentNo,
      projectLocation: e.projectLocation.name,
      daysElapsed: dateDifference(new Date(), daysElapsedVariable),
    };
  });

  for (const n of EJOList) {
    newEJOList.push(n);
  }

  return newEJOList;
};

export const useFormatGetEJOWithoutESRQuery = (
  ejoData: IEJOApproval[],
  esrData: IESRApproval[],
  status: string
) => {
  const ejoWithoutESR = ejoData.filter(
    (e) => !esrData.map((esr) => esr.ejoId).includes(e.id)
  );

  console.log(
    "EJO List: ",
    ejoData.map((ejo) => {
      return {
        id: ejo.id,
        ejoNo: ejo.ejoNumber,
        sr: ejo.serviceRequestList,
        srCount: ejo.serviceRequestList.length,
      };
    })
  );
  console.log(
    "ESR List: ",
    esrData.map((esr) => {
      return {
        id: esr.id,
        ejoId: esr.ejoId,
        ejoNo: esr.ejoNumber,
        sr: esr.serviceRequestId,
      };
    })
  );
  console.log(
    "EJO w/o ESR List: ",
    ejoWithoutESR.map((ejo) => {
      return {
        id: ejo.id,
        ejoNo: ejo.ejoNumber,
        sr: ejo.serviceRequestList,
        srCount: ejo.serviceRequestList.length,
      };
    })
  );

  const newEJOList = [];

  const EJOList = ejoWithoutESR.map((e) => {
    const daysElapsedVariable =
      status === "Approved" ? e.dateApproved : e.dateCreated;

    return {
      id: e.id,
      ejoNumber: e.ejoNumber,
      equipmentNo: e.equipmentNo,
      projectLocation: e.projectLocation.name,
      daysElapsed: dateDifference(new Date(), daysElapsedVariable),
    };
  });

  for (const n of EJOList) {
    newEJOList.push(n);
  }

  return newEJOList;
};

export const useGetAllServiceRequests = (id: string | undefined) => {
  return useQuery<EJOSR[]>(
    [`get-all-${id}`, id],
    () => ejoAPI.getAllServiceRequests(id!),
    {
      enabled: id ? true : false,
    }
  );
};

export const useGetCurrentEJOSelected = (id: string | undefined) => {
  return useQuery<EJORequestBodyApproval>(
    [`curr-ejo-${id}`, id],
    () => ejoAPI.getCurrentEJOById(id!),
    {
      enabled: id ? true : false,
    }
  );
};

export function useUpdateEJO() {
  const { showSnackBar } = useSnackBar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { setServiceRequestsArray } = useEJOESRApprovalContext();

  return useMutation(
    (updatedEJOData: EJORequestBodyApproval) => {
      if (updatedEJOData.serviceRequestList.length <= 0) {
        throw new Error("Service Request list cannot be empty");
      }

      return ejoAPI.updateEJO(id!, updatedEJOData);
    },
    {
      onSuccess(data) {
        queryClient.invalidateQueries({ queryKey: ["ejo", "esr"] }).then(() => {
          showSnackBar(
            `${data.ejoNumber} has been successfully approved`,
            "success"
          );
          setServiceRequestsArray([]);
          navigate("/ejo-and-esr-approval/ejo-pending", { replace: true });
        });
      },
      onError(err: unknown) {
        let errorMessage = "An unexpected error occurred.";

        if (err && axios.isAxiosError(err)) {
          const axiosError = err as AxiosError<ErrorResponseData>;
          errorMessage = axiosError.response?.data.error || axiosError.message;
        } else if (err instanceof Error) {
          errorMessage = err.message;
        }

        if (errorMessage !== "An unexpected error occurred.") {
          showSnackBar(errorMessage, "error");
        }
      },
    }
  );
}

import * as yup from 'yup';
import { InitialHECertificateType } from './HECertificateType-utils';


export const HECertificateTypeInitialValues: InitialHECertificateType = {
    certificateType: '',
    productFamilyId: '',
    isActive: 'true',
};

export const HECertificateTypeSchema = yup.object({
    certificateType: yup.string().trim().required('Certificate Type is required'),
    productFamilyId: yup.string().trim().required('Product Family is required'),
    isActive: yup.boolean().required('Status is required'),
  });
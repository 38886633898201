import { useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  SortingState,
  PaginationState,
  ColumnDef,
  FilterFn,
} from '@tanstack/react-table';

export default function useDefaultTableERF<T>(data: T[], columns: ColumnDef<T, any>[]) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  },

);
  const globalFilterFn: FilterFn<T> = (row, columnId, filterValue: string) => {
    const search = filterValue.toLowerCase();
  
    let value = row.getValue(columnId) as string;
    if (typeof value === 'number') value = String(value);
  
    return value?.toLowerCase().includes(search);
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: data ? data : [],
    columns,
    enableFilters: true,
    enableColumnFilters: true,
    enableRowSelection: true,
    enableGlobalFilter: true,
    
    state: {
      sorting,
      pagination,
      globalFilter: searchFilter,
      columnVisibility,
      rowSelection,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setSearchFilter,
    onRowSelectionChange: setRowSelection,

    onSortingChange: setSorting,
    globalFilterFn: globalFilterFn,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex:true,
  });

  return {
    table,
    dataCount: !searchFilter ? data.length : table.getRowModel().rows.length,
    searchFilter,
    setSearchFilter,
    pageIndex,
    pageSize,
  };
}
